require("dotenv").config();

export function discoverService(serviceName) {
  const value = process.env[`REACT_APP_${serviceName.toUpperCase()}`];

  if (!value) {
    throw new Error(
      `Env configuration ${`REACT_APP_${serviceName.toUpperCase()}`} missing`
    );
  }

  return value;
}
