import React from "react";
import PropTypes from "prop-types";

const InsuranceComplements = ({
  complement,
  handleInput,
  handleBrochureInput,
}) => {
  const { name = "", fee = "", coverage = "", brochures = [] } = complement;
  return (
    <div className="mt-5">
      <hr />
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Name Complement*</label>
        <div className="col-sm-4">
          <input
            required
            defaultValue={name}
            type="text"
            className="form-control"
            placeholder="Name Complement"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "name",
              })
            }
          />
        </div>
        <label className="col-sm-2 col-form-label">Fee Complement*</label>
        <div className="col-sm-4">
          <input
            required
            defaultValue={fee}
            type="number"
            step=".01"
            className="form-control"
            placeholder="Fee Complement"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "fee",
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Coverage Complement*</label>
        <div className="col-sm-10">
          <input
            required
            defaultValue={coverage}
            type="text"
            className="form-control"
            placeholder="Coverage"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "coverage",
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Brochure 1</label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={brochures[0]?.title || ""}
            className="form-control"
            placeholder="Title Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "title",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={brochures[0]?.url || ""}
            className="form-control"
            placeholder="Link Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "url",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Brochure 2</label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={brochures[1]?.title || ""}
            className="form-control"
            placeholder="Title Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "title",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={brochures[1]?.url || ""}
            className="form-control"
            placeholder="Link Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "url",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Brochure 3</label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={brochures[2]?.title || ""}
            className="form-control"
            placeholder="Title Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "title",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={brochures[2]?.url || ""}
            className="form-control"
            placeholder="Link Brochure"
            onBlur={(e) =>
              handleBrochureInput({
                label: "url",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

InsuranceComplements.defaultProps = {
  complement: {},
  handleInput: () => {},
  handleBrochureInput: () => {},
};

InsuranceComplements.propTypes = {
  complement: PropTypes.object,
  handleInput: PropTypes.func,
  handleBrochureInput: PropTypes.func,
};

export default InsuranceComplements;
