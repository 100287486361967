import { makeStyles, darken } from "@material-ui/core/styles";

import { white, secondary, grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(5)}px ${theme.spacing(2.5)}px ${theme.spacing(
      5
    )}px `,
  },
  button: {
    height: theme.spacing(5),
    borderRadius: 20,
  },
  outlinedButton: {
    borderColor: secondary,
    marginRight: theme.spacing(2),
    color: secondary,
  },
  containedButton: {
    backgroundColor: secondary,
    "&:hover": {
      backgroundColor: secondary,
    },
    color: white,
  },
  progress: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  row: {
    paddingBottom: theme.spacing(1),
  },
  input: {
    marginRight: theme.spacing(1),
  },
  spinner: {
    color: darken(grey300, 0.4),
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
