import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 300,
  },
  labelContainer: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  content: {
    padding: `${theme.spacing(4)}px 0`,
  },
  svg: {
    margin: `${theme.spacing(8)}px ${theme.spacing(32)}px`,
    width: 240,
    height: 180,
  },
}));

export default useStyles;
