import { showSuccessMessage } from "../commons/show-success-message";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";
import { createNursery } from "../../infrastructure/employee-repository";

export async function saveEmployeeNursery({
  employee,
  amount,
  nurseryCentreName,
  childName,
  address,
  isRecurrent,
  exemptionIRPF,
  lastExec,
  effectiveDate,
  phoneNumber,
  recipientId,
}) {
  if (
    !employee.id ||
    !childName ||
    !recipientId ||
    !nurseryCentreName ||
    (address && !address.streetName && !phoneNumber) ||
    !amount ||
    !effectiveDate
  ) {
    showErrorMessage("Missing compulsory parameters", false);
  }

  const res = await createNursery({
    employeeId: employee.id,
    amount,
    nurseryCentreName,
    childName,
    address,
    isRecurrent,
    exemptionIRPF,
    lastExec,
    effectiveDate,
    phoneNumber,
    recipientId,
  });

  if (res.status === 200 && res.data.response.success) {
    showSuccessMessage("Change saved!");
  } else {
    showErrorMessage(res.data.response.reason || "Error!", false);
  }
  displayEmployeeDetail(employee.email);
}
