import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    paddingTop: theme.spacing(2),

    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  textSpacing: {
    padding: `${theme.spacing(1)}px 0`,
  },
  boldText: {
    fontWeight: 600,
  },
}));

export default useStyles;
