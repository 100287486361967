import PropTypes from "prop-types";
import * as React from "react";
import { TextList, MUI } from "@cobee/cobee-ui";
import { ReadOnlyTextField } from "v2/ui/components/ReadOnlyTextField";
import { Complement } from "v2/domain/contexts/healthInsurance/entities/Complement";

export const RowDetailsBox = ({ data, open: isOpen }) => {
  return (
    <MUI.TableRow>
      <MUI.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <MUI.Collapse in={isOpen} timeout="auto" unmountOnExit>
          <MUI.Box sx={{ margin: 2 }}>
            <MUI.Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ paddingBottom: 1 }}
            >
              Details
            </MUI.Typography>
            <MUI.Grid container spacing={2}>
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField label="Complement name" value={data.name} />
              </MUI.Grid>
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField label="Complement Id" value={data.id} />
              </MUI.Grid>{" "}
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField label="Price" value={data.fee.parsed()} />
              </MUI.Grid>
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField
                  label="Insurance Company"
                  value={data.insuranceCompany}
                />
              </MUI.Grid>
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField label="Broker" value={data.broker} />
              </MUI.Grid>
              <MUI.Grid item xs={4}>
                <ReadOnlyTextField
                  label="Policy"
                  value={data.insurancePolicy}
                />
              </MUI.Grid>
              <MUI.Grid item xs={12}>
                <MUI.Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ paddingBottom: 1 }}
                >
                  Coverage
                </MUI.Typography>
                {data.coverage.split("\n").map((paragraph) => (
                  <p key={paragraph}>{paragraph}</p>
                ))}
              </MUI.Grid>
              <MUI.Grid item xs={12}>
                <MUI.Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ paddingBottom: 1 }}
                >
                  Brochures
                </MUI.Typography>
                <TextList
                  items={data.brochures.map(({ title, url }, index) => (
                    <span key={`${title}-${index + 0}`}>
                      {title}:&nbsp;&nbsp;
                      <a
                        href={url}
                        title={title}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {url}
                      </a>
                    </span>
                  ))}
                />
              </MUI.Grid>
            </MUI.Grid>
          </MUI.Box>
        </MUI.Collapse>
      </MUI.TableCell>
    </MUI.TableRow>
  );
};

RowDetailsBox.propTypes = {
  data: PropTypes.instanceOf(Complement).isRequired,
  open: PropTypes.bool.isRequired,
};
