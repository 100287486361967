import { makeStyles } from "@material-ui/core/styles";
import { secondary, grey300 } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  paginationButton: {
    height: 32,
    textTransform: "none",
    color: secondary,

    "& svg": {
      height: 12,
    },
  },
  prevButton: {
    marginLeft: theme.spacing(2),
    borderRadius: "16px 0 0 16px",
  },
  nextButton: {
    borderRadius: "0 16px 16px 0",
    marginLeft: -1,
  },
  disabled: {
    color: grey300,
  },
  pageCountText: {
    minWidth: 100,
  },
}));

export default useStyles;
