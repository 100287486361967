import moment from "moment";
import { Modality } from "../../../../domain/contexts/healthInsurance/entities/Modality";
import { prependYearToPartialDate } from "../../../common/utils/partialDate";
import { restoreBrochure } from "./restoreBrochure";
import { restoreMoney } from "../../../common/builders/restoreMoney";
import { restorePeriod } from "./restorePeriod";
import { restoreLooseModality } from "./restoreLooseModality";

const pastDate = moment().subtract(1, "day").toDate();
const futureDate = moment().add(2, "year").toDate();

export const restoreModality = ({
  canBeArchived,
  activeRecurrentPaymentCount,
  insuranceCompany,
  broker,
  insurancePolicy,
  dateSignUpTimestamp: dateSignUp,
  modalityId,
  modalityName,
  modalityFee,
  allowRequestOutsideEngagement,
  requiresEmployeeSignature,
  allowExemptDomesticPartner,
  modalityCoverageDescription,
  modalityBrochures,
  insideSignatureDocuments,
  outsideSignatureDocuments,
  insuranceBrand,
  firstEngagementPeriod,
  nextEngagementsPeriod,
  complements,
  isInstantaneous,
  allowDifferentModalitiesPerFamilyMember,
  renewalPeriod,
  expiresAt,
  availableFrom,
  automaticRenewalEnabled,
  state,
  pastPeriods,
  futurePeriod,
  modalityCommissionRate,
}) =>
  new Modality({
    activeRecurrentPaymentCount,
    insuranceCompany,
    id: modalityId,
    name: modalityName,
    fee: restoreMoney(modalityFee),
    modalityCommissionRate: modalityCommissionRate
      ? modalityCommissionRate * 100
      : undefined,
    broker,
    insurancePolicy,
    signUpDate: new Date(dateSignUp * 1000),
    allowRequestOutsideEngagement,
    // TODO: remove this when the API is fixed
    requiresEmployeeSignature: !!requiresEmployeeSignature,
    allowExemptDomesticPartner,
    coverageDescription: modalityCoverageDescription,
    brochures: modalityBrochures.map(restoreBrochure),
    insideSignatureDocuments: insideSignatureDocuments.map(restoreBrochure),
    outsideSignatureDocuments: outsideSignatureDocuments.map(restoreBrochure),
    insuranceBrand,
    firstEngagementPeriod: firstEngagementPeriod.map((period) =>
      restorePeriod(prependYearToPartialDate(period))
    ),
    nextEngagementPeriods: nextEngagementsPeriod.map((period) =>
      restorePeriod(prependYearToPartialDate(period))
    ),
    complements: complements.map((complement) => complement.complementId),
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    state,
    // To aid the adoption of renewal periods.
    // TODO: Ask in 2022-Q1 if this is still needed. if not treat every condition as if it was true
    renewalPeriod: renewalPeriod
      ? restorePeriod(renewalPeriod)
      : { from: pastDate, to: pastDate },
    availableFrom: availableFrom ? new Date(availableFrom) : pastDate,
    expiresAt: expiresAt ? new Date(expiresAt) : futureDate,
    automaticRenewalEnabled,
    pastPeriods: pastPeriods ? pastPeriods.map(restoreModality) : [],
    futurePeriod: futurePeriod ? restoreLooseModality(futurePeriod) : undefined,
    canBeArchived,
  });
