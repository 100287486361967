import Papa from "papaparse";
import { showErrorMessage } from "../commons/show-error-message";

export async function getCsvTransfers(filename) {
  return new Promise((resolve) => {
    Papa.parse(filename, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const csvProperties = {
          isInvalid: false,
          errorMessage: "",
          wrongTransfer: [],
          adaptedTransfers: [],
        };
        if (
          results.data.length === results.errors.length ||
          results.meta.fields.length !== 4
        ) {
          csvProperties.isInvalid = true;
          csvProperties.errorMessage =
            (results.errors[0] && results.errors[0].message) || "invalid csv";
        }
        if (!csvProperties.isInvalid) {
          const adaptedTransfers = results.data.map((csvUser) =>
            adaptCsvTransfer(csvUser, results.meta.fields)
          );
          csvProperties.adaptedTransfers = adaptedTransfers;
        } else {
          showErrorMessage(
            "Wrong. The format of the csv is incorrect. Check that all columns are correct"
          );
        }
        resolve(csvProperties);
      },
    });
  });
}

function adaptCsvTransfer(csvTransfer, fields) {
  const amountInCents = +(
    (csvTransfer[fields[3]]
      ? parseFloat(csvTransfer[fields[3]].replace(",", ".")).toFixed(2)
      : 0) * 100
  ).toFixed(0);

  return {
    origin: csvTransfer[fields[0]]
      ? csvTransfer[fields[0]].toUpperCase().trim()
      : null,
    destination: csvTransfer[fields[1]]
      ? csvTransfer[fields[1]].toUpperCase().trim()
      : null,
    concept: csvTransfer[fields[2]]
      ? csvTransfer[fields[2]].toUpperCase().trim()
      : null,
    amount: {
      amountInCents,
      currency: "EUR",
    },
  };
}
