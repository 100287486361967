import ReactDOM from "react-dom";
import React from "react";
import queryString from "query-string";
import renderAssignMultipleRelativesModal from "../../../infrastructure/renders/AssignMultipleRelativesModal/render-assign-multiple-relatives-modal";
import { showErrorMessage } from "../../commons/show-error-message";
import { hideModal } from "../../commons/hide-modal";
import { showSuccessMessage } from "../../commons/show-success-message";
import renderCensusProgressPage from "../../../infrastructure/renders/CensusProgressPage/render-census-progress-page";
import { assignRelativesBulk } from "../../../infrastructure/census-repository";

export default function assignMultipleRelatives({ relatives }) {
  const assignAction = async () => {
    try {
      renderAssignMultipleRelativesModal({
        isLoading: true,
        action: () => {},
      });

      await assignRelativesBulk({
        payload: {
          rows: relatives.map(({ errors, data }) => {
            const { data: internalData } = errors?.[0];
            return {
              employeeId: internalData.employee.id,
              familyMemberData: { ...data.insured, relationStartDate: null },
            };
          }),
        },
      });

      const { company } = queryString.parse(window.location.search);
      window.location.replace(
        `/upload-census-file?censusType=health-insurance-benefit&company=${company}`
      );

      hideModal();
      showSuccessMessage("Relatives assigned successfully");
    } catch (e) {
      showErrorMessage(
        "An error occurred while attempting to assign multiple family members"
      );
      hideModal();
    }
  };
  renderAssignMultipleRelativesModal({
    action: assignAction,
    isLoading: false,
  });
}
