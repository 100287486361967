import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import renderCardPurchaseRefundModal from "infrastructure/renders/ShowCardPurchaseRefundModal/render-show-card-purchase-refund-modal";
import { displayTransactionDetailsPage } from "../../../companies/display-transaction-details";
import { downloadTransactions } from "../../download-transactions";
import { prompt } from "../../../modals/prompt";
import { refundNursery } from "../../refund-nursery";
import { cancelTransaction } from "../../cancel-transaction";
import { confirm } from "../../../modals/confirm";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";
import categorizeMerchant from "../../categorize-merchant";
import CobeeButton from "../../../../app/components/CobeeButton";
import Table from "../../../../app/components/Table";
import { getHeaders } from "./headers";
import useStyles from "./styles";

export default function TransactionsTablePage({
  transactions,
  email,
  numMp,
  unknownSelected,
  categories,
  showOnlyUnknown,
}) {
  const [isLoadingSolve, setIsLoadingSolve] = useState(false);
  const [rowSelected, setRowSelected] = useState(-1);
  const {
    principalButton,
    secondButton,
    checkIcon,
    disabledIcon,
  } = useStyles();

  const handleSolveUnknown = async (cancelTransactionId, index) => {
    setIsLoadingSolve(true);
    setRowSelected(index);
    await cancelTransaction({
      transactionId: cancelTransactionId,
      unknownId: unknownSelected?.unknownProcessorNotificationId,
    });
    setIsLoadingSolve(false);
  };

  const checkStateField = (trx, type) => {
    if (trx.state) {
      return trx.state === type;
    }
    return trx.status === type;
  };

  const shouldShowCategorizeButton = (trx) =>
    (checkStateField(trx, "rejected") ||
      checkStateField(trx, "rejected-by-cobee")) &&
    trx.reason === "invalid-merchant" &&
    !trx.isCategorized;

  const shouldShowRefundsButton = (trx) =>
    ["nursery-benefit", "health-insurance-benefit"].includes(trx.category) &&
    trx.isPaymentGateway &&
    checkStateField(trx, "confirmed");

  const parseAmounts = (amount) =>
    amount
      ? MonetaryAmount.ofCurrency(amount).prettifyMoney({})
      : MonetaryAmount.ZERO.prettifyMoney({});

  const handleClickSolveUnknown = (transaction, index) => {
    const { concept, amount } = unknownSelected;
    const amountFormatted = MonetaryAmount.ofCurrency(amount).prettifyMoney({});
    confirm({
      message: `Are you sure to cancel ${
        concept || "this transaction"
      } with an amount of ${amountFormatted} ?`,
      onConfirm: () => handleSolveUnknown(transaction?.id, index),
    });
  };

  const handleRefundNursery = (trx) => {
    prompt({
      message: `Are you sure you want to refund the nursery Payment? ONLY nursery transactions that happened in the current payroll cycle can be refunded. If affirmative, enter the amount to be returned (must be LESS THAN OR EQUAL to the value of the transaction) Now, the total amount of transaction is: ${
        trx.amount.amountInCents / 100
      }€`,
      placeholder: "Amount in Euros",
      onConfirm: (amount) =>
        refundNursery({ transactionId: trx.id, amount, email, numMp }),
    });
  };

  const flexFields = (trx) => {
    const {
      flexDebitAmount,
      flexCreditAmount,
      flexNotExemptDebitAmount,
      flexNotExemptCreditAmount,
      allowanceDebitAmount,
      allowanceCreditAmount,
      allowanceNotExemptDebitAmount,
      allowanceNotExemptCreditAmount,
      type,
    } = trx;

    const isCardPurchase = type === "card-purchase-refund";
    return {
      flexDebitAmount: isCardPurchase
        ? parseAmounts(flexCreditAmount)
        : parseAmounts(flexDebitAmount),
      flexNotExemptDebitAmount: isCardPurchase
        ? parseAmounts(flexNotExemptDebitAmount)
        : parseAmounts(flexNotExemptCreditAmount),
      allowanceDebitAmount: isCardPurchase
        ? parseAmounts(allowanceDebitAmount)
        : parseAmounts(allowanceCreditAmount),
      allowanceNotExemptDebitAmount: isCardPurchase
        ? parseAmounts(allowanceNotExemptDebitAmount)
        : parseAmounts(allowanceNotExemptCreditAmount),
    };
  };

  const payrollField = (payrollCycle) => {
    if (!payrollCycle || !payrollCycle.reported) {
      return "No reported";
    }
    return `${payrollCycle.fiscalYear}-${payrollCycle.payrollMonth}`;
  };

  const shouldDisableSolveButton = ({
    isRefunded,
    refundTransactions,
    unknownAmount,
    originalTrxAmount,
  }) => {
    if (isRefunded) {
      const refundsAmounts = refundTransactions.map(({ amount }) =>
        MonetaryAmount.ofCurrency(amount)
      );
      const zeroAmount =
        refundsAmounts.length > 0
          ? MonetaryAmount.zeroWithCurrency(refundsAmounts[0].currency)
          : MonetaryAmount.ZERO;
      const totalRefundsAmount = refundsAmounts.reduce(
        (acc, current) => acc.add(current),
        zeroAmount
      );
      return totalRefundsAmount
        .add(unknownAmount)
        .greaterThan(originalTrxAmount);
    }
    return unknownAmount.greaterThan(originalTrxAmount);
  };

  const tableActions = (trx, index) => {
    return (
      <Grid item container xs={12}>
        {Object.entries(unknownSelected).length === 0 ? (
          <Grid item className={principalButton}>
            <CobeeButton
              onClick={() => {
                displayTransactionDetailsPage(
                  {
                    ...trx,
                    ...trx.merchantInfo,
                    ...trx.processorDetails,
                    transactionId: trx.id,
                    userTransactionIsoTime: moment(
                      trx.userTransactionIsoTime
                    ).format("DD-MM-YYYY HH:mm"),
                    timestamp: moment(trx.userTransactionIsoTime).unix(),
                    iso2CountryCode: trx.merchantInfo.country,
                    reason: trx?.reason,
                    payrollCycle: trx.payrollCycle
                      ? `${trx.payrollCycle.fiscalYear}-${trx.payrollCycle.payrollMonth}`
                      : "",
                  },
                  email,
                  numMp
                );
              }}
            >
              Detail
            </CobeeButton>
          </Grid>
        ) : (
          <>
            {trx.isRefunded && (
              <Grid item className={principalButton}>
                <CobeeButton
                  onClick={() =>
                    renderCardPurchaseRefundModal({
                      refundTransactions: trx.refundTransactions,
                    })
                  }
                >
                  {isLoadingSolve && index === rowSelected ? (
                    <ClipLoader size={16} color="white" loading />
                  ) : (
                    "Details"
                  )}
                </CobeeButton>
              </Grid>
            )}
            <Grid item className={principalButton}>
              <CobeeButton
                onClick={() => {
                  handleClickSolveUnknown(trx, index);
                }}
                disabled={shouldDisableSolveButton({
                  isRefunded: trx.isRefunded,
                  refundTransactions: trx.refundTransactions,
                  unknownAmount: MonetaryAmount.ofCurrency(
                    unknownSelected?.amount
                  ),
                  originalTrxAmount: MonetaryAmount.ofCurrency(trx.amount),
                })}
              >
                {isLoadingSolve && index === rowSelected ? (
                  <ClipLoader size={16} color="white" loading />
                ) : (
                  "Solve"
                )}
              </CobeeButton>
            </Grid>
          </>
        )}
        {shouldShowCategorizeButton(trx) && (
          <Grid item className={secondButton}>
            <CobeeButton
              onClick={() => {
                categorizeMerchant({
                  categories,
                  transactionId: trx.id,
                  email,
                  numMp,
                  unknownSelected,
                });
              }}
            >
              Categorize
            </CobeeButton>
          </Grid>
        )}
        {shouldShowRefundsButton(trx) && (
          <Grid item className={secondButton}>
            <CobeeButton onClick={() => handleRefundNursery(trx)}>
              Refund
            </CobeeButton>
          </Grid>
        )}
      </Grid>
    );
  };

  const headers = getHeaders({ showOnlyUnknown });
  const filteredTransactions = showOnlyUnknown
    ? transactions.filter(
        ({ status, type, parentTransactionId, flexCreditAmount }) =>
          status === "confirmed" &&
          type === "card-purchase" &&
          parentTransactionId === null &&
          flexCreditAmount
      )
    : transactions;

  if (filteredTransactions.length === 0 && unknownSelected) {
    return (
      <div className="card">
        <div className="card-body">
          <span>
            There are not transactions linked to employee of category
            meal-benefit or transport-benefit
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title buttonRight">
          Transactions
          <div className="buttonRight">
            <button
              className="btn btn-sm btn-primary mb-2 mr-2"
              onClick={() => downloadTransactions({ transactions })}
            >
              Download transactions
            </button>
          </div>
        </h5>

        <Table
          columns={headers}
          data={filteredTransactions.map(
            (
              {
                cardId,
                description,
                intendedAmount,
                clearedAmount,
                flexDebitAmount,
                flexNotExemptDebitAmount,
                allowanceDebitAmount,
                allowanceNotExemptDebitAmount,
                userTransactionIsoTime: date,
                payrollCycle,
                isRefunded,
                ...rest
              },
              i
            ) => ({
              cardId,
              description: (
                <>
                  {description}
                  <div className="font-weight-lighter">
                    {filteredTransactions[i].transactionId}
                  </div>
                </>
              ),
              intendedAmount: parseAmounts(intendedAmount),
              clearedAmount: parseAmounts(clearedAmount),
              ...flexFields(filteredTransactions[i]),
              userTransactionIsoTime: (
                <>
                  <div>{moment(date).format("DD-MM-YYYY")}</div>
                  <div>{moment(date).format("HH:mm")}</div>
                </>
              ),
              isRefunded: (
                <CheckCircleIcon
                  className={isRefunded ? checkIcon : disabledIcon}
                />
              ),
              actions: tableActions(filteredTransactions[i], i),
              payrollCycle: payrollField(payrollCycle),
              ...rest,
            })
          )}
        />
      </div>
    </div>
  );
}

TransactionsTablePage.defaultProps = {
  transactions: [],
  email: "",
  numMp: "",
  unknownSelected: {},
  categories: [],
  showOnlyUnknown: false,
};

TransactionsTablePage.propTypes = {
  transactions: PropTypes.array,
  email: PropTypes.string,
  numMp: PropTypes.string,
  unknownSelected: PropTypes.object,
  categories: PropTypes.array,
  showOnlyUnknown: PropTypes.bool,
};
