import moment from "moment";
import { adaptDataToCsv } from "../../infrastructure/adapters/adaptDataToCsv";
import generateCsv from "../../services/generateCsv";

export async function downloadTransactions({ transactions }) {
  const today = moment().format();
  const { cardId } = transactions.find((trx) => trx.cardId);
  const title = `transactions-${cardId}-${today}`;
  const { headers, rows } = adaptDataToCsv({
    type: "ledgerTransactions",
    data: transactions,
  });
  await generateCsv({ title, headers, rows });
}
