import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import useStyles from "./styles";
import Modal from "../../../app/components/modals/Modal";
import CobeeButton from "../../../app/components/CobeeButton";
import { hideModal } from "../../../usecases/commons/hide-modal";

export default function AssignMultipleRelativesModal({ isLoading, action }) {
  const { warningContainer } = useStyles();

  return (
    <Modal title="Assign relatives automatically">
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className={warningContainer}
        >
          <WarningIcon />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>WARNING:</b> This functionality will automatically assign the
            relatives you specified on the census spreadsheet to each employee
            that has no relatives registered. Be sure all the data is correct
            before proceeding with this process. (This action cannot be reversed
            by bulk).
          </Typography>
          <br />
          <Typography variant="body1">
            A new census would be needed to be done after the relatives have
            been created
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="flex-end" spacing={1}>
          <Grid item>
            <CobeeButton variant="secondary" onClick={hideModal}>
              Cancel
            </CobeeButton>
          </Grid>
          <Grid item>
            <CobeeButton
              disabled={isLoading}
              isLoading={isLoading}
              onClick={action}
            >
              Confirm
            </CobeeButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

AssignMultipleRelativesModal.defaultProps = {
  isLoading: false,
};

AssignMultipleRelativesModal.propTypes = {
  isLoading: PropTypes.bool,
  action: PropTypes.func.isRequired,
};
