import React from "react";
import Proptypes from "prop-types";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";
import PopperMenu from "../../../../app/components/PopperMenu";
import Table from "../../../../app/components/Table";
import sendInvoices from "../../send-invoices/send-invoices";
import previewInvoice from "../../preview-invoice";
import discardInvoices from "../../discard-invoices/discard-invoices";

const columnConfig = [
  { label: "Date", id: "date" },
  { label: "Company", id: "company" },
  { label: "Concept", id: "concept" },
  { label: "Amount", id: "amount" },
  { label: "Previous balance", id: "prevProviderBalance" },
  { label: "Subsequent balance", id: "postProviderBalance" },
  { label: "Status", id: "status" },
  { label: "Actions", id: "actions" },
];

const actionsConfig = ({ status, id, company, refetch }) => {
  const sendAction = () =>
    sendInvoices({ invoiceIds: [id], companyId: company.id, refetch });
  const discardAction = () => discardInvoices({ invoiceIds: [id], refetch });
  const config = {
    pending: [
      {
        label: "Preview",
        action: () => {
          previewInvoice({ invoiceId: id });
        },
      },
      {
        label: "Send",
        action: sendAction,
      },
      {
        label: "Discard",
        action: discardAction,
      },
    ],
    resolved: [
      {
        label: "Preview",
        action: () => {
          previewInvoice({ invoiceId: id });
        },
      },
      {
        label: "Send",
        action: sendAction,
      },
    ],
    discarded: [
      {
        label: "Preview",
        action: () => {
          previewInvoice({ invoiceId: id });
        },
      },
      {
        label: "Send",
        action: sendAction,
      },
    ],
    default: [],
  };

  return status in config ? config[status] : config.default;
};

export default function InvoicesTable({
  data,
  statusFilter,
  refetch,
  ...additionalProps
}) {
  const resultantData = data.map(
    (
      {
        company,
        amount,
        prevProviderBalance,
        postProviderBalance,
        status,
        id,
        ...rest
      },
      i
    ) => ({
      ...rest,
      id,
      company: company.name,
      amount: MonetaryAmount.of(amount.amountInCents).prettifyMoney({}),
      prevProviderBalance: MonetaryAmount.ofCurrency(
        prevProviderBalance
      ).prettifyMoney({}),
      postProviderBalance: MonetaryAmount.ofCurrency(
        postProviderBalance
      ).prettifyMoney({}),
      status,
      actions: (
        <PopperMenu
          options={actionsConfig({
            status: statusFilter,
            id,
            company,
            refetch,
          })}
        />
      ),
    })
  );

  return (
    <Table
      withCheckbox
      columns={columnConfig}
      data={resultantData}
      {...additionalProps}
    />
  );
}

InvoicesTable.defaultProps = {};

InvoicesTable.propTypes = {
  data: Proptypes.array.isRequired,
  statusFilter: Proptypes.string.isRequired,
};
