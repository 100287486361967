import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import { Address } from "../../../../../app/domain/address";
import Table from "../../../../../app/components/Table";

export default function HealthInsuranceRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row } = useStyles();

  function optionalMonetaryAmount(payment) {
    return payment ? MonetaryAmount.ofCurrency(payment).prettifyMoney({}) : "";
  }

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty(
          "Insurance Company",
          recurrentPayment.healthInsuranceModality.insuranceCompany
        )}
        {textProperty(
          "Broker",
          recurrentPayment.healthInsuranceModality.broker
        )}
        {textProperty(
          "Policy",
          recurrentPayment.healthInsuranceModality.insurancePolicy
        )}
        {textProperty(
          "Modality",
          recurrentPayment.healthInsuranceModality.modalityName
        )}
        {textProperty(
          "Fee",
          optionalMonetaryAmount(
            recurrentPayment.healthInsuranceModality.modalityFee
          )
        )}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2">
            <b>Complements:</b>
          </Typography>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Table
            columns={[
              { label: "Name", id: "complementName" },
              { label: "Fee", id: "fee" },
            ]}
            data={recurrentPayment.healthInsuranceComplements.map((hic) => ({
              ...hic,
              fee: optionalMonetaryAmount(hic.complementFee),
            }))}
          />
        </Grid>
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
