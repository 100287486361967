import { toast } from "@cobee/cobee-ui";
import { getServerErrorMessage } from "usecases/ErrorHandling";
import { list } from "../../../../infrastructure/contexts/healthInsurance/repository/Complement";

export const listComplements = async ({ Company }) => {
  try {
    const companyComplements = await list({ companyId: Company.id });
    return companyComplements;
  } catch (e) {
    toast.show(getServerErrorMessage(e), { type: "error" });
    return [];
  }
};
