import { updateBenefitBagConfig } from "../../infrastructure/company-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";
import showBenefitBagDetail from "./show-benefit-bag-details";

export default async function enableAccumulated({
  isEnabled,
  shouldDeliverOnPeriodEnd,
  shouldRenewAtPeriodEnd,
  companyId,
  benefitPlanId,
  benefitBagConfigId,
  allowedBenefits,
  alias,
}) {
  const payload = {
    accumulableAtPeriodEnd: isEnabled,
    deliverUnusedExemptToPurses: shouldDeliverOnPeriodEnd,
    renewAtPeriodEnd: shouldRenewAtPeriodEnd,
  };
  try {
    await updateBenefitBagConfig({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
      payload,
    });
    await showBenefitBagDetail({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
      allowedBenefits,
      alias,
    });
    showSuccessMessage("Accumulation successfully changed");
  } catch (error) {
    showErrorMessage("There was an error enabling accumulation");
  }
}
