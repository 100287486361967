import React, { useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import moment from "moment";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";
import Table from "../../../../app/components/Table";

export default function CardDetailsModal({ card }) {
  const { container, row, buttonRow, rowDescription } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={2} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={10} className={row}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </>
  );

  return (
    <Modal title="Card Detail">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          {textProperty("Card ID", card.cardId)}
          {textProperty("Expiration Date", card.expirationIsoDate)}
          {textProperty("Category Type", card.categoryType)}
          {textProperty("Tag", card.tag)}
          {textProperty("Bin", card.bin)}
          {textProperty("State", card.state)}

          {card.stateHistory.length > 0 && (
            <>
              <Grid item container xs={12} className={row}>
                <Typography variant="body1">
                  <b>What does each state mean?</b>
                </Typography>
              </Grid>
              <Grid item container xs={12} className={row}>
                <Typography component="ul">
                  <Typography component="li">
                    <b>pending</b>: The employee has not requested the card
                  </Typography>
                  <Typography component="li">
                    <b>deactivated</b>: The employee has requested but has not
                    activated (Or has been manually deactivated)
                  </Typography>
                  <Typography component="li">
                    <b>active</b>: The employee has requested and activated the
                    card
                  </Typography>
                  <Typography component="li">
                    <b>cancelled</b>: The card has been cancelled. The card is
                    cancelled when requesting a duplicate
                  </Typography>
                </Typography>
              </Grid>

              <Grid item container xs={12} className={row}>
                <Typography variant="body2">
                  <b>State History:</b>
                </Typography>
              </Grid>
              <Grid item container xs={12} className={row}>
                <Table
                  columns={[
                    { label: "Date", id: "date" },
                    { label: "State", id: "state" },
                  ]}
                  data={card.stateHistory.map(({ timestamp, ...rest }) => ({
                    ...rest,
                    date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
                  }))}
                />
              </Grid>
            </>
          )}

          {card.cardIdHistory.length > 0 && (
            <>
              <Grid item container xs={12} className={row}>
                <Typography variant="body2">
                  <b>Card ID History:</b>
                </Typography>
              </Grid>
              <Grid item container xs={12} className={row}>
                <Table
                  columns={[
                    { label: "Date", id: "date" },
                    { label: "Card ID", id: "cardId" },
                    { label: "Source", id: "source" },
                  ]}
                  data={card.cardIdHistory.map(({ timestamp, ...rest }) => ({
                    ...rest,
                    date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
                  }))}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Close
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
