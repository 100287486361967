import qs from "qs";
import { downloadContributions } from "infrastructure/benefits-repository";
import { fileDownloadHandler } from "usecases/commons/file-download-handler";
import adaptContributionsQuery from "infrastructure/adapters/contributions/adaptContributionsQuery";

const downloadContributionsFile = async ({ queryObject, selectedBenefit }) => {
  const resultantObject = {};
  Object.keys(queryObject).forEach((key) => {
    if (queryObject[key] !== "" && queryObject[key] !== "all") {
      resultantObject[key] = queryObject[key];
    }
  });

  const adaptedQueryObject = adaptContributionsQuery(resultantObject);
  const { fromDate, toDate } = adaptedQueryObject;
  const { data } = await downloadContributions({
    category: selectedBenefit,
    query: qs.stringify(adaptedQueryObject),
  });

  fileDownloadHandler(
    `${selectedBenefit}-contributions-from-${fromDate}-to-${toDate}`,
    data
  );
};

export default downloadContributionsFile;
