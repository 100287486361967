import { lighten, makeStyles } from "@material-ui/core/styles";
import { error, grey300, secondary } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingBottom: theme.spacing(2),
  },
  stepCard: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: theme.spacing(2),
    backgroundColor: lighten(grey300, 0.8),
  },
  errorColor: {
    color: error,
  },
  checkbox: {
    "& svg": {
      color: secondary,
    },
  },
  select: {
    width: 400,
    marginBottom: theme.spacing(2),
  },
  textInput: {
    backgroundColor: "white",
  },
  submitButtonContainer: {
    marginTop: theme.spacing(3),
  },
  rentingStepsTitle: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
