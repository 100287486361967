import React from "react";
import { defaultTheme } from "@cobee/cobee-ui";
import { useParams } from "react-router-dom";
import { Providers } from "../../../../providers";
import { DefaultLayout } from "../../../../layouts/DefaultLayout";
import { FlexBox } from "../../../../components/FlexBox";
import { PageHeading } from "../../../../components/PageHeading";
import { ModalitiesOverview } from "./components/ModalitiesOverview";
import { ComplementsOverview } from "./components/ComplementsOverview";

const HealthInsuranceOverview = () => {
  const { companyId } = useParams();
  return (
    <FlexBox
      direction="column"
      gap={24}
      style={{
        backgroundColor: defaultTheme.palette.grey[50],
        padding: 32,
      }}
    >
      <PageHeading
        title="Health insurance overview"
        actions={[
          {
            label: "Back to company",
            variant: "outlined",
            onClick: () => {
              window.location.href = `/companies/${companyId}`;
            },
          },
        ]}
      />
      <ModalitiesOverview />
      <ComplementsOverview />
    </FlexBox>
  );
};

export default function HealthInsuranceOverviewPage() {
  return (
    <Providers>
      <DefaultLayout>
        <HealthInsuranceOverview />
      </DefaultLayout>
    </Providers>
  );
}
