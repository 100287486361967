import renderDiscardInvoiceModal from "./render-discard-invoice-modal";
import { discardInvoice } from "../../../infrastructure/finance-repository";
import { hideModal } from "../../commons/hide-modal";
import { showErrorMessage } from "../../commons/show-error-message";

export default function discardInvoices({ invoiceIds, refetch }) {
  const action = async () => {
    renderDiscardInvoiceModal({ action, isLoading: true });
    try {
      await Promise.all(
        invoiceIds.map(async (invoiceId) => {
          await discardInvoice({ invoiceId });
        })
      );
      refetch();
      hideModal();
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);
      renderDiscardInvoiceModal({ action, isLoading: false });
    }
  };

  renderDiscardInvoiceModal({ action, isLoading: false });
}
