import React from "react";
import ReactDOM from "react-dom";
import EditCardIdModal from "./components/EditCardIdModal";
import CardDetailsModal from "./components/CardDetailsModal";

export default function renderEditCardDetailModal({ card }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<CardDetailsModal card={card} />, domTarget);
  }
}
