import { makeStyles } from "@material-ui/core/styles";
import { lightGrey } from "../../../../../assets/themeColors";

const useStyles = makeStyles(() => ({
  configurationItem: {
    flex: 1,
  },
  configurationLabel: {
    color: lightGrey,
  },
}));

export default useStyles;
