import { makeStyles } from "@material-ui/core/styles";
import { primary, secondary, grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(1),
  },
  card: {
    border: `solid 1px ${grey300}`,
    borderRadius: 4,
    minHeight: 200,
    padding: theme.spacing(2),
  },
  benefitName: {
    margin: theme.spacing(1),
  },
  optionsContainer: {
    width: "100%",
    minHeight: 64,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
