import { makeStyles } from "@material-ui/core/styles";
import {
  primary,
  secondary,
  grey300,
  error,
} from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    padding: theme.spacing(4),
  },
  noMoreResultsMessage: {
    color: error,
  },
  moreResultsMessage: {
    color: primary,
  },
}));

export default useStyles;
