import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  employeeItem: {
    width: "33%",
    marginBottom: theme.spacing(3),

    "& svg": {
      marginRight: "12px",
    },
  },
}));

export default useStyles;
