import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import { MUI } from "@cobee/cobee-ui";
import useStyles from "./styles";
import CobeeButton from "../../../../app/components/CobeeButton";
import { hideModal } from "../../../commons/hide-modal";
import Modal from "../../../../app/components/modals/Modal";
import Table from "../../../../app/components/Table";

export default function FamilyMemberDetailsModal({
  familyMember,
  homeAddress,
}) {
  const { container, row, buttonRow, divider } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={12} md={6} className={row}>
        <Grid item container xs={12}>
          <Typography variant="body2">
            <b>{label}: </b>
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography variant="body2">{value?.toString()}</Typography>
        </Grid>
      </Grid>
    </>
  );

  const renderRecurrentPaymentSummary = () => (
    <>
      <MUI.Grid item container xs={12} className={row} sx={{ pt: 5 }}>
        <Typography variant="h5">Active Recurrent Payment Summary</Typography>
        <Typography variant="p">
          DISCLAIMER: This is just a quick overall view. The detail of this
          recurrent payments can be found in the recurrent payment section
        </Typography>
      </MUI.Grid>
      <Grid item container xs={12} className={row}>
        <Table
          columns={[
            { label: "Category", id: "category" },
            { label: "First Execution", id: "firstExecutionDate" },
            { label: "Last Execution", id: "lastExecutionDate" },
          ]}
          data={familyMember.recurrentPayments.map(
            ({ category, firstExecTimestamp, lastExecTimestamp }) => ({
              category,
              firstExecutionDate: moment
                .unix(firstExecTimestamp)
                .format("YYYY-MM-DD"),
              lastExecutionDate: moment
                .unix(lastExecTimestamp)
                .format("YYYY-MM-DD"),
            })
          )}
        />
      </Grid>
    </>
  );

  const startDate = familyMember?.relationStartDate
    ? moment(familyMember.relationStartDate)
    : undefined;
  const birthday = familyMember?.birthDate
    ? moment(familyMember.birthDate)
    : undefined;

  return (
    <Modal title="Family member Detail">
      <MUI.Divider className={divider} />
      <Grid item container xs={12} className={container}>
        {textProperty("id", familyMember.id)}
        {textProperty("Name", familyMember?.name)}
        {textProperty("Surname", familyMember?.surname)}
        {textProperty("Type", familyMember?.type)}
        {textProperty("Subtype", familyMember?.subtype)}
        {textProperty("Legal ID", familyMember?.legalId)}
        {textProperty("Sex", familyMember?.sex)}
        {textProperty(
          "Relationship Start Date",
          startDate?.format("YYYY-MM-DD")
        )}
        {textProperty("Birth Date", birthday?.format("YYYY-MM-DD"))}
        {textProperty("Incapacity Level", familyMember?.incapacityLevel)}

        <Grid item container xs={12} className={row}>
          <Typography variant="h5">Address</Typography>
        </Grid>

        {textProperty("Street Name", homeAddress?.streetName)}
        {textProperty("Steet Number", homeAddress?.streetNumber)}
        {textProperty("Details", homeAddress?.details)}
        {textProperty("Postal Code", homeAddress?.postalCode)}
        {textProperty("Province", homeAddress?.province)}
        {textProperty("Locality", homeAddress?.locality)}

        <MUI.Divider className={divider} />

        {renderRecurrentPaymentSummary(familyMember)}

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Close
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

FamilyMemberDetailsModal.propTypes = {
  familyMember: PropTypes.object.isRequired,
  homeAddress: PropTypes.object.isRequired,
};
