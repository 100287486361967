import React from "react";
import ReactDOM from "react-dom";
import LifeInsuranceRiskIndexModal from "./index";

export default function renderLifeInsuranceRiskIndexModal(props) {
  ReactDOM.render(
    <LifeInsuranceRiskIndexModal title="Risk index" {...props} />,
    document.getElementById("modal-container")
  );
}
