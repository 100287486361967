import { showSuccessMessage } from "../commons/show-success-message";

import * as cardRepository from "../../infrastructure/card-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function editCardId({ oldCardId, employeeId, newCardId }) {
  try {
    const card = await cardRepository.editCardId({
      oldCardId,
      employeeId,
      newCardId,
    });
    showSuccessMessage("Card ID updated successfully");
    return card;
  } catch (e) {
    showErrorMessage(
      getServerErrorMessage(e, "An unexpected error has ocurred")
    );
    return false;
  }
}
