import { makeStyles } from "@material-ui/core/styles";
import { secondary } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    maxWidth: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(2),
  },
  header: {
    justifyContent: "space-between",
  },
  buttonRow: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  checkbox: {
    "&:hover": { backgroundColor: "transparent" },
    "& svg": {
      color: secondary,
    },
  },
}));

export default useStyles;
