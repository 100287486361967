import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, InputAdornment, MenuItem, Select } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import useStyles from "./styles";
import CobeeDatePicker from "../../../../app/components/CobeeDatePicker";

export default function NurseriesFilter({
  overrideLabel,
  items,
  dateFilter,
  handleDateFilter,
  handleFilter,
  handleSearch,
}) {
  const { container, searchIcon, select, datePicker } = useStyles();

  const [value, setValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dateFilter);

  return (
    <Grid
      container
      className={container}
      justify="flex-end"
      alignItems="center"
      spacing={3}
    >
      {dateFilter && handleDateFilter && (
        <Grid item>
          <CobeeDatePicker
            selectedDate={selectedDate}
            onChange={(e) => {
              setSelectedDate(e);
              handleDateFilter(e);
            }}
            showMonthYearPicker
            customInput={
              <div className={datePicker}>
                {moment(selectedDate).format("MMMM YYYY")}
              </div>
            }
          />
        </Grid>
      )}
      <Grid item>
        <Select
          className={select}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            handleFilter(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            {overrideLabel || "State filter"}
          </MenuItem>
          <MenuItem value="all">All</MenuItem>
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <CobeeTextField
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

NurseriesFilter.propTypes = {
  items: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDateFilter: PropTypes.func,
  dateFilter: PropTypes.object,
  overrideLabel: PropTypes.string,
};

NurseriesFilter.defaultProps = {
  items: [],
  handleDateFilter: undefined,
  dateFilter: new Date(),
  overrideLabel: undefined,
};
