import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  ruleTypeCard: {
    minHeight: 200,
    padding: theme.spacing(1),
  },
  ruleTypeCardContent: {
    border: `1px solid ${grey300}`,
    borderRadius: 4,
    height: "100%",
    padding: theme.spacing(2),
  },
  emptyCard: {
    backgroundColor: "#efefef",
    color: grey300,
  },
}));

export default useStyles;
