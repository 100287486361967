import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(1)}px 0`,
    height: 1,
    backgroundColor: grey300,
  },
}));

export default useStyles;
