import { findCompanyDetail } from "infrastructure/company-repository";
import renderEditRentingSteps from "infrastructure/renders/EditRentingSteps/render-edit-renting-steps";
import queryString from "query-string";
import { showErrorMessage } from "usecases/commons/show-error-message";
import adaptEditRentingSteps from "infrastructure/adapters/adaptRentingSteps";
import { showSuccessMessage } from "usecases/commons/show-success-message";
import editBenefitConfig from "usecases/benefit-models/editBenefitConfig";
import { displayCompanyDetailsPage } from "./display-company-details";

export async function editRentingBenefitConfig() {
  const onSave = async ({ companyId, ...payload }) => {
    const adaptedPayload = adaptEditRentingSteps({ ...payload });
    try {
      await editBenefitConfig({
        companyId,
        category: "renting-benefit",
        payload: adaptedPayload,
      });
      displayCompanyDetailsPage(companyId);
      window.location.replace(`/companies/${companyId}`);
      showSuccessMessage("Renting steps successfully edited");
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the benefit creation"
      );
    }
  };

  const { companyId } = queryString.parse(window.location.search);
  renderEditRentingSteps({ companyId, isLoading: true, callback: () => {} });
  try {
    const { benefitConfigs } = await findCompanyDetail(companyId);
    renderEditRentingSteps({
      companyId,
      benefitConfig: benefitConfigs["renting-benefit"],
      steps: benefitConfigs["renting-benefit"].requestSteps || [],
      callback: onSave,
    });
  } catch (error) {
    showErrorMessage("There was an error loading the renting steps");
    displayCompanyDetailsPage(companyId);
  }
}
