import React from "react";
import moment from "moment";

import { MonetaryAmount } from "app/domain/monetary-amount";
import CobeeButton from "app/components/CobeeButton";
import { compensateTransactions } from "usecases/transactions/compensate-transactions";

import * as S from "./styles";

export default function CompensationDetail({ trx }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const { concept, amount, password } = e.target;

    compensateTransactions({
      form: {
        concept: concept.value,
        amount: amount.value,
        password: password.value,
      },
      trx,
    });
  };

  return (
    <S.Container className="row">
      <div className="col-sm-12">
        <div className="alert alert-warning" role="alert">
          La transacción va a ser compensada (asumida por Cobee). Comunícaselo a
          finanzas antes de realizar la operación
        </div>
      </div>

      <div className="col-sm-6">
        <div className="card">
          <div className="card-body">
            <h4>Transaction Info</h4>

            <div className="form-group">
              <label className="col-form-label">Transaction Id</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={trx?.id}
                  readOnly
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-6">
                <label className="col-form-label">Card Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={trx?.cardId}
                  readOnly
                />
              </div>

              <div className="col-sm-6">
                <label className="col-form-label">Payroll</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={`${trx?.payrollCycle?.payrollMonth || ""}-${
                      trx?.payrollCycle?.fiscalYear || ""
                    }`}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-form-label">Concept</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={trx?.description}
                  readOnly
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-6">
                <label className="col-form-label">LocalIsoTime</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={moment(trx.userTransactionIsoTime).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="col-form-label">Timestamp</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={trx?.localDateTime}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="card">
          <form className="card-body" onSubmit={handleSubmit}>
            <h4>Compensation</h4>
            <div className="form-group">
              <label className="col-form-label">
                Concept <small> (Optional)</small>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  name="concept"
                  className="form-control"
                  placeholder=""
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-sm-6">
                <label className="col-form-label">Original amount</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={MonetaryAmount.ofCurrency(
                      trx?.originalAmount
                    ).toUnitary()}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <label className="col-form-label">Compensation amount</label>
                <input
                  type="number"
                  name="amount"
                  className="form-control"
                  step="0.01"
                  min="0"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label className="col-form-label">Password</label>
              <div className="input-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  required
                />
              </div>
            </div>

            <S.Hr />

            <S.Actions>
              <CobeeButton type="submit" size="large">
                Send Compensation
              </CobeeButton>
            </S.Actions>
          </form>
        </div>
      </div>
    </S.Container>
  );
}
