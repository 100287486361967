import { makeStyles } from "@material-ui/core/styles";
import { primary, warning } from "../../../../../assets/themeColors";

const useStyles = makeStyles(() => ({
  icon: {
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-11px",
  },
  addIcon: {
    color: primary,
  },
  cancelIcon: {
    color: warning,
  },
}));

export default useStyles;
