import React, { useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";

export default function EditCardModal({ card, callback }) {
  const { container, row, buttonRow, select } = useStyles();
  const [values, setValues] = useState({
    categoryType: card.categoryType,
  });
  const [isLoading, setIsLoading] = useState(false);

  const selectOptions = ["primary", "disuse"];
  if (card.categoryType === "replacement") {
    selectOptions.push(card.categoryType);
  }

  const formIsReady = () => {
    return Boolean(values.categoryType);
  };

  return (
    <Modal title="Edit card">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row}>
            <Typography variant="body2">Category Type:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <Select
              id="step-type-select"
              className={select}
              variant="outlined"
              value={values.categoryType}
              onChange={(e) =>
                setValues({
                  ...values,
                  categoryType: e.target.value,
                })
              }
            >
              <MenuItem value={undefined} disabled>
                Select a type
              </MenuItem>
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            disabled={isLoading || !formIsReady()}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(values);
              setIsLoading(false);
              await hideModal();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
