import React from "react";
import ReactDOM from "react-dom";
import CancelHealthInsuranceModal from "./components/CancelHealthInsuranceModal";

export default function renderCancelHealthInsuranceModal({ callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <CancelHealthInsuranceModal callback={callback} />,
      domTarget
    );
  }
}
