import React from "react";
import ReactDOM from "react-dom";
import RentingSteps from "./index";

export default async function renderEditRentingSteps({ ...rest }) {
  const domTarget = document.getElementById("page-container");
  if (domTarget) {
    ReactDOM.render(<RentingSteps {...rest} />, domTarget);
  }
}
