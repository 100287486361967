import React from "react";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";

const adaptTaxRuleConfig = ({
  name,
  rule: {
    amount,
    period,
    behaviors,
    percentageOfGrossPay,
    minimumHourlyWage,
    incapacityLevels,
    applicableOnlyForRecipient,
  },
}) => {
  const config = {
    "max-exempt": {
      label: "Max exempt",
      data:
        !amount && !period && !behaviors
          ? []
          : [
              {
                label: "Amount",
                value: amount
                  ? MonetaryAmount.ofCurrency(amount).prettifyMoney({})
                  : "",
              },
              {
                label: "Period",
                value: period,
              },
              {
                label: "Behaviors",
                value: (
                  <b>
                    {`${
                      behaviors ? `${behaviors.join(", ")}.` : ""
                    }`.toUpperCase()}
                  </b>
                ),
              },
            ],
    },
    "max-salary-in-kind": {
      label: "Max salary in kind",
      data:
        !percentageOfGrossPay && !period && !behaviors
          ? []
          : [
              {
                label: "Percentage of gross pay",
                value: `${percentageOfGrossPay * 100}%`,
              },
              { label: "Period", value: period },
              {
                label: "Behaviors",
                value: (
                  <b>
                    {`${
                      behaviors ? `${behaviors.join(", ")}.` : ""
                    }`.toUpperCase()}
                  </b>
                ),
              },
            ],
    },
    "max-salary-reduction": {
      label: "Max salary reduction",
      data:
        !minimumHourlyWage && !period && !behaviors
          ? []
          : [
              {
                label: "Minimum hourly wage",
                value: minimumHourlyWage
                  ? MonetaryAmount.ofCurrency(minimumHourlyWage).prettifyMoney(
                      {}
                    )
                  : "",
              },
              {
                label: "Period",
                value: period,
              },
              {
                label: "Behaviors",
                value: (
                  <b>
                    {`${
                      behaviors ? `${behaviors.join(", ")}.` : ""
                    }`.toUpperCase()}
                  </b>
                ),
              },
            ],
    },
    "max-prorated-exempt": {
      label: "Max Prorated Exempt",
      data:
        !period && !behaviors
          ? []
          : [
              {
                label: "Max Prorated Exempt",
                value: amount
                  ? MonetaryAmount.ofCurrency(amount).prettifyMoney({})
                  : "",
              },
              {
                label: "Period",
                value: period,
              },
              {
                label: "Incapacity Levels",
                value: (
                  <b>
                    {`${
                      incapacityLevels ? `${incapacityLevels.join(", ")}.` : ""
                    }`.toUpperCase()}
                  </b>
                ),
              },
              {
                label: "Applicable Only For Recipient",
                value: applicableOnlyForRecipient?.toString(),
              },
            ],
    },
  };
  return config[name];
};

export default adaptTaxRuleConfig;
