import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import useStyles from "./styles";

export default function CustomPagination({
  count,
  page,
  rowsPerPage,
  onChangePage,
}) {
  const {
    container,
    paginationButton,
    prevButton,
    nextButton,
    disabled,
    pageCountText,
  } = useStyles();

  return (
    <div className={container}>
      <Typography variant="body2" className={pageCountText}>
        {`Page ${page + 1} of ${Math.floor(count / rowsPerPage) + 1}`}
      </Typography>
      <Button
        variant="outlined"
        className={`${paginationButton} ${prevButton} ${
          page === 0 && disabled
        }`}
        onClick={() => onChangePage(null, page - 1)}
        disabled={page === 0}
        startIcon={<ChevronLeft />}
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        className={`${paginationButton} ${nextButton} ${
          page === Math.floor(count / rowsPerPage) && disabled
        }`}
        onClick={() => onChangePage(null, page + 1)}
        disabled={page === Math.floor(count / rowsPerPage)}
        endIcon={<ChevronRight />}
      >
        Next
      </Button>
    </div>
  );
}

CustomPagination.defaultProps = {
  count: 0,
  page: 0,
  rowsPerPage: 10,
};

CustomPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};
