import { makeStyles } from "@material-ui/core/styles";
import { secondary } from "../../../../../assets/themeColors";

const useStyles = makeStyles(() => ({
  checkbox: {
    "&:hover": { backgroundColor: "transparent" },
    "& svg": {
      color: secondary,
    },
  },
  buttonContainer: {
    marginLeft: "auto",
  },
}));

export default useStyles;
