import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { editEmployee } from "../../infrastructure/employee-repository";

export async function saveEmployee(employee, cardId) {
  try {
    const res = await editEmployee(employee.companyId, employee);
    if (res.status === 200 && res.data.error === null) {
      showSuccessMessage("Change saved!");
    }
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message || "Error trying to update employee"
    );
  }
}
