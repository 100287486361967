import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Table from "../../../app/components/Table";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";

const RetentionsDetail = ({ retentions }) => {
  const renderAmount = (amount) =>
    MonetaryAmount.ofCurrency(amount).prettifyMoney({});

  return (
    <div className="retentions-detail">
      {["payroll", "yearly"].map((retentionType) => (
        <div key={retentionType} className="retentions-section">
          <Typography variant="h6">{`${retentionType
            .charAt(0)
            .toUpperCase()}${retentionType.slice(1)} Retentions`}</Typography>
          <Table
            columns={[
              { label: "Benefit", id: "benefit" },
              { label: "Amount", id: "amount" },
            ]}
            data={[
              ...retentions[retentionType].breakdown.map((item) => ({
                benefit: item.benefit,
                amount: renderAmount(item.amount),
              })),
              {
                benefit: "Total",
                amount: renderAmount(retentions[retentionType].total),
              },
            ]}
            pageSize={retentions[retentionType].breakdown.length + 1}
          />
        </div>
      ))}
    </div>
  );
};

RetentionsDetail.propTypes = {
  retentions: PropTypes.shape({
    payroll: PropTypes.shape({
      total: PropTypes.object.isRequired,
      breakdown: PropTypes.arrayOf(
        PropTypes.shape({
          benefit: PropTypes.string.isRequired,
          amount: PropTypes.object.isRequired,
        })
      ).isRequired,
    }).isRequired,
    yearly: PropTypes.shape({
      total: PropTypes.object.isRequired,
      breakdown: PropTypes.arrayOf(
        PropTypes.shape({
          benefit: PropTypes.string.isRequired,
          amount: PropTypes.object.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default RetentionsDetail;
