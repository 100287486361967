import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { NavMenu } from "v2/ui/components/NavMenu";
import useStyles from "./styles";
import ContentWrapper from "../../../infrastructure/renders/ContentWrapper";
import CobeeButton from "../../../app/components/CobeeButton";
import CobeeTextField from "../../../app/components/CobeeTextField";
import { findAuthenticationLogs } from "../../../usecases/authentication-logs/find-authentication-logs";
import InfiniteTable from "../../../app/components/InfiniteTable";

export default function AuthenticationLogsSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [haveMadeASearch, setHaveMadeASearch] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [pageSize, setPageSize] = useState(20);

  const handleSearchButton = () => findLogs(1);

  const findLogs = async (newPage = 1) => {
    setIsLoading(true);
    const response = await findAuthenticationLogs({
      searchTerm,
      pageSize,
      page: newPage - 1,
    });
    if (response) {
      if (newPage <= 1) {
        setLogs([...response.content]);
        setPage(1);
      } else if (response.content.length > 0) {
        setLogs([...logs, ...response.content]);
        setPage(newPage);
      }

      if (response.content.length < pageSize) {
        setHasMoreResults(false);
      } else {
        setHasMoreResults(true);
      }

      if (!haveMadeASearch) {
        setHaveMadeASearch(true);
      }
    }

    setIsLoading(false);
  };

  const {
    loaderContainer,
    noMoreResultsMessage,
    moreResultsMessage,
  } = useStyles();

  return (
    <div id="container-app" className="flex">
      <ToastContainer
        id="toast-container"
        position="top-right"
        closeOnClick
        pauseOnVisibilityChange
        pauseOnHover
      />
      <NavMenu />
      <ContentWrapper title="Auth0 Autentication Logs">
        {isLoading ? (
          <Grid
            item
            container
            xs={12}
            justify="center"
            className={loaderContainer}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={10}>
                <Typography variant="body1">Search Term:</Typography>
                <CobeeTextField
                  type="text"
                  disabled={isLoading}
                  minWidth="100%"
                  defaultValue={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} container alignItems="flex-end">
                <CobeeButton
                  disabled={searchTerm === ""}
                  isLoading={isLoading}
                  onClick={() => handleSearchButton()}
                >
                  Search
                </CobeeButton>
              </Grid>
            </Grid>
          </>
        )}
      </ContentWrapper>
      <Grid item xs={12}>
        <Box p={2}>
          <Typography component="li">
            The logs have different lifespans depending on the auth0
            subscription. As of this day (2021-09-01) the subscription has 2
            days of log retention.
          </Typography>
          <Typography component="li">
            Auth does not support searching a space between. Ej:{" "}
            <b>Team A.R.E.P.A es super pro!</b>
          </Typography>
        </Box>
      </Grid>
      {!isLoading && haveMadeASearch && (
        <>
          <Grid item xs={12}>
            <Box p={2}>
              {hasMoreResults && (
                <Typography className={moreResultsMessage}>
                  Have downloaded {logs.length} results. Can search for more
                  results by scrolling down
                </Typography>
              )}

              {!hasMoreResults && (
                <Typography className={noMoreResultsMessage}>
                  Have found {logs.length} results. No more results can be
                  found. Scrolling disabled.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <InfiniteTable
                columns={[
                  { label: "#", dataKey: "index" },
                  { label: "Date", dataKey: "date" },
                  { label: "User ID", dataKey: "userId" },
                  { label: "User Name", dataKey: "userName" },
                  { label: "Type", dataKey: "type" },
                ]}
                data={logs.map((log, index) => ({
                  ...log,
                  index: index + 1,
                  date: moment(log.date).format("YYYY-MM-DD HH:mm"),
                  userName: (
                    <Tooltip title={log.userName} placement="top-start">
                      <span>{log.userName}</span>
                    </Tooltip>
                  ),
                  type: (
                    <Tooltip title={log.type} placement="top-start">
                      <span>{log.type}</span>
                    </Tooltip>
                  ),
                }))}
                handleLoadMoreData={() => findLogs(page + 1)}
                hasPagination={hasMoreResults}
                emptyComponent={<Typography>No results</Typography>}
                totalItems={10000}
              />
            </Box>
          </Grid>
        </>
      )}
    </div>
  );
}
