import { makeStyles } from "@material-ui/core/styles";
import { secondary, white } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  activeButton: {
    backgroundColor: secondary,
    color: white,

    "&:hover": {
      backgroundColor: secondary,
      color: white,
    },
  },
  loaderContainer: {
    padding: theme.spacing(18),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  filterBar: {
    paddingBottom: theme.spacing(3),
  },
  actionButtons: {
    padding: `${theme.spacing(2)}px 0`,
  },
}));

export default useStyles;
