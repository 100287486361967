import { previewInvoicePdf } from "../../infrastructure/finance-repository";
import { showErrorMessage } from "../commons/show-error-message";

export default async function previewInvoice({ invoiceId }) {
  try {
    const { data } = await previewInvoicePdf({ invoiceId });
    const pdfUrl = URL.createObjectURL(data);
    window.open(pdfUrl, "_blank");
  } catch (error) {
    if (error.response?.status === 404) {
      showErrorMessage("Couldn't find the invoice pdf (404)");
    } else {
      showErrorMessage(error.message);
    }
  }
}
