import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked, Warning } from "@material-ui/icons";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { showSuccessMessage } from "usecases/commons/show-success-message";
import { displayCompanyDetailsPage } from "usecases/companies/display-company-details";
import editBenefitConfig from "usecases/benefit-models/editBenefitConfig";

import setBenefitState from "usecases/benefit-models/setBenefitState";
import useStyles, { WarningTooltip } from "./styles";

export default function Toggle({ company, benefitId }) {
  const {
    iconContainer,
    radio,
    checkedRadio,
    radioText,
    activeRadioText,
    warningIconStyles,
  } = useStyles();

  const [areLoading, setAreLoading] = useState([]);

  const benefitHasWarning = () => {
    const benefitsCondition = {
      "renting-benefit":
        company?.benefitConfigs["renting-benefit"]?.requestSteps?.length === 0,
      "life-insurance-benefit":
        company?.benefitConfigs["life-insurance-benefit"]?.requestSteps
          ?.length === 0,
      "retirement-insurance":
        company?.retirementInsuranceBenefit?.products.length === 0,
      "pension-plan": company?.pensionPlanBenefit?.products.length === 0,
    };
    return benefitsCondition[benefitId] || false;
  };

  const getBenefitEnabled = () => {
    const enabledConfig = {
      "renting-benefit": company?.benefitConfigs[benefitId]?.enabled,
      "life-insurance-benefit": company?.benefitConfigs[benefitId]?.enabled,
      "retirement-insurance": company?.retirementInsuranceBenefit?.enabled,
      "pension-plan": company?.pensionPlanBenefit?.enabled,
      unknown: company?.allExpenseConfig?.enabled,
    };

    return enabledConfig[benefitId] || false;
  };

  const benefitTooggleUsecase = async ({ companyId, category, payload }) => {
    const tooggleUsecaseConfig = {
      "renting-benefit": editBenefitConfig,
      "life-insurance-benefit": editBenefitConfig,
      "retirement-insurance": setBenefitState,
      "pension-plan": setBenefitState,
      unknown: setBenefitState,
    };
    await tooggleUsecaseConfig[benefitId]({ companyId, category, payload });
  };

  const getBenefitConfig = () =>
    company.benefitConfigs[benefitId] || {
      enabled: false,
    };

  const toggleBenefit = async () => {
    setAreLoading([
      ...areLoading.filter((item) => item !== benefitId),
      benefitId,
    ]);
    const companyBenefitConfig = getBenefitConfig({ benefitId });
    try {
      await benefitTooggleUsecase({
        companyId: company.id,
        category: benefitId,
        payload: {
          ...companyBenefitConfig,
          enabled: !getBenefitEnabled(),
        },
      });
      showSuccessMessage("Benefit configured successfully");
      displayCompanyDetailsPage(company.id);
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the benefit creation"
      );
    } finally {
      setAreLoading([...areLoading.filter((item) => item !== benefitId)]);
    }
  };

  const getIcon = () =>
    getBenefitEnabled() ? (
      <CheckCircle className={`${radio} ${checkedRadio}`} />
    ) : (
      <RadioButtonUnchecked className={radio} />
    );

  const getToggleOption = () => {
    if (benefitHasWarning()) {
      return (
        <WarningTooltip
          title={<Typography variant="body2">Pending configuration</Typography>}
          placement="right-end"
        >
          <IconButton
            aria-label="pending-configuration"
            className={`${iconContainer} ${warningIconStyles}`}
          >
            <Warning />
          </IconButton>
        </WarningTooltip>
      );
    }
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={iconContainer}
        onClick={toggleBenefit}
      >
        {getIcon()}
        <Typography
          variant="body2"
          className={`${getBenefitEnabled() ? activeRadioText : radioText}`}
        >
          Active
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      {areLoading.find((item) => item === benefitId) ? (
        <CircularProgress size={12} />
      ) : (
        getToggleOption()
      )}
    </>
  );
}

Toggle.propTypes = {
  company: PropTypes.object.isRequired,
  benefitId: PropTypes.string.isRequired,
};
