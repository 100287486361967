import { findBenefitBagDetail } from "../../infrastructure/company-repository";
import renderBenefitBagDetail from "../../infrastructure/renders/ShowBenefitBagDetail/render-show-benefit-bag-detail";
import { hideModal } from "../commons/hide-modal";
import { showErrorMessage } from "../commons/show-error-message";

export default async function showBenefitBagDetail({
  companyId,
  benefitPlanId,
  benefitBagConfigId,
  allowedBenefits,
  alias,
}) {
  try {
    renderBenefitBagDetail({ isLoading: true });
    const {
      data: {
        content: {
          consumableFrom,
          allowedFor,
          periodDurationInPayrolls,
          accumulableAtPeriodEnd,
          limitAmount,
          deliverUnusedExemptToPurses,
          benefitBags,
          editableBenefits,
          renewAtPeriodEnd,
        },
      },
    } = await findBenefitBagDetail({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
    });
    renderBenefitBagDetail({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
      initialDate: consumableFrom,
      employees: benefitBags.map((item) => item.employee),
      availableSummarys: benefitBags.map((item) => item.availableSummary),
      initialLimitAmount: benefitBags.map((item) => item.initialLimitAmount),
      accumulatedFromPreviousPeriod: benefitBags.map(
        (item) => item.accumulatedFromPreviousPeriod
      ),
      activeBenefits: allowedFor.map((item) => item.benefit),
      allowedBenefits,
      editableBenefits,
      adjustments: {
        amount: limitAmount,
        periodicity: periodDurationInPayrolls,
        accumulable: accumulableAtPeriodEnd,
        deliverUnusedExemptToPurses,
      },
      alias,
      renewAtPeriodEnd,
    });
  } catch (error) {
    hideModal();
    showErrorMessage("Could not find the details of this benefit bag");
  }
}
