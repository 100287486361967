export default function adaptWellnessBenefitCreationData({ data }) {
  return {
    ...data,
    products: [...data.products].map(({ id, languages, fee, ...rest }) => ({
      id,
      fee: Math.round(Number(fee) * 100),
      languages: Object.keys(languages).reduce((acc, curr) => {
        const { name, description, attachments } = languages[curr];
        const filteredAttachments = attachments.filter(
          ({ title, url }) => title && url && title !== "" && url !== ""
        );
        if (
          name !== "" &&
          description !== "" &&
          filteredAttachments.length > 0
        ) {
          return {
            ...acc,
            [curr]: { name, description, attachments: filteredAttachments },
          };
        }

        return acc;
      }, {}),
      ...rest,
    })),
  };
}
