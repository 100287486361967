import { addHealthInsuranceComplement } from "../../../infrastructure/health-insurance-repository";
import { HealthInsuranceDetails } from "../displays/health-insurance-details";
import { showErrorMessage } from "../../commons/show-error-message";
import { showSuccessMessage } from "../../commons/show-success-message";

export async function createModalityComplement({ companyId, complement }) {
  const res = await addHealthInsuranceComplement({
    companyId,
    complement,
  });
  if (res.status === 200) {
    showSuccessMessage("Complement created");
    HealthInsuranceDetails({ company: res.data });
  } else {
    showErrorMessage("Error updating complement");
  }
}
