import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { darken, lighten } from "polished";
import { secondary, error, grey300 } from "../../../assets/themeColors";

export const useStyles = makeStyles((theme) => ({
  spinner: {
    color: darken(0.2, grey300),
    marginRight: theme.spacing(1),
  },
}));

export const buttonTypes = makeStyles((theme) => ({
  primary: {
    backgroundColor: secondary,
    color: "white",

    "&:hover": {
      backgroundColor: lighten(0.1, secondary),
      color: "white",
    },
  },
  secondary: {
    backgroundColor: "transparent",
    color: secondary,
    borderColor: secondary,
    borderWidth: 2,

    "&:hover": {
      color: lighten(0.1, secondary),
      borderColor: lighten(0.1, secondary),
    },
  },
  unContained: {
    backgroundColor: "transparent",
    color: secondary,
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "none",

    "& svg": {
      color: ({ iconColor }) => iconColor || secondary,
      width: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px`,
    },

    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: 0,
    },
  },
  iconOnlySecondary: {
    color: secondary,
    padding: 0,
    borderColor: secondary,
    minWidth: 0,
    borderWidth: 2,
    borderRadius: 50,
    width: 32,
    height: 32,

    "&:hover": {
      color: lighten(0.1, secondary),
      borderColor: lighten(0.1, secondary),
    },
  },
  danger: {
    backgroundColor: error,
    borderColor: error,
    color: "white",

    "&:hover": {
      color: "white",
      backgroundColor: lighten(0.2, error),
      borderColor: lighten(0.2, error),
    },

    "&:disabled": {
      backgroundColor: grey300,
      borderColor: grey300,
      color: darken(0.6, grey300),
    },
  },
  default: {
    padding: `0 ${theme.spacing(2)}px`,
  },
}));

export const StyledButton = withStyles((theme) => {
  return {
    root: {
      height: theme.spacing(4),
      borderRadius: theme.spacing(2),

      "&:disabled": {
        border: "none",
      },
    },
  };
})(Button);
