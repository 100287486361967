import React, { Fragment } from "react";
import { v4 } from "uuid";
import PropTypes from "prop-types";
import BInputForm from "../../../../app/components/InputForm/BInputForm";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";

const InsuranceComplementsForm = ({
  complement,
  brokers,
  disabled,
  handleInput,
  handleBrochureInput,
  handlerSave,
  handlerCancel,
}) => {
  return (
    <div>
      <div className="form-group row">
        {complement.complementId && (
          <BInputForm
            label="Complement Id"
            disabled
            value={complement.complementId}
          />
        )}

        <BInputForm
          label="Name"
          value={complement.complementName}
          onChange={(e) =>
            handleInput({
              value: e.target.value,
              property: "complementName",
            })
          }
        />

        <BInputForm
          label="Fee"
          type="number"
          value={MonetaryAmount.ofCurrency(
            complement.complementFee
          ).toUnitary()}
          onChange={(e) =>
            handleInput({
              value: MonetaryAmount.fromUnitary(e.target.value),
              property: "complementFee",
            })
          }
        />
      </div>

      <div className="form-group row">
        <BInputForm
          label="Insurance Company"
          value={complement.insuranceCompany}
          onChange={(e) =>
            handleInput({
              value: e.target.value,
              property: "insuranceCompany",
            })
          }
        />

        <label className="col-sm-2 col-form-label col-form-label-sm">
          Broker
        </label>
        <div className="col-sm-2">
          <select
            className="align-bottom"
            onChange={(e) =>
              handleInput({
                property: "broker",
                value: e.target.value,
              })
            }
          >
            <option defaultValue>{complement.broker}</option>
            {brokers?.map((broker) => (
              <option key={broker} value={broker}>
                {broker}
              </option>
            ))}
          </select>
        </div>

        <BInputForm
          label="Policy"
          value={complement.insurancePolicy}
          onChange={(e) =>
            handleInput({
              value: e.target.value,
              property: "insurancePolicy",
            })
          }
        />
      </div>

      <div className="form-group row">
        <BInputForm
          label="Coverage"
          inputStyle="col-sm-10"
          value={complement.complementCoverage}
          onChange={(e) =>
            handleInput({
              value: e.target.value,
              property: "complementCoverage",
            })
          }
        />
      </div>

      <div className="form-group row">
        {complement.complementBrochures.map((brochure, i) => (
          <Fragment key={v4()}>
            <BInputForm
              label={`Brochure ${i}`}
              placeholder="Title Brochure"
              inputStyle="col-sm-4"
              defaultValue={brochure.title}
              onBlur={(e) =>
                handleBrochureInput({
                  property: "title",
                  value: e.target.value,
                  index: i,
                })
              }
            />
            <BInputForm
              type="url"
              placeholder="Link Brochure"
              inputStyle="col-sm-6"
              defaultValue={brochure.url}
              onBlur={(e) =>
                handleBrochureInput({
                  property: "url",
                  value: e.target.value,
                  index: i,
                })
              }
            />
          </Fragment>
        ))}
        {/* Task COB-3144 Codere case with old form format */}
        {complement.complementBrochures.length === 0 && (
          <>
            <BInputForm
              label="Brochure 0"
              placeholder="Title Brochure"
              inputStyle="col-sm-4"
              defaultValue={complement?.complementBrochures[0]?.title || ""}
              onBlur={(e) =>
                handleBrochureInput({
                  property: "title",
                  value: e.target.value,
                  index: 0,
                })
              }
            />
            <BInputForm
              type="url"
              placeholder="Link Brochure"
              inputStyle="col-sm-6"
              defaultValue={complement?.complementBrochures[0]?.url || ""}
              onBlur={(e) =>
                handleBrochureInput({
                  property: "url",
                  value: e.target.value,
                  index: 0,
                })
              }
            />
          </>
        )}
      </div>

      <div className="form-group">
        <div className="gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-secondary btn-sm mr-2"
            onClick={handlerCancel}
            disabled={disabled}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handlerSave}
            disabled={disabled}
          >
            {complement.complementId ? "Save" : "Create"}
          </button>
        </div>
      </div>

      <hr />
    </div>
  );
};

InsuranceComplementsForm.defaultProps = {
  complement: {},
  brokers: [],
  disabled: false,
  handleInput: () => {},
  handleBrochureInput: () => {},
  handlerSave: () => {},
  handlerCancel: () => {},
};

InsuranceComplementsForm.propTypes = {
  complement: PropTypes.object,
  brokers: PropTypes.array,
  disabled: PropTypes.bool,
  handleInput: PropTypes.func,
  handleBrochureInput: PropTypes.func,
  handlerSave: PropTypes.func,
  handlerCancel: PropTypes.func,
};

export default InsuranceComplementsForm;
