import * as papaparse from "papaparse";

export const parseToCsv = ({ headers, rows }) => {
  return papaparse.unparse(
    {
      fields: headers,
      data: rows,
    },
    {
      escapeFormulae: true,
    }
  );
};
