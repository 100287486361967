import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Wrapper } from "./styles";

export default function CobeeDatePicker({
  customInput,
  selectedDate,
  fullWidth,
  ...rest
}) {
  return (
    <Wrapper fullWidth={fullWidth}>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selected={selectedDate ? new Date(selectedDate) : new Date()}
        customInput={customInput}
        formatWeekDay={(nameOfDay) => (
          <Typography variant="body2">{nameOfDay.substr(0, 1)}</Typography>
        )}
        {...rest}
      />
    </Wrapper>
  );
}

CobeeDatePicker.defaultProps = {
  customInput: null,
  selectedDate: undefined,
  fullWidth: false,
};

CobeeDatePicker.propTypes = {
  customInput: PropTypes.node,
  selectedDate: PropTypes.instanceOf(Date),
  fullWidth: PropTypes.bool,
};
