import { showSuccessMessage } from "../../commons/show-success-message";
import { createInsuranceProduct } from "../../../infrastructure/company-repository";
import { displayCompaniesPage } from "../display-companies-page";
import { showErrorMessage } from "../../commons/show-error-message";

export async function saveInsuranceProduct(companyId, insuranceProduct) {
  const res = await createInsuranceProduct(companyId, insuranceProduct);
  if (res.status === 200 && res.data.response.success) {
    showSuccessMessage("Insurance product created successfully!");
    displayCompaniesPage();
  } else {
    showErrorMessage(res.data.response.reason || "Error!", false);
  }
}
