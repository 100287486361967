import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import CobeeTextField from "../../CobeeTextField";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function TransferMoneyFromTransactionModal({
  isLoading,
  defaultValues,
  callback,
}) {
  const { container, row, buttonRow } = useStyles();

  const [values, setValues] = useState(defaultValues);
  const isInvalid = () => {
    return !values || !values.concept || !values.password || !values.amount;
  };

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Transfer money from transaction</Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">Concept:</Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <CobeeTextField
          type="text"
          disabled={isLoading}
          minWidth="100%"
          onChange={(e) =>
            setValues({
              ...values,
              concept: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">Password:</Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <CobeeTextField
          type="password"
          disabled={isLoading}
          minWidth="100%"
          onChange={(e) =>
            setValues({
              ...values,
              password: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">Amount:</Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item xs={11}>
          <CobeeTextField
            disabled={isLoading}
            minWidth="100%"
            type="number"
            onChange={(e) =>
              setValues({
                ...values,
                amount: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={1} container justify="center" alignItems="center">
          <Typography variant="body2">EUR</Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading || isInvalid()}
          onClick={() => callback({ values })}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
