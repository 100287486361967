import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import { Address } from "../../../../../app/domain/address";

export default function NurseryRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row } = useStyles();

  function optionalMonetaryAmount(payment) {
    return payment ? MonetaryAmount.ofCurrency(payment).prettifyMoney({}) : "";
  }

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty("Nursery ID", recurrentPayment.benefitDetails.nurseryId)}
        {textProperty(
          "Is Next Year Working Mother Exemption Enabled",
          recurrentPayment.benefitDetails
            .isNextYearWorkingMotherExemptionEnabled
        )}
        {textProperty("Nursery", recurrentPayment.benefitDetails.nurseryName)}
        {textProperty(
          "Nursery Address",
          new Address(recurrentPayment.nursery.address).inline()
        )}
        {textProperty(
          "Price",
          optionalMonetaryAmount(recurrentPayment?.benefitDetails?.nurseryPrice)
        )}
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
