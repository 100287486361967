import * as React from "react";
import PropTypes from "prop-types";
import { MUI, MIcon, Button } from "@cobee/cobee-ui";
import { Complement } from "v2/domain/contexts/healthInsurance/entities/Complement";
import { RowDetailsBox } from "./RowDetailsBox";

export const Row = ({
  data,
  id,
  selected,
  onSelect = () => null,
  onClickEdit = () => null,
}) => {
  const [open, setOpen] = React.useState(false);

  const labelId = `enhanced-table-checkbox-${id}`;

  return (
    <>
      <MUI.TableRow
        sx={{ "& td": { borderBottom: "unset" } }}
        hover
        onClick={(event) => {
          if (event.target.closest("button")) {
            return;
          }
          onSelect(event, data.id);
        }}
        role="checkbox"
        aria-checked={selected}
        tabIndex={-1}
        selected={selected}
      >
        <MUI.TableCell>
          <MUI.IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <MIcon.KeyboardArrowUp /> : <MIcon.KeyboardArrowDown />}
          </MUI.IconButton>
        </MUI.TableCell>
        <MUI.TableCell id={labelId} scope="row" padding="none">
          {data.name}
        </MUI.TableCell>
        <MUI.TableCell align="left">
          <MUI.Chip label={data.id} size="small" />
        </MUI.TableCell>
        <MUI.TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {data.fee.parsed()}
        </MUI.TableCell>
      </MUI.TableRow>
      <RowDetailsBox data={data} open={open} />
    </>
  );
};

Row.propTypes = {
  data: PropTypes.instanceOf(Complement).isRequired,
  id: PropTypes.any.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};
