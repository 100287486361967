import XLSX from "xlsx";

export default function downloadBenefitBagFailureFile({ data }) {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Benefit bags",
    Subject: "Benefit bags",
    Author: "Backoffice",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Benefit bag with errors");

  if (data?.length > 0) {
    wb.Sheets["Benefit bag with errors"] = XLSX.utils.json_to_sheet(
      data.map(({ legalId, available, error }) => ({
        DNI: legalId,
        "Saldo (€)": available,
        Errores: error?.message,
      }))
    );
    XLSX.writeFile(wb, "Benefit-bags-edit-failures.xlsx");
  }
}
