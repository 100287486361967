import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { IconButton, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import retirementFile from "assets/templates/retirement-census-template.xlsx";
import pensionPlanFile from "assets/templates/pension-plan-census-template.xlsx";
import { parseRetirementFailures } from "infrastructure/adapters/adaptCensusesFailures/adaptRetirementInsuranceFailures";
import { parsePensionPlanFailures } from "infrastructure/adapters/adaptCensusesFailures/adaptPensionPlanFailures";
import { MonetaryAmount } from "../../app/domain/monetary-amount";
import rentingFile from "../../assets/templates/Renting-Plantilla-Censo.xlsx";
import lifeFile from "../../assets/templates/Life-Plantilla-Censo.xlsx";
import trainingFile from "../../assets/templates/Training-Plantilla-Censo.xlsx";
import healthInsuranceFile from "../../assets/templates/health_insurance_census_template.xlsx";
import wellnessFile from "../../assets/templates/wellness_census_template.xlsx";
import { parseRentingFailures } from "../../infrastructure/adapters/adaptCensusesFailures/adaptRentingFailures";
import { parseLifeFailures } from "../../infrastructure/adapters/adaptCensusesFailures/adaptLifeFailures";
import { parseTrainingFailures } from "../../infrastructure/adapters/adaptTrainingFailures";
import reviewCensusErrors from "../../usecases/census/reviewCensusErrors";
import assignRelative from "../../usecases/census/assignRelative";
import { parseHealthInsuranceFailures } from "../../infrastructure/adapters/adaptCensusesFailures/adaptHealthInsuranceFailures";
import { parseWellnessFailures } from "../../infrastructure/adapters/adaptCensusesFailures/adaptWellnessFailures";

const censusesConfiguration = {
  "renting-benefit": {
    id: "renting",
    title: "renting",
    getPreviousURL: ({ companyId }) => `/companies/${companyId}`,
    errorParsing: parseRentingFailures,
    options: [
      {
        label: "renting",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "plantilla-renting.xlsx";
          link.href = rentingFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Employee Id",
        id: "legalId",
      },
      {
        label: "Description",
        id: "description",
      },
      {
        label: "Energetic efficiency",
        id: "energyEfficiency",
      },
      {
        label: "Vehicle price",
        id: "vehiclePrice",
      },
      {
        label: "Private use",
        id: "privateUseRatio",
      },
      {
        label: "Number of payments",
        id: "numberOfPayments",
      },
      {
        label: "Initial payment by employee",
        id: "initialPaymentByEmployee",
      },
      {
        label: "Initial payment by company",
        id: "initialPaymentByCompany",
      },
      {
        label: "Renting cost per payment (employee)",
        id: "rentingCostPerPaymentEmployee",
      },
      {
        label: "Renting cost per payment (company)",
        id: "rentingCostPerPaymentCompany",
      },
      {
        label: "Service start",
        id: "serviceStart",
      },
      {
        label: "Vat ratio supported by employee",
        id: "vatRatioSupportedByEmployee",
      },
    ],
    dataParser: ({
      initialPayment,
      rentingCostPerPayment,
      privateUseRatio,
      vehiclePrice,
      energyEfficiency,
      serviceStart,
      errors,
      vatRatioSupportedByEmployee,
      ...rest
    }) => ({
      initialPayment: MonetaryAmount.fromUnitary(
        initialPayment || 0
      ).prettifyMoney({}),
      rentingCostPerPayment: MonetaryAmount.fromUnitary(
        rentingCostPerPayment || 0
      ).prettifyMoney({}),
      vehiclePrice: MonetaryAmount.fromUnitary(vehiclePrice || 0).prettifyMoney(
        {}
      ),
      privateUseRatio: `${privateUseRatio * 100}%`,
      energyEfficiency: `${energyEfficiency * 100}%`,
      vatRatioSupportedByEmployee: `${vatRatioSupportedByEmployee * 100}%`,
      serviceStart: moment(serviceStart).format("DD-MM-YYYY"),
      errors: (
        <>
          {errors?.map(({ code, field }) => (
            <Typography
              key={field}
              variant="body2"
            >{`${field}: ${code}`}</Typography>
          ))}
          <IconButton onClick={() => reviewCensusErrors({ errors })}>
            <InfoIcon color="primary" />
          </IconButton>
        </>
      ),
      ...rest,
    }),
  },
  "life-insurance-benefit": {
    id: "life",
    title: "life",
    getPreviousURL: ({ companyId }) => `/companies/${companyId}`,
    errorParsing: parseLifeFailures,
    options: [
      {
        label: "life",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "plantilla-life.xlsx";
          link.href = lifeFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Employee Id",
        id: "legalId",
      },
      {
        label: "Insurance",
        id: "insurancePolicy",
      },
      {
        label: "Mode",
        id: "modalityName",
      },
      {
        label: "Insured capital",
        id: "totalCapitalInCents",
      },
      {
        label: "Monthly premium",
        id: "monthlyFeeInCents",
      },
      {
        label: "Birth date",
        id: "birthDate",
      },
      {
        label: "Grade of incapacity",
        id: "incapacityLevel",
      },
      {
        label: "Sex",
        id: "sex",
      },
      {
        label: "Profession",
        id: "profession",
      },
      {
        label: "Address",
        id: "street",
      },
      {
        label: "Postal code",
        id: "postalCode",
      },
      {
        label: "Locality",
        id: "locality",
      },
      {
        label: "Province",
        id: "province",
      },
    ],
    dataParser: ({
      totalCapitalInCents,
      monthlyFeeInCents,
      birthDate,
      errors,
      homeAddress,
      ...rest
    }) => ({
      totalCapitalInCents: MonetaryAmount.fromUnitary(
        totalCapitalInCents / 100 || 0
      ).prettifyMoney({}),
      monthlyFeeInCents: MonetaryAmount.fromUnitary(
        monthlyFeeInCents / 100 || 0
      ).prettifyMoney({}),
      birthDate: moment(birthDate).format("DD-MM-YYYY"),
      street: homeAddress.street,
      postalCode: homeAddress.postalCode,
      locality: homeAddress.locality,
      province: homeAddress.province,
      errors: (
        <>
          {errors?.map(({ code, field }) => (
            <Typography
              key={field}
              variant="body2"
            >{`${field}: ${code}`}</Typography>
          ))}
          <IconButton onClick={() => reviewCensusErrors({ errors })}>
            <InfoIcon color="primary" />
          </IconButton>
        </>
      ),
      ...rest,
    }),
    sheetParser: ({ homeAddress, ...rest }) => ({
      homeAddress: homeAddress.street,
      ...rest,
    }),
  },
  "education-benefit": {
    id: "training",
    title: "training",
    getPreviousURL: ({ companyId }) => `/companies/${companyId}`,
    errorParsing: parseTrainingFailures,
    options: [
      {
        label: "training",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "plantilla-training.xlsx";
          link.href = trainingFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Employee Id",
        id: "legalId",
      },
      {
        label: "Course name",
        id: "courseName",
      },
      {
        label: "Education centre",
        id: "centreName",
      },
      {
        label: "Start date",
        id: "startDate",
      },
      {
        label: "Last payment date",
        id: "endDate",
      },
      {
        label: "Course cost",
        id: "amount",
      },
      {
        label: "Subsidized by company",
        id: "fromFunds",
      },
      {
        label: "Amount of remaining quotas",
        id: "splitBy",
      },
    ],
    dataParser: ({
      errors,
      amount,
      fromFunds,
      startDate,
      endDate,
      ...rest
    }) => {
      return {
        amount: MonetaryAmount.fromUnitary(amount).prettifyMoney({}),
        fromFunds: MonetaryAmount.fromUnitary(fromFunds).prettifyMoney({}),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        errors: (
          <>
            {errors?.map(({ code, field }) => (
              <Typography
                key={field}
                variant="body2"
              >{`${field}: ${code}`}</Typography>
            ))}
            <IconButton onClick={() => reviewCensusErrors({ errors })}>
              <InfoIcon color="primary" />
            </IconButton>
          </>
        ),
        ...rest,
      };
    },
  },
  "health-insurance-benefit": {
    id: "health",
    title: "health insurance",
    getPreviousURL: ({ companyId }) => `/companies/${companyId}`,
    errorParsing: parseHealthInsuranceFailures,
    options: [
      {
        label: "health insurance",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "health_insurance_census_template.xlsx";
          link.href = healthInsuranceFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Emp. Legal Id",
        id: "employeeLegalId",
      },
      {
        label: "Insurance",
        id: "insurancePolicy",
      },
      {
        label: "Mode",
        id: "modalityName",
      },
      {
        label: "Complements",
        id: "complementsNames",
      },
      {
        label: "Insured legalId",
        id: "legalId",
      },
      {
        label: "Insured name",
        id: "name",
      },
      {
        label: "Insured surname",
        id: "surname",
      },
      {
        label: "Birth date",
        id: "birthDate",
      },
      {
        label: "Type",
        id: "familyMemberType",
      },
      {
        label: "Subtype",
        id: "subtype",
      },
    ],
    dataParser: ({
      rowId,
      errors,
      employeeLegalId,
      insurancePolicy,
      modalityName,
      complementsNames,
      setData,
      wasModified,
      setWasModified,
      insured: {
        legalId,
        name,
        surname,
        birthDate,
        incapacityLevel,
        homeAddress,
        type: familyMemberType,
        subtype,
      } = {},
      ...rest
    }) => {
      return {
        employeeLegalId,
        insurancePolicy,
        modalityName,
        complementsNames: complementsNames?.join(" "),
        legalId,
        name,
        surname,
        birthDate: moment(birthDate).format("DD-MM-YYYY"),
        incapacityLevel,
        homeAddress: `${homeAddress?.streetName} ${homeAddress?.streetNumber}`,
        familyMemberType,
        subtype,
        errors: (
          <>
            {errors?.map(({ code, field, message }) => (
              <Typography key={field} variant="body2">
                {`- ${message}` ?? `${field}: ${code}`}
              </Typography>
            ))}
            <IconButton onClick={() => reviewCensusErrors({ errors })}>
              <InfoIcon color="primary" />
            </IconButton>
            {errors.some(
              ({ code, field }) =>
                field === "insured" && code === "resource-not-found"
            ) &&
              familyMemberType !== "employee" && (
                <IconButton
                  onClick={() => {
                    const modData = ({ relative }) => {
                      setData((prevState) => {
                        return prevState.map(
                          ({ rowId: prevRow, ...properties }) => {
                            if (rowId === prevRow) {
                              setWasModified({
                                ...wasModified,
                                [prevRow]: true,
                              });
                              return {
                                ...rest,
                                errors,
                                rowId: prevRow,
                                data: {
                                  employeeLegalId,
                                  insurancePolicy,
                                  modalityName,
                                  complementsNames,
                                  setData,
                                  insured: {
                                    ...relative,
                                    homeAddress: {
                                      ...relative.homeAddress,
                                      streetName:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[0] || "",
                                      streetNumber:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[1] || "",
                                      details:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[2] || "",
                                    },
                                  },
                                },
                              };
                            }
                            return {
                              ...properties,
                              employeeLegalId,
                              rowId: prevRow,
                            };
                          }
                        );
                      });
                    };
                    assignRelative({
                      relatives:
                        errors.find(({ code }) => code === "resource-not-found")
                          ?.data.familyMembers || [],
                      modData,
                      employee:
                        errors.find(({ code }) => code === "resource-not-found")
                          ?.data.employee || {},
                    });
                  }}
                >
                  {wasModified?.[rowId] ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <SettingsIcon color="primary" />
                  )}
                </IconButton>
              )}
          </>
        ),
        ...rest,
      };
    },
    sheetParser: ({ homeAddress, ...rest }) => ({
      homeAddress: homeAddress.street,
      ...rest,
    }),
  },
  "wellness-benefit": {
    id: "wellness",
    title: "Wellness",
    getPreviousURL: ({ companyId }) => `/companies/${companyId}`,
    errorParsing: parseWellnessFailures,
    options: [
      {
        label: "wellness",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "wellness_census_template.xlsx";
          link.href = wellnessFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Product code",
        id: "productCode",
      },
      {
        label: "Emp. Legal Id",
        id: "employeeLegalId",
      },
      {
        label: "Applicant`s id",
        id: "applicantId",
      },
      { label: "Type", id: "type" },
      { label: "Subtype", id: "subtype" },
      {
        label: "Applicant`s name",
        id: "applicantName",
      },
      {
        label: "Applicant`s surnames",
        id: "applicantSurnames",
      },
      {
        label: "Applicant`s birth date",
        id: "applicantBirthDate",
      },
    ],
    dataParser: ({
      rowId,
      productCode,
      errors,
      employeeLegalId,
      applicant: { legalId, subtype, name, surname, birthDate, type },
      startDayOfYear,
      wasModified,
      setWasModified,
      setData,
      ...rest
    }) => {
      return {
        productCode,
        employeeLegalId,
        applicantId: legalId,
        type,
        subtype,
        applicantName: name,
        applicantSurnames: surname,
        effectiveDate: startDayOfYear
          ? moment(startDayOfYear).format("DD/MM/YYYY")
          : "",
        applicantBirthDate: birthDate
          ? moment(birthDate).format("DD/MM/YYYY")
          : "",
        errors: (
          <>
            {errors?.map(({ code, field }) => (
              <Typography
                key={field}
                variant="body2"
              >{`${field}: ${code}`}</Typography>
            ))}
            <IconButton onClick={() => reviewCensusErrors({ errors })}>
              <InfoIcon color="primary" />
            </IconButton>
            {errors.some(
              ({ code, field, data }) =>
                field === "applicant" &&
                code === "resource-not-found" &&
                data.familyMembers.length > 0
            ) &&
              subtype !== "employee" && (
                <IconButton
                  onClick={() => {
                    const modData = ({ relative }) => {
                      setData((prevState) => {
                        return prevState.map(
                          ({ rowId: prevRow, ...properties }) => {
                            if (rowId === prevRow) {
                              setWasModified({
                                ...wasModified,
                                [prevRow]: true,
                              });
                              return {
                                ...rest,
                                errors,
                                rowId: prevRow,
                                data: {
                                  employeeLegalId,
                                  setData,
                                  applicant: {
                                    ...relative,
                                    homeAddress: {
                                      ...relative.homeAddress,
                                      streetName:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[0] || "",
                                      streetNumber:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[1] || "",
                                      details:
                                        relative?.homeAddress?.street?.split(
                                          ","
                                        )?.[2] || "",
                                    },
                                  },
                                },
                              };
                            }
                            return {
                              ...properties,
                              employeeLegalId,
                              rowId: prevRow,
                            };
                          }
                        );
                      });
                    };
                    assignRelative({
                      relatives:
                        errors.find(({ code }) => code === "resource-not-found")
                          ?.data.familyMembers || [],
                      modData,
                      employee:
                        errors.find(({ code }) => code === "resource-not-found")
                          ?.data.employee || {},
                    });
                  }}
                >
                  {wasModified?.[rowId] ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <SettingsIcon color="primary" />
                  )}
                </IconButton>
              )}
          </>
        ),
        ...rest,
      };
    },
    sheetParser: ({
      ProductId,
      EmployeeId,
      Type,
      Subtype,
      ApplicantId,
      ApplicantName,
      ApplicantSurnames,
      ServiceEffectiveDate,
      ApplicantBirthDate,
      ApplicantAddressLocality,
      ApplicantAddressPostalCode,
      ApplicantAddressProvince,
      ApplicantAddressStreetName,
      ApplicantAddressStreetNumber,
      ApplicantAddressDetails,
    }) => ({
      employeeLegalId: EmployeeId,
      productName: ProductId,
      startDayOfYear: moment(ServiceEffectiveDate).format("YYYY-MM-DD"),
      applicant: {
        type: Type,
        subtype: Subtype,
        name: ApplicantName,
        surname: ApplicantSurnames,
        legalId: ApplicantId,
        birthDate: moment(ApplicantBirthDate).format("YYYY-MM-DD"),
        homeAddress: {
          streetName: ApplicantAddressStreetName,
          streetNumber: ApplicantAddressStreetNumber,
          details: ApplicantAddressDetails,
          postalCode: ApplicantAddressPostalCode,
          province: ApplicantAddressProvince,
          locality: ApplicantAddressLocality,
        },
      },
    }),
  },
  "retirement-insurance-benefit": {
    id: "retirement-insurance",
    title: "Retirement insurance",
    getPreviousURL: ({ companyId }) =>
      `/companies/benefit-config/retirement-insurance?company=${companyId}`,
    errorParsing: parseRetirementFailures,
    options: [
      {
        label: "retirement insurance",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "retirement-census-template.xlsx";
          link.href = retirementFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Employee legal id",
        id: "employeeLegalId",
      },
      {
        label: "Previous company contribution",
        id: "previousCompanyContribution",
      },
      {
        label: "Previous employee contribution",
        id: "previousEmployeeContribution",
      },
      {
        label: "Accepted date",
        id: "acceptedDate",
      },
      {
        label: "Policy number",
        id: "policyNumber",
      },
    ],
    dataParser: ({
      previousCompanyContribution,
      previousEmployeeContribution,
      errors,
      ...rest
    }) => ({
      previousCompanyContribution: MonetaryAmount.ofCurrency(
        previousCompanyContribution || 0
      ).prettifyMoney({}),
      previousEmployeeContribution: MonetaryAmount.ofCurrency(
        previousEmployeeContribution || 0
      ).prettifyMoney({}),
      errors: (
        <>
          {errors?.map(({ code, field }) => (
            <Typography
              key={field}
              variant="body2"
            >{`${field}: ${code}`}</Typography>
          ))}
          {errors.length > 0 && (
            <IconButton onClick={() => reviewCensusErrors({ errors })}>
              <InfoIcon color="primary" />
            </IconButton>
          )}
        </>
      ),
      ...rest,
    }),
  },
  "pension-plan-benefit": {
    id: "pension-plan",
    title: "Pension Plan",
    getPreviousURL: ({ companyId }) =>
      `/companies/benefit-config/pension-plan-benefit?company=${companyId}`,
    errorParsing: parsePensionPlanFailures,
    options: [
      {
        label: "pension plan",
        icon: <GetAppIcon />,
        action: () => {
          const link = document.createElement("a");
          link.download = "pension-plan-census-template.xlsx";
          link.href = pensionPlanFile;
          link.click();
        },
      },
    ],
    tableColumns: [
      {
        label: "Employee legal id",
        id: "employeeLegalId",
      },
      {
        label: "Previous company contribution",
        id: "previousCompanyContribution",
      },
      {
        label: "Previous employee contribution",
        id: "previousEmployeeContribution",
      },
      {
        label: "Accepted date",
        id: "acceptedDate",
      },
    ],
    dataParser: ({
      previousCompanyContribution,
      previousEmployeeContribution,
      errors,
      ...rest
    }) => ({
      previousCompanyContribution: MonetaryAmount.ofCurrency(
        previousCompanyContribution || 0
      ).prettifyMoney({}),
      previousEmployeeContribution: MonetaryAmount.ofCurrency(
        previousEmployeeContribution || 0
      ).prettifyMoney({}),
      errors: (
        <>
          {errors?.map(({ code, field }) => (
            <Typography
              key={field}
              variant="body2"
            >{`${field}: ${code}`}</Typography>
          ))}
          {errors.length > 0 && (
            <IconButton onClick={() => reviewCensusErrors({ errors })}>
              <InfoIcon color="primary" />
            </IconButton>
          )}
        </>
      ),
      ...rest,
    }),
  },
};

export default censusesConfiguration;
