import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { black, grey300 } from "../../../assets/themeColors";

export const textFieldTypes = makeStyles({
  default: {
    borderColor: grey300,
    color: black,
    minWidth: ({ minWidth }) => minWidth || 440,
  },
  small: {
    maxWidth: "100px",
  },
});

export const StyledTextField = withStyles((theme) => {
  return {
    root: {
      height: theme.spacing(4),

      "& label.Mui-focused": {},
      "& .MuiInput-underline:after": {},
      "& .MuiOutlinedInput-root": {
        height: theme.spacing(4),
        fontSize: theme.typography.body2,
        padding: 0,
        "& fieldset": {
          borderRadius: 0,
          borderWidth: 1,
        },
        "&:hover fieldset": {
          borderColor: grey300,
          borderWidth: 1,
        },
        "&.Mui-focused fieldset": {
          borderColor: grey300,
          borderWidth: 1,
        },
      },
    },
  };
})(TextField);
