import { makeStyles } from "@material-ui/core/styles";
import { primary, secondary } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  containerModal: {
    width: "80vw",
  },
  currentPeriodContainer: {
    borderRadius: 16,
    backgroundColor: primary,
    width: "100%",
    padding: `${theme.spacing(1)}px 0`,
    textAlign: "center",
  },
  benefitItem: {
    padding: theme.spacing(2),
    borderRadius: 16,
    textAlign: "center",
    width: "fit-content",
    backgroundColor: "#E5F0FF",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  section: {
    borderRadius: 16,
    border: `1px solid ${secondary}`,
    margin: `${theme.spacing(3)}px 0`,
    padding: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  editText: {
    color: secondary,
    marginLeft: "auto",
    cursor: "pointer",
  },
}));

export default useStyles;
