import XLSX from "xlsx";

export default function downloadEmployeesLaborDaysFailureFile({ data }) {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Labor days",
    Subject: "Labor days",
    Author: "Backoffice",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Labor days with errors");

  if (data?.length > 0) {
    wb.Sheets["Labor days with errors"] = XLSX.utils.json_to_sheet(
      data.map(({ legalId, daysPerMonth, error }) => ({
        DNI: legalId,
        "Días laborables (#)": daysPerMonth,
        Errores: error,
      }))
    );
    XLSX.writeFile(wb, "employee-labor-days-failures.xlsx");
  }
}
