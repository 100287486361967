import React, { useState } from "react";
import { Grid, Typography, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import { v4 } from "uuid";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import CobeeTextField from "../../CobeeTextField";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import { MonetaryAmount } from "../../../domain/monetary-amount";

const amountTypeByBehaviour = {
  "flex jit": {
    behaviour: "flex",
    benefitBag: false,
    purse: false,
  },
  "flex purse": {
    behaviour: "flex",
    benefitBag: false,
    purse: true,
  },
  "allowance jit": {
    behaviour: "allowance",
    benefitBag: false,
    purse: false,
  },
  "allowance purse": {
    behaviour: "allowance",
    benefitBag: false,
    purse: true,
  },
  "allowance bag": {
    behaviour: "allowance",
    benefitBag: true,
    purse: false,
  },
  "flex-not-exempt jit": {
    behaviour: "flex-not-exempt",
    benefitBag: false,
    purse: false,
  },
  "flex-not-exempt purse": {
    behaviour: "flex-not-exempt",
    benefitBag: false,
    purse: true,
  },
  "allowance-not-exempt jit": {
    behaviour: "allowance-not-exempt",
    benefitBag: false,
    purse: false,
  },
  "allowance-not-exempt purse": {
    behaviour: "allowance-not-exempt",
    benefitBag: false,
    purse: true,
  },
  "allowance-not-exempt bag": {
    behaviour: "allowance-not-exempt",
    benefitBag: true,
    purse: false,
  },
};

const getValueTemplate = ({ type, amount }) => {
  if (type in amountTypeByBehaviour) {
    return { ...amountTypeByBehaviour[type], amount };
  }
  return {};
};

export default function RestoreVoidTransactionModal({
  isLoading,
  behaviours,
  payrollCycles,
  bagAmount,
  intendedAmount,
  pursesAmount,
  allowance,
  allowanceNotExempt,
  flex,
  flexNotExempt,
  callback,
  defaultValues,
  type,
}) {
  const {
    container,
    row,
    header,
    buttonRow,
    select,
    behaviourRow,
    divider,
    currencyContainer,
  } = useStyles();
  const [values, setValues] = useState(
    defaultValues || { payrollId: "", data: {} }
  );
  const [selectedBehaviours, setSelectedBehaviours] = useState([]);

  const formatLabel = (time) => {
    if (time === null) {
      return "Current payroll";
    }
    if (time === -1) {
      return "No payroll";
    }
    return moment.unix(time).format("MMM YYYY");
  };

  const setPayrollId = (e) => {
    setValues((prev) => ({ ...prev, payrollId: e.target.value }));
  };

  const setBehaviours = (e) => {
    if (type === "accumulation-delivery" || type === "allowance-delivery") {
      setSelectedBehaviours([
        {
          behaviour: e.target.value,
        },
      ]);
    } else {
      setSelectedBehaviours(
        e.target.value.map((behaviour) => ({
          behaviour,
        }))
      );
    }
  };

  const handleAmountChange = ({ value, amountType, behaviour, currency }) => {
    const key = `${behaviour} ${amountType}`;
    setValues((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [key]: getValueTemplate({
          type: key,
          amount: MonetaryAmount.ofCurrency({
            amountInCents: Math.round(Number(value) * 100),
            currency,
          }),
        }),
      },
    }));
  };

  const formIsReady = () => {
    return (
      "password" in values && values.payrollId !== "" && behaviours.length > 0
    );
  };

  const filterByType = (behaviour) => {
    if (type === "accumulation-delivery" || type === "allowance-delivery") {
      if (behaviour.includes("-not-exempt")) {
        return false;
      }
    }
    return true;
  };

  const isAllowance = ({ behaviour }) =>
    behaviour === "allowance" || behaviour === "allowance-not-exempt";

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={`${row}, ${header}`}>
        <Typography variant="h5">Restore a void transaction</Typography>
        <Typography variant="h5">
          {intendedAmount
            ? MonetaryAmount.ofCurrency(intendedAmount).prettifyMoney({})
            : "-"}
        </Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={3} className={row}>
          <Typography variant="body2">Available:</Typography>
        </Grid>
        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Bag:{" "}
            <b>
              {bagAmount
                ? MonetaryAmount.ofCurrency(bagAmount).prettifyMoney({})
                : "-"}
            </b>
          </Typography>
        </Grid>
        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Allowance: <b>{allowance ? allowance.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>

        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Allowance not exempt:{" "}
            <b>
              {allowanceNotExempt ? allowanceNotExempt.prettifyMoney({}) : "-"}
            </b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={3} className={row} />
        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Flex: <b>{flex ? flex.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>

        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Flex not exempt:{" "}
            <b>{flexNotExempt ? flexNotExempt.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row}>
          <Typography variant="body2">Payroll cycle:</Typography>
        </Grid>

        <Grid item container xs={10} className={row}>
          <Select
            className={select}
            onChange={setPayrollId}
            disabled={!payrollCycles}
            value={values.payrollId}
          >
            {payrollCycles &&
              payrollCycles.map(({ endTimestamp, id }) => (
                <MenuItem key={v4()} value={id}>
                  {formatLabel(endTimestamp)}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>

      <Grid item container xs={2} className={row}>
        <Typography variant="body2">Behaviours:</Typography>
      </Grid>

      <Grid item container xs={10} className={row}>
        <Select
          className={select}
          onChange={setBehaviours}
          multiple={
            type !== "accumulation-delivery" && type !== "allowance-delivery"
          }
          disabled={!behaviours}
          value={selectedBehaviours.map(({ behaviour }) => behaviour)}
          renderValue={(selected) =>
            selected.reduce(
              (acc, behaviour, i) =>
                `${acc}${behaviour}${i === selected.length - 1 ? "" : ", "}`,
              ""
            )
          }
        >
          {behaviours &&
            behaviours.filter(filterByType).map((behaviour) => (
              <MenuItem key={v4()} value={behaviour}>
                {behaviour}
              </MenuItem>
            ))}
        </Select>
      </Grid>

      {selectedBehaviours.map(({ behaviour }, i) => (
        <Grid
          item
          container
          xs={12}
          className={`${row} ${behaviourRow}`}
          key={behaviour}
        >
          <Grid item xs={2} container alignItems="center">
            <Typography variant="body2">{behaviour}:</Typography>
          </Grid>

          <Grid
            item
            xs={isAllowance({ behaviour }) ? 2 : 4}
            container
            direction="row"
            alignItems="center"
          >
            <CobeeTextField
              label="From company balance"
              type="number"
              defaultValue={0}
              minWidth="100%"
              min={0}
              onChange={(e) =>
                handleAmountChange({
                  value: e.target.value,
                  amountType: "jit",
                  behaviour,
                  currency: intendedAmount.currency,
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={1}
            className={currencyContainer}
            container
            alignContent="center"
          >
            <Typography variant="body2">
              {intendedAmount ? intendedAmount.currency : "-"}
            </Typography>
          </Grid>

          <Grid
            item
            xs={isAllowance({ behaviour }) ? 2 : 4}
            container
            direction="row"
            alignItems="center"
          >
            <CobeeTextField
              label="From purse balance"
              type="number"
              defaultValue={0}
              minWidth="100%"
              min={0}
              onChange={(e) =>
                handleAmountChange({
                  value: e.target.value,
                  amountType: "purse",
                  behaviour,
                  currency: intendedAmount.currency,
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={1}
            className={currencyContainer}
            container
            alignContent="center"
          >
            <Typography variant="body2">
              {intendedAmount ? intendedAmount.currency : "-"}
            </Typography>
          </Grid>

          {isAllowance({ behaviour }) && (
            <>
              <Grid item xs={2} container direction="row" alignItems="center">
                <CobeeTextField
                  label="From benefit bag"
                  type="number"
                  defaultValue={0}
                  minWidth="100%"
                  min={0}
                  onChange={(e) =>
                    handleAmountChange({
                      value: e.target.value,
                      amountType: "bag",
                      behaviour,
                      currency: intendedAmount.currency,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={1}
                className={currencyContainer}
                container
                alignContent="center"
              >
                <Typography variant="body2">
                  {intendedAmount ? intendedAmount.currency : "-"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      ))}

      <Grid item container xs={12} className={divider} />

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row}>
          <Typography variant="body2">Password:</Typography>
        </Grid>
        <Grid item container xs={10} className={row}>
          <CobeeTextField
            type="password"
            disabled={isLoading}
            minWidth="100%"
            onChange={(e) =>
              setValues({
                ...values,
                password: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading || !formIsReady()}
          isLoading={isLoading}
          onClick={() => {
            callback({ values });
          }}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
