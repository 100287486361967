export const parseLifeFailures = (data) => {
  return data.map(
    ({
      data: {
        legalId,
        insurancePolicy,
        modalityName,
        totalCapitalInCents,
        monthlyFeeInCents,
        birthDate,
        incapacityLevel,
        sex,
        profession,
        homeAddress,
      },
      ...rest
    }) => ({
      data: {
        "DNI Empleado": legalId,
        "Número de póliza": insurancePolicy,
        Modalidad: modalityName,
        "Capital asegurado (€)": totalCapitalInCents / 100,
        "Prima mensual (€)": monthlyFeeInCents / 100,
        "Fecha de nacimiento del asegurado (aaaa-mm-aa)": birthDate,
        "Grado de discapacidad (%)": parseInt(
          incapacityLevel.split("%")[0],
          10
        ),
        Sexo: sex === "male" ? "Hombre" : "Mujer",
        Profesión: profession,
        Dirección: homeAddress.street,
        "Código postal": homeAddress.postalCode,
        Localidad: homeAddress.locality,
        Provincia: homeAddress.province,
      },
      ...rest,
    })
  );
};
