import apiClient from "./api-client";

export async function updateHealthInsuranceEmailsSubscribers({
  companyId,
  emails,
}) {
  try {
    const res = await apiClient().put(
      `/v1/back-office/companies/${companyId}/update-health-insurance-requests-email-subscriber`,
      { emails }
    );
    return res;
  } catch (error) {
    return {};
  }
}

export async function addHealthInsuranceComplement({ companyId, complement }) {
  try {
    const res = await apiClient().put(
      `/v1/back-office/companies/${companyId}/add-health-insurance-complements`,
      { complement }
    );
    return res;
  } catch (error) {
    return {};
  }
}

export async function updateHealthInsuranceComplement({
  companyId,
  complement,
}) {
  try {
    const res = await apiClient().put(
      `/v1/back-office/companies/${companyId}/update-health-insurance-complements/${complement.complementId}`,
      { complement }
    );
    return res;
  } catch (error) {
    const { response: responseError } = error;
    return responseError;
  }
}

export async function updateHealthInsuranceModality({ companyId, modality }) {
  try {
    const res = await apiClient().put(
      `/v1/back-office/companies/${companyId}/update-health-insurance-modality/${modality.modalityId}`,
      { modality }
    );
    return res;
  } catch (error) {
    return {};
  }
}

export async function updateHealthInsuranceFirstExec({
  recurrentPaymentId,
  firstExecTimestamp,
}) {
  const res = await apiClient().patch(
    `/v1/back-office/recurrent-payments/${recurrentPaymentId}`,
    {
      firstExecTimestamp,
    }
  );
  return res;
}
