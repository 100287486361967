import React from "react";
import ReactDOM from "react-dom";
import MovementsPage from "./components/MovementsPage";
import { findCompanies } from "../../infrastructure/company-repository";
import { displayLoader } from "../commons/render-loader";

export async function displayMovementsPage() {
  displayLoader();
  const companies = await findCompanies();
  renderMovementsPage([{ id: "all", companyName: "TODAS" }].concat(companies));
}

export function renderMovementsPage(companies) {
  ReactDOM.render(
    <MovementsPage companies={companies} />,
    document.getElementById("page-container")
  );
}
