import { MonetaryAmount } from "../../app/domain/monetary-amount";
import { adaptExcelPayload } from "../../infrastructure/adapters/adaptExcelPayload";
import renderGenericModalErrors from "../../infrastructure/renders/ShowGenericModalErrors/render-generic-modal-errors";
import parseRowsFromExcel from "../../services/parseRowsFromExcel";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";
import downloadBenefitBagFailureFile from "./donwload-benefit-bag-failure-file";
import editBenefitBagAvailable from "./edit-benefit-bag-available";
import showBenefitBagDetail from "./show-benefit-bag-details";

export default async function uploadBenefitBagAvailableFromFile({
  companyId,
  benefitPlanId,
  benefitConfigId,
  fileName,
  allowedBenefits,
  alias,
}) {
  try {
    const data = await parseRowsFromExcel(fileName);
    const adaptedRows = data.map((item) =>
      adaptExcelPayload({ type: "benefit-bag-available-config", item })
    );
    const payload = adaptedRows.map(({ legalId, available }) => ({
      legalId,
      available: MonetaryAmount.fromUnitary(parseFloat(available)),
    }));
    const {
      data: {
        content: { bagsWithError },
      },
    } = await editBenefitBagAvailable({
      companyId,
      benefitPlanId,
      benefitConfigId,
      payload,
    });
    if (bagsWithError.length === 0) {
      showSuccessMessage("Successfully updated");
      await showBenefitBagDetail({
        companyId,
        benefitPlanId,
        benefitBagConfigId: benefitConfigId,
        allowedBenefits,
        alias,
      });
    } else {
      renderGenericModalErrors({
        title: "Benefit bag errors",
        errors: bagsWithError,
        callback: async () => {
          downloadBenefitBagFailureFile({ data: bagsWithError });
          await showBenefitBagDetail({
            companyId,
            benefitPlanId,
            benefitBagConfigId: benefitConfigId,
            allowedBenefits,
            alias,
          });
        },
      });
    }
  } catch (error) {
    showErrorMessage("There was an error uploading the spreadsheet file");
  }
}
