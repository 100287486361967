import { styled } from "@material-ui/core/styles";

export const Container = styled("div")({
  width: "70vw",
});

export const Actions = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

export const Hr = styled("hr")({
  margin: "37px 0",
});
