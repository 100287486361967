import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { ReactComponent as EmptyListIllustration } from "../../../assets/img/empty-list.svg";

export default function EmptyDataForTableMessage({ message }) {
  const { svg } = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <EmptyListIllustration className={svg} />
      <Typography variant="body1" align="center">
        {message}
      </Typography>
    </Grid>
  );
}

EmptyDataForTableMessage.defaultProps = {
  message: undefined,
};

EmptyDataForTableMessage.propTypes = {
  message: PropTypes.string,
};
