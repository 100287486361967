import apiClient from "./api-client";

export async function deactivateCard({ cardId, employeeId }) {
  const res = await apiClient().put(
    `/v1/back-office/employees/${employeeId}/cards/${cardId}/deactivate`
  );
  return res.data.content;
}

export async function requestDuplicateCard({
  cardId,
  employeeId,
  replacementReason,
}) {
  const res = await apiClient().put(
    `/v1/back-office/employees/${employeeId}/cards/${cardId}/request-duplicate`,
    { replacementReason }
  );
  return res.data.content;
}

export async function editCard({ cardId, employeeId, data }) {
  const res = await apiClient().put(
    `/v1/back-office/employees/${employeeId}/cards/${cardId}`,
    data
  );
  return res.data.content;
}

export async function editCardId({ oldCardId, employeeId, newCardId }) {
  const res = await apiClient().put(
    `/v1/back-office/employees/${employeeId}/cards/${oldCardId}/id`,
    { cardId: newCardId }
  );
  return res.data.content;
}

export async function allowRequestNewCard({ employeeId, cardId }) {
  const res = await apiClient().post(
    `/v1/back-office/employees/${employeeId}/cards/${cardId}/allow-request-new`
  );
  return res.data.content;
}

export async function cancelCard({ employeeId, cardId }) {
  await apiClient().delete(
    `/v1/back-office/employees/${employeeId}/cards/${cardId}`
  );
}
