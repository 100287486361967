import PropTypes from "prop-types";
import * as React from "react";
import { Checkbox } from "@cobee/cobee-ui";

export const ReadOnlyCheckbox = ({ label = "", checked = false } = {}) => (
  <Checkbox
    label={label}
    onChange={() => {}}
    checked={checked}
    sx={{ pointerEvents: "none" }}
  />
);

ReadOnlyCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.string,
};

ReadOnlyCheckbox.defaultProps = {
  label: "",
  checked: "",
};
