import React from "react";
import { Radio } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { black, secondary, error, grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  row: {
    padding: `${theme.spacing(1)}px 0`,
  },
  buttonRow: {
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  divider: {
    height: 1,
    backgroundColor: grey300,
    margin: `${theme.spacing(1)}px 0`,
  },
  checkbox: {
    "&:hover": { backgroundColor: "transparent" },
    "& svg": {
      color: secondary,
    },
  },
  select: {
    borderColor: grey300,
    color: black,
    minWidth: 440,
    height: 37,
  },
  datePicker: {
    border: `1px solid ${grey300}`,
    minWidth: 440,
    height: 37,
    display: "flex",
    alignItems: "center",
    textIndent: theme.spacing(1),
    cursor: "pointer",
  },
  capitalContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    border: `1px solid ${grey300}`,
    marginBottom: theme.spacing(1),
  },
  validURLMessage: {
    color: error,
    marginLeft: theme.spacing(1),
  },
}));

export const CustomRadio = withStyles({
  root: {
    color: secondary,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      color: secondary,

      "&:hover": { backgroundColor: "transpar ent" },
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

export default useStyles;
