import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import { optionalMonetaryAmount } from "../../../../../infrastructure/format";

export default function WellnessRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty("Benefit", recurrentPayment?.wellnessVendor?.benefit)}
        {textProperty("Vendor", recurrentPayment?.wellnessVendor?.name)}
        {textProperty("Product", recurrentPayment?.wellnessProduct?.name)}
        {textProperty(
          "Description",
          recurrentPayment?.wellnessProduct?.description
        )}
        {textProperty(
          "Fee",
          optionalMonetaryAmount(recurrentPayment?.wellnessProduct?.fee)
        )}
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
