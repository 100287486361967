import React from "react";
import ReactDOM from "react-dom";
import { findCompanyDetail } from "../../infrastructure/company-repository";
import {
  findEmployeeInsuranceModalities,
  findEmployeeHealthInsuranceModalitiesForNextPeriod,
  findFamilyMembers,
} from "../../infrastructure/employee-repository";
import EmployeeHealthInsurance from "./components/EmployeeHealthInsurance";
import { displayLoader } from "../commons/render-loader";

export async function displayEmployeeHealthInsurance({
  employee,
  healthInsurance = null,
  numMp,
  isNextPeriod = false,
}) {
  displayLoader("employee-detail");
  const [modalities, company, familyMembers] = await Promise.all([
    isNextPeriod
      ? findEmployeeHealthInsuranceModalitiesForNextPeriod(
          employee.id,
          employee.companyId
        )
      : findEmployeeInsuranceModalities(employee.id),
    findCompanyDetail(employee.companyId),
    findFamilyMembers(employee.id),
  ]);

  renderEmployeeHealthInsurance({
    modalities: isNextPeriod ? modalities.content : modalities,
    employee: { ...employee, familyMembers },
    healthInsurance,
    numMp,
    lastPayrollClose: company.lastPayrollCloseIsoTime,
    companyId: company.id,
    isNextPeriod,
  });
}

function renderEmployeeHealthInsurance({
  modalities,
  employee,
  healthInsurance,
  numMp,
  lastPayrollClose,
  isNextPeriod,
  companyId,
}) {
  ReactDOM.render(
    <EmployeeHealthInsurance
      modalities={modalities}
      numMp={numMp}
      employee={employee}
      healthInsurance={healthInsurance}
      lastPayrollClose={lastPayrollClose}
      isNextPeriod={isNextPeriod}
      companyId={companyId}
    />,
    document.getElementById("employee-detail")
  );
}
