import { showSuccessMessage } from "../commons/show-success-message";
import * as cardRepository from "../../infrastructure/card-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function allowRequestNewCard({ employeeId, cardId }) {
  try {
    const card = await cardRepository.allowRequestNewCard({
      employeeId,
      cardId,
    });
    showSuccessMessage(
      "A new card has been created and all previous cards have been cancelled"
    );
    return card;
  } catch (e) {
    showErrorMessage(
      getServerErrorMessage(e, "An unexpected error has occurred")
    );
    return false;
  }
}
