import { deleteHealthInsurance } from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";

export async function removeHealthInsurance(
  employee,
  insuredId,
  reason,
  numMp
) {
  const res = await deleteHealthInsurance(employee.id, insuredId, reason);

  if (res.data.success) {
    showSuccessMessage("Successfully removed!");
    displayEmployeeDetail(employee.email, numMp);
  } else {
    showErrorMessage(`Error: ${res.data.reason}`);
  }
}
