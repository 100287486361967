import React from "react";

const InsuranceISaludBrokerForm = ({ handlerInput }) => {
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Company phone number</label>
      <div className="col-sm-10">
        <input
          required
          type="tel"
          className="form-control"
          placeholder="Compañía aseguradora"
          pattern="[0-9]{9}"
          onChange={(e) =>
            handlerInput({
              property: "companyPhone",
              value: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export default InsuranceISaludBrokerForm;
