export class Address {
  constructor({
    street,
    streetName,
    streetNumber,
    details,
    postalCode,
    province,
    locality,
  }) {
    if (street && !streetName) {
      const [
        legacyStreetName,
        legacyStreetNumber,
        legacyStreetDetails,
      ] = street.split(", ");
      this.streetName = legacyStreetName;
      this.streetNumber = legacyStreetNumber;
      this.details = legacyStreetDetails;
    } else {
      this.streetName = streetName?.toString()?.trim();
      this.streetNumber = streetNumber?.toString()?.trim();
      this.details = details?.toString()?.trim();
      this.street =
        street?.toString()?.trim() ||
        [this.streetName, this.streetNumber, this.details]
          .filter((e) => e)
          .join(", ");
    }

    this.postalCode = postalCode?.toString()?.trim();
    this.province = province?.toString()?.trim();
    this.locality = locality?.toString()?.trim();
  }

  inline() {
    if (this.streetName && this.streetName !== "") {
      return `${this.streetName}, ${this.streetNumber}, ${this.details}, ${this.postalCode}, ${this.locality}, ${this.province}`;
    }

    return `${this.street}, ${this.postalCode}, ${this.locality}, ${this.province}`;
  }
}
