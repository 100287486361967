import * as React from "react";
import ReactLoader from "react-loaders";
import PropTypes from "prop-types";

const Loader = ({ active }) => {
  return (
    <ReactLoader
      type="ball-grid-pulse"
      active={active}
      color="#00598a"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

Loader.propTypes = {
  active: PropTypes.bool,
};

Loader.defaultProps = {
  active: true,
};

export default Loader;
