import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import CobeeButton from "../../../../app/components/CobeeButton";
import Table from "../../../../app/components/Table";
import renderEditCardModal from "../../render-edit-card-modal";
import renderRequestDuplicateCardModal from "../../render-request-duplicate-card-modal";
import { requestDuplicateCard } from "../../../cards/request-duplicate-card";
import { allowRequestNewCard } from "../../../cards/allow-request-new-card";
import { cancelCard } from "../../../cards/cancel-card";
import { deactivateCard } from "../../../cards/deactivate-card";
import renderDeactivateCardModal from "../../render-deactivate-card-modal";
import { editCard } from "../../../cards/edit-card";
import { editCardId } from "../../../cards/edit-card-id";
import renderEditCardIdModal from "../../render-edit-card-id-modal";
import renderEditCardDetailModal from "../../render-card-details-modal";
import renderAllowRequestNewCard from "../../render-allow-request-new-card";
import displayActionConfirmationModal from "../../../commons/display-action-confirmation-modal";
import { hideModal } from "../../../commons/hide-modal";

export default function CardTable({ cards }) {
  const [stateCards, setStateCards] = useState(cards);

  const getColorFromState = (state) => {
    const colorPerState = {
      cancelled: "red",
      active: "green",
      deactivated: "orange",
    };
    return colorPerState[state];
  };

  const getColorFromType = (type) => {
    const colorPerType = {
      disuse: "red",
      primary: "green",
      replacement: "blue",
    };
    return colorPerType[type];
  };

  function updateCardInState(index, newCard) {
    const newList = [...stateCards];
    newList[index] = newCard;
    setStateCards(newList);
  }

  function onRequestDuplicateSuccess({ index, cancelledCard, newCard }) {
    const newList = [...stateCards];
    newList[index] = cancelledCard;
    setStateCards([newCard, ...newList]);
  }

  function onAllowRequestNewCard({ index, newCard }) {
    const newList = [...stateCards];
    newList[index] = newCard;
    setStateCards([newCard, ...newList]);
  }

  function onCancelVirtualCard({ index, card }) {
    displayActionConfirmationModal({
      title: "Cancel card",
      message: `Are you sure you want to cancel the card ${card.cardId}?`,
      isWarning: false,
      cantBeReverted: true,
      callback: async () => {
        await cancelCard({
          cardId: card.cardId,
          employeeId: card.employeeId,
        });
        updateCardInState(index, {
          ...card,
          state: "cancelled",
          categoryType: "disuse",
        });
        hideModal();
      },
      actionName: "Confirm",
      disableClose: false,
    });
  }

  return (
    <Table
      columns={[
        { label: "NumMP", id: "cardId" },
        { label: "Type", id: "type" },
        { label: "CategoryType", id: "categoryType" },
        { label: "State", id: "state" },
        { label: "Actions", id: "actions" },
      ]}
      data={stateCards.map((card, i) => ({
        cardId: card.cardId,
        type: card.type,
        categoryType: (
          <Typography style={{ color: getColorFromType(card.categoryType) }}>
            {card.categoryType}
          </Typography>
        ),
        state: (
          <Typography style={{ color: getColorFromState(card.state) }}>
            {card.state}
          </Typography>
        ),
        actions: (
          <>
            <CobeeButton
              variant="secondary"
              onClick={() =>
                renderEditCardDetailModal({
                  card,
                })
              }
            >
              Details
            </CobeeButton>
            <CobeeButton
              variant="primary"
              onClick={() =>
                renderEditCardModal({
                  card,
                  callback: async (values) => {
                    const responseCard = await editCard({
                      cardId: card.cardId,
                      employeeId: card.employeeId,
                      data: values,
                    });
                    if (responseCard) {
                      updateCardInState(i, responseCard);
                    }
                  },
                })
              }
            >
              Edit
            </CobeeButton>
            <CobeeButton
              variant="danger"
              onClick={() =>
                renderEditCardIdModal({
                  card,
                  callback: async (values) => {
                    const responseCard = await editCardId({
                      oldCardId: card.cardId,
                      employeeId: card.employeeId,
                      newCardId: values.cardId,
                    });
                    if (responseCard) {
                      updateCardInState(i, responseCard);
                    }
                  },
                })
              }
            >
              Change Card ID
            </CobeeButton>
            {card.state === "active" && (
              <CobeeButton
                variant="danger"
                onClick={() =>
                  renderDeactivateCardModal({
                    card,
                    callback: async () => {
                      const responseCard = await deactivateCard({
                        cardId: card.cardId,
                        employeeId: card.cardId,
                      });
                      if (responseCard) {
                        updateCardInState(i, responseCard);
                      }
                    },
                  })
                }
              >
                Deactivate
              </CobeeButton>
            )}
            {card.state !== "pending" && card.categoryType === "primary" && (
              <CobeeButton
                variant="danger"
                onClick={() =>
                  renderRequestDuplicateCardModal({
                    card,
                    callback: async (reason) => {
                      const response = await requestDuplicateCard({
                        cardId: card.cardId,
                        employeeId: card.employeeId,
                        replacementReason: reason,
                      });

                      if (response) {
                        const { newCard, cancelledCard } = response;
                        onRequestDuplicateSuccess({
                          index: i,
                          cancelledCard,
                          newCard,
                        });
                      }
                    },
                  })
                }
              >
                Request Duplicate
              </CobeeButton>
            )}
            <CobeeButton
              variant="danger"
              onClick={() => {
                renderAllowRequestNewCard({
                  card,
                  callback: async () => {
                    const newCard = await allowRequestNewCard({
                      employeeId: card.employeeId,
                      cardId: card.cardId,
                    });
                    if (newCard) {
                      onAllowRequestNewCard({ index: i, newCard });
                    }
                  },
                });
              }}
            >
              Allow New Card
            </CobeeButton>
            {card.state === "active" &&
              card.type === "virtual" &&
              card.categoryType === "secondary" && (
                <CobeeButton
                  variant="danger"
                  onClick={() => {
                    onCancelVirtualCard({ index: i, card });
                  }}
                >
                  Cancel
                </CobeeButton>
              )}
          </>
        ),
      }))}
    />
  );
}

CardTable.propTypes = {
  cards: PropTypes.array,
};

CardTable.defaultProps = {
  cards: [],
};
