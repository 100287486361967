import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    "& .dropzone": {
      width: "100%",
      height: 200,
      backgroundColor: "red",
    },
  },
  dropzoneText: {
    color: ({ hasFiles }) => hasFiles && "black",
  },
}));

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: grey300,
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default useStyles;
