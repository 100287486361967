export const getHeaders = ({ showOnlyUnknown }) => {
  let headers = [
    {
      label: "Concept",
      id: "description",
      hasTooltip: true,
      toolTipText:
        "Money from flex, flex not exempt, allowance and allowance not exempt is part of employee report",
    },
    { label: "Intended amount", id: "intendedAmount" },
    { label: "Cleared amount", id: "clearedAmount" },
    {
      label: "Flex",
      id: "flexDebitAmount",
    },
    {
      label: "Flex Not Exempt",
      id: "flexNotExemptDebitAmount",
    },
    {
      label: "Allowance",
      id: "allowanceDebitAmount",
    },
    {
      label: "Allowance Not Exempt",
      id: "allowanceNotExemptDebitAmount",
    },
    { label: "Num MP", id: "cardId" },
    { label: "MCC", id: "merchantCategoryCode" },
    { label: "State", id: "status" },
    { label: "Date", id: "userTransactionIsoTime" },
    { label: "Payroll", id: "payrollCycle" },
    { label: "Category", id: "category" },
  ];

  if (showOnlyUnknown) {
    headers = [...headers, { label: "Is Refunded", id: "isRefunded" }];
  }

  headers = [...headers, { label: "Actions", id: "actions" }];

  return headers;
};
