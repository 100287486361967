import ReactDOM from "react-dom";
import React from "react";
import MentalWellnessPage from "../components/wellness/WellnessPage";

export function renderMentalWellnessPage(props) {
  ReactDOM.render(
    <MentalWellnessPage {...props} />,
    document.getElementById("page-container")
  );
}
