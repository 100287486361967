export class Modality {
  constructor({
    activeRecurrentPaymentCount,
    insuranceCompany,
    id,
    name,
    fee,
    broker,
    insurancePolicy,
    signUpDate,
    allowRequestOutsideEngagement,
    requiresEmployeeSignature,
    allowExemptDomesticPartner,
    coverageDescription,
    brochures,
    insideSignatureDocuments,
    outsideSignatureDocuments,
    insuranceBrand,
    firstEngagementPeriod,
    nextEngagementPeriods,
    complements,
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    renewalPeriod,
    expiresAt,
    availableFrom,
    automaticRenewalEnabled,
    state,
    pastPeriods,
    futurePeriod,
    modalityCommissionRate,
    canBeArchived,
  }) {
    Object.assign(this, {
      activeRecurrentPaymentCount,
      insuranceCompany,
      id,
      name,
      fee,
      broker,
      insurancePolicy,
      signUpDate,
      allowRequestOutsideEngagement,
      requiresEmployeeSignature,
      allowExemptDomesticPartner,
      coverageDescription,
      brochures,
      insideSignatureDocuments,
      outsideSignatureDocuments,
      insuranceBrand,
      firstEngagementPeriod,
      nextEngagementPeriods,
      complements,
      isInstantaneous,
      allowDifferentModalitiesPerFamilyMember,
      renewalPeriod,
      expiresAt,
      availableFrom,
      automaticRenewalEnabled,
      state,
      pastPeriods,
      futurePeriod,
      modalityCommissionRate,
      canBeArchived,
    });
  }
}
