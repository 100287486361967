import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as EmployeeIcon } from "../../../../../assets/img/employee-icon.svg";
import useStyles from "./styles";

export default function ShowEmployeesSection({ employees }) {
  const { employeeItem } = useStyles();
  return (
    <>
      <Grid container>
        {employees.slice(0, 10).map(
          (employee) =>
            employee && (
              <Grid
                key={employee.id}
                className={employeeItem}
                container
                alignItems="center"
              >
                <EmployeeIcon />
                <Typography variant="body2">{`${employee.name} ${employee.surname}`}</Typography>
              </Grid>
            )
        )}
      </Grid>
      {employees.length > 10 && (
        <Grid container justify="flex-end">
          <Typography variant="body2">{`...and ${
            employees.length - 10
          } more`}</Typography>
        </Grid>
      )}
    </>
  );
}

ShowEmployeesSection.propTypes = {
  employees: PropTypes.array,
};

ShowEmployeesSection.defaultProps = {
  employees: [],
};
