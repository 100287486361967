export default function getBenefitName({ type }) {
  const benefitNames = {
    "meal-benefit": "Meals",
    "nursery-benefit": "Nursery",
    "education-benefit": "Training",
    "health-insurance-benefit": "Health insurance",
    "life-insurance-benefit": "Life insurance",
    "renting-benefit": "Renting",
    "transport-benefit": "transport",
  };

  if (type in benefitNames) {
    return benefitNames[type];
  }
  return type;
}
