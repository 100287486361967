import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";

export default function DeactivateCardModal({ card, callback }) {
  const { container, row, buttonRow, divider } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal title="Deactivate card">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          <Typography variant="body2">
            <i>
              Are you sure you want to deactivate this card? NumMP:{" "}
              {card.cardId}{" "}
            </i>
          </Typography>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Cancel
          </CobeeButton>
          <CobeeButton
            variant="danger"
            onClick={async () => {
              setIsLoading(true);
              await callback();
              setIsLoading(false);
              await hideModal();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
