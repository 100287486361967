import { showErrorMessage } from "../commons/show-error-message";

import * as authenticationLogsRepository from "../../infrastructure/authentication-logs-repository";
import { getServerErrorMessage } from "../ErrorHandling";

export const findAuthenticationLogs = async ({
  searchTerm,
  page,
  pageSize,
}) => {
  try {
    const response = await authenticationLogsRepository.findAuthenticationLogs({
      searchTerm,
      page,
      pageSize,
    });
    return response;
  } catch (error) {
    showErrorMessage(getServerErrorMessage(error, "Error fetching logs"));
    return null;
  }
};
