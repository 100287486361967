import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import useStyles from "./styles";
import Modal from "../../../app/components/modals/Modal";
import CobeeButton from "../../../app/components/CobeeButton";
import { hideModal } from "../../../usecases/commons/hide-modal";

export default function AssignRelativeModal({
  employee,
  relatives,
  isLoading,
  action,
}) {
  const { select } = useStyles();

  const [selectedRelative, setSelectedRelative] = useState("");

  return (
    <Modal title="Assign a relative">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            To: {employee?.name} {employee?.surname}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            className={select}
            value={selectedRelative}
            onChange={(e) => setSelectedRelative(e.target.value)}
          >
            <MenuItem value="" disabled selected>
              Select a relative...
            </MenuItem>
            {relatives.map((relative) => (
              <MenuItem key={relative.id} value={relative}>
                {`${relative.name} ${relative.surname}${
                  relative.legalId ? `, ${relative.legalId}` : ""
                }`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item container xs={12} justify="flex-end" spacing={1}>
          <Grid item>
            <CobeeButton variant="secondary" onClick={hideModal}>
              Cancel
            </CobeeButton>
          </Grid>
          <Grid item>
            <CobeeButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => {
                action({ selectedRelative });
                hideModal();
              }}
            >
              Confirm
            </CobeeButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

AssignRelativeModal.defaultProps = {
  isLoading: false,
};

AssignRelativeModal.propTypes = {
  isLoading: PropTypes.bool,
  relatives: PropTypes.array.isRequired,
  action: PropTypes.func.isRequired,
  employee: PropTypes.string.isRequired,
};
