import { updateNursery } from "../../infrastructure/nursery-repository";

import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";

export async function editNursery({ nurseryId, ...rest }) {
  try {
    await updateNursery({ nurseryId, ...rest });
    showSuccessMessage("Change saved!");
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message || "Error editing the nursery"
    );
  }
}
