import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import CobeeButton from "../../../app/components/CobeeButton";
import Modal from "../../../app/components/modals/Modal";
import useStyles from "./styles";

export default function GenericModalErrors({ title, errors, callback }) {
  const { containerModal, errorsMessage } = useStyles();
  return (
    <Modal title={title}>
      <Grid container className={containerModal} direction="column">
        <Typography className={errorsMessage}>{`There ${
          errors.length > 1 ? "are" : "is"
        } ${errors.length} ${
          errors.length > 1 ? "rows" : "row"
        } with errors, download the failures file to see the details`}</Typography>
        <Grid container justify="flex-end">
          <CobeeButton onClick={callback}>Download failures</CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

GenericModalErrors.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.array,
  callback: PropTypes.func,
};

GenericModalErrors.defaultProps = {
  title: "",
  errors: [],
  callback: () => {},
};
