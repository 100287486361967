import React from "react";
import { v4 } from "uuid";
import { Grid, Typography } from "@material-ui/core";
import ReactJson from "react-json-view";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function CensusErrorModal({ errors }) {
  const { container, row, buttonRow, message } = useStyles();

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Census row errors</Typography>
      </Grid>

      {errors.map(({ data, field, code, message: errorMessage }) => (
        <Grid key={v4()} item container xs={12} className={row}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {errorMessage ?? `${field}: ${code}`}
            </Typography>
          </Grid>
          {data &&
            Object.keys(data).map((key) => (
              <Grid key={key} item xs={12} component="li" className={message}>
                <Typography variant="caption">{key}:</Typography>

                {typeof data[key] === "object" ? (
                  <ReactJson
                    src={data[key]}
                    name={false}
                    collapsed
                    enableClipboard={false}
                    displayDataTypes={false}
                  />
                ) : (
                  <Typography variant="caption">{` ${data[key]}`}</Typography>
                )}
              </Grid>
            ))}
        </Grid>
      ))}

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton onClick={hideModal}>Ok</CobeeButton>
      </Grid>
    </Grid>
  );
}
