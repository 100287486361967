import PropTypes from "prop-types";
import * as React from "react";
import { Button, MUI, defaultTheme } from "@cobee/cobee-ui";
import { FlexBox } from "../FlexBox";

export const PageHeading = ({ title = "", hookProps, actions }) => {
  const _actionsProps = hookProps?.dialogActionsProps || actions || [];

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 3,
      }}
    >
      <FlexBox
        style={{
          background: `linear-gradient(${defaultTheme.palette.grey[50]}, ${defaultTheme.palette.grey[50]} 60%, #f9f9fa00)`,
          padding: 32,
        }}
        gap={8}
      >
        {title && <MUI.Typography variant="h3">{title}</MUI.Typography>}
        <div style={{ flex: 1 }} />
        {_actionsProps.map((action) => (
          <Button key={action.label} {...action}>
            {action.label}
          </Button>
        ))}
      </FlexBox>
    </div>
  );
};

PageHeading.propTypes = {
  actions: PropTypes.any,
  hookProps: PropTypes.any,
  title: PropTypes.string,
};

PageHeading.defaultProps = {
  actions: undefined,
  hookProps: undefined,
  title: undefined,
};
