import React from "react";
import ReactDOM from "react-dom";
import EditCardModal from "./components/EditCardModal";

export default function renderEditCardModal({ card, callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <EditCardModal card={card} callback={callback} />,
      domTarget
    );
  }
}
