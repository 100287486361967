import { MonetaryAmount } from "app/domain/monetary-amount";
import moment from "moment";

const isValidLanguageVariant = ({ language }) => {
  const {
    name,
    description,
    attachments: { url, title },
  } = language;
  return name !== "" && description !== "" && url !== "" && title !== "";
};

const formatLanguage = ({ language }) => ({
  ...language,
  attachments: [language.attachments],
});

const restoreLanguage = ({ language, key }) => {
  if (!key) {
    return {
      ...language,
      attachments: language.attachments[0],
    };
  }

  return {
    [key]: {
      ...language,
      attachments: language.attachments[0],
    },
  };
};

export const adaptPensionPlanCreationData = ({ data }) => {
  const {
    enabled,
    broker,
    insurancePolicy,
    availabilityDate,
    minPunctualContribution,
    minRecurrentContribution,
    vendorId,
    en,
    es,
    pt,
  } = data;
  return {
    enabled,
    broker,
    vendorId,
    policyNumber: insurancePolicy,
    availableFrom: moment(availabilityDate).format("YYYY-MM-DD"),
    minPunctualContributionAmount: MonetaryAmount.ofCurrency({
      amountInCents: minPunctualContribution * 100,
      currency: "EUR",
    }),
    minRecurrentContributionAmount: MonetaryAmount.ofCurrency({
      amountInCents: minRecurrentContribution * 100,
      currency: "EUR",
    }),
    languages: {
      en: formatLanguage({ language: en }),
      es: isValidLanguageVariant({ language: es })
        ? formatLanguage({ language: es })
        : undefined,
      pt: isValidLanguageVariant({ language: pt })
        ? formatLanguage({ language: pt })
        : undefined,
    },
  };
};

export const restorePensionPlanProduct = ({ product }) => {
  const {
    availableFrom,
    policyNumber,
    minPunctualContributionAmount,
    minRecurrentContributionAmount,
    broker,
    enabled,
    id,
    languages: { en, es, pt },
    vendorId,
  } = product;
  return {
    availabilityDate: new Date(availableFrom),
    minPunctualContribution: MonetaryAmount.ofCurrency({
      amountInCents: minPunctualContributionAmount.amountInCents,
      currency: "EUR",
    }).toUnitary(),
    minRecurrentContribution: MonetaryAmount.ofCurrency({
      amountInCents: minRecurrentContributionAmount.amountInCents,
      currency: "EUR",
    }).toUnitary(),
    insurancePolicy: policyNumber,
    broker,
    enabled,
    id,
    languages: {
      en: restoreLanguage({ language: en }),
      ...(es && restoreLanguage({ language: es, key: "es" })),
      ...(pt && restoreLanguage({ language: pt, key: "pt" })),
    },
    vendorId,
  };
};
