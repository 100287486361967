export const columnConfig = [
  { label: "DNI/NIE", id: "legalId", searchable: true },
  { label: "Name", id: "completeName", searchable: true },
  { label: "Email", id: "email", searchable: true },
  { label: "Initial amount", id: "initialAmount" },
  { label: "Accumulated amount", id: "accumulatedAmount" },
  { label: "Today's amount", id: "availableAmount" },
  { label: "", id: "action" },
];

export const keys = columnConfig
  .filter(({ searchable }) => searchable)
  .reduce((acc, { id }) => ({ ...acc, [id]: id }), {});
