import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import renderConfirmationAboveAuthorizedTransactionsPage from "infrastructure/renders/ConfirmationAboveAuthorizedTransactions/render-confirmation-above-authorized-transactions";
import renderConfirmCancelledTransactionsPage from "infrastructure/renders/ConfirmCancelledTransactions/render-confirm-cancelled-transactions";
import renderConfirmVoidTransactionsPage from "infrastructure/renders/ConfirmVoidTransactions/render-confirm-void-transactions";
import renderOfflineTransactionsPage from "infrastructure/renders/OfflineTransactions/render-offline-transactions";
import {
  findConfirmationAboveAuthorizedTransactions,
  findConfirmCancelledTransactions,
  findOfflineTransactions,
  findConfirmVoidTransactions,
} from "infrastructure/unknown-notifications-repository";

import { displayLoader } from "usecases/commons/render-loader";
import { showErrorMessage } from "usecases/commons/show-error-message";

import { displayUnknownNotificationsTable } from "../display-unknown-notifications-table";
import EmployeeTransactions from "./EmployeeTransactions";

export default function TransactionsPage() {
  const [currentTab, setCurrentTab] = useState("search");

  useEffect(() => {
    const loadOfflineTrxData = async () => {
      try {
        displayLoader("offline-notification-list");
        const { data } = await findOfflineTransactions();
        const { allNotifications: tableData } = data;
        renderOfflineTransactionsPage({ tableData });
      } catch (error) {
        showErrorMessage("Could not find offline transactions");
        ReactDOM.render(
          "",
          document.getElementById("offline-notification-listt")
        );
      }
    };
    const loadConfirmCancelledData = async () => {
      try {
        displayLoader("confirm-cancelled-notification-list");
        const { data } = await findConfirmCancelledTransactions();
        const { allNotifications: tableData } = data;
        renderConfirmCancelledTransactionsPage({ tableData });
      } catch (error) {
        showErrorMessage(
          "Could not find transactions that are both confirmed and also cancelled"
        );
        ReactDOM.render(
          "",
          document.getElementById("confirm-cancelled-notification-list")
        );
      }
    };

    const loadConfirmVoidData = async () => {
      try {
        displayLoader("confirm-void-notification-list");
        const { data } = await findConfirmVoidTransactions();
        const { allNotifications: tableData } = data;
        renderConfirmVoidTransactionsPage({ tableData });
      } catch (error) {
        showErrorMessage("Could not find confirmed void transactions");
        ReactDOM.render(
          "",
          document.getElementById("confirm-void-notification-list")
        );
      }
    };

    const loadConfirmationAboveAuthorizedData = async () => {
      try {
        displayLoader("confirmation-above-authorized-notification-list");
        const { data } = await findConfirmationAboveAuthorizedTransactions();
        const { allNotifications: tableData } = data;
        renderConfirmationAboveAuthorizedTransactionsPage({ tableData });
      } catch (error) {
        showErrorMessage(
          "Could not find transactions that confirmation are above the authorization"
        );
        ReactDOM.render(
          "",
          document.getElementById(
            "confirmation-above-authorized-notification-list"
          )
        );
      }
    };
    if (currentTab === "unknown") {
      displayUnknownNotificationsTable();
    }
    if (currentTab === "offline") {
      loadOfflineTrxData();
    }
    if (currentTab === "confirmCancelled") {
      loadConfirmCancelledData();
    }
    if (currentTab === "confirmVoid") {
      loadConfirmVoidData();
    }
    if (currentTab === "confirmationAboveAuthorized") {
      loadConfirmationAboveAuthorizedData();
    }
  }, [currentTab]);

  return (
    <main className="container-fluid">
      <div className="card mb-3">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <button
                className={
                  currentTab === "search" ? "nav-link active" : "nav-link"
                }
                onClick={() => setCurrentTab("search")}
              >
                Search employee transactions
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  currentTab === "unknown" ? "nav-link active" : "nav-link"
                }
                onClick={() => setCurrentTab("unknown")}
              >
                Unknown refunds
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  currentTab === "offline" ? "nav-link active" : "nav-link"
                }
                onClick={() => setCurrentTab("offline")}
              >
                Offline transactions
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  currentTab === "confirmCancelled"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setCurrentTab("confirmCancelled")}
              >
                Confirm Cancelled
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  currentTab === "confirmVoid" ? "nav-link active" : "nav-link"
                }
                onClick={() => setCurrentTab("confirmVoid")}
              >
                Confirm Void
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  currentTab === "confirmationAboveAuthorized"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setCurrentTab("confirmationAboveAuthorized")}
              >
                Confirmation amount above authorized
              </button>
            </li>
          </ul>
        </div>
        {currentTab === "search" && <EmployeeTransactions />}
        {currentTab === "unknown" && (
          <div className="mb-3" id="unknown-notification-list" />
        )}
        {currentTab === "offline" && (
          <div className="mb-3" id="offline-notification-list" />
        )}
        {currentTab === "confirmCancelled" && (
          <div className="mb-3" id="confirm-cancelled-notification-list" />
        )}
        {currentTab === "confirmVoid" && (
          <div className="mb-3" id="confirm-void-notification-list" />
        )}
        {currentTab === "confirmationAboveAuthorized" && (
          <div
            className="mb-3"
            id="confirmation-above-authorized-notification-list"
          />
        )}
      </div>
    </main>
  );
}
