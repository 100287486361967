import displayActionConfirmationModal from "../commons/display-action-confirmation-modal";
import { showErrorMessage } from "../commons/show-error-message";
import { solveConfirmCancelledTrx } from "../../infrastructure/transaction-repository";
import { hideModal } from "../commons/hide-modal";
import { findConfirmCancelledTransactions } from "../../infrastructure/unknown-notifications-repository";
import renderConfirmCancelledTransactionsPage from "../../infrastructure/renders/ConfirmCancelledTransactions/render-confirm-cancelled-transactions";

export default function solveConfirmedCancelledTransaction({
  unknownProcessorNotificationId,
  cardId,
  transactionId,
}) {
  const solveTransaction = async () => {
    try {
      const res = await solveConfirmCancelledTrx({
        payload: { cardId, transactionId, unknownProcessorNotificationId },
      });
      if (res.data.success === false) {
        showErrorMessage(res.data.error);
      }
      const { data } = await findConfirmCancelledTransactions();
      const { allNotifications: tableData } = data;
      renderConfirmCancelledTransactionsPage({ tableData });
    } catch (err) {
      showErrorMessage(err.response.data.message);
    } finally {
      hideModal();
    }
  };

  displayActionConfirmationModal({
    title: "Solve a confirmed and cancelled transaction",
    message:
      "This action will introduce a new transaction to the user. Are you sure?",
    callback: solveTransaction,
  });
}
