import { Complement } from "../../../../domain/contexts/healthInsurance/entities/Complement";
import { restoreMoney } from "../../../common/builders/restoreMoney";
import { restoreBrochure } from "./restoreBrochure";

export const restoreComplement = ({
  insuranceCompany,
  broker,
  insurancePolicy,
  complementId,
  complementName,
  complementCoverage,
  complementFee,
  complementBrochures,
}) =>
  new Complement({
    insuranceCompany,
    broker,
    insurancePolicy,
    id: complementId,
    name: complementName,
    coverage: complementCoverage,
    fee: complementFee ? restoreMoney(complementFee) : undefined,
    brochures: complementBrochures
      ? complementBrochures.map(restoreBrochure)
      : undefined,
  });
