import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  findCompanyDetail,
  findEmployeesPerModel,
} from "infrastructure/company-repository";
import { findBenefitModel } from "infrastructure/benefit-models-repository";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { QueryProvider } from "v2/ui/providers/QueryProvider";
import { NavMenu } from "v2/ui/components/NavMenu";
import { Button, Link, Typography } from "@material-ui/core";
import { MUI } from "@cobee/cobee-ui";
import BenefitModelEmployeesDetails from "../../../infrastructure/renders/ShowBenefitModelEmployeesDetails";
import {
  generateBenefitModelDetailLink,
  generateCompanyDetailLink,
} from "../../../infrastructure/links";
import Loader from "../../components/loader";

export default function BenefitModelEmployeesPage() {
  const { companyId, benefitModelId } = useParams();

  const { data, isLoading, isFetching, error: hasError } = useQuery(
    ["companyBenefitModels", companyId, benefitModelId],
    async () => {
      const [company, benefitModel] = await Promise.all([
        findCompanyDetail(companyId),
        findBenefitModel({ companyId, benefitModelId }),
      ]);
      const fields = "name,surname,legalId,email,daysPerMonth";
      const {
        data: { content: employeesPerModel },
      } = await findEmployeesPerModel({
        companyId,
        modelId: benefitModelId,
        fields,
      });
      return {
        company,
        benefitModel,
        employeesPerModel,
      };
    },
    {
      onError(error) {
        showErrorMessage(
          error?.response?.data?.message || "Error loading company details"
        );
      },
    }
  );

  if (hasError) {
    return "Error";
  }

  if (isLoading || isFetching) {
    return <Loader active />;
  }

  const { benefitModel, company, employeesPerModel } = data;

  return (
    <>
      <main className="container-fluid" id="page-container">
        <MUI.Grid
          container
          xs={12}
          item
          justifyContent="flex-end"
          sx={{ mb: 3 }}
        >
          <Link
            href={generateBenefitModelDetailLink({ benefitModelId, companyId })}
          >
            <Button variant="contained" color="primary">
              Detail
            </Button>
          </Link>
        </MUI.Grid>
        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <Typography className="card-title">
              Benefit Model:{" "}
              <Link
                href={generateBenefitModelDetailLink({
                  companyId,
                  benefitModelId,
                })}
              >
                {benefitModel.name}
              </Link>
            </Typography>
            <Typography className="card-title">
              Company:{" "}
              <Link href={generateCompanyDetailLink({ companyId })}>
                {company.companyName}
              </Link>
            </Typography>
          </MUI.CardContent>
        </MUI.Card>

        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <BenefitModelEmployeesDetails
              companyId={benefitModel.companyId}
              employees={employeesPerModel}
            />
          </MUI.CardContent>
        </MUI.Card>
        <div className="card mb-3" />
      </main>
    </>
  );
}
