import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";

export default function EducationRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row, buttonRow } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const formatDateFromTimestamp = (timestamp) =>
    timestamp ? moment.unix(timestamp).format("YYYY-MM-DD HH:mm") : "N/A";

  function optionalMonetaryAmount(payment) {
    return payment ? MonetaryAmount.ofCurrency(payment).prettifyMoney({}) : "";
  }
  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty("centreName", recurrentPayment.benefitDetails.centreName)}
        {textProperty("courseName", recurrentPayment.benefitDetails.courseName)}
        {textProperty(
          "Total Training Amount",
          optionalMonetaryAmount(
            recurrentPayment.benefitDetails.totalTrainingAmount
          )
        )}
        {textProperty(
          "Total Training Amount Paid By Company",
          optionalMonetaryAmount(
            recurrentPayment.benefitDetails.totalTrainingAmountPaidByCompany
          )
        )}
        {textProperty(
          "Training Start Date",
          recurrentPayment.benefitDetails.trainingStartDate
        )}
        {textProperty(
          "Training End Date",
          recurrentPayment.benefitDetails.trainingEndDate
        )}
        {textProperty(
          "Number of payroll in which it will be paid",
          recurrentPayment.benefitDetails.splitBy
        )}
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
