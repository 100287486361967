import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Typography } from "@material-ui/core";
import Table from "../../../app/components/Table";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import CobeeButton from "../../../app/components/CobeeButton";
import generateTransaction from "../../../usecases/transactions/generate-transaction";
import useStyles from "./styles";

export default function OfflineTransactions({ tableData }) {
  const [companyName, setCompanyName] = useState("");
  const [processorTransactionId, setProcessorTransactionId] = useState("");
  const [currency, setCurrency] = useState("");
  const { title } = useStyles();
  return (
    <main className="container-fluid">
      <Typography variant="h5" className={title}>
        Offline Transactions
      </Typography>
      <div className="form-group row">
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Company name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Processor transaction id"
            onChange={(e) => setProcessorTransactionId(e.target.value)}
          />
        </div>
        <div className="col-sm-3 mb-2">
          <select
            className="form-control"
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option selected value="">
              All Countries
            </option>
            <option value="MXN">Mexico</option>
            <option value="EUR">ES & PT</option>
          </select>
        </div>
      </div>
      {tableData.length === 0 ? (
        <Typography variant="body1" className="text-center">
          There are currently no offline transactions
        </Typography>
      ) : (
        <Table
          columns={[
            { label: "Processor transaction id", id: "transactionId" },
            { label: "Category", id: "category" },
            { label: "Concept", id: "concept" },
            { label: "Company", id: "company" },
            { label: "Amount", id: "amount" },
            { label: "Initial Balance", id: "initial" },
            { label: "Final Balance", id: "final" },
            { label: "Date", id: "date" },
            { label: "Card Id", id: "cardId" },
            { label: "Actions", id: "actions" },
          ]}
          data={tableData
            .filter(
              (td) =>
                !companyName ||
                td.company?.name?.match(new RegExp(companyName, "gi"))
            )
            .filter(
              (td) =>
                !processorTransactionId ||
                td.transactionId?.match(
                  new RegExp(`^${processorTransactionId}`, "i")
                )
            )
            .filter((td) => !currency || td.amount?.currency === currency)
            .map(
              ({
                amount,
                category,
                company,
                initialAccountBalance,
                finalAccountBalance,
                localIsoTime,
                cardId,
                unknownProcessorNotificationId,
                employeeId,
                ...rest
              }) => ({
                amount: MonetaryAmount.ofCurrency(amount).prettifyMoney({}),
                category,
                company: company?.name,
                initial: MonetaryAmount.ofCurrency(
                  initialAccountBalance
                ).prettifyMoney({}),
                final: MonetaryAmount.ofCurrency(
                  finalAccountBalance
                ).prettifyMoney({}),
                date: moment(localIsoTime).format("DD-MM-YYYY HH:mm"),
                cardId,
                actions: (
                  <>
                    <CobeeButton
                      onClick={() =>
                        generateTransaction({
                          intendedAmount: MonetaryAmount.ofCurrency(amount),
                          employeeId,
                          cardId,
                          unknownProcessorNotificationId,
                        })
                      }
                    >
                      Generate
                    </CobeeButton>
                  </>
                ),
                ...rest,
              })
            )}
        />
      )}
    </main>
  );
}

OfflineTransactions.defaultProps = {
  tableData: [],
};

OfflineTransactions.propTypes = {
  tableData: PropTypes.array,
};
