import React from "react";
import ReactDOM from "react-dom";
import HexModal from "../../app/components/modals/HexModal";
import GenerateTransactionModal from "../../app/components/modals/GenerateTransactionModal";
import { findEmployeePayrollCycles } from "../../infrastructure/finance-repository";
import { findFields } from "../../infrastructure/public-repository";
import { findEmployeesByEmail } from "../../infrastructure/employee-repository";
import { hideModal } from "../commons/hide-modal";
import { showSuccessMessage } from "../commons/show-success-message";
import { displayLoader } from "../commons/render-loader";
import {
  findOfflineTransactions,
  solveOfflineNotification,
} from "../../infrastructure/unknown-notifications-repository";
import renderOfflineTransactionsPage from "../../infrastructure/renders/OfflineTransactions/render-offline-transactions";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

const renderGenerateOfflineTransactionModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <GenerateTransactionModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default async function generateTransaction({
  intendedAmount,
  employeeId,
  cardId,
  unknownProcessorNotificationId,
}) {
  renderGenerateOfflineTransactionModal({ isLoading: true });
  const data = await findEmployeesByEmail(null, cardId);
  const {
    benefitModel: { allowedBenefits },
    limits,
  } = data[0];
  const { currency } = intendedAmount;
  if (currency === "MXN") {
    allowedBenefits.push("groceries-benefit");
  } else {
    allowedBenefits.push("unknown");
  }
  const filterBenefits = allowedBenefits.filter(
    (benefit) => benefit !== "gift-card-benefit"
  );
  const {
    data: { payrollCycles },
  } = await findEmployeePayrollCycles({ employeeId });
  const { employeeBehaviours: behaviours } = await findFields();
  renderGenerateOfflineTransactionModal({
    employeeId,
    intendedAmount,
    filterBenefits,
    payrollCycles,
    behaviours,
    unknownProcessorNotificationId,
    limits,
    callback: async (payload) => {
      try {
        const { status, data: response } = await solveOfflineNotification(
          payload
        );
        hideModal();
        if (status === 200) {
          displayLoader("offline-notification-list");
          const { data: trx } = await findOfflineTransactions();
          const { allNotifications: tableData } = trx;
          renderOfflineTransactionsPage({ tableData });
          showSuccessMessage("Transaction generated successfully");
        } else {
          showErrorMessage(
            getServerErrorMessage(
              response,
              "There was a failure in the resolution of the transaction"
            )
          );
        }
      } catch (error) {
        showErrorMessage(
          getServerErrorMessage(
            error,
            "The resolution request could not be processed"
          )
        );
      }
    },
  });
}
