import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";

import { displayTransactionCompensateModal } from "usecases/companies/display-transaction-compensate-modal";

import { findLedgerTransactionsByEmail } from "../../../../infrastructure/transaction-repository";
import Table from "../../../../app/components/Table";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";
import CobeeButton from "../../../../app/components/CobeeButton";
import EmptyDataForTableMessage from "../../../../app/components/EmptyDataForTableMessage";
import useStyles, { CustomCheckbox } from "./styles";
import transferMoneyFromEmployeeTransactions from "../../transfer-money-from-transaction";
import voidTransactions from "../../void-transactions";
import restoreVoidTransaction from "../../restore-void-transaction";
import { displayTransactionDetailsModal } from "../../../companies/display-transaction-details-modal";

export default function TransferTransactionSection({ searchValues }) {
  const { row, actionRow, errorMessage } = useStyles();

  const [formData, setFormData] = useState({
    email: searchValues?.email || "",
    numMp: searchValues?.numMp || "",
    transactionId: "",
    employeeId: "",
    amount: 0,
    password: "",
  });

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableMessage, setTableMessage] = useState("");
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const handleSearch = useCallback(
    async ({ override }) => {
      setSelectedTransactions([]);
      setTableMessage("");
      setIsLoading(true);
      const { email, numMp } = formData;
      const res = await findLedgerTransactionsByEmail(
        override ? searchValues.email : email,
        override ? searchValues.numMp : numMp
      );
      if (res.success) {
        setTableData(res.data);
      } else {
        setTableMessage(res.error);
      }

      setIsLoading(false);
    },
    [formData, searchValues]
  );

  useEffect(() => {
    if (
      searchValues &&
      (searchValues.email !== "" || searchValues.numMp !== "") &&
      tableData.length < 1 &&
      !isLoading
    ) {
      handleSearch({ override: true });
    }
  }, [searchValues, handleSearch, tableData, isLoading]);

  const handleCheckbox = ({ value, id }) => {
    if (value) {
      setSelectedTransactions((prevState) => [...prevState, id]);
    } else {
      setSelectedTransactions((prevState) =>
        prevState.filter((itemId) => itemId !== id)
      );
    }
  };

  const handleCheckAll = ({ value }) => {
    if (value) {
      setSelectedTransactions(tableData.map(({ id }) => id));
    } else {
      setSelectedTransactions([]);
    }
  };

  const handleMultipleTransferFromTransaction = () => {
    transferMoneyFromEmployeeTransactions({
      employeeId: tableData?.[0].employeeId,
      transactionIds: selectedTransactions,
    });
  };

  const handleVoidTransactions = async () => {
    voidTransactions({
      transactionIds: selectedTransactions,
      intendedAmount: tableData?.find(
        (data) => data.id === selectedTransactions[0]
      )?.intendedAmount,
      employeeId: tableData?.[0].employeeId,
      searchValues: { email: formData.email, numMp: formData.numMp },
    });
  };

  const selectedTransactionsAreNotAcceptableForVoid = useCallback(() => {
    return tableData.reduce(
      (acc, { id, status }) =>
        selectedTransactions.reduce(
          (acc2, curr) => (curr === id && status !== "confirmed") || acc2,
          false
        ) || acc,
      false
    );
  }, [selectedTransactions, tableData]);

  const selectedTransactionsIsNotCardPurchase = useCallback(() => {
    return tableData.reduce(
      (acc, { id, type }) =>
        selectedTransactions.reduce(
          (acc2, curr) => (curr === id && type !== "card-purchase") || acc2,
          false
        ) || acc,
      false
    );
  }, [selectedTransactions, tableData]);

  return (
    <main className="container-fluid pb-3">
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">Employees</h5>
          <p className="card-text">Search employees transactions</p>
          <div className="form-group row">
            <Grid item container xs={12} alignItems="center" className={row}>
              <Grid item xs={4} className="mr-2">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4} className="mr-2">
                <input
                  type="numMP"
                  className="form-control"
                  placeholder="numMp"
                  value={formData.numMp}
                  onChange={(e) =>
                    setFormData({ ...formData, numMp: e.target.value })
                  }
                />
              </Grid>
              <CobeeButton
                isLoading={isLoading}
                disabled={!formData.email && !formData.numMp}
                onClick={() => handleSearch({})}
              >
                Search
              </CobeeButton>
            </Grid>
          </div>
        </div>
      </div>

      <Grid item container xs={12} justify="flex-end" className={actionRow}>
        <Grid item>
          <CobeeButton
            onClick={handleMultipleTransferFromTransaction}
            disabled={
              selectedTransactions.length === 0 ||
              selectedTransactions.length > 1 ||
              selectedTransactionsAreNotAcceptableForVoid() ||
              selectedTransactionsIsNotCardPurchase()
            }
          >
            Transfer money from transaction
          </CobeeButton>
          {selectedTransactions.length > 1 && (
            <Grid item container xs={12} justify="center">
              <Typography
                className={errorMessage}
                variant="caption"
                color="error"
              >
                Can only transfer money from a single transaction
              </Typography>
            </Grid>
          )}
          {selectedTransactionsAreNotAcceptableForVoid() &&
            tableData.length > 0 && (
              <Grid item container xs={12} justify="center">
                <Typography
                  className={errorMessage}
                  variant="caption"
                  color="error"
                >
                  Can only void confirmed transactions
                </Typography>
              </Grid>
            )}
        </Grid>
        <Grid item>
          <CobeeButton
            onClick={handleVoidTransactions}
            disabled={
              selectedTransactions.length === 0 ||
              selectedTransactionsAreNotAcceptableForVoid()
            }
          >
            Void transaction
          </CobeeButton>
          {selectedTransactionsAreNotAcceptableForVoid() &&
            tableData.length > 0 && (
              <Grid item container xs={12} justify="center">
                <Typography
                  className={errorMessage}
                  variant="caption"
                  color="error"
                >
                  Can only void confirmed transactions
                </Typography>
              </Grid>
            )}
        </Grid>
      </Grid>

      {tableData.length === 0 && (
        <EmptyDataForTableMessage
          message={
            tableMessage !== ""
              ? tableMessage
              : `No transactions found for your search`
          }
        />
      )}

      {!isLoading && tableData.length > 0 && (
        <Table
          columns={[
            {
              label: (
                <CustomCheckbox
                  checked={selectedTransactions.length === tableData.length}
                  onChange={(e) => handleCheckAll({ value: e.target.checked })}
                />
              ),
              id: "checkbox",
            },
            { label: "Concept", id: "description" },
            { label: "Intended amount", id: "amount" },
            { label: "Flex Exempt", id: "flexExemptAmount" },
            { label: "Allowance Exempt", id: "allowanceExemptAmount" },
            { label: "Payroll", id: "payrollCycle" },
            { label: "Date", id: "userTransactionIsoTime" },
            { label: "Type", id: "type" },
            { label: "Category", id: "category" },
            { label: "State", id: "status" },
            { label: "Actions", id: "actions" },
          ]}
          data={tableData.map((trx) => ({
            ...trx,
            checkbox: (
              <CustomCheckbox
                checked={selectedTransactions.some((item) => trx.id === item)}
                onChange={(e) =>
                  handleCheckbox({ value: e.target.checked, id: trx.id })
                }
              />
            ),
            description: (
              <>
                {trx.description}
                <div className="font-weight-lighter">ID: {trx.id}</div>
              </>
            ),
            amount: MonetaryAmount.ofCurrency(trx.amount).prettifyMoney({}),
            flexExemptAmount: MonetaryAmount.ofCurrency(
              trx.flexCreditAmount
            ).prettifyMoney({}),
            allowanceExemptAmount: MonetaryAmount.ofCurrency(
              trx.allowanceCreditAmount
            ).prettifyMoney({}),
            userTransactionIsoTime: moment(trx.userTransactionIsoTime).format(
              "DD-MM-YYYY HH:mm"
            ),
            status: !trx.parentTransactionId
              ? trx.status
              : `${trx.status}${trx.status === "void" ? " (regenerated)" : ""}`,
            type: trx.type,
            category: trx.category,
            payrollCycle: trx.payrollCycle
              ? `${trx.payrollCycle.fiscalYear}-${trx.payrollCycle.payrollMonth}`
              : "",
            actions: (
              <>
                <CobeeButton
                  variant="unContained"
                  disabled={trx.status !== "void" || !!trx.parentTransactionId}
                  onClick={() => {
                    displayTransactionCompensateModal({ trx });
                  }}
                >
                  Compensate
                </CobeeButton>

                <br />

                <CobeeButton
                  variant="unContained"
                  disabled={trx.status !== "void" || !!trx.parentTransactionId}
                  onClick={() =>
                    restoreVoidTransaction({
                      transactionId: trx.id,
                      intendedAmount: trx.intendedAmount,
                      employeeId: trx.employeeId,
                      category: trx.category,
                      searchValues,
                      type: trx.type,
                    })
                  }
                >
                  Regenerate
                </CobeeButton>

                <br />

                <CobeeButton
                  variant="unContained"
                  onClick={() => {
                    displayTransactionDetailsModal(
                      {
                        ...trx,
                        merchantRfcId: trx.merchantInfo.rfcId,
                        merchantPostalCode: trx.merchantInfo.merchantPostalCode,
                        transactionId: trx.id,
                        merchantId: trx.merchantInfo.merchantId,
                        merchantName: trx.merchantInfo.merchantName,
                        userTransactionIsoTime: moment(
                          trx.userTransactionIsoTime
                        ).format("DD-MM-YYYY HH:mm"),
                        timestamp: moment(trx.userTransactionIsoTime).unix(),
                        merchantCategoryCode:
                          trx.merchantInfo.merchantCategoryCode,
                        authorizationId: trx?.processorDetails?.authorizationId,
                        brand: trx.merchantInfo.brand,
                        iso2CountryCode: trx.merchantInfo.country,
                        reason: trx?.reason,
                        payrollCycle:
                          trx?.payrollCycle?.fiscalYear &&
                          trx?.payrollCycle?.payrollMonth
                            ? `${trx.payrollCycle.fiscalYear}-${trx.payrollCycle.payrollMonth}`
                            : "-",
                      },
                      formData.email,
                      formData.numMp
                    );
                  }}
                >
                  Detail
                </CobeeButton>
              </>
            ),
          }))}
        />
      )}
      <div id="transaction-detail" />
    </main>
  );
}
