import renderLifeInsuranceRiskIndexModal from "../../../infrastructure/renders/LifeInsuranceRiskIndexModal/render-life-insurance-risk-index-modal";
import { hideModal } from "../../commons/hide-modal";
import { changeRiskInformation } from "../../../infrastructure/life-insurance-repository";
import reviewLifeInsuranceRequests from "../reviewLifeInsuranceRequests";

export default function assignRiskIndex({ recurrentPaymentId, valueOverride }) {
  renderLifeInsuranceRiskIndexModal({
    valueOverride: valueOverride || 0,
    callback: async (value) => {
      try {
        renderLifeInsuranceRiskIndexModal({
          callBackIsLoading: true,
          valueOverride: value,
        });
        await changeRiskInformation({
          recurrentPaymentId,
          payload: { riskFactor: value },
        });
        reviewLifeInsuranceRequests();
        hideModal();
      } catch (err) {
        renderLifeInsuranceRiskIndexModal({ valueOverride: value });
      }
    },
  });
}
