import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "56px",
    padding: theme.spacing(2),
  },
}));

export default useStyles;
