import apiClient from "./api-client";

export const findAllUnknownNotifications = async () => {
  try {
    const response = apiClient().get(
      `/v1/back-office/unknown-notifications?resolutionStatus=active&operationType=unknown-refund`
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const findOfflineTransactions = async () => {
  try {
    const response = await apiClient().get(
      "/v1/back-office/unknown-notifications?resolutionStatus=active&operationType=offline-rejected"
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const findConfirmCancelledTransactions = async () => {
  try {
    const response = await apiClient().get(
      "/v1/back-office/unknown-notifications?resolutionStatus=active&operationType=confirm-cancelled"
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const findConfirmVoidTransactions = async () => {
  try {
    const response = await apiClient().get(
      "/v1/back-office/unknown-notifications?resolutionStatus=active&operationType=confirm-void"
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const findConfirmationAboveAuthorizedTransactions = async () => {
  try {
    const response = await apiClient().get(
      "/v1/back-office/unknown-notifications?resolutionStatus=active&operationType=confirmation-amount-above-authorized"
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const solveOfflineNotification = async (payload) => {
  try {
    const response = await apiClient().post(
      "/v1/back-office/solve-offline-unknown-notification",
      payload
    );
    return response;
  } catch (error) {
    return { data: error };
  }
};
