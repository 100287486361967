import React from "react";
import EmailList from "../../../commons/email-list";

const EmailsInsuranceList = ({
  emails,
  isLoading,
  newEmail,
  handlerInput,
  deleteEmail,
  addEmail,
}) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">Insurance requests email subscribers:</h5>
        <EmailList
          handlerInput={handlerInput}
          isLoading={isLoading}
          newEmail={newEmail}
          deleteEmail={deleteEmail}
          addEmail={addEmail}
          emails={emails}
        />
      </div>
    </div>
  );
};

export default EmailsInsuranceList;
