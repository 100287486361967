import PropTypes from "prop-types";
import React from "react";
import { CobeeProvider, defaultTheme } from "@cobee/cobee-ui";

import { QueryProvider } from "./QueryProvider";

export const Providers = ({ children }) => (
  <CobeeProvider theme={defaultTheme}>
    <QueryProvider>{children}</QueryProvider>
  </CobeeProvider>
);

Providers.propTypes = {
  children: PropTypes.any.isRequired,
};
