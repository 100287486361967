import React from "react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";
import { findFields } from "../../infrastructure/public-repository";
import FinancePage from "./components/FinancePage";

export async function displayFinancePage(props) {
  const fields = await findFields();
  renderFinancePage({ fields, ...props });
}

export function renderFinancePage({ fields, ...rest }) {
  ReactDOM.render(
    <FinancePage
      key={v4()}
      behaviours={fields.employeeBehaviours}
      categories={fields.employeeCategories}
      {...rest}
    />,
    document.getElementById("page-container")
  );
}
