import React, { Component } from "react";
import { hideModal } from "../commons/hide-modal";

class PromptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
  }

  render() {
    const { message, onConfirm, placeholder } = this.props;
    return (
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-left-line" />
          <span className="message">{message}</span>
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={this.state.data}
            onChange={(e) => this.setState({ data: e.target.value })}
          />
          <div className="cob-btn-group center-group">
            <button
              className="cob-btn btn-primary-outline"
              onClick={() => onConfirm(this.state.data) && hideModal()}
            >
              Confirm
            </button>
            <button
              className="cob-btn btn-primary-outline"
              onClick={() => hideModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PromptModal;
