import moment from "moment";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";

const literals = (arr) => {
  const translated = {};
  arr.forEach((el) => {
    translated[el] = el;
  });
  return translated;
};

export const cachedT = literals([
  "NumeroPoliza",
  "Modalidad",
  "Tipo",
  "Subtipo",
  "NombreAsegurado",
  "ApellidosAsegurado",
  "FechaNacimientoAsegurado",
  "Sexo",
  "DireccionAsegurado",
  "CodPostalAsegurado",
  "ProvinciaAsegurado",
  "LocalidadAsegurado",
  "DniAsegurado",
  "Número de póliza",
  "Modalidad",
  "Capital asegurado (€)",
  "Prima mensual (€)",
  "Fecha de nacimiento del asegurado (aaaa-mm-aa)",
  "Grado de discapacidad (%)",
  "Sexo",
  "Profesión",
  "Dirección",
  "Código postal",
  "Localidad",
  "Provincia",
  "DNI Empleado",
  "DniEmpleado",
  "Vehículo: Marca y modelo",
  "PVP - con IVA (€)",
  "Uso privativo (%)",
  "Eficiencia energética (%)",
  "Empleado: Importe inicial - sin IVA (€)",
  "Empresa: Importe inicial - sin IVA (€)",
  "Número de cuotas (#)",
  "Fecha de inicio (aaaa-mm-dd)",
  "Empleado: Cuota renting - sin IVA (€)",
  "Empresa: Cuota renting - sin IVA (€)",
  "IVA de la cuota soportado por…",
  "Empleado: Porcentaje de IVA",
  "Nombre del curso",
  "Centro formativo",
  "Fecha de inicio (aaaa-mm-aa)",
  "Fecha de último pago (aaaa-mm-aa)",
  "Importe del curso (€)",
  "Importe subsidiado por la empresa (€)",
  "Nóminas en las que se fracciona el pago restante (#)",
  "GradoDiscapacidad",
  "Complemento1",
  "Complemento2",
  "Complemento3",
  "Complemento4",
  "Complemento5",
  "NombreDeCalle",
  "NumeroDePortal",
  "CodigoPostal",
  "Provincia",
  "Localidad",
  "DetallesDeDireccion",
  "DiaDeInicio",
  "FechaComienzoAnual",
  "NombreAsegurado",
  "ApellidosAsegurado",
  "FechaComienzoAnual",
  "FechaNacimientoAsegurado",
  "GradoDiscapacidadAsegurado",
  "SexoAsegurado",
  "NombreDeCalleAsegurado",
  "NumeroDePortalAsegurado",
  "CodigoPostalAsegurado",
  "ProvinciaAsegurado",
  "LocalidadAsegurado",
  "DetallesDeDireccionAsegurado",
  "DniAsegurado",
  "DNI",
  "Saldo (€)",
  "Días laborables (#)",
  "ProductId",
  "ProductCode",
  "EmployeeId",
  "Type",
  "Subtype",
  "ApplicantId",
  "ApplicantName",
  "ApplicantSurnames",
  "ServiceEffectiveDate",
  "ApplicantBirthDate",
  "ApplicantAddressStreetName",
  "ApplicantAddressStreetNumber",
  "ApplicantAddressPostalCode",
  "ApplicantAddressProvince",
  "ApplicantAddressLocality",
  "ApplicantAddressDetails",
  "Aportado por la empresa este año (€)",
  "Aportado por el empleado este año (€)",
  "Fecha de efecto en el servicio (aaaa-mm-dd)",
  "Número de póliza",
]);

const forceToCents = (amount) =>
  amount ? MonetaryAmount.fromUnitary(amount).amountInCents : 0;

const formatDate = ({ date }) => {
  return moment(date, "DD/MM/YYYY", true).format("MM/DD/YYYY");
};

export const adaptExcelPayload = ({ type, item }) => {
  const sexMap = {
    hombre: "male",
    mujer: "female",
  };

  switch (type) {
    case "health-insurance-benefit":
      return {
        employeeLegalId: item[cachedT.DniEmpleado]?.toString(),
        insurancePolicy: item[cachedT.NumeroPoliza]?.toString() || "",
        modalityName: item[cachedT.Modalidad]?.toString() || "",
        complementsNames: [1, 2, 3, 4, 5]
          .reduce(
            (acc, curr) => [
              ...acc,
              item[cachedT[`Complemento${curr}`]] &&
              item[cachedT[`Complemento${curr}`]] !== ""
                ? item[cachedT[`Complemento${curr}`]]
                : "",
            ],
            []
          )
          .filter((complement) => complement !== ""),
        insured: {
          legalId: item[cachedT.DniAsegurado]?.toString(),
          name: item[cachedT.NombreAsegurado],
          surname: item[cachedT.ApellidosAsegurado],
          birthDate:
            item[cachedT.FechaNacimientoAsegurado] &&
            item[cachedT.FechaNacimientoAsegurado] !== ""
              ? moment(
                  formatDate({ date: item[cachedT.FechaNacimientoAsegurado] })
                ).format("YYYY-MM-DD")
              : "",
          sex: item[cachedT.SexoAsegurado]
            ? item[cachedT.SexoAsegurado].toLowerCase()
            : "",
          homeAddress: {
            street: [
              item[cachedT.NombreDeCalleAsegurado],
              item[cachedT.NumeroDePortalAsegurado]?.toString(),
              item[cachedT.DetallesDeDireccionAseguradoAsegurado],
            ].join(", "),
            streetName: item[cachedT.NombreDeCalleAsegurado],
            streetNumber: item[cachedT.NumeroDePortalAsegurado]?.toString(),
            postalCode: item[cachedT.CodigoPostalAsegurado]?.toString(),
            province: item[cachedT.ProvinciaAsegurado],
            locality: item[cachedT.LocalidadAsegurado],
            details: item[cachedT.DetallesDeDireccionAsegurado],
          },
          type: item[cachedT.Tipo] ? item[cachedT.Tipo].toLowerCase() : "",
          subtype: item[cachedT.Subtipo]
            ? item[cachedT.Subtipo].toLowerCase()
            : "",
          incapacityLevel: `${item[cachedT.GradoDiscapacidadAsegurado] * 100}%`,
        },
        startDayOfYear:
          item[cachedT.FechaComienzoAnual] &&
          item[cachedT.FechaComienzoAnual] !== ""
            ? moment(
                formatDate({ date: item[cachedT.FechaComienzoAnual] })
              ).format("YYYY-MM-DD")
            : "",
      };
    case "life-insurance-benefit":
      return {
        legalId: item[cachedT["DNI Empleado"]]?.toString(),
        insurancePolicy: item[cachedT["Número de póliza"]]?.toString(),
        modalityName: item[cachedT.Modalidad]?.toString(),
        totalCapitalInCents: forceToCents(
          item[cachedT["Capital asegurado (€)"]]
        ),
        monthlyFeeInCents: forceToCents(item[cachedT["Prima mensual (€)"]]),
        birthDate: moment(
          item[cachedT["Fecha de nacimiento del asegurado (aaaa-mm-aa)"]]
        ).format("YYYY-MM-DD"),
        incapacityLevel: `${item[cachedT["Grado de discapacidad (%)"]]}%`,
        sex: sexMap[item[cachedT.Sexo].toLowerCase()],
        profession: item[cachedT["Profesión"]]?.toString(),
        homeAddress: {
          street: item[cachedT["Dirección"]]?.toString(),
          postalCode: item[cachedT["Código postal"]]?.toString(),
          locality: item[cachedT.Localidad]?.toString(),
          province: item[cachedT.Provincia]?.toString(),
        },
      };
    case "renting-benefit":
      return {
        legalId: item[cachedT["DNI Empleado"]]?.toString(),
        description: item[cachedT["Vehículo: Marca y modelo"]]?.toString(),
        vehiclePrice: Number(item[cachedT["PVP - con IVA (€)"]]),
        privateUseRatio: Number(item[cachedT["Uso privativo (%)"]]),
        energyEfficiency: Number(item[cachedT["Eficiencia energética (%)"]]),
        initialPaymentByEmployee: Number(
          item[cachedT["Empleado: Importe inicial - sin IVA (€)"]]
        ),
        initialPaymentByCompany: Number(
          item[cachedT["Empresa: Importe inicial - sin IVA (€)"]]
        ),
        numberOfPayments: Number(item[cachedT["Número de cuotas (#)"]]),
        serviceStart: moment(
          item[cachedT["Fecha de inicio (aaaa-mm-dd)"]]
        ).format("YYYY-MM-DD"),
        rentingCostPerPaymentEmployee: Number(
          item[cachedT["Empleado: Cuota renting - sin IVA (€)"]]
        ),
        rentingCostPerPaymentCompany: Number(
          item[cachedT["Empresa: Cuota renting - sin IVA (€)"]]
        ),
        vatRatioSupportedByEmployee: Number(
          item[cachedT["Empleado: Porcentaje de IVA"]]
        ),
      };
    case "education-benefit":
      return {
        legalId: item[cachedT["DNI Empleado"]]?.toString(),
        courseName: item[cachedT["Nombre del curso"]]?.toString(),
        centreName: item[cachedT["Centro formativo"]]?.toString(),
        startDate: moment(item[cachedT["Fecha de inicio (aaaa-mm-aa)"]]).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(
          item[cachedT["Fecha de último pago (aaaa-mm-aa)"]]
        ).format("YYYY-MM-DD"),
        amount: Number(item[cachedT["Importe del curso (€)"]]),
        fromFunds: Number(
          item[cachedT["Importe subsidiado por la empresa (€)"]]
        ),
        splitBy: Number(
          item[cachedT["Nóminas en las que se fracciona el pago restante (#)"]]
        ),
      };
    case "wellness-benefit":
      return {
        employeeLegalId: item[cachedT.EmployeeId]?.toString(),
        productCode: item[cachedT.ProductCode]?.toString(),
        startDayOfYear: item[cachedT.ServiceEffectiveDate]
          ? moment(
              formatDate({ date: item[cachedT.ServiceEffectiveDate] })
            ).format("YYYY-MM-DD")
          : "",
        applicant: {
          type: item[cachedT.Type]?.toString(),
          subtype: item[cachedT.Subtype]?.toString(),
          name: item[cachedT.ApplicantName]?.toString(),
          surname: item[cachedT.ApplicantSurnames]?.toString(),
          legalId: item[cachedT.ApplicantId]?.toString() || "",
          birthDate: item[cachedT.ApplicantBirthDate]
            ? moment(
                formatDate({ date: item[cachedT.ApplicantBirthDate] })
              ).format("YYYY-MM-DD")
            : "",
          homeAddress: {
            streetName: item[cachedT.ApplicantAddressStreetName]?.toString(),
            streetNumber: item[
              cachedT.ApplicantAddressStreetNumber
            ]?.toString(),
            details: item[cachedT.ApplicantAddressDetails]?.toString(),
            postalCode: item[cachedT.ApplicantAddressPostalCode]?.toString(),
            province: item[cachedT.ApplicantAddressProvince]?.toString(),
            locality: item[cachedT.ApplicantAddressLocality]?.toString(),
          },
        },
      };
    case "retirement-insurance-benefit":
      return {
        employeeLegalId: item[cachedT["DNI Empleado"]]?.toString(),
        previousCompanyContribution: MonetaryAmount.fromUnitary(
          Number(item[cachedT["Aportado por la empresa este año (€)"]])
        ),
        previousEmployeeContribution: MonetaryAmount.fromUnitary(
          Number(item[cachedT["Aportado por el empleado este año (€)"]])
        ),
        acceptedDate: moment(
          item[cachedT["Fecha de efecto en el servicio (aaaa-mm-dd)"]]
        ).format("YYYY-MM-DD"),
        policyNumber: item[cachedT["Número de póliza"]]?.toString(),
      };
    case "pension-plan-benefit":
      return {
        employeeLegalId: item[cachedT["DNI Empleado"]]?.toString(),
        previousCompanyContribution: MonetaryAmount.fromUnitary(
          Number(item[cachedT["Aportado por la empresa este año (€)"]])
        ),
        previousEmployeeContribution: MonetaryAmount.fromUnitary(
          Number(item[cachedT["Aportado por el empleado este año (€)"]])
        ),
        acceptedDate: moment(
          item[cachedT["Fecha de efecto en el servicio (aaaa-mm-dd)"]]
        ).format("YYYY-MM-DD"),
      };
    case "benefit-bag-available-config":
      return {
        legalId: item[cachedT.DNI],
        available: Number(item[cachedT["Saldo (€)"]]),
      };
    case "labor-days-config":
      return {
        legalId: item[cachedT.DNI],
        laborDays: Number(item[cachedT["Días laborables (#)"]]),
      };
    default:
      return item;
  }
};
