import { MonetaryAmount } from "../../app/domain/monetary-amount";
import { updateBenefitBagConfigAvailable } from "../../infrastructure/company-repository";
import { showErrorMessage } from "../commons/show-error-message";

export default async function editBenefitBagAvailable({
  companyId,
  benefitPlanId,
  benefitConfigId,
  payload,
}) {
  try {
    const parsePayload = payload.map(({ legalId, available }) => ({
      legalId,
      available: MonetaryAmount.of(available.amountInCents).toUnitary(),
    }));
    const res = await updateBenefitBagConfigAvailable({
      companyId,
      benefitPlanId,
      benefitConfigId,
      operations: parsePayload,
    });
    return res;
  } catch (error) {
    showErrorMessage("There was an error during the update operation");
    return {};
  }
}
