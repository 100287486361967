import React from "react";
import ReactDOM from "react-dom";
import NurseriesPageV2 from "./components/NurseriesPage/index";

export function renderNurseriesPage() {
  ReactDOM.render(
    <NurseriesPageV2 />,
    document.getElementById("page-container")
  );
}

export async function displayNurseriesPage() {
  renderNurseriesPage();
}
