import { makeStyles } from "@material-ui/core/styles";
import { primary } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  containerModal: {
    width: "80vw",
  },
  item: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid ${primary}`,
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
