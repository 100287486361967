import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import Table from "../../../../../app/components/Table";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import CobeeButton from "../../../../../app/components/CobeeButton";
import { hideModal } from "../../../../commons/hide-modal";
import Modal from "../../../../../app/components/modals/Modal";

export default function RecurrentPaymentDetailsModal({
  recurrentPayment,
  benefitDetailComponent,
}) {
  const { container, row, buttonRow } = useStyles();

  const renderStateHistory = (stateHistory) => (
    <>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <b>State History:</b>
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Table
          columns={[
            { label: "Date", id: "date" },
            { label: "State", id: "state" },
            { label: "Reason", id: "reason" },
            { label: "Unsubscription Type", id: "unsubscriptionType" },
          ]}
          data={stateHistory.map(({ timestamp, metadata = {}, ...rest }) => ({
            ...rest,
            date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
            reason: metadata?.reason || "N/A",
            unsubscriptionType: metadata?.unsubscriptionType || "N/A",
          }))}
        />
      </Grid>
    </>
  );

  const renderApprovals = (approvals) => (
    <>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <b>Approvals:</b>
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Table
          columns={[
            { label: "Date", id: "date" },
            { label: "Email", id: "email" },
          ]}
          data={approvals.map(({ timestamp = {}, ...rest }) => ({
            ...rest,
            date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
          }))}
        />
      </Grid>
    </>
  );

  const renderExecutionHistory = (executionHistory) => (
    <>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <b>Execution History:</b>
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Table
          columns={[
            { label: "Date", id: "date" },
            { label: "Result", id: "result" },
            { label: "Reason", id: "reason" },
          ]}
          data={executionHistory.map(
            ({ timestamp, metadata = {}, ...rest }) => ({
              ...rest,
              reason: metadata?.reason || "N/A",
              date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
            })
          )}
        />
      </Grid>
    </>
  );

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString()}</Typography>
      </Grid>
    </>
  );

  const formatDateFromTimestamp = (timestamp) =>
    timestamp ? moment.unix(timestamp).format("YYYY-MM-DD HH:mm") : "N/A";

  return (
    <Modal title="Recurrent Payment Detail">
      <Grid item container xs={12} className={container}>
        {textProperty("id", recurrentPayment.id)}
        {textProperty(
          "Recipient",
          `${recurrentPayment?.recipient?.name} ${recurrentPayment?.recipient?.surname}`
        )}
        {textProperty("Concept", recurrentPayment.concept)}
        {textProperty("State", recurrentPayment.state)}
        {textProperty("Created In Census", recurrentPayment.createdInCensus)}
        {textProperty("Periodicity", recurrentPayment.periodicity)}
        {textProperty(
          "Requested At",
          formatDateFromTimestamp(recurrentPayment.requestedAtTimestamp)
        )}
        {textProperty("Effective Since", recurrentPayment.effectiveSince)}
        {textProperty(
          "First Execution",
          formatDateFromTimestamp(recurrentPayment.firstExecTimestamp)
        )}
        {textProperty(
          "Next Execution",
          formatDateFromTimestamp(recurrentPayment.nextExecTimestamp)
        )}
        {textProperty(
          "Last Execution",
          formatDateFromTimestamp(recurrentPayment.lastExecTimestamp)
        )}
        {textProperty("Paused", recurrentPayment.paused)}

        {renderApprovals(recurrentPayment.approvals)}
        {renderExecutionHistory(recurrentPayment.executionHistory)}
        {renderStateHistory(recurrentPayment.stateHistory)}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Benefit Details</b>
            </h5>
          </Typography>
        </Grid>
        {benefitDetailComponent}

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Close
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
