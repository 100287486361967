import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import { useForm } from "react-hook-form";
import generateCsv from "services/generateCsv";
import { Money } from "v2/domain/common/entities/Money";
import Table from "../../../../../app/components/Table";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import CobeeButton from "../../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../../app/components/CobeeTextField";
import { showErrorMessage } from "../../../../../usecases/commons/show-error-message";
import editBenefitBagAvailable from "../../../../../usecases/companies/edit-benefit-bag-available";
import { showSuccessMessage } from "../../../../../usecases/commons/show-success-message";
import uploadBenefitBagAvailableFromFile from "../../../../../usecases/companies/upload-benefit-bag-available-file";
import { columnConfig, keys } from "./config";
import searchInKeys from "../../../../../services/searchInKeys";
import showBenefitBagDetail from "../../../../../usecases/companies/show-benefit-bag-details";
import MassiveUploadFilter from "../../../../../app/components/MassiveUploadFilter";

export default function EditEmployeesSection({
  companyId,
  benefitPlanId,
  benefitConfigId,
  allowedBenefits,
  alias,
  employeesData,
}) {
  const [search, setSearch] = useState("");
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isSavingAmount, setIsSavingAmount] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [updatedRow, setUpdatedRow] = useState("");

  const { register, getValues, setValue } = useForm();

  useEffect(() => {
    employeesData.forEach(({ legalId, availableSummary }) => {
      register(`available-${legalId}`);
      setValue(
        `available-${legalId}`,
        MonetaryAmount.ofCurrency(availableSummary.availableAmount).toUnitary()
      );
      setIsRegistered(true);
    });
  }, [employeesData, register, setValue]);

  const isValidValue = (legalId) => {
    const fieldValue = getValues(`available-${legalId}`);
    return (
      fieldValue >= 0 && fieldValue !== "" && !Number.isNaN(Number(fieldValue))
    );
  };

  const handleFileChange = (e) => {
    const fileName = e.target.files[0];
    setIsProcessingFile(true);
    uploadBenefitBagAvailableFromFile({
      companyId,
      benefitPlanId,
      benefitConfigId,
      fileName,
      allowedBenefits,
      alias,
    });
  };

  const searchResults = useCallback(
    (employees) => {
      return searchInKeys({ search, items: employees, keys });
    },
    [search]
  );

  const handleSearch = debounce(1000, false, (value) => {
    setSearch(value);
    setValue("currentPage", 0);
  });

  const tableData = () =>
    searchResults(employeesData).map(
      ({
        legalId,
        email,
        name,
        surname,
        availableSummary,
        initialLimitAmount,
        accumulatedFromPreviousPeriod,
        ...rest
      }) => {
        return {
          legalId,
          name,
          surname,
          completeName: `${name} ${surname}`,
          email,
          initialAmount: MonetaryAmount.ofCurrency(
            initialLimitAmount
          ).prettifyMoney({}),
          accumulatedAmount: MonetaryAmount.ofCurrency(
            accumulatedFromPreviousPeriod
          ).prettifyMoney({}),
          availableAmount: (
            <Grid container>
              <CobeeTextField
                type="number"
                defaultValue={getValues(`available-${legalId}`)}
                minWidth={80}
                min={0}
                step="0.01"
                styleType="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setValue(`available-${legalId}`, value);
                }}
              />
            </Grid>
          ),
          action: (
            <CobeeButton
              isLoading={isSavingAmount && legalId === updatedRow}
              onClick={async () => {
                if (isValidValue(legalId)) {
                  setIsSavingAmount(true);
                  setUpdatedRow(legalId);
                  const { data } = await editBenefitBagAvailable({
                    companyId,
                    benefitPlanId,
                    benefitConfigId,
                    payload: [
                      {
                        legalId,
                        available: MonetaryAmount.fromUnitary(
                          Number(getValues(`available-${legalId}`))
                        ),
                      },
                    ],
                  });
                  await showBenefitBagDetail({
                    companyId,
                    benefitPlanId,
                    benefitBagConfigId: benefitConfigId,
                    allowedBenefits,
                    alias,
                  });
                  if (data?.content?.bagsWithError?.length > 0) {
                    const bagWithError = data?.content?.bagsWithError[0];
                    const {
                      error: { message },
                    } = bagWithError;
                    showErrorMessage(message);
                  } else if (data?.content?.bagsWithError?.length === 0) {
                    showSuccessMessage("Successfully updated value");
                  }
                } else {
                  showErrorMessage("This value is invalid");
                }
              }}
            >
              Save
            </CobeeButton>
          ),
          ...rest,
        };
      }
    );

  const dataRows = tableData();
  return (
    <Grid>
      {isProcessingFile ? (
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <CircularProgress size={32} />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Processing file, please wait
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <MassiveUploadFilter
            handleDownload={() => {
              const filteredEmployees = searchResults(employeesData);
              return generateCsv({
                title: "avalaible-amounts-template.xlsx",
                headers: ["DNI", "Nombre", "Apellidos", "Saldo (€)"],
                rows: filteredEmployees.map((row) => [
                  row.legalId,
                  row.name,
                  row.surname,
                  new Money(row.availableSummary.availableAmount)
                    .toUnitary()
                    .toString(),
                ]),
              });
            }}
            handleFileChange={handleFileChange}
            handleSearch={handleSearch}
          />
          {isRegistered && <Table columns={columnConfig} data={dataRows} />}
        </>
      )}
    </Grid>
  );
}

EditEmployeesSection.propTypes = {
  companyId: PropTypes.string,
  benefitPlanId: PropTypes.string,
  benefitConfigId: PropTypes.string,
  employeesData: PropTypes.array,
  allowedBenefits: PropTypes.array,
  alias: PropTypes.string,
};

EditEmployeesSection.defaultProps = {
  companyId: "",
  benefitPlanId: "",
  benefitConfigId: "",
  employeesData: [],
  allowedBenefits: [],
  alias: "",
};
