import { makeStyles } from "@material-ui/core/styles";
import { primary, grey300 } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  principalButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  secondButton: {
    marginTop: theme.spacing(1),
  },
  checkIcon: {
    color: primary,
  },
  disabledIcon: {
    color: grey300,
  },
}));

export default useStyles;
