import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { defaultTheme } from "@cobee/cobee-ui";
import { FlexBox } from "../FlexBox";

const { typography } = defaultTheme;

const _Fieldset = styled.fieldset`
  & > legend {
    font-family: ${typography.fontFamily};
    font-size: ${typography.h4.fontSize}px;
    font-style: ${typography.h4.fontStyle};
    font-weight: ${typography.h4.fontWeight};
    line-height: ${typography.h4.lineHeight};
  }

  & > fieldset > legend {
    padding-left: 16px;
    font-size: ${typography.h6.fontSize}px;
    font-style: ${typography.h6.fontStyle};
    font-weight: ${typography.h6.fontWeight};
    line-height: ${typography.h6.lineHeight};
  }
`;

export const Fieldset = ({ children, legend }) => (
  <FlexBox Element={_Fieldset} direction="column" gap={16}>
    {legend && <legend style={{ marginBottom: 16 }}>{legend}</legend>}
    {children}
  </FlexBox>
);

Fieldset.propTypes = {
  legend: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Fieldset.defaultProps = {
  legend: undefined,
};
