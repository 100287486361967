import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  containerModal: {
    width: "60vw",
  },
  errorsMessage: {
    fontWeight: 700,
  },
}));

export default useStyles;
