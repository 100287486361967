import React, { Component, Fragment } from "react";
import moment from "moment";
import { displayEmployeeDetail } from "../display-employee-detail";
import { saveEmployeeNursery } from "../save-employee-nursery";
import { showErrorMessage } from "../../commons/show-error-message";

export default class newEmployeeNursery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipientId: "",
      nurseryCentreName: "",
      streetName: "",
      streetNumber: "",
      details: "",
      postalCode: "",
      province: "",
      locality: "",
      phoneNumber: "",
      amount: "",
      effectiveDate: moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      lastExec: moment().add(1, "month").startOf("month").format("YYYY-MM-DD"),
      exemptionIRPF: false,
    };
  }

  handleChangeLastExec = (date) => {
    let lastExec = moment(date, "YYYY-MM-DD").startOf("month");
    if (
      lastExec.isBefore(moment(this.state.effectiveDate, "YYYY-MM-DD"), "month")
    ) {
      lastExec = this.state.effectiveDate;
      alert("lastExec should be after than effective date");
    } else {
      lastExec = moment(date, "YYYY-MM-DD")
        .startOf("month")
        .format("YYYY-MM-DD");
    }

    this.setState({ lastExec });
  };

  handleChangeEffectiveDate = (date) => {
    let effectiveDate = moment(date, "YYYY-MM-DD").startOf("month");
    if (
      effectiveDate.isAfter(
        moment(this.state.lastExec, "YYYY-MM-DD"),
        "month"
      ) &&
      this.state.isRecurrent
    ) {
      effectiveDate = this.state.lastExec;
      alert("lastExec should be after than effective date");
    } else {
      effectiveDate = moment(date, "YYYY-MM-DD")
        .startOf("month")
        .format("YYYY-MM-DD");
    }

    this.setState({ effectiveDate });
  };

  handlerSave = () => {
    const {
      amount,
      nurseryCentreName,
      phoneNumber,
      recipientId,
      isRecurrent,
      exemptionIRPF,
      lastExec,
      effectiveDate,
    } = this.state;
    const { employee } = this.props;
    const child = employee.familyMembers.find(
      (member) => member.id === recipientId
    );
    const address = {
      streetName: this.state.streetName,
      streetNumber: this.state.streetNumber,
      details: this.state.details,
      postalCode: this.state.postalCode,
      province: this.state.province,
      locality: this.state.locality,
    };

    if (isRecurrent && effectiveDate === lastExec) {
      showErrorMessage("Effective date can not be the same as Last monthly");
    } else {
      saveEmployeeNursery({
        amount,
        employee,
        nurseryCentreName,
        phoneNumber,
        address,
        recipientId,
        childName: child.name,
        isRecurrent,
        exemptionIRPF,
        lastExec,
        effectiveDate,
      });
    }
  };

  render() {
    const { employee, numMp } = this.props;
    const { recipientId } = this.state;

    return (
      <div className="card mb-5">
        <div className="card-body">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Child</label>
            <div className="col-sm-10">
              <select
                className="custom-select"
                value={recipientId}
                onChange={(e) => this.setState({ recipientId: e.target.value })}
              >
                <option selected>Select child</option>
                {employee.familyMembers.map(
                  (familyMember) =>
                    familyMember.type === "descendant" && (
                      <option key={familyMember.id} value={familyMember.id}>
                        {`${familyMember.name} ${familyMember.surname}`}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Nursery name </label>
            <div className="col-sm-6">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Nombre de la guardería"
                value={this.state.nurseryCentreName}
                onChange={(e) =>
                  this.setState({ nurseryCentreName: e.target.value })
                }
              />
            </div>

            <label className="col-sm-1 col-form-label">Phone</label>
            <div className="col-sm-3">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Phone number"
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Nursery Address:</label>
            <label className="col-sm-2 col-form-label">Street</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Street"
                value={this.state.streetName}
                onChange={(e) => this.setState({ streetName: e.target.value })}
              />
            </div>

            <label className="col-sm-2 col-form-label">Number</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Number"
                value={this.state.streetNumber}
                onChange={(e) =>
                  this.setState({ streetNumber: e.target.value })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Details</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Details"
                value={this.state.details}
                onChange={(e) => this.setState({ details: e.target.value })}
              />
            </div>

            <label className="col-sm-2 col-form-label">Postal Code</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Postal Code"
                value={this.state.postalCode}
                onChange={(e) => this.setState({ postalCode: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Province</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Province"
                value={this.state.province}
                onChange={(e) => this.setState({ province: e.target.value })}
              />
            </div>

            <label className="col-sm-2 col-form-label">Locality</label>
            <div className="col-sm-4">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Locality"
                value={this.state.locality}
                onChange={(e) => this.setState({ locality: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Recurrent Payment</label>
            <div className="col-sm-1">
              <input
                type="checkbox"
                checked={this.state.isRecurrent}
                onChange={() =>
                  this.setState((prev) => ({ isRecurrent: !prev.isRecurrent }))
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-2">
              <label className="col-form-label">Effective date</label>
            </div>
            <div className="col-sm-4">
              <input
                type="date"
                required
                defaultValue={this.state.effectiveDate}
                value={this.state.effectiveDate}
                onChange={(e) => this.handleChangeEffectiveDate(e.target.value)}
              />
            </div>

            {this.state.isRecurrent && (
              <>
                <div className="col-sm-3">
                  <label className="col-form-label">
                    Last monthly payment(included)
                  </label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="date"
                    required
                    defaultValue={this.state.lastExec}
                    value={this.state.lastExec}
                    onChange={(e) => this.handleChangeLastExec(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Amount</label>
            <div className="col-sm-4">
              <input
                required
                type="number"
                step=".01"
                className="form-control"
                placeholder="Precio"
                value={this.state.amount}
                onChange={(e) => this.setState({ amount: e.target.value })}
              />
            </div>

            <label className="col-sm-4 col-form-label">
              Employee will apply deduction of 1000€
            </label>
            <div className="col-sm-1">
              <input
                type="checkbox"
                checked={this.state.exemptionIRPF}
                onChange={() =>
                  this.setState((prev) => ({
                    exemptionIRPF: !prev.exemptionIRPF,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row buttonCenter">
            <button
              className="btn btn-primary mr-4"
              disabled={!recipientId}
              onClick={this.handlerSave}
            >
              Save changes
            </button>
            <button
              className="btn btn-danger ml-2 mr-2"
              onClick={() => {
                displayEmployeeDetail(employee.email, numMp);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
