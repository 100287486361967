import apiClient from "infrastructure/api-client";

export const addRetirementInsuranceBenefit = async ({ companyId, payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/retirement-insurance/products`,
    payload
  );
  return res;
};

export const updateRetirementInsuranceProduct = async ({
  companyId,
  retirementInsuranceProductId,
  payload,
}) => {
  const res = await apiClient().put(
    `/v1/back-office/companies/${companyId}/retirement-insurance/products/${retirementInsuranceProductId}`,
    payload
  );

  return res;
};
