const comparePatternVsTransaction = ({
  pattern,
  transaction,
  patternKey,
  transactionKey,
}) => {
  return patternKey in pattern && pattern[patternKey] !== null
    ? pattern[patternKey] === transaction?.merchantInfo[transactionKey]
    : false;
};

export const mapCategorization = ({ transactions, categorizationPatterns }) => {
  return transactions.map((transaction) => {
    let isCategorized = false;
    categorizationPatterns.forEach((pattern) => {
      isCategorized =
        isCategorized ||
        (comparePatternVsTransaction({
          pattern,
          transaction,
          patternKey: "country",
          transactionKey: "country",
        }) &&
          (comparePatternVsTransaction({
            pattern,
            transaction,
            patternKey: "merchantId",
            transactionKey: "merchantId",
          }) ||
            comparePatternVsTransaction({
              pattern,
              transaction,
              patternKey: "merchantCategoryCode",
              transactionKey: "merchantCategoryCode",
            }) ||
            comparePatternVsTransaction({
              pattern,
              transaction,
              patternKey: "terminalId",
              transactionKey: "terminalId",
            })));
    });
    return {
      ...transaction,
      isCategorized,
    };
  });
};
