import React from "react";
import ReactDOM from "react-dom";
import CensusProgressPage from "./index";

export default function renderCensusProgressPage({
  target = "page-container",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(<CensusProgressPage {...rest} />, domTarget);
  }
}
