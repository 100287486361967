import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svg: {
    margin: `${theme.spacing(8)}px ${theme.spacing(32)}px`,
    width: 240,
    height: 180,
  },
}));

export default useStyles;
