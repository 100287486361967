import React, { Component } from "react";

import authClient from "./auth/Auth";
import Routes from "./routes/Routes";

import "loaders.css";
import Loader from "../ui/components/loader";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: "wait",
    };
  }

  async componentDidMount() {
    if (authClient.isAuthenticated()) {
      const safetyBufferMillis = 10000;
      this.scheduleSessionRefresh(
        authClient.getExpiresInMillis() - safetyBufferMillis
      );
      this.setState({ loadPage: "navigation" });
    } else if (window.location.pathname === "/callback") {
      if (/access_token|id_token|error/.test(window.location.hash)) {
        await authClient.handleAuthentication();
        this.setState({ loadPage: "navigation" });
      }
    } else if (window.location.pathname !== "/error") {
      authClient.logIn();
    }
  }

  scheduleSessionRefresh = (timeout) => {
    window.setInterval(() => {
      authClient.refreshSession();
    }, timeout);
  };

  render() {
    return (
      <div>
        <div id="modal-container" />
        {this.state.loadPage === "wait" && <Loader active />}
        {this.state.loadPage === "navigation" && <Routes />}
      </div>
    );
  }
}

export default App;
