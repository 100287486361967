import React from "react";
import ReactDOM from "react-dom";
import { findBrokers } from "../../../infrastructure/company-repository";
import InsuranceProducts from "../components/InsuranceProducts";

export function renderHealthInsuranceDetails({ company, brokers }) {
  ReactDOM.render(
    <InsuranceProducts
      companyId={company.id}
      brokers={brokers}
      insuranceProducts={company.insuranceProducts}
      insuranceRequestsEmailSubscribers={
        company.insuranceRequestsEmailSubscribers
      }
      healthInsuranceComplements={company.healthInsuranceComplements}
    />,
    document.getElementById("page-container")
  );
}

export async function HealthInsuranceDetails({ company }) {
  const brokers = await findBrokers();
  renderHealthInsuranceDetails({ company, brokers });
}
