import { toast } from "react-toastify";

export function showSuccessMessage(message) {
  toast.success(message, {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}
