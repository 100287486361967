import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../RestoreVoidTransactionModal/styles";
import CobeeButton from "../../CobeeButton";
import CobeeTextField from "../../CobeeTextField";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function ConfirmationWithPasswordModal({ callback, payload }) {
  const { container, row, buttonRow, divider } = useStyles();
  const [values, setValues] = useState([]);

  const formIsReady = () => {
    return "password" in values;
  };

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Enter password to confirm action</Typography>
      </Grid>

      <Grid item container xs={12} className={divider} />

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row}>
          <Typography variant="body2">Password:</Typography>
        </Grid>
        <Grid item container xs={10} className={row}>
          <CobeeTextField
            type="password"
            minWidth="100%"
            onChange={(e) =>
              setValues({
                ...values,
                password: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton variant="secondary" onClick={hideModal}>
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={!formIsReady()}
          onClick={() => {
            callback({ ...payload, ...values });
          }}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}

ConfirmationWithPasswordModal.propTypes = {
  callback: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
};
