import ReactDOM from "react-dom";
import React from "react";

import HexModal from "../../app/components/modals/HexModal";
import NurseryModalDetail from "./components/NurseryModalDetail";

export function displayNurseryDetail(props) {
  ReactDOM.render(
    <HexModal>
      <NurseryModalDetail {...props} />
    </HexModal>,
    document.getElementById("modal-container")
  );
}
