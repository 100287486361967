import React from "react";
import ReactDOM from "react-dom";
import CompaniesPage from "./components/CompaniesPage";
import { findCompanies } from "../../infrastructure/company-repository";
import { displayLoader } from "../commons/render-loader";

export async function displayCompaniesPage() {
  displayLoader();
  renderCompaniesPage();
}

export function renderCompaniesPage() {
  ReactDOM.render(<CompaniesPage />, document.getElementById("page-container"));
}
