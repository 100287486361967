import PropTypes from "prop-types";
import * as React from "react";
import moment from "moment";
import { MUI, Select } from "@cobee/cobee-ui";

export const PeriodSelect = ({
  data,
  selectedPeriodState: { selectedPeriod, setSelectedPeriod },
}) => {
  return (
    <MUI.Box sx={{ display: "flex" }}>
      <Select
        value={selectedPeriod.periodIndex}
        onChange={(e) => {
          switch (e.target.value) {
            case 1:
              setSelectedPeriod({
                modality: data.futurePeriod,
                periodIndex: 1,
              });
              break;
            case 2:
              setSelectedPeriod({
                modality: data.pastPeriods[0],
                periodIndex: 2,
              });
              break;
            default:
              setSelectedPeriod({
                modality: data,
                periodIndex: 0,
              });
          }
        }}
      >
        <MUI.MenuItem value={0}>Current period</MUI.MenuItem>
        <MUI.MenuItem disabled={!data.futurePeriod} value={1}>
          Next period
        </MUI.MenuItem>
        <MUI.MenuItem disabled={!data.pastPeriods[0]} value={2}>
          Previous periods
        </MUI.MenuItem>
      </Select>
      {selectedPeriod.periodIndex === 2 ? (
        <Select
          value={selectedPeriod.modality.id}
          onChange={(e) => {
            setSelectedPeriod({
              modality: data.pastPeriods.find(
                (period) => period.id === e.target.value
              ),
              periodIndex: 2,
            });
          }}
        >
          {data.pastPeriods.map((period) => {
            return (
              <MUI.MenuItem key={period.id} value={period.id}>
                {`${moment(period.availableFrom).format(
                  "DD/MM/YYYY"
                )} - ${moment(period.expiresAt).format("DD/MM/YYYY")}`}
              </MUI.MenuItem>
            );
          })}
        </Select>
      ) : null}
    </MUI.Box>
  );
};

PeriodSelect.propTypes = {
  data: PropTypes.any.isRequired,
  selectedPeriodState: PropTypes.shape({
    selectedPeriod: PropTypes.shape({
      modality: PropTypes.any,
      periodIndex: PropTypes.number,
    }),
    setSelectedPeriod: PropTypes.func,
  }).isRequired,
};
