import apiClient from "./api-client";

export const findVendors = async ({ benefit }) => {
  try {
    const res = await apiClient().get(
      `/v1/back-office/vendors?benefit=${benefit}`
    );
    return res;
  } catch (err) {
    return {};
  }
};
