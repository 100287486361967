import React, { Component, useState } from "react";
import { v4 } from "uuid";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { displayTransactionsTable } from "../display-transactions-table";
import { hideModal } from "../../commons/hide-modal";
import updateTransactionDetail from "../update-transaction-detail";

const styles = () => ({
  container: {
    width: "90vw",
  },
});

class TransactionDetail extends Component {
  copyToClipboard = (e) => {
    this.input.select();
    document.execCommand("copy");
    e.target.focus();
  };

  render() {
    const { transaction, email, numMp, inModal } = this.props;
    const infoToShow = [
      { name: "Card Id", key: "cardId", placeholder: "Internal Id" },
      { name: "Payroll", key: "payrollCycle", placeholder: "Payroll" },
      { name: "Concept", key: "description", placeholder: "Concept" },
      {
        name: "Employee Id",
        key: "employeeId",
        placeholder: "Employee Id",
      },
      {
        name: "Recipient Id",
        key: "recipientId",
        placeholder: "Recipient Id",
      },
      { name: "Company Id", key: "companyId", placeholder: "Company Id" },
      {
        name: "Merchant Id",
        key: "merchantId",
        placeholder: "Merchant Id",
        type: "editable",
      },
      {
        name: "Merchant Rfc Id",
        key: "rfcId",
        placeholder: "Rfc Id",
        type: "editable",
      },
      {
        name: "Merchant Postal Code",
        key: "merchantPostalCode",
        placeholder: "Merchant Postal Code",
        type: "editable",
      },
      {
        name: "Merchant Name",
        key: "merchantName",
        placeholder: "Merchant Name",
      },
      {
        name: "Intended Amount",
        key: "intendedAmount",
        placeholder: "Intended Amount",
        type: "amount",
      },
      {
        name: "Cleared Amount",
        key: "clearedAmount",
        placeholder: "Cleared Amount",
        type: "amount",
      },

      {
        name: "Flex Amount",
        key: "flexDebitAmount",
        placeholder: "Flex Amount",
        type: "amount",
      },
      {
        name: "From Flex not exempt",
        key: "flexNotExemptDebitAmount",
        placeholder: "Flex not exempt",
        type: "amount",
      },

      {
        name: "Allowance",
        key: "allowanceDebitAmount",
        placeholder: "Allowance",
        type: "amount",
      },
      {
        name: "From Allowance not exempt",
        key: "allowanceNotExemptDebitAmount",
        placeholder: "Allowance not exempt",
        type: "amount",
      },
      { name: "Category", key: "category", placeholder: "Category" },
      {
        name: "Iso2 Country Code",
        key: "iso2CountryCode",
        placeholder: "Iso2 Country Code",
      },
      {
        name: "LocalIsoTime",
        key: "userTransactionIsoTime",
        placeholder: "localIsoTime",
      },
      { name: "Timestamp", key: "timestamp", placeholder: "timestamp" },
      {
        name: "Merchant Category Code",
        key: "merchantCategoryCode",
        placeholder: "Merchant Category Code",
      },
      { name: "State", key: "status", placeholder: "timestamp" },
      {
        name: "Rejected Reason",
        key: "reason",
        placeholder: "Rejected Reason",
      },
      {
        name: "Authorization id",
        key: "authorizationId",
        placeholder: "Authorization id",
      },
      { name: "Brand", key: "brand", placeholder: "brand" },
    ];
    const debitKeys = [
      { name: "flex", key: "flexDebitAmount" },
      { name: "flex not exempt", key: "flexNotExemptDebitAmount" },
      { name: "allowance", key: "allowanceDebitAmount" },
      { name: "allowance not exempt", key: "allowanceNotExemptDebitAmount" },
    ];
    const creditKeys = [
      { name: "flex", key: "flexCreditAmount" },
      { name: "flex not exempt", key: "flexNotExemptCreditAmount" },
      { name: "allowance", key: "allowanceCreditAmount" },
      { name: "allowance not exempt", key: "allowanceNotExemptCreditAmount" },
    ];

    const { classes } = this.props;

    const handleSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const payload = {
        merchantId: formData.get("merchantId"),
        merchantRfcId: formData.get("rfcId"),
        merchantPostalCode: formData.get("merchantPostalCode"),
      };
      updateTransactionDetail({
        payload,
        transactionId: transaction.transactionId,
      });
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className={`card mb-5 ${inModal ? classes.container : ""}`}>
          <div className="card-body">
            <p className="card-text">Transaction:</p>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Transaction Id</label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    readOnly
                    ref={(input) => {
                      this.input = input;
                    }}
                    type="text"
                    className="form-control"
                    placeholder="transactionId"
                    value={transaction.transactionId}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={this.copyToClipboard}
                      className="input-group-text"
                    >
                      COPY
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              {infoToShow.map((value, tkey) => {
                let { key } = value;
                if (transaction.type === "card-purchase-refund") {
                  key = key.replace("Debit", "Credit");
                }
                const amountValue = transaction[`${key}`]?.amountInCents
                  ? transaction[`${key}`].amountInCents / 100
                  : 0;
                return (
                  <div key={value.name} className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      {value.name}
                    </label>
                    <div className="col-sm-10">
                      {value.type === "amount" ? (
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          placeholder={transaction[`${key}`] || ""}
                          value={amountValue}
                        />
                      ) : (
                        <>
                          {value.type === "editable" ? (
                            <input
                              type="text"
                              name={key}
                              className="form-control"
                              placeholder="-"
                              defaultValue={
                                value.type === "amount"
                                  ? amountValue
                                  : transaction[`${key}`] || undefined
                              }
                            />
                          ) : (
                            <input
                              readOnly
                              type="text"
                              className="form-control"
                              placeholder="-"
                              title={value.placeholder}
                              value={transaction[`${key}`] || ""}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              <p className="font-weight-bold">State History</p>{" "}
              {transaction.stateHistory.map((state) => (
                <li key={v4()}>
                  {state.state}{" "}
                  {moment(state.timestampMillis).format("YYYY-MM-DD")}{" "}
                  {moment(state.timestampMillis).format("HH:mm")}
                </li>
              ))}
              {transaction.flexCreditAmount &&
                transaction.type !== "card-purchase-refund" && (
                  <>
                    <br />

                    <p className="font-weight-bold">
                      From company to empoyee purses - Money to Report{" "}
                    </p>
                    {debitKeys.map((value) => (
                      <li key={value.key}>
                        {value.name}
                        {": "}{" "}
                        {transaction[`${value.key}`]?.amountInCents
                          ? transaction[`${value.key}`].amountInCents / 100
                          : 0}
                        {value.name === "allowance"
                          ? ` - From Benefit Bag: ${
                              transaction.benefitBagAmount?.amountInCents
                                ? transaction.benefitBagAmount.amountInCents /
                                  100
                                : 0
                            }`
                          : ""}
                      </li>
                    ))}
                    {transaction.status === "authorized-by-cobee" ||
                    transaction.status === "authorizing" ? (
                      <p className="font-weight-bold">
                        Money hold in employee purses{" "}
                      </p>
                    ) : (
                      <p className="font-weight-bold">
                        From employee purses to settlement{" "}
                      </p>
                    )}

                    {creditKeys.map((value) => (
                      <li key={value.key}>
                        {value.name}
                        {": "}{" "}
                        {transaction[`${value.key}`]?.amountInCents
                          ? transaction[`${value.key}`].amountInCents / 100
                          : 0}
                      </li>
                    ))}
                  </>
                )}
              {transaction.type === "card-purchase-refund" && (
                <>
                  <br />
                  <p className="card-text font-weight-bold">
                    From settlement to empoyee purses{" "}
                  </p>
                  {debitKeys.map((value) => (
                    <li key={value.key}>
                      {value.name}
                      {": "}{" "}
                      {transaction[`${value.key}`]?.amountInCents
                        ? transaction[`${value.key}`].amountInCents / 100
                        : 0}
                    </li>
                  ))}
                </>
              )}
              <div className="row float-right">
                <button
                  className="btn btn-secondary mr-2 "
                  onClick={() => {
                    if (window.location.pathname.endsWith("transactions")) {
                      displayTransactionsTable({ email, numMp });
                    } else {
                      hideModal();
                    }
                  }}
                >
                  Return
                </button>
                <button type="submit" className="btn btn-primary mr-2 ">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(TransactionDetail);
