import React from "react";
import ReactDOM from "react-dom";
import EducationRecurrentPaymentDetailsModal from "./components/RecurrentPayments/EducationRecurrentPaymentDetailsModal";
import HealthInsuranceRecurrentPaymentDetailsModal from "./components/RecurrentPayments/HealthInsuranceRecurrentPaymentDetailsModal";

export default function renderHealthInsuranceRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <HealthInsuranceRecurrentPaymentDetailsModal
        recurrentPayment={recurrentPayment}
      />,
      domTarget
    );
  }
}
