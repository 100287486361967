import React from "react";
import ReactDOM from "react-dom";
import HexModal from "../../../app/components/modals/HexModal";
import DiscardInvoiceModal from "../../../app/components/modals/DiscardInvoiceModal";

export default function renderDiscardInvoiceModal({
  target = "modal-container",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(
      <HexModal>
        <DiscardInvoiceModal {...rest} />
      </HexModal>,
      domTarget
    );
  }
}
