import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { findErrorLogs } from "../../../../infrastructure/error-logs-repository";
import Table from "../../../../app/components/Table";
import CobeeButton from "../../../../app/components/CobeeButton";
import renderErrorLogDetailsModal from "../../render-error-log-details-modal";

export default function ErrorLogTable({ employeeId }) {
  const [loading, setLoading] = useState(true);
  const [errorLogs, setErrorLogs] = useState([]);

  useEffect(() => {
    async function execute() {
      setLoading(true);
      const result = await findErrorLogs({
        employeeId,
        pageSize: 9999,
        pageNumber: 1,
      });
      setErrorLogs(result.content);
      setLoading(false);
    }
    execute();
  }, [employeeId]);

  return (
    <>
      {errorLogs.length === 0 && (
        <Typography variant="body1" align="center">
          No results found
        </Typography>
      )}
      {errorLogs.length > 0 && (
        <Table
          columns={[
            { label: "Datetime", id: "createdAt" },
            { label: "Type", id: "type" },
            { label: "Reason", id: "code" },
            { label: "Recipient Subtype", id: "recipientSubtype" },
            { label: "Actions", id: "actions" },
          ]}
          forcePagination
          data={errorLogs.map((errorLog) => {
            const { code, type, createdAt, recipient } = errorLog;
            return {
              createdAt: moment(createdAt).format("YYYY-MM-DD HH:mm"),
              type,
              code,
              recipientSubtype: recipient.subtype || "N/A",
              actions: (
                <>
                  <CobeeButton
                    variant="secondary"
                    onClick={() => renderErrorLogDetailsModal({ errorLog })}
                  >
                    Details
                  </CobeeButton>
                </>
              ),
            };
          })}
        />
      )}
    </>
  );
}

ErrorLogTable.propTypes = {
  employeeId: PropTypes.string,
};

ErrorLogTable.defaultProps = {
  employeeId: "",
};
