import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { white, black, secondary, grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    border: `1px solid ${grey300}`,
    padding: theme.spacing(0.2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(8),
  },
  label: {
    fontWeight: 500,
  },
  date: {
    minWidth: theme.spacing(15),
    textAlign: "center",
    cursor: "pointer",
    color: grey300,
  },
  icon: {
    color: "#0000ff",
  },
  customButtons: {
    borderRadius: theme.spacing(8),
    color: secondary,
  },
  resetIcon: {
    color: secondary,
    cursor: "pointer",
  },
  continueButton: {
    backgroundColor: secondary,
    color: white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(8),
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: secondary,
      opacity: 0.8,
      color: white,
    },
    "&:disabled": {
      color: white,
      opacity: "0.3",
    },
  },
}));

export const Wrapper = styled.div`
  .react-datepicker-wrapper {
    width: ${(props) => props.fullWidth && "100%"};
  }

  .react-datepicker {
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__current-month {
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }

  .react-datepicker__day-name {
    margin: 0;
    width: 30px;
    height: 30px;

    p {
      height: 30px;
      line-height: 30px;
      font-weight: bold;
    }
  }

  .react-datepicker__day {
    margin: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, 1);
    border-radius: 0;
    vertical-align: bottom;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .react-datepicker__day--disabled {
    color: rgba(0, 0, 0, 0.4);
  }

  .react-datepicker__day--in-range {
    background-color: rgba(0, 0, 255, 0.1);
  }

  .react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 0, 255, 0.1);
  }

  .react-datepicker__day--range-start {
    background-color: ${secondary};
    color: white;
    z-index: 1;

    &.react-datepicker__day--today {
      background-color: rgba(0, 0, 255, 1);
    }
    &.react-datepicker__day--outside-month {
      background-color: rgba(0, 0, 255, 0.1);
      border-radius: 0;
      color: ${black};
    }
  }

  .react-datepicker__day--range-end {
    border-radius: 50%;
    background-color: ${secondary};
    color: white;

    &.react-datepicker__day--today {
      background-color: rgba(0, 0, 255, 1);
    }

    &.react-datepicker__day--outside-month {
      background-color: rgba(0, 0, 255, 0.1);
      border-radius: 0;
      color: ${black};
    }
  }

  .react-datepicker__day--selected {
    background-color: ${secondary};
    color: ${white};
    outline: none;

    &.react-datepicker__day--outside-month {
      background-color: rgba(0, 0, 255, 0.1);
      color: ${black};
      border-radius: 0;
    }
  }

  .react-datepicker__day--today {
    background-color: rgba(0, 0, 255, 0.2);
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: rgba(0, 0, 255, 1);
  }
`;

export default useStyles;
