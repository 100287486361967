import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Modal from "../../../app/components/modals/Modal";
import useStyles from "./styles";
import ShowEmployeesSection from "./components/ShowEmployeesSection";
import EditEmployeesSection from "./components/EditEmployeesSection";
import ShowBenefitsSection from "./components/ShowBenefitsSection";
import EditBenefitsSection from "./components/EditBenefitsSection";
import ShowConfigSection from "./components/ShowConfigSection";
import EditConfigSection from "./components/EditConfigSection";
import enableAccumulated from "../../../usecases/companies/enable-bag-accumulated";
import editBagBenefits from "../../../usecases/companies/edit-bag-benefits";

export default function BenefitBagDetail({
  isLoading,
  companyId,
  benefitPlanId,
  benefitBagConfigId,
  initialDate,
  employees,
  availableSummarys,
  initialLimitAmount,
  accumulatedFromPreviousPeriod,
  activeBenefits,
  allowedBenefits,
  adjustments,
  alias,
  renewAtPeriodEnd,
}) {
  const {
    containerModal,
    currentPeriodContainer,
    section,
    sectionTitle,
    editText,
  } = useStyles();

  const [isEditEmployeesMode, setIsEditEmployeesMode] = useState(false);
  const [isEditBenefitMode, setIsEditBenefitsMode] = useState(false);
  const [isEditConfigMode, setIsEditConfigMode] = useState(false);

  const {
    periodicity,
    amount,
    accumulable,
    deliverUnusedExemptToPurses,
  } = adjustments;
  const finalCurrentDate = moment(initialDate)
    .add(1, periodicity === 12 ? "y" : "M")
    .format("YYYY-MM-DD");

  return (
    <Modal title="Benefit Bag Detail">
      <Grid container className={containerModal}>
        {!isLoading ? (
          <>
            <Grid container justify="center">
              <Grid item className={currentPeriodContainer}>
                <Typography>Current</Typography>
                <Typography variant="body2">{`${initialDate} / ${finalCurrentDate}`}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>
                  EMPLOYEES INCLUDED
                </Typography>
                <Typography
                  variant="body2"
                  className={editText}
                  onClick={() => setIsEditEmployeesMode((prev) => !prev)}
                >
                  {isEditEmployeesMode ? "Back" : "Edit"}
                </Typography>
              </Grid>
              {!isEditEmployeesMode ? (
                <ShowEmployeesSection employees={employees} />
              ) : (
                <EditEmployeesSection
                  companyId={companyId}
                  benefitPlanId={benefitPlanId}
                  benefitConfigId={benefitBagConfigId}
                  allowedBenefits={allowedBenefits}
                  alias={alias}
                  employeesData={employees
                    .filter((item) => item !== undefined)
                    .map((employee, index) => ({
                      ...employee,
                      availableSummary: availableSummarys[index],
                      initialLimitAmount: initialLimitAmount[index],
                      accumulatedFromPreviousPeriod:
                        accumulatedFromPreviousPeriod[index],
                    }))}
                />
              )}
            </Grid>
            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>
                  BENEFITS INCLUDED
                </Typography>
                <Typography
                  variant="body2"
                  className={editText}
                  onClick={() => setIsEditBenefitsMode((prev) => !prev)}
                >
                  {isEditBenefitMode ? "Back" : "Edit"}
                </Typography>
              </Grid>
              {!isEditBenefitMode ? (
                <ShowBenefitsSection
                  activeBenefits={activeBenefits}
                  allowedBenefits={allowedBenefits}
                />
              ) : (
                <EditBenefitsSection
                  intialActiveBenefits={activeBenefits}
                  allowedBenefits={allowedBenefits}
                  callback={async (benefits) => {
                    await editBagBenefits({
                      companyId,
                      benefitPlanId,
                      benefitBagConfigId,
                      benefits,
                      allowedBenefits,
                      alias,
                    });
                    setIsEditBenefitsMode(false);
                  }}
                />
              )}
            </Grid>
            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>CONFIGURATION</Typography>
                <Typography
                  variant="body2"
                  className={editText}
                  onClick={() => setIsEditConfigMode((prev) => !prev)}
                >
                  {!isEditConfigMode ? "Edit" : "Back"}
                </Typography>
              </Grid>
              {!isEditConfigMode ? (
                <ShowConfigSection
                  amount={amount}
                  periodicity={periodicity}
                  accumulable={accumulable}
                  deliverUnusedExemptToPurses={deliverUnusedExemptToPurses}
                  renewAtPeriodEnd={renewAtPeriodEnd}
                />
              ) : (
                <EditConfigSection
                  accumulable={accumulable}
                  deliverUnusedExemptToPurses={deliverUnusedExemptToPurses}
                  renewAtPeriodEnd={renewAtPeriodEnd}
                  periodicity={periodicity}
                  callback={async ({
                    isEnabled,
                    shouldDeliverOnPeriodEnd,
                    shouldRenewAtPeriodEnd,
                  }) => {
                    await enableAccumulated({
                      isEnabled,
                      shouldDeliverOnPeriodEnd,
                      shouldRenewAtPeriodEnd,
                      companyId,
                      benefitPlanId,
                      benefitBagConfigId,
                      allowedBenefits,
                      alias,
                    });
                    setIsEditConfigMode(false);
                  }}
                />
              )}
            </Grid>
            <Grid container direction="column" className={section}>
              <Typography className={sectionTitle}>ALIAS</Typography>
              <Typography>{alias}</Typography>
            </Grid>
          </>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={32} />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

BenefitBagDetail.propTypes = {
  isLoading: PropTypes.bool,
  companyId: PropTypes.string,
  benefitPlanId: PropTypes.string,
  benefitBagConfigId: PropTypes.string,
  employees: PropTypes.array,
  initialDate: PropTypes.string,
  availableSummarys: PropTypes.array,
  initialLimitAmount: PropTypes.array,
  accumulatedFromPreviousPeriod: PropTypes.array,
  activeBenefits: PropTypes.array,
  allowedBenefits: PropTypes.array,
  adjustments: PropTypes.object,
  alias: PropTypes.string,
  renewAtPeriodEnd: PropTypes.bool,
};

BenefitBagDetail.defaultProps = {
  isLoading: false,
  companyId: "",
  benefitPlanId: "",
  benefitBagConfigId: "",
  initialDate: "2021-01-01",
  employees: [],
  availableSummarys: [],
  initialLimitAmount: [],
  accumulatedFromPreviousPeriod: [],
  activeBenefits: [],
  allowedBenefits: [],
  adjustments: {},
  alias: "",
  renewAtPeriodEnd: true,
};
