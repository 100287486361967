import React from "react";
import ReactDOM from "react-dom";
import CancelNurseryModal from "./components/CancelNurseryModal";

export default function renderCancelNurseryModal({ callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<CancelNurseryModal callback={callback} />, domTarget);
  }
}
