export const parseRentingFailures = (data) => {
  return data.map(
    ({
      data: {
        legalId,
        description,
        vehiclePrice,
        privateUseRatio,
        energyEfficiency,
        initialPaymentByEmployee,
        initialPaymentByCompany,
        numberOfPayments,
        serviceStart,
        rentingCostPerPaymentEmployee,
        rentingCostPerPaymentCompany,
        vatRatioSupportedByEmployee,
      },
      ...rest
    }) => ({
      data: {
        "DNI Empleado": legalId,
        "Vehículo: Marca y modelo": description,
        "PVP - con IVA (€)": vehiclePrice,
        "Uso privativo (%)": privateUseRatio,
        "Eficiencia energética (%)": energyEfficiency,
        "Empleado: Importe inicial - sin IVA (€)": initialPaymentByEmployee,
        "Empresa: Importe inicial - sin IVA (€)": initialPaymentByCompany,
        "Número de cuotas (#)": numberOfPayments,
        "Fecha de inicio (aaaa-mm-dd)": serviceStart,
        "Empleado: Cuota renting - sin IVA (€)": rentingCostPerPaymentEmployee,
        "Empresa: Cuota renting - sin IVA (€)": rentingCostPerPaymentCompany,
        "Empleado: Porcentaje de IVA": vatRatioSupportedByEmployee,
      },
      ...rest,
    })
  );
};
