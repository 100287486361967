import * as React from "react";
import { MUI } from "@cobee/cobee-ui";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Modality name",
    minWidth: 250,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Modality ID",
    minWidth: 0,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    minWidth: 0,
  },
];

export const TableHead = () => {
  return (
    <MUI.TableHead>
      <MUI.TableRow>
        <MUI.TableCell />
        <MUI.TableCell />
        {headCells.map((headCell) => (
          <MUI.TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ minWidth: headCell.minWidth }}
          >
            {headCell.label}
          </MUI.TableCell>
        ))}
        <MUI.TableCell />
      </MUI.TableRow>
    </MUI.TableHead>
  );
};
