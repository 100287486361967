import React, { Component } from "react";
import moment from "moment";
import { downloadMovementsCompanies } from "../download-movements";
import { showErrorMessage } from "../../commons/show-error-message";
import { sendPecunpayMovements } from "../send-pecunpay-movements";

export default class MovementsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetch: false,
      isFetchPecunpay: false,
      from: null,
      to: null,
      companyId: null,
      pecunpayFrom: null,
      pecunpayTo: null,
      pecunpayCompanyId: null,
      pecunpayEmail: null,
    };
  }

  async handlerDownload() {
    const { from, to, companyId } = this.state;
    this.setState({ isFetch: true });
    try {
      const { companyName } = this.props.companies.find(
        (c) => c.id === companyId
      );
      await downloadMovementsCompanies({ companyName, companyId, from, to });
    } catch (e) {
      showErrorMessage(`An unexpected error has ocurred: ${e}`);
    }
    this.setState({ isFetch: false });
  }

  async handlerPecunpayDownload() {
    const {
      pecunpayCompanyId,
      pecunpayFrom,
      pecunpayTo,
      pecunpayEmail,
    } = this.state;
    this.setState({ isFetchPecunpay: true });
    try {
      await sendPecunpayMovements({
        companyId: pecunpayCompanyId,
        from: pecunpayFrom,
        to: pecunpayTo,
        email: pecunpayEmail,
      });
    } catch (e) {
      showErrorMessage("An unexpected error has ocurred");
    }
    this.setState({ isFetchPecunpay: false });
  }

  render() {
    const {
      isFetch,
      from,
      to,
      companyId,
      pecunpayCompanyId,
      pecunpayFrom,
      pecunpayTo,
      pecunpayEmail,
      isFetchPecunpay,
    } = this.state;
    return (
      <main className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Movements</h5>
            <p className="card-text">
              Download all the movements of the companies between the selected
              dates.
            </p>

            <div className="form-inline">
              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Company:
                  </span>
                </div>
                <select
                  className="custom-select"
                  value={companyId}
                  onChange={(e) => this.setState({ companyId: e.target.value })}
                >
                  <option defaultValue>Select company</option>
                  {this.props.companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    From:
                  </span>
                </div>
                <input
                  type="date"
                  name="from"
                  disabled={!this.state.companyId}
                  onChange={(e) => this.setState({ from: e.target.value })}
                  max={moment().subtract(1, "day").format("YYYY-MM-DD")}
                />
              </div>

              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    To:
                  </span>
                </div>
                <input
                  type="date"
                  name="to"
                  disabled={!this.state.from}
                  onChange={(e) => this.setState({ to: e.target.value })}
                  min={moment(this.state.from)
                    .add(1, "day")
                    .format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>

              <button
                className="btn btn-primary mb-3 mx-sm-3"
                disabled={isFetch || !companyId || !from || !to}
                onClick={() => this.handlerDownload()}
              >
                {isFetch ? "Loading..." : "Download"}
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Movements Pecunpay</h5>
            <p className="card-text">
              Download all the pecunpay movements of the company between the
              selected dates.
            </p>

            <div className="form-inline">
              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Company:
                  </span>
                </div>
                <select
                  className="custom-select"
                  value={pecunpayCompanyId}
                  onChange={(e) =>
                    this.setState({ pecunpayCompanyId: e.target.value })
                  }
                >
                  <option defaultValue>Select company</option>
                  {this.props.companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    From:
                  </span>
                </div>
                <input
                  type="date"
                  name="from"
                  disabled={!this.state.pecunpayCompanyId}
                  onChange={(e) =>
                    this.setState({ pecunpayFrom: e.target.value })
                  }
                  max={moment().subtract(1, "day").format("YYYY-MM-DD")}
                />
              </div>

              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    To:
                  </span>
                </div>
                <input
                  type="date"
                  name="to"
                  disabled={!this.state.pecunpayFrom}
                  onChange={(e) =>
                    this.setState({ pecunpayTo: e.target.value })
                  }
                  min={moment(this.state.from)
                    .add(1, "day")
                    .format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>

              <div className="input-group mb-3 mx-sm-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Email:
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  onChange={(e) =>
                    this.setState({ pecunpayEmail: e.target.value })
                  }
                />
              </div>

              <button
                className="btn btn-primary mb-3 mx-sm-3"
                disabled={
                  isFetchPecunpay ||
                  !pecunpayCompanyId ||
                  !pecunpayFrom ||
                  !pecunpayTo ||
                  !pecunpayEmail
                }
                onClick={() => this.handlerPecunpayDownload()}
              >
                {isFetchPecunpay ? "Loading..." : "Send email"}
              </button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
