export const UserState = {
  ACTIVE: "active",
  REMOVED: "removed",
  BLOCKED: "blocked",
  PENDING: "pending",
  UPLOADING: "uploading",
  NOT_ACTIVE: "not-active",
  PENDING_EMAIL_VERIFICATION: "pending-email-verification",
};

export const EMPLOYEE_ACTIVE_STATES = [
  UserState.ACTIVE,
  UserState.NOT_ACTIVE,
  UserState.PENDING_EMAIL_VERIFICATION,
];
