import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { error } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    maxWidth: 1200,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(2),
  },
  buttonRow: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    color: error,
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    marginBottom: theme.spacing(5),
  },
}));

export default useStyles;
