import React from "react";
import PropTypes from "prop-types";

export default function CompaniesPage() {
  return (
    <main className="container-fluid">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title buttonRight">Companies</h5>

          <p>
            The company list must now be accessed by the{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://cobee.retool.com/app/companies"
              rel="noopener"
              target="_blank"
            >
              new backoffice
            </a>
          </p>
        </div>
      </div>
    </main>
  );
}

CompaniesPage.defaultProps = {};

CompaniesPage.propTypes = {};
