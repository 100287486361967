import React from "react";
import ReactDOM from "react-dom";
import AddLifeInsurancePage from "./index";

export default function renderAddLifeInsurancePage({
  target = "page-container",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(<AddLifeInsurancePage {...rest} />, domTarget);
  }
}
