import { makeStyles } from "@material-ui/core/styles";
import { secondary } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  filterRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  searchIcon: {
    color: secondary,
    cursor: "pointer",
  },
}));

export default useStyles;
