import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import {
  formatDateFromTimestamp,
  optionalMonetaryAmount,
} from "../../../../../infrastructure/format";

export default function RentingRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty("Vehicle", recurrentPayment?.benefitDetails?.description)}
        {textProperty(
          "Energy Efficiency",
          recurrentPayment?.benefitDetails?.energyEfficiency
            ? recurrentPayment.benefitDetails.energyEfficiency.toFixed(2)
            : ""
        )}
        {textProperty(
          "Vat ratio supported by employee",
          recurrentPayment?.benefitDetails?.vatRatioSupportedByEmployee
        )}
        {textProperty(
          "Private Usage Ratio",
          recurrentPayment?.benefitDetails?.privateUseRatio?.toFixed(2)
        )}
        {textProperty(
          "Number of payments",
          recurrentPayment?.benefitDetails?.numberOfPayments
        )}
        {textProperty(
          "Service Start",
          formatDateFromTimestamp(
            recurrentPayment?.benefitDetails?.serviceStartTimestamp
          )
        )}
        {textProperty(
          "Initial Payment By Company",
          optionalMonetaryAmount(
            recurrentPayment?.benefitDetails?.initialPaymentByCompany
          )
        )}

        {textProperty(
          "Initial Payment By Employee",
          optionalMonetaryAmount(
            recurrentPayment?.benefitDetails?.initialPaymentByEmployee
          )
        )}

        {textProperty(
          "Renting Cost Per Payment Company",
          optionalMonetaryAmount(
            recurrentPayment?.benefitDetails?.rentingCostPerPaymentCompany
          )
        )}

        {textProperty(
          "Renting Cost Per Payment Employee",
          optionalMonetaryAmount(
            recurrentPayment?.benefitDetails?.rentingCostPerPaymentEmployee
          )
        )}

        {textProperty(
          "Total",
          optionalMonetaryAmount(
            recurrentPayment?.benefitDetails?.totalAmount ||
              recurrentPayment?.rentingSummary?.totalAmount
          )
        )}
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
