import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import Table from "../../../../app/components/Table";
import { columnConfig } from "./config";
import CobeeButton from "../../../../app/components/CobeeButton";
import NurseriesFilter from "../NurseriesFilter";
import searchInKeys from "../../../../services/searchInKeys";
import { keys } from "../NurseriesPage/config";
import { displayNurseryDetail } from "../../display-nursery-detail";

export default function NurseriesRequests({
  data,
  isLoading,
  handleUpdateState,
}) {
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const [processedData, setProcessedData] = useState([]);
  const [page, setPage] = useState(undefined);
  const [forcePagination, setForcePagination] = useState(false);

  const nextState = {
    "pending-acquiring": "acquiring",
    acquiring: "acquired",
  };

  const searchResults = useCallback(
    (requests) => {
      return searchInKeys({
        search,
        items: requests,
        keys: keys(columnConfig),
      });
    },
    [search]
  );

  useEffect(() => {
    setProcessedData(
      searchResults(
        filterValue === "all"
          ? data
          : data.filter(({ state }) => state === filterValue)
      )
    );
  }, [setProcessedData, search, filterValue, data, searchResults]);

  const handleSearch = debounce(1000, false, (value) => {
    setPage(value === "" ? undefined : 0);
    setForcePagination(value !== "");
    setSearch(value);
  });

  const showNurseryDetail = (id) => {
    const nursery = data.find(({ nurseryId }) => nurseryId === id);
    displayNurseryDetail({ nursery, handleUpdateState });
  };

  const isAcquiring = (state) =>
    state === "pending-acquiring" || state === "acquiring";

  return (
    <>
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <>
          <NurseriesFilter
            overrideLabel="Nursery state"
            items={[
              { id: "pending-acquiring", name: "pending-acquiring" },
              { id: "acquiring", name: "acquiring" },
            ]}
            handleFilter={(value) => {
              setPage(value === "all" ? undefined : 0);
              setForcePagination(value !== "all");
              setFilterValue(value);
            }}
            handleSearch={handleSearch}
          />
          <Table
            columns={columnConfig}
            data={processedData.map(
              ({ nurseryId, nurseryState, state, ...rest }) => {
                return {
                  nurseryState,
                  state,
                  action: (
                    <Grid container spacing={2}>
                      <Grid item>
                        <CobeeButton
                          variant="secondary"
                          onClick={() => showNurseryDetail(nurseryId)}
                        >
                          Show details
                        </CobeeButton>
                      </Grid>
                      {nurseryState === "acquiring" && (
                        <Grid item>
                          <CobeeButton
                            variant="danger"
                            onClick={() =>
                              handleUpdateState({
                                nurseryId,
                                state: "not-acquired",
                              })
                            }
                          >
                            Not acquired
                          </CobeeButton>
                        </Grid>
                      )}
                      {isAcquiring(state) && (
                        <Grid item>
                          <CobeeButton
                            onClick={() =>
                              handleUpdateState({
                                nurseryId,
                                state: nextState[nurseryState],
                              })
                            }
                          >
                            {state === "acquiring" ? "acquired" : "next state"}
                          </CobeeButton>
                        </Grid>
                      )}
                    </Grid>
                  ),
                  ...rest,
                };
              }
            )}
            pageNumber={page}
            forcePagination={forcePagination}
          />
        </>
      )}
    </>
  );
}

NurseriesRequests.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  handleUpdateState: PropTypes.func,
};

NurseriesRequests.defaultProps = {
  isLoading: false,
  handleUpdateState: () => {},
};
