import React from "react";
import CobeeButton from "../../../app/components/CobeeButton";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import showBenefitBagDetail from "../../companies/show-benefit-bag-details";

// Table columns
export const benefitBagColumns = [
  { label: "Id", id: "benefitBagConfigId" },
  { label: "Benefits", id: "benefits" },
  { label: "Amount", id: "amount" },
  { label: "Periodicity", id: "periodDurationInPayrolls" },
  { label: "", id: "details" },
];

// Table data
export const benefitConfigsData = ({ data, alias, benefitModel }) =>
  data.map(
    ({
      benefitBagConfigId,
      allowedFor,
      limitAmount,
      periodDurationInPayrolls,
      accumulableAtPeriodEnd,
      consumableFrom,
      ...rest
    }) => ({
      benefitBagConfigId,
      benefits: allowedFor.map(({ benefit }) => (
        <ul key={benefit} className="pl-3">
          <li>{benefit}</li>
        </ul>
      )),
      periodDurationInPayrolls,
      amount: MonetaryAmount.ofCurrency(limitAmount).prettifyMoney({}),
      details: (
        <CobeeButton
          onClick={() => {
            const {
              allowedBenefits,
              companyId,
              id: benefitPlanId,
            } = benefitModel;
            showBenefitBagDetail({
              companyId,
              benefitPlanId,
              benefitBagConfigId,
              allowedBenefits,
              alias,
            });
          }}
        >
          Details
        </CobeeButton>
      ),
      ...rest,
    })
  );
