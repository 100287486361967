import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    maxWidth: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(2),
  },
  buttonRow: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default useStyles;
