import { makeStyles } from "@material-ui/core/styles";
import { lightGrey, softGrey } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: lightGrey,
    fontSize: "12px",
  },
  defaultEnglish: {
    color: lightGrey,
    marginLeft: theme.spacing(1),
  },
  languageBlock: {
    marginBottom: "10px",
    cursor: "pointer",
  },
  languageChecked: {
    backgroundColor: softGrey,
    borderRadius: "4px",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: softGrey,
    margin: `0px ${theme.spacing(3)}px`,
  },
  horizontalDivider: {
    height: "1px",
    backgroundColor: softGrey,
    margin: `${theme.spacing(5)}px 0px`,
  },
  fullWidth: {
    width: "100%",
  },
  englishBlock: {
    marginTop: theme.spacing(2),
  },
  languageLabel: {
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
