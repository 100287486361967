import React from "react";
import { v4 } from "uuid";
import moment from "moment";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";

const ModalityDetail = ({ insurance, handlerEdit }) => {
  return (
    <>
      <hr />
      <div className="form-group row">
        <div className="col-sm-4">
          <p>
            <b>Modality Id: </b>
            {insurance.modalityId}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Modality Name: </b>
            {insurance.modalityName}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Modality Price: </b>
            {MonetaryAmount.ofCurrency(insurance.modalityFee).prettifyMoney({})}
          </p>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-4">
          <p>
            <b>Insurance Company: </b>
            {insurance.insuranceCompany}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Broker: </b>
            {insurance.broker}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Policy: </b>
            {insurance.insurancePolicy}
          </p>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-12">
          <p>
            <b>Coverage: </b>
            {insurance.modalityCoverageDescription}
          </p>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <p>
            <b>Allow request outside engagement: </b>
            {insurance.allowRequestOutsideEngagement ? "Yes" : "No"}
          </p>
        </div>
        <div className="col-sm-6">
          <p>
            <b>Requires employee signature: </b>
            {insurance.requiresEmployeeSignature ? "Yes" : "No"}
          </p>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-4">
          <p>
            <b>Allow exempt domestic partner: </b>
            {insurance.allowExemptDomesticPartner ? "Yes" : "No"}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Is instantaneous: </b>
            {insurance.isInstantaneous ? "Yes" : "No"}
          </p>
        </div>
        <div className="col-sm-4">
          <p>
            <b>Allow Family Modalities: </b>
            {insurance.allowDifferentModalitiesPerFamilyMember ? "Yes" : "No"}
          </p>
        </div>
      </div>

      {insurance?.modalityBrochures.length !== 0 && (
        <div className="form-group row">
          <div className="col-sm-12">
            <p className="font-weight-bold">Brochures: </p>
            {insurance.modalityBrochures.map((brochure) => (
              <p key={v4()} className="col-sm-12">
                {brochure.title}: <a href={brochure.url}>{brochure.url}</a>
              </p>
            ))}
          </div>
        </div>
      )}

      {insurance?.insideSignatureDocuments.length !== 0 && (
        <div className="form-group row">
          <div className="col-sm-12">
            <p className="font-weight-bold">Inside signature documents: </p>
            {insurance.insideSignatureDocuments.map((document) => (
              <p key={v4()} className="col-sm-12">
                {document.title}: <a href={document.url}>{document.url}</a>
              </p>
            ))}
          </div>
        </div>
      )}

      {insurance?.outsideSignatureDocuments.length !== 0 && (
        <div className="form-group row">
          <div className="col-sm-12">
            <p className="font-weight-bold">Outside signature documents: </p>
            {insurance.outsideSignatureDocuments.map((document) => (
              <p key={v4()} className="col-sm-12">
                {document.title}: <a href={document.url}>{document.url}</a>
              </p>
            ))}
          </div>
        </div>
      )}

      <div className="form-group row">
        <div className="col-sm-6">
          <p>
            <b>Date sign up: </b>
            {moment.unix(insurance.dateSignUpTimestamp).format("DD MMM YYYY")}
          </p>
        </div>
        <div className="col-sm-6">
          <p>
            <b>First engagement period: </b>
            {` from 
					${moment(insurance.firstEngagementPeriod[0].from, "MM-DD").format("DD MMM")}
					 to 
					${moment(insurance.firstEngagementPeriod[0].to, "MM-DD").format("DD MMM")}`}
          </p>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-12">
          <p>
            <b>Next engagement periods:</b>
          </p>
          {insurance.nextEngagementsPeriod.map((period) => (
            <p key={v4()} className="col-sm-12">
              {`from ${moment(period.from).format("DD MMM")} 
							to
							${moment(period.to, "MM-DD").format("DD MMM")}`}
            </p>
          ))}
        </div>
      </div>

      {insurance?.complementsDetails.length > 0 && (
        <div className="form-group row">
          <div className="col-sm-12">
            <p className="card-text">
              <b>Applied complements:</b>
            </p>
            {insurance.complementsDetails.map((complement) => (
              <div className="row" key={complement.complementId}>
                <p className="col-sm-4">Id: {complement.complementId}</p>
                <p className="col-sm-4">Name: {complement.complementName}</p>
                <p className="col-sm-4">
                  Fee:{" "}
                  {MonetaryAmount.ofCurrency(
                    complement.complementFee
                  ).prettifyMoney({})}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <div className="gap-2 d-md-flex justify-content-md-end">
          <button className="btn btn-primary btn-sm" onClick={handlerEdit}>
            Edit modality
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalityDetail;
