import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { findCompanyDetail } from "infrastructure/company-repository";
import { findBenefitModel } from "infrastructure/benefit-models-repository";
import { showErrorMessage } from "usecases/commons/show-error-message";
import moment from "moment";
import { Link, Typography } from "@material-ui/core";
import { MUI } from "@cobee/cobee-ui";
import { People as PeopleIcon, Edit as EditIcon } from "@material-ui/icons";
import Table from "../../../app/components/Table";
import {
  benefitBagColumns,
  benefitConfigsData,
} from "../../../usecases/benefit-models/components/config";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import {
  generateBenefitModelEmployeesLink,
  generateCompanyDetailLink,
  generateEditBenefitModelLink,
} from "../../../infrastructure/links";
import { getAllTransactionCategories } from "../../../app/domain/transaction-category";
import Loader from "../../components/loader";

const BENEFIT_BAG_CONFIG_STATES = {
  ACTIVE: "active",
  CANCELLED: "cancelled",
};

const getWeekDay = (index) => {
  return moment()
    .isoWeekday(index + 1)
    .format("dddd");
};

export default function BenefitModelDetailsPage() {
  const { companyId, benefitModelId } = useParams();

  const { data, isLoading, isFetching, error: hasError } = useQuery(
    ["companyBenefitModels", companyId, benefitModelId],
    async () => {
      const [company, benefitModel] = await Promise.all([
        findCompanyDetail(companyId),
        findBenefitModel({ companyId, benefitModelId }),
      ]);
      return {
        company,
        benefitModel,
      };
    },
    {
      onError(error) {
        showErrorMessage(
          error?.response?.data?.message || "Error loading company details"
        );
      },
    }
  );

  if (hasError) {
    return "Error";
  }

  if (isLoading || isFetching) {
    return <Loader active />;
  }

  const { benefitModel, company, employeesPerModel } = data;
  const benefits = getAllTransactionCategories();
  const schedule = benefitModel.mealConfig.schedule
    .map((scheduleItem, i) => ({
      ...scheduleItem,
      weekday: getWeekDay(i),
    }))
    .filter((scheduleItem) => scheduleItem.enabled);

  function optionalAmount(amount) {
    if (!amount) {
      return MonetaryAmount.ZERO;
    }
    return MonetaryAmount.ofCurrency(amount);
  }

  const maxDailyAmount = optionalAmount(
    benefitModel?.mealConfig?.maxDailyAmount
  );
  const exemptMealAmountPerDay = optionalAmount(
    benefitModel?.mealConfig?.exemptMealAmountPerDay
  );
  const minSalaryAgreement = optionalAmount(benefitModel.minSalaryAgreement);
  const daysPerMonth = benefitModel.daysPerMonth || 11;

  const activeBenefitBags = benefitModel?.benefitBagConfigs.filter(
    (benefitBagConfig) =>
      benefitBagConfig.state === BENEFIT_BAG_CONFIG_STATES.ACTIVE
  );

  return (
    <>
      <main className="container-fluid" id="page-container">
        <MUI.Grid
          container
          xs={12}
          item
          justifyContent="flex-end"
          sx={{ mb: 3 }}
        >
          <MUI.Link
            href={generateEditBenefitModelLink({ benefitModelId, companyId })}
          >
            <MUI.Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Edit
            </MUI.Button>
          </MUI.Link>
          <MUI.Link
            href={generateBenefitModelEmployeesLink({
              benefitModelId,
              companyId,
            })}
            sx={{ pl: 1 }}
          >
            <MUI.Button
              variant="contained"
              color="primary"
              startIcon={<PeopleIcon />}
            >
              Employees In Model
            </MUI.Button>
          </MUI.Link>
        </MUI.Grid>
        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <Typography className="card-title">{benefitModel.name}</Typography>
            <Typography className="card-title">
              Company:{" "}
              <Link href={generateCompanyDetailLink({ companyId: company.id })}>
                {company.companyName}
              </Link>
            </Typography>
          </MUI.CardContent>
        </MUI.Card>

        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <h5 className="card-title">Benefits</h5>
            <p className="card-text">
              <Table
                columns={[
                  { label: "Name", id: "name" },
                  { label: "Allowed In Model", id: "allowedInModel" },
                  { label: "Accumulation Allowed", id: "accumulationAllowed" },
                  { label: "Allows Flex", id: "allowsFlex" },
                  { label: "Allows Not Exempt", id: "notExemptAllowed" },
                ]}
                data={benefits.map((benefit) => {
                  return {
                    name: benefit,
                    allowedInModel: benefitModel.allowedBenefits
                      .includes(benefit)
                      .toString(),
                    accumulationAllowed: benefitModel.accumulationAllowed
                      .includes(benefit)
                      .toString(),
                    allowsFlex: Boolean(
                      !benefitModel.disabledFlex[benefit]
                    ).toString(),
                    notExemptAllowed: benefitModel.notExemptAllowedBenefits
                      .includes(benefit)
                      .toString(),
                  };
                })}
              />
            </p>
            <MUI.Divider sx={{ mb: 3 }} />
            <h5 className="card-title">Meal Config</h5>

            {!minSalaryAgreement.isZero() > 0 && (
              <p className="card-text">
                <span className="font-weight-bold">
                  Minimum Salary Agreement:
                </span>
                {minSalaryAgreement.prettifyMoney({})}
              </p>
            )}
            {!maxDailyAmount.isZero() > 0 && (
              <p className="card-text">
                <span className="font-weight-bold">Max Meal Daily Amount:</span>
                {maxDailyAmount.prettifyMoney({})}
              </p>
            )}
            {!exemptMealAmountPerDay.isZero() && (
              <p className="card-text">
                <span className="font-weight-bold">Exempt Meal available:</span>
                {` ${daysPerMonth} (days) x ${exemptMealAmountPerDay.prettifyMoney(
                  {}
                )} = ${exemptMealAmountPerDay
                  .multiply(daysPerMonth)
                  .prettifyMoney({})}`}
              </p>
            )}
            {schedule.length > 0 && (
              <p className="card-text">
                <span className="font-weight-bold">Meal Schedule:</span>
                <Table
                  columns={[
                    { label: "Weekday", id: "weekday" },
                    { label: "From", id: "from" },
                    { label: "To", id: "to" },
                  ]}
                  data={schedule.map((scheduleItem) => {
                    return {
                      ...scheduleItem,
                    };
                  })}
                />
              </p>
            )}
            {benefitModel.disabledGiftCards.length > 0 && (
              <p className="card-text">
                <span className="font-weight-bold">Disabled Gift Cards:</span>
                {benefitModel.disabledGiftCards.map((benefit) => (
                  <li key={benefit}>{benefit.code} </li>
                ))}
              </p>
            )}
          </MUI.CardContent>
        </MUI.Card>

        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <h5 className="card-title">Benefit Bags</h5>
            {activeBenefitBags?.length > 0 && (
              <div className="card-text">
                <span className="font-weight-bold">Benefit Bag Configs</span>
                <Table
                  data={benefitConfigsData({
                    data: activeBenefitBags,
                    alias: benefitModel.name,
                    benefitModel,
                  })}
                  columns={benefitBagColumns}
                />
              </div>
            )}
          </MUI.CardContent>
        </MUI.Card>
      </main>
    </>
  );
}
