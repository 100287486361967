import React from "react";
import ReactDOM from "react-dom";
import HexModal from "../../app/components/modals/HexModal";
import CategorizeMerchantModal from "../../app/components/modals/CategorizeMerchantModal";
import { categorizeMerchantByTransactionId } from "../../infrastructure/transaction-repository";
import { hideModal } from "../commons/hide-modal";
import { displayTransactionsTable } from "./display-transactions-table";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";

const renderMerchantCategorizationModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <CategorizeMerchantModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default function categorizeMerchant({
  email,
  numMp,
  unknownSelected,
  ...rest
}) {
  renderMerchantCategorizationModal({
    ...rest,
    action: async (payload) => {
      renderMerchantCategorizationModal({ isLoading: true, disabled: true });
      try {
        await categorizeMerchantByTransactionId(payload);
        await displayTransactionsTable({ email, numMp, unknownSelected });
      } catch (e) {
        showErrorMessage("Merchant could not be categorized");
      } finally {
        await hideModal();
      }
    },
  });
}
