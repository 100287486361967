import { findAllMovements } from "../../infrastructure/movement-repository";
import { adaptDataToCsv } from "../../infrastructure/adapters/adaptDataToCsv";
import generateCsv from "../../services/generateCsv";

export async function downloadMovementsCompanies({
  companyName,
  companyId,
  from,
  to,
}) {
  const title = `movements-${companyName.toLowerCase()}-company-${from}-${to}`;
  const res = await findAllMovements({ companyId, from, to });
  const { headers, rows } = adaptDataToCsv({ type: "movements", data: res });
  await generateCsv({ title, headers, rows });
}
