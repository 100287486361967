import PropTypes from "prop-types";
import * as React from "react";
import { MUI, Divider } from "@cobee/cobee-ui";
import { Modality } from "../../../../../../../domain/contexts/healthInsurance/entities/Modality";
import { FlexBox } from "../../../../../../components/FlexBox";
import { PeriodSelect } from "./PeriodSelect";
import { PeriodDetails } from "./PeriodDetails";

export const RowDetailsBox = ({ data, open: isOpen }) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    modality: data,
    periodIndex: 0,
  });
  return (
    <MUI.TableRow>
      <MUI.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <MUI.Collapse in={isOpen} timeout="auto">
          <MUI.Box sx={{ margin: 2 }}>
            <FlexBox
              Element={MUI.Box}
              gap={16}
              sx={{ alignItems: "center", marginBottom: 3 }}
            >
              <MUI.Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{ marginBottom: 0 }}
              >
                Details
              </MUI.Typography>
              <Divider margin={12} />
              <PeriodSelect
                data={data}
                selectedPeriodState={{ selectedPeriod, setSelectedPeriod }}
              />
            </FlexBox>

            <PeriodDetails data={selectedPeriod.modality} />
          </MUI.Box>
        </MUI.Collapse>
      </MUI.TableCell>
    </MUI.TableRow>
  );
};

RowDetailsBox.propTypes = {
  data: PropTypes.instanceOf(Modality).isRequired,
  open: PropTypes.bool.isRequired,
};
