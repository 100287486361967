import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import ReactJson from "react-json-view";
import useStyles from "./styles";
import CobeeButton from "../../../../../app/components/CobeeButton";
import { hideModal } from "../../../../commons/hide-modal";
import Modal from "../../../../../app/components/modals/Modal";

export default function ErrorLogDetailModal({ errorLog }) {
  const { container, row, buttonRow } = useStyles();

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString()}</Typography>
      </Grid>
    </>
  );

  const formatDateFromDatetime = (datetime) =>
    datetime ? moment(datetime).format("YYYY-MM-DD HH:mm") : "N/A";

  return (
    <Modal title="Error Log Detail">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Basic</b>
            </h5>
          </Typography>
        </Grid>

        {textProperty("id", errorLog.id)}
        {textProperty("Type", errorLog.type)}
        {textProperty("Reason", errorLog.code)}
        {textProperty(
          "Datetime",
          `${formatDateFromDatetime(errorLog.createdAt)}`
        )}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Company</b>
            </h5>
          </Typography>
        </Grid>
        {textProperty("ID", `${errorLog.companyId}`)}
        {textProperty("Name", `${errorLog.company.companyName}`)}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Employee</b>
            </h5>
          </Typography>
        </Grid>
        {textProperty("ID", `${errorLog.employeeId}`)}
        {textProperty("Email", `${errorLog.employee.email}`)}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Recipient</b>
            </h5>
          </Typography>
        </Grid>
        {textProperty("ID", `${errorLog.recipient?.id}`)}
        {textProperty(
          "Email",
          `${errorLog.recipient?.name} ${errorLog.recipient?.surname}`
        )}
        {textProperty("Type", `${errorLog.recipient?.subtype}`)}
        {textProperty("Subtype", `${errorLog.recipient?.type}`)}

        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Error</b>
            </h5>
          </Typography>
        </Grid>

        <Grid item container xs={12} className={row}>
          <ReactJson
            src={errorLog.details}
            name={false}
            displayDataTypes={false}
          />
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Close
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
