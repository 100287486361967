import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Modal from "../../../app/components/modals/Modal";
import useStyles from "./styles";
import CobeeButton from "../../../app/components/CobeeButton";
import CobeeTextField from "../../../app/components/CobeeTextField";

export default function LifeInsuranceRiskIndexModal({
  title,
  callback,
  callBackIsLoading,
  actionName,
  valueOverride,
}) {
  const { container, row, input, spinner } = useStyles();

  const [value, setValue] = useState(valueOverride);

  return (
    <Modal title={title}>
      <Grid item container xs={12} className={container}>
        <Grid item xs={12} container className={row}>
          <Typography variant="body1">Insert employee risk index</Typography>
        </Grid>
        <Grid item xs={12} container className={row}>
          <ErrorIcon />
          <Typography variant="body1">
            The risk index is provided by the insurance company.
          </Typography>
        </Grid>
        <Grid item xs={12} container className={row}>
          <CobeeTextField
            type="number"
            min={0}
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
            disabled={callBackIsLoading}
          />
        </Grid>
        <Grid item xs={12} container justify="flex-end" className={row}>
          <CobeeButton
            className={input}
            onClick={() => callback(value)}
            disabled={callBackIsLoading}
          >
            {callBackIsLoading && (
              <CircularProgress size={20} className={spinner} />
            )}{" "}
            {actionName || "Save"}
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

LifeInsuranceRiskIndexModal.defaultProps = {
  title: "",
  callBackIsLoading: false,
  actionName: null,
  valueOverride: 0,
};

LifeInsuranceRiskIndexModal.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func.isRequired,
  callBackIsLoading: PropTypes.bool,
  actionName: PropTypes.string,
  valueOverride: PropTypes.number,
};
