import { deleteNursery } from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";

export async function removeNursery(
  employee,
  recurrentPaymentId,
  reason,
  numMp
) {
  if (!reason || reason === "" || reason.trim() === "") {
    showErrorMessage("Write reason please");
  } else {
    const res = await deleteNursery(employee.id, recurrentPaymentId, reason);
    if (res.status === 200) {
      showSuccessMessage("Successfully removed!");
      displayEmployeeDetail(employee.email, numMp);
    } else {
      showErrorMessage(`Error: ${res.data.reason}`);
    }
  }
}
