import React, { useState } from "react";
import PropTypes from "prop-types";
import EmployeeDetail from "./EmployeeDetail";

const EmployeesWrapperDetail = ({ employeesAllData }) => {
  const [tab, setTab] = useState(employeesAllData[0].employee.id);
  const employeeSelected = employeesAllData.find(
    ({ employee: emp }) => emp.id === tab
  );

  return (
    <div>
      <div className="card-body">
        <ul className="nav nav-tabs card-header-tabs">
          {employeesAllData.map(({ employee: emp, company: comp }) => (
            <li className="nav-item" key={emp.id}>
              <button
                className={`nav-link ${tab === emp.id && "active"}`}
                onClick={() => setTab(emp.id)}
              >
                {comp?.companyName || "N/A"} - {`${emp.name} - ${emp.state}`}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <EmployeeDetail {...employeeSelected} />
    </div>
  );
};

EmployeesWrapperDetail.defaultProps = {};
EmployeesWrapperDetail.propTypes = {
  employeesAllData: PropTypes.array.isRequired,
};

export default EmployeesWrapperDetail;
