import { adaptRetirementInsuranceCreationData } from "infrastructure/adapters/adaptRetirementInsurance";
import { updateRetirementInsuranceProduct } from "infrastructure/retirement-repository";

const updateRetirementProduct = async ({ companyId, data }) => {
  const adaptedData = adaptRetirementInsuranceCreationData({ data });
  await updateRetirementInsuranceProduct({
    companyId,
    retirementInsuranceProductId: data.id,
    payload: adaptedData,
  });
};

export default updateRetirementProduct;
