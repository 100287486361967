import React from "react";
import PropTypes from "prop-types";
import Modal from "app/components/modals/Modal";
import { Grid, Typography } from "@material-ui/core";
import { v4 } from "uuid";
import moment from "moment";
import { MonetaryAmount } from "app/domain/monetary-amount";
import useStyles from "./styles";

export default function CardPurchaseRefundModal({ refundTransactions }) {
  const { containerModal, item, label } = useStyles();
  return (
    <Modal title="Card purchase refund">
      <div className={containerModal}>
        {refundTransactions.map(
          ({ amount, description, userTransactionIsoTime: date }) => (
            <Grid container item xs={12} className={item} key={v4()}>
              <Grid container item xs={4} justify="flex-start">
                <Typography variant="body2" className={label}>
                  Concept:
                </Typography>
                <Typography variant="body2">{description}</Typography>
              </Grid>

              <Grid container item xs={4} justify="center">
                <Typography variant="body2" className={label}>
                  Date:
                </Typography>
                <Typography variant="body2">
                  {`${moment(date).format("DD-MM-YYYY")} 
                  ${moment(date).format("HH:mm")}`}
                </Typography>
              </Grid>

              <Grid container item xs={4} justify="center">
                <Typography variant="body2" className={label}>
                  Amount:
                </Typography>
                <Typography variant="body2">
                  {MonetaryAmount.ofCurrency(amount).prettifyMoney({})}
                </Typography>
              </Grid>
            </Grid>
          )
        )}
      </div>
    </Modal>
  );
}

CardPurchaseRefundModal.propTypes = {
  refundTransactions: PropTypes.array.isRequired,
};
