import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 56,
  },
  content: {
    padding: theme.spacing(2),
  },
  block: {
    border: `1px solid ${grey300}`,
    borderRadius: 4,
    padding: theme.spacing(2),
  },
  bar: {
    borderBottom: `1px solid ${grey300}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  backButton: {
    padding: 0,
    marginRight: theme.spacing(1),

    "& svg": {
      marginLeft: 0,
    },
  },
  barContainer: {
    display: "flex",
    alignItems: "center",
  },
  optionButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
  },
}));

export default useStyles;
