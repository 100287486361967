import React from "react";
import ReactDOM from "react-dom";
import AddCapitalToModalityModal from "./index";

export default function renderAddCapitalToModalityModal(props) {
  ReactDOM.render(
    <AddCapitalToModalityModal title="Add capital to modality" {...props} />,
    document.getElementById("modal-container")
  );
}
