import React from "react";
import ReactDOM from "react-dom";
import EditCardIdModal from "./components/EditCardIdModal";

export default function renderEditCardIdModal({ card, callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <EditCardIdModal card={card} callback={callback} />,
      domTarget
    );
  }
}
