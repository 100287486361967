import { toast } from "@cobee/cobee-ui";
import { getServerErrorMessage } from "usecases/ErrorHandling";
import { list } from "../../../../infrastructure/contexts/healthInsurance/repository/Modality";

export const listModalities = async ({ Company }) => {
  try {
    const companyModalities = await list({ companyId: Company.id });
    return companyModalities;
  } catch (e) {
    toast.show(getServerErrorMessage(e), { type: "error" });
    return [];
  }
};
