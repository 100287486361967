import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { v4 } from "uuid";
import removeEmployeeFromAuth0 from "../remove-employee-auth0";
import removeEmployeeFromPecunpay from "../remove-employee-pecunpay";
import removeEmployeeGDPR from "../remove-employee-gdpr";
import CobeeButton from "../../../app/components/CobeeButton";
import BInputForm from "../../../app/components/InputForm/BInputForm";
import displayActionConfirmationModal from "../../commons/display-action-confirmation-modal";
import { hideModal } from "../../commons/hide-modal";

const EmployeeForm = ({
  employee,
  card,
  handlerInputChange,
  handleChangeAddress,
}) => {
  const {
    id,
    internalId,
    costCenter,
    payrollCompany,
    name,
    surname,
    email,
    profession,
    maritalStatus,
    legalId,
    mobileCountryCode,
    mobilePhone,
    birthDate,
    hiringDate,
    sex,
    taxRegime,
    incapacityLevel,
    removedFromAuth0Timestamp,
    state: employeeState,
    deliveryAddress,
    homeAddress,
    warning,
    error,
  } = employee;

  const {
    streetName: deliveryAddressStreetName,
    streetNumber: deliveryAddressStreetNumber,
    details: deliveryAddressDetails,
    postalCode: deliveryAddressPostalCode,
    province: deliveryAddressProvince,
    locality: deliveryAddressLocality,
    street: deliveryAddressLegacyStreet,
  } = deliveryAddress;

  const {
    street: homeAddressLegacyStreet,
    streetName: homeAddressStreetName,
    streetNumber: homeAddressStreetNumber,
    details: homeAddressDetails,
    postalCode: homeAddressPostalCode,
    province: homeAddressProvince,
    locality: homeAddressLocality,
  } = homeAddress;

  return (
    <div className="card mb-2">
      <div className="card-body">
        <p className="card-text">Employee:</p>
        <div className="form-group row">
          <BInputForm
            readOnly
            inputStyle="col-sm-4"
            label="Auth0"
            value={id || ""}
            onClick={() => {}}
          />

          <BInputForm
            readOnly
            inputStyle="col-sm-4"
            label="Removed at"
            value={
              removedFromAuth0Timestamp === null
                ? "Not removed"
                : new Date(removedFromAuth0Timestamp * 1000).toLocaleString()
            }
            onClick={() => {}}
          />
        </div>

        <div className="row justify-content-end mr-0">
          <CobeeButton
            variant="danger"
            disabled={employeeState !== "removed"}
            onClick={() => {
              displayActionConfirmationModal({
                title: "Delete due to GDPR",
                message:
                  "Are you sure you want to delete the employee due to GDPR?",
                isWarning: false,
                cantBeReverted: true,
                callback: async () => {
                  await removeEmployeeGDPR({ employee });
                  hideModal();
                },
                actionName: "Confirm",
                disableClose: false,
              });
            }}
          >
            Delete due to GDPR
          </CobeeButton>
          <CobeeButton
            variant="danger"
            className="ml-3"
            disabled={
              employeeState !== "removed" || removedFromAuth0Timestamp !== null
            }
            onClick={() =>
              removeEmployeeFromAuth0({ employee, email, cardId: card?.cardId })
            }
          >
            Delete from auth0
          </CobeeButton>
          <CobeeButton
            variant="danger"
            className="ml-3"
            disabled={
              employeeState !== "removed" ||
              !card ||
              card?.cardIdcardState === "cancelled" ||
              card?.cardIdcardState === "pending"
            }
            onClick={() =>
              removeEmployeeFromPecunpay({
                employee,
                email,
                cardId: card?.cardId,
              })
            }
          >
            Delete from Pecunpay
          </CobeeButton>
        </div>
        <hr />

        <div>
          <div className="form-group row">
            <BInputForm
              label="Internal Id"
              value={internalId || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "internalId",
                  value: e.target.value,
                })
              }
            />
            <BInputForm
              label="Cost Center"
              value={costCenter || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "costCenter",
                  value: e.target.value,
                })
              }
            />
            <BInputForm
              label="Payroll Company"
              value={payrollCompany || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "payrollCompany",
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group row">
            <BInputForm
              label="Name"
              value={name || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "name",
                  value: e.target.value,
                })
              }
            />
            <BInputForm
              label="Surnames"
              value={surname || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "surname",
                  value: e.target.value,
                })
              }
            />
            <BInputForm
              label="Legal Id"
              value={legalId || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "legalId",
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group row">
            <BInputForm
              label="Email"
              value={email || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "email",
                  value: e.target.value,
                })
              }
            />

            <BInputForm
              label="Profession"
              value={profession || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "profession",
                  value: e.target.value,
                })
              }
            />

            <label className="col-sm-2 col-form-label col-form-label-sm">
              Marital Status
            </label>
            <div className="col-sm-2">
              <select
                className="custom-select custom-select-sm"
                value={maritalStatus || ""}
                onChange={(e) =>
                  handlerInputChange({
                    property: "maritalStatus",
                    value: e.target.value,
                  })
                }
              >
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <BInputForm
            label="Phone"
            placeholder="Country code"
            value={mobileCountryCode || ""}
            onChange={(e) =>
              handlerInputChange({
                property: "mobileCountryCode",
                value: e.target.value,
              })
            }
          />

          <BInputForm
            inputStyle="col-sm-8"
            placeholder="Mobile Phone"
            value={mobilePhone || ""}
            onChange={(e) =>
              handlerInputChange({
                property: "mobilePhone",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            Incapacity Level
          </label>
          <div className="col-sm-2">
            <select
              className="custom-select custom-select-sm"
              value={incapacityLevel || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "incapacityLevel",
                  value: e.target.value,
                })
              }
            >
              <option value="0%">0%</option>
              <option value="33%">33%</option>
              <option value="66%">66%</option>
            </select>
          </div>

          <label className="col-sm-2 col-form-label col-form-label-sm">
            Sex
          </label>
          <div className="col-sm-2">
            <select
              className="custom-select custom-select-sm"
              value={sex || ""}
              onChange={(e) =>
                handlerInputChange({ property: "sex", value: e.target.value })
              }
            >
              {sex === null ? (
                <option value>Undefined</option>
              ) : (
                <option value disabled>
                  Undefined
                </option>
              )}
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <label className="col-sm-2 col-form-label col-form-label-sm">
            Tax Regime
          </label>
          <div className="col-sm-2">
            <select
              className="custom-select custom-select-sm"
              value={taxRegime || ""}
              onChange={(e) =>
                handlerInputChange({
                  property: "taxRegime",
                  value: e.target.value,
                })
              }
            >
              <option value="general">General</option>
              <option value="basque">Alava</option>
              <option value="biscayan">Biscayan and Guipuzcoa</option>
              <option value="navarrese">Navarrese</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            Birth date
          </label>
          <div className="col-sm-4">
            <input
              type="date"
              name="Birth date"
              value={
                moment(birthDate).isValid()
                  ? moment(birthDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) =>
                handlerInputChange({
                  property: "birthDate",
                  value: e.target.value,
                })
              }
            />
          </div>

          <label className="col-sm-2 col-form-label col-form-label-sm">
            Hiring date
          </label>
          <div className="col-sm-4">
            <input
              type="date"
              name="Birth date"
              value={
                moment(hiringDate).isValid()
                  ? moment(hiringDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) =>
                handlerInputChange({
                  property: "hiringDate",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>

        <hr />
        <p className="card-text">Delivery Address:</p>
        <div className="form-group row">
          <BInputForm
            label="Province"
            inputStyle="col-sm-4"
            value={deliveryAddressProvince || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "province",
                value: e.target.value,
              })
            }
          />

          <BInputForm
            label="Locality"
            inputStyle="col-sm-4"
            value={deliveryAddressLocality || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "locality",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group row">
          <BInputForm
            label="Street"
            inputStyle="col-sm-4"
            value={deliveryAddressStreetName || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "streetName",
                value: e.target.value,
              })
            }
          />
          <BInputForm
            label="Street"
            inputStyle="col-sm-4"
            value={deliveryAddressStreetNumber || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "streetNumber",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group row">
          <BInputForm
            label="Postal Code"
            inputStyle="col-sm-4"
            value={deliveryAddressPostalCode || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "postalCode",
                value: e.target.value,
              })
            }
          />

          <BInputForm
            label="Details"
            inputStyle="col-sm-4"
            value={deliveryAddressDetails || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "deliveryAddress",
                property: "details",
                value: e.target.value,
              })
            }
          />
        </div>

        <hr />
        <p className="card-text">Home Address:</p>
        <div className="form-group row">
          <BInputForm
            label="Province"
            inputStyle="col-sm-4"
            value={homeAddressProvince || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "province",
                value: e.target.value,
              })
            }
          />

          <BInputForm
            label="Locality"
            inputStyle="col-sm-4"
            value={homeAddressLocality || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "locality",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group row">
          <BInputForm
            label="Street Name"
            inputStyle="col-sm-4"
            value={homeAddressStreetName || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "streetName",
                value: e.target.value,
              })
            }
          />
          <BInputForm
            label="Street Number"
            inputStyle="col-sm-4"
            value={homeAddressStreetNumber || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "streetNumber",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group row">
          <BInputForm
            label="Postal Code"
            inputStyle="col-sm-4"
            value={homeAddressPostalCode || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "postalCode",
                value: e.target.value,
              })
            }
          />

          <BInputForm
            label="Details"
            inputStyle="col-sm-4"
            value={homeAddressDetails || ""}
            onChange={(e) =>
              handleChangeAddress({
                type: "homeAddress",
                property: "details",
                value: e.target.value,
              })
            }
          />
        </div>

        <hr />
        <div className="form-group row">
          <BInputForm
            label="Activation Id"
            inputStyle="col-sm-4"
            value={employee.activationId || ""}
            readOnly
          />

          <BInputForm
            label="Current State"
            inputStyle="col-sm-4"
            value={employee.state || ""}
            readOnly
          />
        </div>
        <div className="form-group row">
          <BInputForm
            label="Warning"
            inputStyle="col-sm-4"
            value={warning || "-"}
            readOnly
          />

          <BInputForm
            label="Error"
            inputStyle="col-sm-4"
            value={error || "-"}
            readOnly
          />
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <p className="card-text">State History:</p>
            <ul>
              {employee.stateHistory.map((state) => (
                <li key={v4()}>{state}</li>
              ))}
            </ul>
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <div className="col-sm-4">
            <p className="card-text">
              <span className="font-weight-bold">Language: </span>
              {employee.language}
            </p>
          </div>

          {employee.state === "uploading" && employee.error && (
            <div className="col-sm-4">
              <p className="card-text">
                <span className="font-weight-bold">Error: </span>
                {employee.error}
              </p>
            </div>
          )}

          {employee.state === "uploading" && employee.warning && (
            <div className="col-sm-4">
              <p className="card-text">
                <span className="font-weight-bold">Warning: </span>
                {employee.warning}
              </p>
            </div>
          )}
        </div>

        <div className="form-group row">
          <div className="col-sm-4">
            <p className="card-text">
              <span className="font-weight-bold">Creation Date: </span>
              {moment.unix(employee.creationTimestamp).format("YYYY-MM-DD")}
            </p>
          </div>
          <div className="col-sm-4">
            <p className="card-text">
              <span className="font-weight-bold">Active Since: </span>
              {moment.unix(employee.activeSinceTimestamp).format("YYYY-MM-DD")}
            </p>
          </div>
          {employee.state === "removed" && (
            <div className="col-sm-4">
              <p className="card-text">
                <span className="font-weight-bold">Removal date: </span>
                {moment.unix(employee.removalTimestamp).format("YYYY-MM-DD")}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EmployeeForm.defaultProps = {
  handlerInputChange: () => {},
  handleChangeAddress: () => {},
};

EmployeeForm.propTypes = {
  employee: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  handlerInputChange: PropTypes.func,
  handleChangeAddress: PropTypes.func,
};

export default EmployeeForm;
