import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";

export const createCensus = async ({ companyId, type, payload }) => {
  try {
    const { data } = await apiClient().post(
      `v1/back-office/companies/${companyId}/censuses/${type}/`,

      payload
    );
    return data;
  } catch (err) {
    showErrorMessage(err?.response?.data?.message || "An error has ocurred");
    return {};
  }
};

export const findCensusSummary = async ({ companyId, type, censusId }) => {
  try {
    const { data } = await apiClient().get(
      `v1/back-office/companies/${companyId}/censuses/${type}/${censusId}/summary/`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const findCensus = async ({ companyId, type, censusId }) => {
  try {
    const { data } = await apiClient().get(
      `v1/back-office/companies/${companyId}/censuses/${type}/${censusId}`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const validateCensus = async ({ companyId, type, censusId }) => {
  try {
    const response = await apiClient().post(
      `v1/back-office/companies/${companyId}/censuses/${type}/${censusId}/validate`
    );
    return response;
  } catch (err) {
    const {
      response: { status },
    } = err;
    if (status !== 400) {
      showErrorMessage("An error has occurred");
    }
    return {};
  }
};

export const finishCensus = async ({ companyId, type, censusId }) => {
  try {
    const { data } = await apiClient().post(
      `v1/back-office/companies/${companyId}/censuses/${type}/${censusId}/finish/`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const assignRelativesBulk = async ({ payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/employees/create-family-members-bulk`,
    payload
  );
  return res;
};
