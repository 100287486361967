import apiClient from "./api-client";

export async function findAllMovements({ companyId, from, to }) {
  const res = await apiClient().get(
    `/v1/back-office/movements?companyId=${companyId}&from=${from}&to=${to}`
  );
  return res.data;
}

export async function pecunpayMovements({ companyId, from, to, email }) {
  const res = await apiClient().get(
    `/v1/back-office/pecunpay-movements?companyId=${companyId}&from=${from}&to=${to}&email=${email}`
  );
  return res.data;
}
