import PropTypes from "prop-types";
import * as React from "react";
import { TextField } from "@cobee/cobee-ui";

export const ReadOnlyTextField = ({ label = "", value = "" } = {}) => (
  <TextField
    label={label}
    value={value}
    onChange={() => {}}
    inputProps={{ readOnly: true }}
    fullWidth
  />
);

ReadOnlyTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

ReadOnlyTextField.defaultProps = {
  label: "",
  value: "",
};
