import { showSuccessMessage } from "../commons/show-success-message";

import * as cardRepository from "../../infrastructure/card-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function cancelCard({ cardId, employeeId }) {
  try {
    await cardRepository.cancelCard({ cardId, employeeId });
    showSuccessMessage("Card canceled successfully");
  } catch (e) {
    showErrorMessage(
      getServerErrorMessage(e, "An unexpected error has ocurred")
    );
  }
}
