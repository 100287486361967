import ReactDOM from "react-dom";
import React from "react";
import HexModal from "../../../app/components/modals/HexModal";
import AssignRelativeModal from "./index";

const renderAssignRelativeModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <AssignRelativeModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default renderAssignRelativeModal;
