import moment from "moment";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import {
  getCreditsEntriesAmounts,
  getDebitsEntriesAmounts,
} from "../../../services/transactions/entries";

const headersCompanyMovements = [
  "userTransactionIsoTime",
  "payrollCompany",
  "costCenter",
  "concept",
  "payroll",
  "createdAt",
  "confirmedAt",
  "initialBalance",
  "finalBalance",
  "originalAmount",
  "intendedAmount",
  "clearedAmount",
  "totalAmount",
  "state",
  "category",
  "type",
  "merchantName",
  "merchantCategoryCode",
  "merchantId",
  "brand",
  "merchantCategory",
  "country",
  "terminalID",
  "id",
  "companyId",
  "payrollId",
  "cardId",
  "employeeId",
  "recurrentPaymentId",
  "recipientId",
  "pecunpayAuthorizationId",
  "pecunpayTransactionId",
  "parentTransactionId",
];

const dataCompanyMovements = (data) => {
  const {
    id = "",
    companyId = "",
    recurrentPaymentId = "",
    costCenter = "",
    payrollCompany = "",
    employeeId = "",
    recipientId = "",
    cardId = "",
    authorizationId = "",
    transactionId = "",
    parentTransactionId = "",
    payrollId = "",
    userTransactionIsoTime = "",
    category = "",
    concept = "",
    merchantInfo = {},
    type = "",
    state = "",
    stateHistory = [],
    originalAmount = "",
    intendedAmount = "",
    clearedAmount = "",
    entries = [],
    payrollCycle = {},
  } = data;
  const totalCredits = MonetaryAmount.ofCurrency(
    getCreditsEntriesAmounts(entries).abs()
  );
  const totalDebits = MonetaryAmount.ofCurrency(
    getDebitsEntriesAmounts(entries).abs().negate()
  );
  const totalAmount = totalCredits.add(totalDebits);
  const zero = MonetaryAmount.ZERO.toUnitary();

  const initialBalance =
    stateHistory.find((sh) => sh?.processorMessage?.saldoInicio !== undefined)
      ?.processorMessage.saldoInicio || "";

  const finalBalance =
    stateHistory.find((sh) => sh?.processorMessage?.saldoFin !== undefined)
      ?.processorMessage.saldoFin || "";

  const payroll = payrollCycle
    ? `${payrollCycle.fiscalYear || moment().format("YYYY")}/${
        payrollCycle.payrollMonth || moment().format("MM")
      }`
    : "";
  const createdAt = moment(
    stateHistory[0]?.timestampMillis || userTransactionIsoTime
  ).format();
  const confirmationHistory = stateHistory.find(
    (sh) => sh.state === "confirmed"
  );
  const confirmedAt = confirmationHistory
    ? moment(confirmationHistory.timestampMillis).format()
    : "";

  return [
    userTransactionIsoTime
      ? moment(userTransactionIsoTime).format("DD/MM/YYYY HH:mm:ss")
      : "",
    payrollCompany,
    costCenter,
    concept,
    payroll,
    createdAt,
    confirmedAt,
    initialBalance,
    finalBalance,
    (originalAmount?.amountInCents &&
      MonetaryAmount.ofCurrency(originalAmount).toUnitary()) ||
      zero,
    (intendedAmount?.amountInCents &&
      MonetaryAmount.ofCurrency(intendedAmount).toUnitary()) ||
      zero,
    (clearedAmount?.amountInCents &&
      MonetaryAmount.ofCurrency(clearedAmount).toUnitary()) ||
      zero,
    (totalAmount?.amountInCents && totalAmount.toUnitary()) || zero,
    state,
    category,
    type,
    merchantInfo?.merchantName || "",
    merchantInfo?.merchantCategoryCode || "",
    merchantInfo?.merchantId || "",
    merchantInfo?.brand || "",
    merchantInfo?.merchantCategory || "",
    merchantInfo?.country || "",
    merchantInfo?.terminalId || "",
    id,
    companyId,
    payrollId,
    cardId,
    employeeId,
    recurrentPaymentId,
    recipientId,
    authorizationId,
    transactionId,
    parentTransactionId,
  ];
};

export { headersCompanyMovements, dataCompanyMovements };
