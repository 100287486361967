export const TransactionType = {
  ACCUMULATION_WITHDRAWAL: "accumulation-withdrawal",
  ACCUMULATION_DELIVERY: "accumulation-delivery",
  ALLOWANCE_DELIVERY: "allowance-delivery",
  ALLOWANCE_WITHDRAWAL: "allowance-withdrawal",
  CARD_PURCHASE: "card-purchase",
  CARD_PURCHASE_REFUND: "card-purchase-refund",
  CARD_PURCHASE_REVERSAL: "card-purchase-reversal",
  FUNDS_LOAD_BY_CARD: "funds-load-by-card",
  FUNDS_LOAD_BY_WIRE: "funds-load-by-wire",
  SCHEDULED_PURCHASE: "scheduled-purchase",
  INTER_ACCOUNT: "inter-account",
  FUNDS_LOAD_BY_OTHER: "funds-load-by-other",
  FUNDS_WITHDRAWAL: "funds-withdrawal",
  FLEX_NOT_EXEMPT_WITHDRAWAL: "flex-not-exempt-withdrawal",
  ALLOWANCE_NOT_EXEMPT_WITHDRAWAL: "allowance-not-exempt-withdrawal",
  FUNDS_LOADED_BY_BO: "funds-load-by-backoffice",
  GIFT_CARD_PURCHASE: "gift-card-purchase",
  PUNCTUAL_PURCHASE: "punctual-purchase",
};
