import React from "react";
import ReactDOM from "react-dom";
import ConfirmationAboveAuthorizedTransactions from "./index";

export default function renderConfirmationAboveAuthorizedTransactionsPage({
  target = "confirmation-above-authorized-notification-list",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(
      <ConfirmationAboveAuthorizedTransactions {...rest} />,
      domTarget
    );
  }
}
