import { MonetaryAmount } from "app/domain/monetary-amount";

export const restoreWellnessBenefit = ({ products, ...rest }) => ({
  products: products.map((product) => ({
    ...product,
    fee: product?.fee ? MonetaryAmount.ofCurrency(product.fee).toUnitary() : 0,
  })),
  ...rest,
});

export default function adaptWellnessBenefitUpdatedData({ data }) {
  return {
    ...data,
    products: [...data.products].map(({ id, languages, fee, ...rest }) => ({
      id,
      fee: {
        amountInCents: fee * 100,
        currency: "EUR",
      },
      languages: Object.keys(languages).reduce((acc, curr) => {
        const { name, description, attachments } = languages[curr];
        const filteredAttachments = attachments.filter(
          ({ title, url }) => title && url && title !== "" && url !== ""
        );
        if (
          name !== "" &&
          description !== "" &&
          filteredAttachments.length > 0
        ) {
          return {
            ...acc,
            [curr]: { name, description, attachments: filteredAttachments },
          };
        }

        return acc;
      }, {}),
      ...rest,
    })),
  };
}
