import { adaptExcelPayload } from "../../infrastructure/adapters/adaptExcelPayload";
import renderGenericModalErrors from "../../infrastructure/renders/ShowGenericModalErrors/render-generic-modal-errors";
import parseRowsFromExcel from "../../services/parseRowsFromExcel";
import { hideModal } from "../commons/hide-modal";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";
import { displayCompanyDetailsPage } from "./display-company-details";
import downloadEmployeesLaborDaysFailureFile from "./download-employees-labor-days-failure-file";
import editLaborDays from "./edit-labor-days";

export default async function uploadLaborDaysFromFile({ companyId, fileName }) {
  try {
    const data = await parseRowsFromExcel(fileName);
    const adaptedRows = data.map((item) =>
      adaptExcelPayload({ type: "labor-days-config", item })
    );
    const { data: dataResponse } = await editLaborDays({
      companyId,
      payload: adaptedRows,
    });
    if (dataResponse) {
      const {
        content: { errors },
      } = dataResponse;
      if (errors.length === 0) {
        showSuccessMessage("Successfully updated");
        displayCompanyDetailsPage(companyId);
      } else {
        renderGenericModalErrors({
          title: "Labor days errors",
          errors,
          callback: () => {
            downloadEmployeesLaborDaysFailureFile({ data: errors });
            hideModal();
            if (data.length !== errors.length) {
              displayCompanyDetailsPage(companyId);
            }
          },
        });
      }
    }
  } catch (error) {
    showErrorMessage("There was an error uploading the spreadsheet file");
  }
}
