import React, { Component } from "react";
import MultiSelect from "@khanacademy/react-multi-select";
import moment from "moment";
import { displayEmployeeDetail } from "../display-employee-detail";
import { saveEmployeeHealthInsurance } from "../save-employee-health-insurance";
import { saveEmployeeHealthInsuranceForNextPeriod } from "../save-employee-health-insurance-for-next-period";
import { editHealthInsurance } from "../edit-health-insurance";
import { showErrorMessage } from "../../commons/show-error-message";

export default class EmployeeHealthInsurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      newInsured: true,
      selectedModality: props.modalities[0]?.modalityId,
      insuredId: props.employee.id,
      complementsSelected: [],
      complementsOptions: [],
      effectiveDate: moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
    };
  }

  componentDidMount() {
    const { healthInsurance, modalities } = this.props;
    if (modalities && modalities[0]?.healthInsuranceModalityComplements) {
      this.setState({
        complementsOptions: modalities[0].healthInsuranceModalityComplements.map(
          (complement) => ({
            label: complement.complementName,
            value: complement.complementId,
          })
        ),
      });
    }

    if (healthInsurance) {
      const complements = [];
      healthInsurance.healthInsuranceComplements.map((complement) =>
        complements.push(complement.complementId)
      );
      this.handleChangeModality(healthInsurance.benefitDetails.modalityId);
      this.setState({
        newInsured: false,
        insuredId: healthInsurance.recipientId,
        complementsSelected: complements,
        effectiveDate: moment(healthInsurance.effectiveDate).format(
          "YYYY-MM-DD"
        ),
      });
    }
  }

  handleChangeInsured = (value) => {
    this.setState({
      insuredId: value,
      complementsSelected: [],
    });
  };

  handleChangeModality = (value) => {
    this.setState({ selectedModality: value });

    const { modalities } = this.props;
    const modality = modalities.find((m) => m.modalityId === value);
    const complements = modality.healthInsuranceModalityComplements || [];

    const complementsOptionsArray = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const complement of complements) {
      complementsOptionsArray.push({
        label: complement.complementName,
        value: complement.complementId,
      });
    }

    this.setState({
      complementsOptions: complementsOptionsArray,
      complementsSelected: [],
    });
  };

  handleChangeComplementsSelected = (value) => {
    const complementsSelected = value;
    this.setState({ complementsSelected });
  };

  handlerSave = async () => {
    this.setState({
      loading: true,
    });
    const { employee, isNextPeriod, companyId } = this.props;
    const {
      complementsSelected,
      insuredId,
      selectedModality,
      effectiveDate,
    } = this.state;

    if (isNextPeriod) {
      await saveEmployeeHealthInsuranceForNextPeriod({
        employee,
        recipientId: insuredId,
        healthInsuranceProductId: selectedModality,
        complementIds: complementsSelected,
      });
    } else {
      await saveEmployeeHealthInsurance({
        employee,
        insuredId,
        modalityId: selectedModality,
        complementsIds: complementsSelected,
        effectiveDate,
        companyId,
      });
    }

    this.setState({
      loading: false,
    });
  };

  handlerEdit = async () => {
    this.setState({
      loading: true,
    });
    const {
      employee,
      lastPayrollClose,
      healthInsurance,
      companyId,
    } = this.props;
    const {
      complementsSelected,
      insuredId,
      selectedModality,
      effectiveDate,
    } = this.state;
    const closeFormatted = moment(lastPayrollClose).format("YYYY-MM-DD");
    const atOneTime = moment(effectiveDate).isSameOrBefore(moment());
    const nextFormatted = moment.unix(healthInsurance.nextExec);
    const firstFormatted = moment
      .unix(healthInsurance.firstExec)
      .add(1, "day")
      .add(1, "month");

    if (moment(effectiveDate).isBefore(closeFormatted)) {
      showErrorMessage("Trying to create an insurance before last payroll");
    } else if (nextFormatted.isAfter(firstFormatted) && atOneTime) {
      showErrorMessage("This insurance cannot be edited on the requested date");
    } else {
      await editHealthInsurance({
        employee,
        insuredId,
        modalityId: selectedModality,
        complementsIds: complementsSelected,
        effectiveDate,
        companyId,
      });
    }
    this.setState({
      loading: false,
    });
  };

  render() {
    const { modalities, employee, numMp, lastPayrollClose } = this.props;
    const {
      loading,
      newInsured,
      complementsOptions,
      complementsSelected,
      insuredId,
      selectedModality,
    } = this.state;

    return (
      <div className="card mb-5">
        <div className="card-body">
          {newInsured ? (
            <p className="card-text">New Health insurance:</p>
          ) : (
            <p className="card-text">Edit Health insurance:</p>
          )}
          <hr />

          <div className="col">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Insured</label>
              <div className="col-sm-4">
                <select
                  disabled={!newInsured}
                  onChange={(e) => this.handleChangeInsured(e.target.value)}
                >
                  <option
                    selected={!newInsured && employee.id === insuredId}
                    value={employee.id}
                  >
                    {`${employee.name} ${employee.surname}`}
                  </option>

                  {employee.familyMembers.map((familyMember) => (
                    <option
                      key={familyMember.id}
                      selected={!newInsured && familyMember.id === insuredId}
                      value={familyMember.id}
                    >
                      {`${familyMember.name} ${familyMember.surname}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Modality</label>
              <div className="col-sm-4">
                <select
                  onChange={(e) => this.handleChangeModality(e.target.value)}
                >
                  {modalities.map((modality) => (
                    <option
                      key={modality.modalityId}
                      selected={
                        !newInsured && modality.modalityId === selectedModality
                      }
                      value={modality.modalityId}
                    >
                      {modality.modalityName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Complements</label>
              <div className="col-sm-10">
                <MultiSelect
                  options={complementsOptions}
                  selected={complementsSelected}
                  onSelectedChanged={(selected) =>
                    this.handleChangeComplementsSelected(selected)
                  }
                />
              </div>
            </div>

            {!this.props.isNextPeriod && (
              <div className="form-group row">
                <div className="col-sm-2">
                  <label className="col-form-label">Effective date</label>
                </div>
                <div className="col-sm-10">
                  <input
                    type="date"
                    required
                    defaultValue={this.state.effectiveDate}
                    value={this.state.effectiveDate}
                    min={moment(lastPayrollClose)
                      .add(1, "day")
                      .format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.setState({
                        effectiveDate: moment(e.target.value)
                          .startOf("month")
                          .format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
              </div>
            )}

            <small>
              The date when the employee will be insured. It will always default
              to the start of the month. <br />
              <strong>If it is in the past</strong>, the employee will see the
              transaction as confirmed and will be deducted from their payroll
            </small>

            <div className="row flow float-right">
              {newInsured ? (
                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={this.handlerSave}
                >
                  Save insured
                </button>
              ) : (
                <button
                  disabled={loading}
                  className="btn btn-primary"
                  onClick={this.handlerEdit}
                >
                  Save changes
                </button>
              )}

              <button
                className="btn btn-danger ml-2 mr-2"
                onClick={() => displayEmployeeDetail(employee.email, numMp)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
