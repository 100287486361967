const themeColors = {
  white: "#FFFFFF",
  black: "#000000",
  primary: "#2eefaa",
  secondary: "#0000ff",
  warningLight: "#FFE3AD",
  warningLight2: "#FEF6E6",
  warning: "#ff5a0f",
  warning90: "#FFA900",
  error: "#e61400",
  greyMedium: "#4B5D72",
  grey300: "#c4c4c4",
  lightGrey: "#929FAD",
  softGrey: "#EEEEEE",
};

export const {
  white,
  black,
  primary,
  secondary,
  warning,
  error,
  grey300,
  lightGrey,
  warning90,
  warningLight,
  warningLight2,
  greyMedium,
  softGrey,
} = themeColors;
