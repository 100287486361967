import apiClient from "./api-client";

export async function getCorporation(corporationId) {
  const { data } = await apiClient().get(
    `/v1/back-office/corporations/${corporationId}`
  );
  return data.content;
}

export async function getCorporations() {
  const { data } = await apiClient().get(`/v1/back-office/corporations`);
  return data.content;
}

export function createCorporation(payload) {
  return apiClient().post(`/v1/back-office/corporations`, payload);
}

export async function getCompanies(corporationId) {
  const { data } = await apiClient().get(
    `/v1/back-office/corporations/${corporationId}/companies`
  );
  return data.content;
}

export async function getHrAdmins(corporationId) {
  const { data } = await apiClient().get(
    `/v1/back-office/corporations/${corporationId}/privileged-users`
  );
  return data.content;
}

export async function deleteCompanyFromCorporation({
  corporationId,
  companyId,
}) {
  const { data } = await apiClient().delete(
    `/v1/back-office/corporations/${corporationId}/companies/${companyId}`
  );
  return data.content;
}
