import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ContentWrapper from "../ContentWrapper";
import countryNamesByIsoCode from "../../countryNamesByISOCode";
import useStyles from "./styles";
import displayCountryTaxRulesPage from "../../../usecases/countries/display-country-tax-rules-page";
import TaxRuleCard from "../../../app/components/TaxRuleCard";
import getBenefitName from "../../get-benefit-name";
import { ReactComponent as EmptyListIllustration } from "../../../assets/img/empty-list.svg";

const columns = [
  "max-exempt",
  "max-salary-in-kind",
  "max-salary-reduction",
  "max-prorated-exempt",
];

export default function CountryTaxRulesPage({
  isLoading,
  countries,
  defaultFilters = { selectedCountry: "", selectedRegion: "" },
  rules,
}) {
  const { select, labelContainer, content, svg } = useStyles();

  const [selectedCountry, setSelectedCountry] = useState(
    defaultFilters.selectedCountry
  );
  const [selectedRegion, setSelectedRegion] = useState(
    defaultFilters.selectedRegion
  );

  useEffect(() => {
    setSelectedRegion("");
  }, [selectedCountry, setSelectedRegion]);

  return (
    <ContentWrapper title="Country tax rules">
      <Grid item container xs={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item className={labelContainer}>
            <Typography variant="body1">Country: </Typography>
          </Grid>
          <Grid item>
            <Select
              className={select}
              value={selectedCountry}
              displayEmpty
              onChange={(e) => setSelectedCountry(e.target.value)}
              disabled={isLoading}
            >
              <MenuItem value="" selected disabled>
                Select a country...
              </MenuItem>
              {countries.map(({ ISO2Code, countryId }) => (
                <MenuItem key={countryId} value={ISO2Code}>
                  {countryNamesByIsoCode({ countryISOCode: ISO2Code })}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item className={labelContainer}>
            <Typography variant="body1">Region: </Typography>
          </Grid>
          <Grid item>
            <Select
              className={select}
              value={selectedRegion}
              displayEmpty
              onChange={(e) => {
                setSelectedRegion(e.target.value);
                displayCountryTaxRulesPage({
                  selectedCountry,
                  selectedRegion: e.target.value,
                });
              }}
              disabled={isLoading || selectedCountry === ""}
            >
              <MenuItem value="" selected disabled>
                Select a region...
              </MenuItem>
              {countries
                .find(({ ISO2Code }) => ISO2Code === selectedCountry)
                ?.regions?.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center" className={content}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid item container xs={12} spacing={4}>
              {rules.map(({ benefitType, rules: benefitRules }) => (
                <Grid item container xs={12} key={benefitType}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <b>{getBenefitName({ type: benefitType })}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    {benefitRules.map((rule, index) => (
                      <TaxRuleCard
                        key={index.toString()}
                        rule={rule}
                        name={rule.type}
                        benefitRules={benefitRules}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {!isLoading && rules.length === 0 && (
            <EmptyListIllustration className={svg} />
          )}
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}

CountryTaxRulesPage.defaultProps = {
  isLoading: false,
};

CountryTaxRulesPage.propTypes = {
  isLoading: PropTypes.bool,
  countries: PropTypes.array.isRequired,
  defaultFilters: PropTypes.shape({
    selectedCountry: PropTypes.string,
    selectedRegion: PropTypes.string,
  }).isRequired,
  rules: PropTypes.array.isRequired,
};
