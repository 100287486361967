import queryString from "query-string";
import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";

export const findEmployeeAvailableBagAmount = async ({
  employeeId,
  category,
}) => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/employees/${employeeId}/categories/${category}/available-bag-amount`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const findEmployeeAvailablePursesAmount = async ({
  employeeId,
  category,
}) => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/employees/${employeeId}/categories/${category}/available-purses-amount`
    );
    return data;
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const findEmployeePayrollCycles = async ({ employeeId }) => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/employees/${employeeId}/all-payroll-cycles`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const restoreEmployeeVoidTransaction = async ({
  employeeId,
  payload,
}) => {
  try {
    const { data } = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/regenerate-void-transaction`,
      payload
    );
    return data;
  } catch (err) {
    showErrorMessage(err?.response?.data?.message || "An error has occurred");
    return {};
  }
};

export const executeManualTransaction = async ({ employeeId, payload }) => {
  try {
    const { data } = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/transaction`,
      payload
    );
    return data;
  } catch (err) {
    showErrorMessage(err?.response?.data?.message || "An error has occurred");
    return {};
  }
};

export const getInvoices = async ({ query }) => {
  const res = await apiClient().get(
    `/v1/back-office/invoices${query ? `?${query}` : ""}`
  );
  return res;
};

export const getMovementCompany = async ({ companyId, fromDate, toDate }) => {
  const query = {};
  if (fromDate) {
    query.fromDate = fromDate;
  }
  if (toDate) {
    query.toDate = toDate;
  }

  const safeUrl = queryString.stringifyUrl({
    query,
    url: `/v1/back-office/companies/${companyId}/transactions`,
  });
  const res = await apiClient().get(safeUrl);
  return res;
};

export const sendAndResolveInvoices = async ({ invoiceId, emails }) => {
  const res = await apiClient().post(
    `/v1/back-office/invoices/${invoiceId}/notification`,
    {
      to: emails,
    }
  );
  return res;
};

export const discardInvoice = async ({ invoiceId }) => {
  const res = await apiClient().delete(`/v1/back-office/invoices/${invoiceId}`);
  return res;
};

export const previewInvoicePdf = async ({ invoiceId }) => {
  const res = await apiClient().get(
    `/v1/back-office/invoices/${invoiceId}/preview`,
    {
      responseType: "blob",
    }
  );
  return res;
};
