import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import { discoverService } from "../discover-service";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: discoverService("auth0_domain"),
      clientID: discoverService("auth0_client_id"),
      redirectUri: `${discoverService("self")}/callback`,
      audience: discoverService("auth0_audience"),
      responseType: "token",
      scope: "access:internal_backoffice",
    });

    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.logIn.bind(this);
    this.signOut = this.logOut.bind(this);
  }

  getCompanyId = () => {
    return localStorage.getItem("company_id");
  };

  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  getExpiresInMillis = () => {
    return localStorage.getItem("expires_in_millis");
  };

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (err) {
        this.logOut(`${discoverService("self")}/`);
        window.location.replace("/");
      } else if (authResult && authResult.accessToken) {
        this.setSession(authResult);
        window.location.reload();
      } else if (err) {
        this.logOut(`${discoverService("self")}/`);
        // history.replace("/error");
      }
    });
  }

  refreshSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        this.logOut(`${discoverService("self")}/`);
        window.location.replace("/");
      } else {
        this.setSession(authResult);
      }
    });
  }

  setSession = (authResult) => {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("expires_in_millis", authResult.expiresIn * 1000);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("access_token", authResult.accessToken);

    const accessTokenPayload = jwtDecode(authResult.accessToken);
    localStorage.setItem(
      "company_id",
      accessTokenPayload["https://www.cobee.io/companyId"]
    );
  };

  isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };

  logIn(history) {
    localStorage.setItem(
      "redirect_url",
      history ? history.location.pathname : ""
    );
    this.auth0.authorize({});
  }

  logOut() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in_millis");
    localStorage.removeItem("expires_at");
    // navigate to the home route
    this.auth0.logout({
      redirectUri: "https://www.cobee.io",
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
