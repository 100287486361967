import React, { useState } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import Cancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import useParentStyles from "../../styles";
import useStyles from "./styles";
import CobeeButton from "../../../../../app/components/CobeeButton";

export default function EditBenefitsSection({
  intialActiveBenefits,
  allowedBenefits,
  callback,
}) {
  const [activeBenefits, setActiveBenefits] = useState(intialActiveBenefits);
  const [isLoading, setIsLoading] = useState(false);

  const { benefitItem, sectionTitle } = useParentStyles();
  const { icon, addIcon, cancelIcon } = useStyles();

  const removeBenefit = ({ benefit }) => {
    setActiveBenefits((state) => state.filter((item) => item !== benefit));
  };

  const addBenefit = ({ benefit }) =>
    setActiveBenefits((state) => [...state, benefit]);

  const resetBenefits = () => setActiveBenefits(intialActiveBenefits);

  const benefitIsValidForBenefitBagFilter = (benefit) =>
    benefit !== "gift-card-benefit";
  const benefitIsNotActiveFilter = (benefit) =>
    activeBenefits.indexOf(benefit) === -1;
  const availableBenefitsToAdd = allowedBenefits
    .filter(benefitIsValidForBenefitBagFilter)
    .filter(benefitIsNotActiveFilter);

  return (
    <>
      <Grid container>
        {activeBenefits.map((item) => (
          <Grid key={item} className={benefitItem} item>
            <Cancel
              className={`${icon} ${cancelIcon}`}
              onClick={() =>
                removeBenefit({
                  benefit: item,
                })
              }
            />
            <Typography variant="body2">{item}</Typography>
          </Grid>
        ))}
      </Grid>

      <Typography className={sectionTitle}>YOU CAN INCLUDE</Typography>
      <Grid container>
        {availableBenefitsToAdd.map((benefit) => (
          <Grid key={benefit} className={benefitItem} item>
            <AddCircle
              className={`${icon} ${addIcon}`}
              onClick={() => addBenefit({ benefit })}
            />
            <Typography variant="body2">{benefit}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container justify="flex-end" spacing={3}>
        <Grid item>
          <CobeeButton disabled={isLoading} onClick={resetBenefits}>
            Reset
          </CobeeButton>
        </Grid>
        <Grid item>
          <CobeeButton
            isLoading={isLoading}
            disabled={activeBenefits.length === 0}
            onClick={() => {
              setIsLoading(true);
              callback(activeBenefits);
            }}
          >
            Save
          </CobeeButton>
        </Grid>
      </Grid>
    </>
  );
}

EditBenefitsSection.propTypes = {
  intialActiveBenefits: PropTypes.array,
  allowedBenefits: PropTypes.array,
  callback: PropTypes.func,
};

EditBenefitsSection.defaultProps = {
  intialActiveBenefits: [],
  allowedBenefits: [],
  callback: () => {},
};
