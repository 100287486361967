import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import MailIcon from "@material-ui/icons/Mail";
import CobeeButton from "../../../app/components/CobeeButton";
import Table from "../../../app/components/Table";
import ContentWrapper from "../ContentWrapper";
import assignRiskIndex from "../../../usecases/lifeInsurance/assignRiskIndex";
import useStyles from "./styles";
import { resendEmail } from "../../life-insurance-repository";

const pendingColumns = [
  {
    label: "Request date",
    id: "requestedAtTimestamp",
  },
  {
    label: "NIF/NIE",
    id: "legalId",
  },
  {
    label: "Employee name",
    id: "name",
  },
  {
    label: "Company",
    id: "company",
  },
  {
    label: "Policy name",
    id: "policy",
  },
  {
    label: "Modality",
    id: "modality",
  },
  {
    label: "",
    id: "actions",
    align: "right",
  },
];

const completedColumns = [
  {
    label: "Request date",
    id: "requestedAtTimestamp",
  },
  {
    label: "NIF/NIE",
    id: "legalId",
  },
  {
    label: "Employee name",
    id: "name",
  },
  {
    label: "Company",
    id: "company",
  },
  {
    label: "Policy name",
    id: "policy",
  },
  {
    label: "Modality",
    id: "modality",
  },
  {
    label: "Risk factor",
    id: "riskFactor",
  },
  {
    label: "",
    id: "actions",
    align: "right",
  },
];

export default function LifeInsuranceRequestsPage({
  recurrentPayments,
  isLoading,
}) {
  const { divider } = useStyles();

  return (
    <ContentWrapper title="Life insurance requests">
      {isLoading ? (
        <Grid item container xs={12} justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12} container>
          <Grid item xs={12} container>
            <Typography variant="h6">
              {recurrentPayments?.filter(
                ({ benefitDetails }) =>
                  benefitDetails && benefitDetails.riskFactor === null
              ).length > 0
                ? "Pending requests"
                : "No pending requests"}
            </Typography>
          </Grid>
          {recurrentPayments?.filter(
            ({ benefitDetails }) =>
              benefitDetails && benefitDetails.riskFactor === null
          ).length > 0 && (
            <Grid item xs={12} container>
              <Table
                columns={pendingColumns}
                data={recurrentPayments
                  ?.filter(
                    ({ benefitDetails }) =>
                      benefitDetails && benefitDetails.riskFactor === null
                  )
                  .map(
                    ({
                      id,
                      requestedAtTimestamp,
                      employee,
                      company,
                      modality,
                      policy,
                      ...rest
                    }) => ({
                      requestedAtTimestamp: moment(
                        new Date(requestedAtTimestamp * 1000)
                      ).format("YYYY-MM-DD"),
                      legalId: employee.legalId,
                      name: employee.name,
                      company: company.companyName,
                      modality: modality.name,
                      policy: policy.insurancePolicy,
                      actions: (
                        <CobeeButton
                          variant="unContained"
                          onClick={() =>
                            assignRiskIndex({
                              recurrentPaymentId: id,
                            })
                          }
                        >
                          <CreateIcon />
                          Risk index
                        </CobeeButton>
                      ),
                      ...rest,
                    })
                  )}
              />
            </Grid>
          )}
          <Grid item container xs={12} className={divider} />
          <Grid item xs={12} container>
            <Typography variant="h6">
              {recurrentPayments?.filter(
                ({ benefitDetails }) =>
                  benefitDetails && benefitDetails.riskFactor !== null
              ).length > 0
                ? "Completed requests"
                : "No completed requests"}
            </Typography>
          </Grid>
          {recurrentPayments?.filter(
            ({ benefitDetails }) =>
              benefitDetails && benefitDetails.riskFactor !== null
          ).length > 0 && (
            <Grid item xs={12} container>
              <Table
                columns={completedColumns}
                data={recurrentPayments
                  ?.filter(
                    ({ benefitDetails }) =>
                      benefitDetails && benefitDetails.riskFactor !== null
                  )
                  .map(
                    ({
                      id,
                      requestedAtTimestamp,
                      benefitDetails: { riskFactor },
                      employee,
                      company,
                      modality,
                      policy,
                      ...rest
                    }) => ({
                      requestedAtTimestamp: moment(
                        new Date(requestedAtTimestamp * 1000)
                      ).format("YYYY-MM-DD"),
                      riskFactor,
                      legalId: employee.legalId,
                      name: employee.name,
                      company: company.companyName,
                      modality: modality.name,
                      policy: policy.insurancePolicy,
                      actions: (
                        <>
                          <CobeeButton
                            variant="unContained"
                            onClick={() =>
                              assignRiskIndex({
                                recurrentPaymentId: id,
                                valueOverride: riskFactor,
                              })
                            }
                          >
                            <CreateIcon />
                            Risk index
                          </CobeeButton>
                          <CobeeButton
                            variant="unContained"
                            onClick={async () => {
                              await resendEmail({ recurrentPaymentId: id });
                            }}
                          >
                            <MailIcon />
                            Resend email
                          </CobeeButton>
                        </>
                      ),
                      ...rest,
                    })
                  )}
              />
            </Grid>
          )}
        </Grid>
      )}
    </ContentWrapper>
  );
}
