import moment from "moment";
import { cachedT } from "../../adaptExcelPayload";

export const parseHealthInsuranceFailures = (data) => {
  return data.map(
    ({
      data: {
        insurancePolicy,
        modalityName,
        employeeLegalId,
        insured: { type, subtype, name, surname, birthDate, legalId, sex },
        complementsNames,
        startDayOfYear,
      },
      ...rest
    }) => {
      return {
        data: {
          [cachedT.NumeroPoliza]: insurancePolicy,
          [cachedT.Modalidad]: modalityName,
          [cachedT.DniEmpleado]: employeeLegalId,
          [cachedT.Tipo]: type,
          [cachedT.Subtipo]: subtype,
          [cachedT.DniAsegurado]: legalId,
          [cachedT.NombreAsegurado]: name,
          [cachedT.ApellidosAsegurado]: surname,
          [cachedT.FechaComienzoAnual]: moment(startDayOfYear).format(
            "DD/MM/YYYY"
          ),
          [cachedT.FechaNacimientoAsegurado]: moment(birthDate).format(
            "DD/MM/YYYY"
          ),
          [cachedT.SexoAsegurado]: sex,
          [cachedT.Complemento1]: complementsNames?.[0] || "",
          [cachedT.Complemento2]: complementsNames?.[1] || "",
          [cachedT.Complemento3]: complementsNames?.[2] || "",
          [cachedT.Complemento4]: complementsNames?.[3] || "",
          [cachedT.Complemento5]: complementsNames?.[4] || "",
        },
        ...rest,
      };
    }
  );
};
