import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import CobeeButton from "../../../../../app/components/CobeeButton";
import validateProgress from "../../../../../assets/img/validate-inprogress.png";
import { CustomLinearProgress, useStyles } from "./styles";
import * as Constants from "./constants";

export default function ValidateProgress({ isValidating, companyId }) {
  const message = isValidating
    ? Constants.VALIDATING_MESSAGE
    : Constants.LOADING_DATA_MESSAGE;
  const {
    containerMessage,
    validateStyle,
    loadingStyle,
    buttonRow,
  } = useStyles();
  return (
    <Grid container>
      <Grid container xs={12} item justify="center">
        <img src={validateProgress} alt="validate-progress-img" />
      </Grid>
      <Grid
        container
        xs={12}
        item
        justify="center"
        className={containerMessage}
      >
        <Typography
          variant="h6"
          className={isValidating ? validateStyle : loadingStyle}
        >
          {message}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="center">
        <CustomLinearProgress />
      </Grid>
      <Grid container item xs={12} justify="center" className={buttonRow}>
        <CobeeButton
          variant="secondary"
          onClick={() => window.location.replace(`/companies/${companyId}`)}
        >
          Cancel
        </CobeeButton>
      </Grid>
    </Grid>
  );
}

ValidateProgress.propTypes = {
  isValidating: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
};

ValidateProgress.defaultProps = {
  isValidating: false,
};
