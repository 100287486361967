import ReactDOM from "react-dom";
import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import HexModal from "../../app/components/modals/HexModal";

export function confirm({ message, onConfirm }) {
  ReactDOM.render(
    <HexModal>
      <ConfirmationModal message={message} onConfirm={onConfirm} />
    </HexModal>,
    document.getElementById("modal-container")
  );
}
