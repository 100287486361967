import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../RestoreVoidTransactionModal/styles";
import CobeeButton from "../../CobeeButton";
import CobeeTextField from "../../CobeeTextField";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import { MonetaryAmount } from "../../../domain/monetary-amount";

export default function ConfirmGenerateManualTransactionModal({
  isLoading,
  category,
  type,
  employee,
  currency,
  behaviours,
  callback,
}) {
  const { container, row, buttonRow, divider } = useStyles();
  const [values, setValues] = useState([]);
  behaviours = behaviours.map((b) => {
    b.amount = MonetaryAmount.of(b.amount.amountInCents, currency);
    return b;
  });

  const totalAmount = behaviours.reduce(
    (accumulated, current) => accumulated.add(current.amount),
    MonetaryAmount.zeroWithCurrency(currency)
  );

  const formIsReady = () => {
    return "password" in values;
  };

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Confirm create manual transaction</Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <i>
            {" "}
            You are going to create to employe {employee.name} with email{" "}
            {employee.email} a transaction of {category} with type {type}
          </i>
        </Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          Total amount:{" "}
          <b>
            {totalAmount
              ? MonetaryAmount.ofCurrency(totalAmount).prettifyMoney({})
              : "-"}
          </b>
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        {behaviours.map(({ behaviour, amount, benefitBag, purse }, i) => (
          <Grid item xs={6} container alignItems="center" key={behaviour}>
            <Typography variant="body2">
              {behaviour} amount:{" "}
              <b>
                {amount
                  ? MonetaryAmount.ofCurrency(amount).prettifyMoney({})
                  : "-"}
              </b>
              {benefitBag && <i> - money from Benefit Bag</i>}
              {purse && <i> - money from Purse</i>}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid item container xs={12} className={divider} />

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row}>
          <Typography variant="body2">Password:</Typography>
        </Grid>
        <Grid item container xs={10} className={row}>
          <CobeeTextField
            type="password"
            disabled={isLoading}
            minWidth="100%"
            onChange={(e) =>
              setValues({
                ...values,
                password: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading || !formIsReady()}
          isLoading={isLoading}
          onClick={() => callback({ values })}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
