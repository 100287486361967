import * as XLSX from "xlsx";
import { showErrorMessage } from "../../usecases/commons/show-error-message";

export default function parseRowsFromExcel(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {
        type: "array",
        cellText: false,
        cellDates: true,
      });
      const sheetName = workbook.SheetNames[0];
      const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {});
      if (rows.length === 0) {
        showErrorMessage("empty file, upload again", true);
      }
      resolve(rows);
    };
  });
}
