import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavMenu } from "v2/ui/components/NavMenu";

export class Website extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div id="container-app" className="flex">
        <div id="modal-container" />
        <ToastContainer
          id="toast-container"
          position="top-right"
          closeOnClick
          pauseOnVisibilityChange
          pauseOnHover
        />
        <NavMenu />
        {children || <div className="container-flex" id="page-container" />}
      </div>
    );
  }
}
