import ReactDOM from "react-dom";
import React from "react";
import HexModal from "../../app/components/modals/HexModal";
import TransferMoneyFromTransactionModal from "../../app/components/modals/TransferMoneyFromTransactionModal";
import { transferMoneyFromTransaction } from "../../infrastructure/employee-repository";
import { hideModal } from "../commons/hide-modal";
import { showErrorMessage } from "../commons/show-error-message";
import { MonetaryAmount } from "../../app/domain/monetary-amount";
import { showSuccessMessage } from "../commons/show-success-message";

const renderTransferMoneyFromTransactionModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <TransferMoneyFromTransactionModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default function transferMoneyFromEmployeeTransactions({
  employeeId,
  transactionIds,
}) {
  renderTransferMoneyFromTransactionModal({
    isLoading: false,
    values: { password: "", amount: 0, concept: "" },
    callback: async ({ values }) => {
      renderTransferMoneyFromTransactionModal({
        isLoading: true,
        values,
        callback: () => {},
      });
      try {
        const transactionId = transactionIds[0];

        const response = await transferMoneyFromTransaction(employeeId, {
          transactionId,
          amount: MonetaryAmount.fromUnitary(values.amount),
          password: values.password,
          concept: values.concept,
        });
        if (response?.data?.content?.id) {
          showSuccessMessage(`Transaction created`);
        } else {
          showErrorMessage(
            `An error has ocurred while transfering money from a transaction`
          );
        }
      } catch (err) {
        showErrorMessage(
          `An error has ocurred while transfering money from a transaction`
        );
      } finally {
        hideModal();
      }
    },
  });
}
