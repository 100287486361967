import React from "react";
import { MUI, MIcon } from "@cobee/cobee-ui";

export const NavMenu = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">
        COBEE
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="/corporations">
              Corporations
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/companies">
              Companies <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/employees">
              Employees
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/nurseries">
              Nurseries
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/movements">
              Movements
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/authentication-logs">
              Auth0 Logs
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/contributions">
              Contributions
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/life-insurance-requests">
              Life Insurance
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/transactions">
              Transactions
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="/finance">
              Finance
            </a>
          </li>
        </ul>
        <MUI.IconButton
          size="small"
          onClick={() => {
            window.location.href = "/country-tax-rules";
          }}
          sx={{
            marginRight: 1,
            "& svg": {
              color: "white",
            },
          }}
        >
          <MIcon.Settings />
        </MUI.IconButton>
        <form className="form-inline">
          <a className="btn btn-outline-light my-2 my-sm-0" href="/logout">
            Exit
          </a>
        </form>
      </div>
    </nav>
  );
};
