import React from "react";
import ReactDOM from "react-dom";
import BenefitBagDetailsModal from "./components/BenefitDetailsModal";

export default function renderEditBenefitBagDetailsModal({ benefitBag }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <BenefitBagDetailsModal benefitBag={benefitBag} />,
      domTarget
    );
  }
}
