import { get } from "lodash";
import { cancelNextPeriodHealthInsurance } from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { displayEmployeeDetail } from "./display-employee-detail";

export async function removeNextPeriodHealthInsurance(
  employee,
  nextPeriodRecurrentPaymentId
) {
  try {
    await cancelNextPeriodHealthInsurance(
      employee.id,
      nextPeriodRecurrentPaymentId
    );

    showSuccessMessage("Successfully canceled!");
    displayEmployeeDetail(employee.email);
  } catch (err) {
    showErrorMessage(
      get(err, "response.data.message", "Unexpected error"),
      false
    );
  }
}
