import React from "react";
import ReactDOM from "react-dom";
import EmployeesPage from "./components/EmployeesPage";

export async function displayEmployeesPage() {
  renderEmployeesPage();
}

export function renderEmployeesPage() {
  ReactDOM.render(<EmployeesPage />, document.getElementById("page-container"));
}
