import React from "react";
import PropTypes from "prop-types";
import { textFieldTypes, StyledTextField } from "./styles";

export default function CobeeTextField({
  styleType,
  minWidth,
  min,
  max,
  step,
  className,
  ref,
  ...rest
}) {
  const textFieldStyles = textFieldTypes({ minWidth, ...rest });
  return (
    <StyledTextField
      {...rest}
      variant="outlined"
      className={`${textFieldStyles.default} ${
        styleType && textFieldStyles[styleType]
      } ${className}`}
      inputProps={{ min, max, step }}
      inputRef={ref}
    />
  );
}

CobeeTextField.defaultProps = {
  styleType: undefined,
  minWidth: undefined,
  min: undefined,
  max: undefined,
  className: "",
  step: "",
  ref: null,
};

CobeeTextField.propTypes = {
  styleType: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  ref: PropTypes.object,
  step: PropTypes.string,
};
