import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import BInputForm from "../../app/components/InputForm/BInputForm";

const EmailList = ({
  emails,
  isLoading,
  newEmail,
  handlerInput,
  deleteEmail,
  addEmail,
}) => {
  return (
    <>
      <div className="col-sm-12">
        <div className="form-group row">
          {emails.map((email) => (
            <button
              key={email}
              className="btn btn-outline-danger btn-sm mr-2"
              disabled={isLoading}
              onClick={() => deleteEmail(email)}
            >
              <DeleteIcon fontSize="small" />
              <span>{email}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="form-group row">
        <BInputForm
          placeholder="New email"
          inputStyle="col-sm-4"
          value={newEmail}
          onChange={(e) => handlerInput(e.target.value)}
        />
        <button
          className="btn btn-success btn-sm"
          onClick={addEmail}
          disabled={isLoading || !newEmail}
        >
          Add email
        </button>
      </div>
    </>
  );
};

export default EmailList;
