import parseRowsFromExcel from "../../../services/parseRowsFromExcel";
import { adaptExcelPayload } from "../../../infrastructure/adapters/adaptExcelPayload";
import { createCensus } from "../../../infrastructure/census-repository";

export default async function censeFromSpreadsheet({
  type = "renting-benefit",
  fileName,
  company,
}) {
  const parsedRows = await parseRowsFromExcel(fileName);
  const data = await createCensus({
    companyId: company,
    type,
    payload: {
      rows: parsedRows?.map((item) => adaptExcelPayload({ type, item })),
    },
  });

  if (data.censusId) {
    window.location.replace(
      `/census-status?id=${data.censusId}&company=${company}&censusType=${type}`
    );
  }
}
