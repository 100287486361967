import moment from "moment";
import { MonetaryAmount } from "../app/domain/monetary-amount";

export const formatDateFromTimestamp = (
  timestamp,
  format = "YYYY-MM-DD HH:mm"
) => (timestamp ? moment.unix(timestamp).format(format) : "N/A");

export function optionalMonetaryAmount(payment) {
  return payment ? MonetaryAmount.ofCurrency(payment).prettifyMoney({}) : "";
}
