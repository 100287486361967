import apiClient from "./api-client";

export const addWellnessBenefit = async ({ companyId, payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/wellness-benefits`,
    payload
  );
  return res;
};

export const findWellnessBenefit = async ({ companyId, benefitId }) => {
  const res = await apiClient().get(
    `/v1/back-office/companies/${companyId}/wellness-benefits/${benefitId}`
  );
  return res;
};

export const updateWellnessBenefit = async ({
  companyId,
  benefitId,
  payload,
}) => {
  const res = await apiClient().put(
    `/v1/back-office/companies/${companyId}/wellness-benefits/${benefitId}`,
    payload
  );
  return res;
};
