import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "../../../app/routes/PrivateRoute";

import Create from "./subPages/Create";
import Detail from "./subPages/Detail";
import List from "./subPages/List";

export default function Corporations() {
  const match = useRouteMatch();

  return (
    <main className="container-fluid">
      <Switch>
        <PrivateRoute path={`${match.path}/create`} component={Create} />
        <PrivateRoute
          path={`${match.path}/:corporationId/detail`}
          component={Detail}
        />
        <PrivateRoute path={match.path} component={List} />
      </Switch>
    </main>
  );
}
