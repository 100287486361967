import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Modal from "../../../../app/components/modals/Modal";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import CobeeButton from "../../../../app/components/CobeeButton";
import { hideModal } from "../../../commons/hide-modal";
import useStyles from "./styles";

export default function AllowRequestNewCardModal({ card, callback }) {
  const { container, row, buttonRow, rowDescription } = useStyles();
  const [values, setValues] = useState({ cardId: card.cardId });
  const [isLoading, setIsLoading] = useState(false);

  const formIsReady = () => Boolean(values.cardId);

  return (
    <Modal title="Allow Request New Card">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={rowDescription}>
          <Typography variant="body2">
            Allowing to request a new card will:
            <ul>
              <li>
                Create a new pending card based on the selected card information
              </li>
              <li>Cancel all current cards</li>
            </ul>
            This action cannot be undone.
          </Typography>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={12} className={row}>
            <Typography variant="body2">Card Id:</Typography>
          </Grid>
          <Grid item container xs={12} className={row}>
            <CobeeTextField
              type="text"
              disabled
              minWidth="100%"
              defaultValue={values.cardId}
              onChange={(e) => setValues({ ...values, cardId: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            disabled={isLoading || !formIsReady()}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(values);
              await setIsLoading(false);
              await hideModal();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
