import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import Table from "../../../../../app/components/Table";
import EmptyDataForTableMessage from "../../../../../app/components/EmptyDataForTableMessage";
import censusesConfiguration from "../../../../../services/censusesConfiguration";

export default function TableContent({
  data,
  censusStatus,
  filterErrors,
  setData,
  wasModified,
  setWasModified,
}) {
  const { censusType } = queryString.parse(window.location.search);
  const getCorrectMessage = () => {
    if (
      censusStatus.state === "validated" &&
      censusStatus.rowSummary.invalid > 0 &&
      censusStatus.rowSummary.invalid === censusStatus.rowSummary.total
    ) {
      return "There are no valid rows for this census";
    }
    if (
      censusStatus.state === "validated" &&
      censusStatus.rowSummary.invalid === 0
    ) {
      return "There are no errors for this census";
    }
    return "";
  };

  return data
    ?.filter(filterErrors)
    .map(({ data: dataFilter, errors }) =>
      censusesConfiguration[censusType].dataParser({ ...dataFilter, errors })
    ).length > 0 ? (
    <Table
      columns={
        [
          ...censusesConfiguration[censusType]?.tableColumns,
          censusStatus?.rowSummary?.invalid > 0
            ? { label: "Errors", id: "errors" }
            : {},
        ] || []
      }
      data={
        data?.filter(filterErrors).map(({ data: dataFilter, rowId, errors }) =>
          censusesConfiguration[censusType].dataParser({
            ...dataFilter,
            rowId,
            errors,
            setData,
            wasModified,
            setWasModified,
          })
        ) || []
      }
    />
  ) : (
    <EmptyDataForTableMessage message={getCorrectMessage()} />
  );
}

TableContent.propTypes = {
  data: PropTypes.array,
  censusStatus: PropTypes.object,
  filterErrors: PropTypes.func,
};

TableContent.defaultProps = {
  data: [],
  censusStatus: {},
  filterErrors: () => {},
};
