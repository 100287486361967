import { showSuccessMessage } from "../commons/show-success-message";
import { removeGDPR } from "../../infrastructure/employee-repository";
import { resetDisplay } from "./display-employee-detail";

export default async function removeEmployeeGDPR({ employee }) {
  const { id } = employee;
  const success = await removeGDPR(id);
  if (success) {
    showSuccessMessage("Employee deleted");
    resetDisplay();
  }
}
