import React from "react";
import { v4 } from "uuid";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";
import BInputForm from "../../../../app/components/InputForm/BInputForm";

const ModalityForm = ({
  insurance,
  brokers,
  healthInsuranceComplements,
  disabled,
  onChange,
  onChangeDate,
  addEngagement,
  deleteEngagement,
  onChangeLink,
  addLink,
  deleteLink,
  handlerSave,
  handlerCancel,
  addComplement,
  deleteComplement,
}) => {
  return (
    <>
      <hr />
      <div className="form-group row">
        <BInputForm
          disabled
          label="Modality Id"
          inputStyle="col-sm-3"
          value={insurance.modalityId}
        />

        <BInputForm
          label="Modality Name"
          value={insurance.modalityName}
          onChange={(e) =>
            onChange({
              property: "modalityName",
              value: e.target.value,
            })
          }
        />

        <BInputForm
          label="Modality Price"
          type="number"
          inputStyle="col-sm-1"
          value={MonetaryAmount.ofCurrency(insurance.modalityFee).toUnitary()}
          onChange={(e) =>
            onChange({
              property: "modalityFee",
              value: MonetaryAmount.fromUnitary(e.target.value),
            })
          }
        />
      </div>

      <div className="form-group row">
        <BInputForm
          label="Insurance Company"
          value={insurance.insuranceCompany}
          onChange={(e) =>
            onChange({
              property: "insuranceCompany",
              value: e.target.value,
            })
          }
        />

        <label className="col-sm-2 col-form-label col-form-label-sm">
          Broker
        </label>
        <div className="col-sm-2">
          <select
            className="align-bottom"
            onChange={(e) =>
              onChange({
                property: "broker",
                value: e.target.value,
              })
            }
          >
            <option defaultValue>{insurance.broker}</option>
            {brokers?.map((broker) => (
              <option key={broker} value={broker}>
                {broker}
              </option>
            ))}
          </select>
        </div>

        <BInputForm
          label="Policy"
          value={insurance.insurancePolicy}
          onChange={(e) =>
            onChange({
              property: "insurancePolicy",
              value: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group row">
        <BInputForm
          label="Coverage"
          inputStyle="col-sm-10"
          value={insurance.modalityCoverageDescription}
          onChange={(e) =>
            onChange({
              property: "modalityCoverageDescription",
              value: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group row">
        <label className="col-sm-4 col-form-label col-form-label-sm">
          Allow request outside engagement
        </label>
        <div className="col-sm-2">
          <input
            type="checkbox"
            className="align-bottom"
            checked={insurance.allowRequestOutsideEngagement}
            onChange={() =>
              onChange({
                property: "allowRequestOutsideEngagement",
                value: !insurance.allowRequestOutsideEngagement,
              })
            }
          />
        </div>

        <label className="col-sm-4 col-form-label col-form-label-sm">
          Requires employee signature
        </label>
        <div className="col-sm-2">
          <input
            type="checkbox"
            className="align-bottom"
            checked={insurance.requiresEmployeeSignature}
            onChange={() =>
              onChange({
                property: "requiresEmployeeSignature",
                value: !insurance.requiresEmployeeSignature,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-4 col-form-label col-form-label-sm">
          Allow exempt domestic partner
        </label>
        <div className="col-sm-2">
          <input
            type="checkbox"
            className="align-bottom"
            checked={insurance.allowExemptDomesticPartner}
            onChange={() =>
              onChange({
                property: "allowExemptDomesticPartner",
                value: !insurance.allowExemptDomesticPartner,
              })
            }
          />
        </div>

        <label className="col-sm-4 col-form-label col-form-label-sm">
          Is instantaneous
        </label>
        <div className="col-sm-2">
          <input
            type="checkbox"
            className="align-bottom"
            checked={insurance.isInstantaneous}
            onChange={() =>
              onChange({
                property: "isInstantaneous",
                value: !insurance.isInstantaneous,
              })
            }
          />
        </div>

        <label className="col-sm-4 col-form-label col-form-label-sm">
          Allow Family Modalities
        </label>
        <div className="col-sm-2">
          <input
            type="checkbox"
            className="align-bottom"
            checked={insurance.allowDifferentModalitiesPerFamilyMember}
            onChange={() =>
              onChange({
                property: "allowDifferentModalitiesPerFamilyMember",
                value: !insurance.allowDifferentModalitiesPerFamilyMember,
              })
            }
          />
        </div>
      </div>

      <hr />

      <div className="form-group row">
        <label className="col-sm-12 col-form-label col-form-label-sm">
          Brochures
        </label>
        <div className="col-sm-12">
          {insurance?.modalityBrochures.map((brochure, i) => (
            <div key={v4()} className="form-group row">
              <BInputForm
                placeholder="Title"
                inputStyle="col-sm-4"
                defaultValue={brochure.title}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "modalityBrochures",
                    property: "title",
                    value: e.target.value,
                  })
                }
              />
              <BInputForm
                placeholder="Url"
                inputStyle="col-sm-6"
                defaultValue={brochure.url}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "modalityBrochures",
                    property: "url",
                    value: e.target.value,
                  })
                }
              />
              <div className="col-sm-2">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() =>
                    deleteLink({ label: "modalityBrochures", index: i })
                  }
                  disabled={disabled}
                >
                  <DeleteIcon fontSize="small" />
                  <span>Remove link</span>
                </button>
              </div>
            </div>
          ))}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-success btn-sm"
                onClick={() =>
                  addLink({
                    label: "modalityBrochures",
                  })
                }
                disabled={disabled}
              >
                Add Link
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label col-form-label-sm">
          Inside signature documents
        </label>
        <div className="col-sm-12">
          {insurance.insideSignatureDocuments.map((document, i) => (
            <div key={v4()} className="form-group row">
              <BInputForm
                placeholder="Title"
                inputStyle="col-sm-4"
                defaultValue={document.title}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "insideSignatureDocuments",
                    property: "title",
                    value: e.target.value,
                  })
                }
              />
              <BInputForm
                placeholder="Url"
                inputStyle="col-sm-6"
                defaultValue={document.url}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "insideSignatureDocuments",
                    property: "url",
                    value: e.target.value,
                  })
                }
              />
              <div className="col-sm-2">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() =>
                    deleteLink({ label: "insideSignatureDocuments", index: i })
                  }
                  disabled={disabled}
                >
                  <DeleteIcon fontSize="small" />
                  <span>Remove link</span>
                </button>
              </div>
            </div>
          ))}

          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-success btn-sm"
                onClick={() =>
                  addLink({
                    label: "insideSignatureDocuments",
                  })
                }
                disabled={disabled}
              >
                Add Link
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label col-form-label-sm">
          Outside signature documents
        </label>
        <div className="col-sm-12">
          {insurance.outsideSignatureDocuments.map((document, i) => (
            <div key={v4()} className="form-group row">
              <BInputForm
                placeholder="Title"
                inputStyle="col-sm-4"
                defaultValue={document.title}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "outsideSignatureDocuments",
                    property: "title",
                    value: e.target.value,
                  })
                }
              />
              <BInputForm
                placeholder="Url"
                inputStyle="col-sm-6"
                defaultValue={document.url}
                onBlur={(e) =>
                  onChangeLink({
                    index: i,
                    label: "outsideSignatureDocuments",
                    property: "url",
                    value: e.target.value,
                  })
                }
              />
              <div className="col-sm-2">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() =>
                    deleteLink({ label: "outsideSignatureDocuments", index: i })
                  }
                  disabled={disabled}
                >
                  <DeleteIcon fontSize="small" />
                  <span>Remove link</span>
                </button>
              </div>
            </div>
          ))}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-success btn-sm"
                onClick={() =>
                  addLink({
                    label: "outsideSignatureDocuments",
                  })
                }
                disabled={disabled}
              >
                Add Link
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="form-group row">
        <label className="col-sm-1 col-form-label col-form-label-sm">
          Date sign up
        </label>
        <div className="col-sm-3">
          <input
            type="date"
            name="from"
            onChange={(e) =>
              onChange({
                property: "dateSignUpTimestamp",
                value: moment(e.target.value).unix(),
              })
            }
            value={moment
              .unix(insurance.dateSignUpTimestamp)
              .format("YYYY-MM-DD")}
          />
        </div>

        <label className="col-sm-2 col-form-label col-form-label-sm">
          First engagement period
        </label>
        <div className="col-sm-3">
          <div className="input-group-prepend">
            <span className="input-group-text">From</span>
            <input
              type="date"
              name="from"
              onChange={(e) =>
                onChangeDate({
                  label: "firstEngagementPeriod",
                  index: 0,
                  property: "from",
                  value: e.target.value,
                })
              }
              value={moment(
                insurance.firstEngagementPeriod[0].from,
                "MM-DD"
              ).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-group-prepend">
            <span className="input-group-text">to</span>
            <input
              type="date"
              name="to"
              onChange={(e) =>
                onChangeDate({
                  label: "firstEngagementPeriod",
                  index: 0,
                  property: "to",
                  value: e.target.value,
                })
              }
              value={moment(
                insurance.firstEngagementPeriod[0].to,
                "MM-DD"
              ).format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label col-form-label-sm">
          Next engagement periods
        </label>
        <div className="col-sm-12">
          {insurance.nextEngagementsPeriod.map((period, i) => (
            <div key={v4()} className="form-group row">
              <div className="col-sm-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">From</span>
                  <input
                    type="date"
                    name="from"
                    onChange={(e) =>
                      onChangeDate({
                        label: "nextEngagementsPeriod",
                        index: i,
                        property: "from",
                        value: e.target.value,
                      })
                    }
                    value={moment(period.from, "MM-DD").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">to</span>
                  <input
                    type="date"
                    name="to"
                    onChange={(e) =>
                      onChangeDate({
                        label: "nextEngagementsPeriod",
                        index: i,
                        property: "to",
                        value: e.target.value,
                      })
                    }
                    value={moment(period.to, "MM-DD").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <button
                  className="btn btn-outline-danger btn-sm mr-2"
                  onClick={() => deleteEngagement({ index: i })}
                  disabled={disabled}
                >
                  <DeleteIcon fontSize="small" />
                  <span>Remove engagement</span>
                </button>
              </div>
            </div>
          ))}
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn-success btn-sm"
                onClick={addEngagement}
                disabled={disabled}
              >
                Add New Period
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="form-group row">
        <label className="col-sm-12 col-form-label col-form-label-sm">
          Applied complements
        </label>
        <div>
          <div className="container">
            {healthInsuranceComplements.map((complement) => {
              if (
                insurance.complements.some(
                  (id) => id === complement.complementId
                )
              ) {
                return (
                  <button
                    key={complement.complementId}
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => deleteComplement(complement.complementId)}
                    disabled={disabled}
                  >
                    <DeleteIcon fontSize="small" />
                    <span>{complement.complementName}</span>
                  </button>
                );
              }
              return (
                <button
                  key={complement.complementId}
                  className="btn btn-outline-success btn-sm mr-2"
                  onClick={() => addComplement(complement.complementId)}
                  disabled={disabled}
                >
                  <AddIcon fontSize="small" />
                  <span>{complement.complementName}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-secondary btn-sm mr-2"
            onClick={handlerCancel}
            disabled={disabled}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handlerSave}
            disabled={disabled}
          >
            Save changes
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ModalityForm;
