export const regexConfig = {
  email: {
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    toLowerCase: true,
  },
  url: {
    regex: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
    toLowerCase: true,
  },
};

export default function regexTester({ value, type }) {
  return type in regexConfig
    ? regexConfig[type].regex.test(
        regexConfig[type].toLowerCase
          ? String(value).toLowerCase()
          : String(value)
      )
    : false;
}
