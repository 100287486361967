import { Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  primary,
  secondary,
  warning90,
  warningLight,
  lightGrey,
} from "assets/themeColors";

export const WarningTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: warningLight,
    color: lightGrey,
    maxWidth: "168px",
    height: "34px",
    fontSize: "12px",
    padding: "2px 10px",
    marginLeft: "12px",
    boxShadow: "0px 4px 40px rgba(160, 160, 160, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    height: theme.spacing(3),

    "& svg": {
      fontSize: theme.spacing(2),
    },
  },
  radio: {
    color: secondary,
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  checkedRadio: {
    color: primary,
  },
  radioText: {
    cursor: "pointer",
  },
  activeRadioText: {
    color: primary,
    cursor: "pointer",
  },
  warningIconStyles: {
    cursor: "pointer",
    color: warning90,
    marginLeft: "auto",
    padding: 0,
  },
}));

export default useStyles;
