import React, { useState } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { MonetaryAmount } from "app/domain/monetary-amount";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import CobeeTextField from "../../CobeeTextField";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function VoidTransactionModal({
  isLoading,
  intendedAmount,
  defaultValues,
  callback,
}) {
  const { container, row, header, buttonRow, checkbox } = useStyles();

  const [values, setValues] = useState(defaultValues);

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={`${row}, ${header}`}>
        <Typography variant="h5">Void transaction(s)</Typography>
        <Typography variant="h5">
          {MonetaryAmount.ofCurrency(intendedAmount).prettifyMoney({})}
        </Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <i>
            When voiding a transaction, it will be as if it never took place
            regarding funds and/or payroll. However, the employee will still see
            it, marked as invalidated
          </i>
        </Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body1" color="error">
          <b>
            Make sure that this transaction has not been reported on the payroll
            of the employee(s) yet
          </b>
        </Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={!!values?.displayApp}
              onChange={() =>
                setValues({
                  ...values,
                  displayApp: !values?.displayApp,
                })
              }
              disableRipple
            />
          }
          label="Hide this transaction(s) in app"
        />
      </Grid>

      <Grid item container xs={12} className={row}>
        <Typography variant="body2">Password:</Typography>
      </Grid>

      <Grid item container xs={12} className={row}>
        <CobeeTextField
          type="password"
          disabled={isLoading}
          minWidth="100%"
          onChange={(e) =>
            setValues({
              ...values,
              password: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton disabled={isLoading} onClick={() => callback({ values })}>
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
