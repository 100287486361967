import { showSuccessMessage } from "../commons/show-success-message";
import { saveInsuredCompany } from "../../infrastructure/company-repository";
import { displayCompaniesPage } from "./display-companies-page";
import { showErrorMessage } from "../commons/show-error-message";

export async function signUpInsuredCompany(companyId, payload) {
  const res = await saveInsuredCompany(companyId, { ...payload });
  if (res.status === 200 && res.data.response.success) {
    showSuccessMessage("Insured company signed up successfully!");
    displayCompaniesPage();
  } else {
    showErrorMessage(res.data.response.reason || "Error!", false);
  }
}
