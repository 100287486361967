import { updateHealthInsuranceComplement } from "../../../infrastructure/health-insurance-repository";
import { HealthInsuranceDetails } from "../displays/health-insurance-details";
import { showSuccessMessage } from "../../commons/show-success-message";
import { showErrorMessage } from "../../commons/show-error-message";

export async function updateModalitiesComplements({ companyId, complement }) {
  const res = await updateHealthInsuranceComplement({
    companyId,
    complement,
  });
  if (res.status === 200) {
    showSuccessMessage("Complement updates");
    HealthInsuranceDetails({ company: res.data });
  } else {
    const {
      data: { message: errorMessage },
    } = res;
    showErrorMessage(errorMessage);
  }
}
