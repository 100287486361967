import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  filterBar: {
    paddingBottom: theme.spacing(3),
  },
}));

export default useStyles;
