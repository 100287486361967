import React from "react";
import PropTypes from "prop-types";

export const FlexBox = ({ Element, direction, gap, wrap, style, ...props }) => {
  return (
    <Element
      {...props}
      style={{
        ...style,
        display: "flex",
        flexDirection: direction,
        gap,
        flexWrap: wrap ? "wrap" : "nowrap",
      }}
    />
  );
};

FlexBox.propTypes = {
  Element: PropTypes.elementType,
  direction: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
  ]),
  gap: PropTypes.number,
  wrap: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FlexBox.defaultProps = {
  Element: "div",
  direction: "row",
  gap: 0,
  wrap: false,
  style: {},
};
