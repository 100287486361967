import * as React from "react";
import PropTypes from "prop-types";
import { MUI } from "@cobee/cobee-ui";
import { Complement } from "v2/domain/contexts/healthInsurance/entities/Complement";
import { TableHead } from "./TableHead";
import { Row } from "./Row";

export const ComplementsTable = ({
  data,
  keyExtractor = (item) => item.id,
}) => {
  return (
    <MUI.TableContainer>
      <MUI.Table
        stickyHeader
        aria-labelledby="tableTitle"
        size="small"
        width="100%"
      >
        <TableHead />
        <MUI.TableBody>
          {data.map((item) => {
            const id = keyExtractor(item);
            return <Row key={id} id={id} data={item} />;
          })}
        </MUI.TableBody>
      </MUI.Table>
    </MUI.TableContainer>
  );
};

ComplementsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Complement)).isRequired,
  keyExtractor: PropTypes.func,
};

ComplementsTable.defaultProps = {
  keyExtractor: (item) => item.id,
};
