import { validateCensus } from "../../../infrastructure/census-repository";

export default async function startCensusValidation({
  companyId,
  type,
  censusId,
}) {
  const response = await validateCensus({ companyId, type, censusId });
  const { status } = response;
  if (status === 200) {
    window.location.replace(
      `/census-status?id=${censusId}&company=${companyId}&censusType=${type}`
    );
  }
}
