import * as React from "react";
import PropTypes from "prop-types";
import { MUI, MIcon } from "@cobee/cobee-ui";
import { Modality } from "v2/domain/contexts/healthInsurance/entities/Modality";
import { RowDetailsBox } from "./RowDetailsBox";

const stateColors = {
  cancelled: "error",
  expired: "error",
  active: "success",
  scheduled: "info",
};

export const Row = ({ data, id }) => {
  const [open, setOpen] = React.useState(false);

  const labelId = `enhanced-table-checkbox-${id}`;

  return (
    <>
      <MUI.TableRow
        sx={{ "& td": { borderBottom: "unset" } }}
        hover
        tabIndex={-1}
      >
        <MUI.TableCell sx={{ paddingRight: 0 }}>
          <MUI.Chip
            color={stateColors[data.state]}
            size="small"
            label={data.state}
          />
        </MUI.TableCell>
        <MUI.TableCell sx={{ paddingLeft: 1 }}>
          <MUI.IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <MIcon.KeyboardArrowUp /> : <MIcon.KeyboardArrowDown />}
          </MUI.IconButton>
        </MUI.TableCell>
        <MUI.TableCell id={labelId} scope="row" padding="none">
          {data.name}
        </MUI.TableCell>
        <MUI.TableCell align="left">
          <MUI.Chip label={data.id} size="small" />
        </MUI.TableCell>
        <MUI.TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {data.fee.parsed()}
        </MUI.TableCell>
      </MUI.TableRow>
      <RowDetailsBox data={data} open={open} />
    </>
  );
};

Row.propTypes = {
  data: PropTypes.instanceOf(Modality).isRequired,
  id: PropTypes.any.isRequired,
};
