import { makeStyles } from "@material-ui/core/styles";
import { lightGrey, grey300, white, error } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: lightGrey,
    fontSize: "12px",
  },
  datePickerInput: {
    border: `1px solid ${grey300}`,
    height: 37,
    display: "flex",
    alignItems: "center",
    textIndent: theme.spacing(1),
    cursor: "pointer",
    backgroundColor: white,
  },
  errorCaption: {
    color: error,
  },
}));

export default useStyles;
