import React from "react";
import ReactDOM from "react-dom";
import OfflineTransactions from "./index";

export default function renderOfflineTransactionsPage({
  target = "offline-notification-list",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(<OfflineTransactions {...rest} />, domTarget);
  }
}
