import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { error, warningLight2 } from "assets/themeColors";
import { hideModal } from "../../../../usecases/commons/hide-modal";

const categorizationRuleOptions = {
  merchantCategoryCode: "merchantCategoryCode",
  merchantId: "merchantId",
};

export default function CategorizeMerchantModal({
  categories,
  transactionId,
  action,
  isLoading,
}) {
  const [reason, setReason] = useState();
  const [category, setCategory] = useState("select");
  const [categorizationRule, setCategorizationRule] = useState(
    categorizationRuleOptions.merchantId
  );

  return (
    <div className="modal-body">
      <div className="modal-header mb-4">
        <h5 className="modal-title">Categorize merchant</h5>
      </div>

      <div className="container-fluid">
        <div className="form-group column">
          <label className="col-xs-12 col-form-label">Which merchant</label>
          <RadioGroup
            value={categorizationRule}
            onChange={(e) => setCategorizationRule(e.target.value)}
          >
            <FormControlLabel
              value={categorizationRuleOptions.merchantId}
              control={<Radio color="primary" />}
              label="Only this merchant (MerchantID)"
            />
            <FormControlLabel
              value={categorizationRuleOptions.merchantCategoryCode}
              control={<Radio color="primary" />}
              label="All related merchants (MCC)"
            />
          </RadioGroup>
          {categorizationRule ===
          categorizationRuleOptions.merchantCategoryCode ? (
            <div
              style={{
                background: warningLight2,
                padding: "28px",
                boxShadow: "0px 5.38728px 14.3661px rgba(162, 162, 162, 0.25)",
                borderRadius: "7px",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: error,
                maxWidth: "520px",
              }}
            >
              You will open all the merchants the work with this MCC. Make sure
              that this is what you want.<b> This action cannot be undone.</b>
              <br />
              Make sure you have your manager`s approval -
              <b>
                Due tu security reasons we will be saving the person that opens
                MCCs since it is an action the has a great impact. Use it
                wisely.
              </b>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="form-group column">
          <label className="col-xs-12 col-form-label">Merchant category</label>
          <div className="col-xs-12">
            <select
              className="custom-select text-capitalize"
              onChange={({ target: { value } }) => {
                setCategory(value);
              }}
              defaultValue="select"
              disabled={isLoading}
            >
              <option disabled value="select">
                Select a category...
              </option>
              {categories?.map(({ category: categoryItem }, i) => (
                <option
                  key={`merchant_category_${String(i)}`}
                  value={categoryItem}
                  className="text-capitalize"
                >
                  {categoryItem}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group column">
          <label className="col-xs-12 col-form-label">Reason</label>
          <div className="col-xs-12">
            <input
              type="text"
              className="form-control"
              placeholder="-"
              onChange={({ target: { value } }) => {
                setReason(value);
              }}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => hideModal()}
        >
          Cancel
        </button>
        <button
          className={`btn ${
            !reason || !category || category === "select" || isLoading
              ? "btn-light"
              : "btn-primary"
          }`}
          type="button"
          disabled={!reason || !category || category === "select" || isLoading}
          onClick={() => {
            action({ transactionId, category, reason, categorizationRule });
          }}
        >
          {isLoading && <ClipLoader size={16} color="#00458f" loading />} Save
          changes
        </button>
      </div>
    </div>
  );
}
