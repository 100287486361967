import React from "react";
import ReactDOM from "react-dom";

export async function hideModal() {
  renderHiddenModal();
}

function renderHiddenModal() {
  ReactDOM.render(<div />, document.getElementById("modal-container"));
}
