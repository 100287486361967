export const employeesColumns = [
  { label: "DNI/NIE", id: "legalId", searchable: true },
  { label: "Name", id: "completeName", searchable: true },
  { label: "Email", id: "email", searchable: true },
  { label: "Laboral days", id: "daysPerMonth" },
  { label: "", id: "action" },
];

export const keys = employeesColumns
  .filter(({ searchable }) => searchable)
  .reduce((acc, { id }) => ({ ...acc, [id]: id }), {});
