import LifeInsurancePolicyModality from "../LifeInsurancePolicyModality";

export default class LifeInsurancePolicy {
  companyId;

  brokers;

  insuranceBrand;

  selectedBroker;

  insuranceCompany;

  insurancePolicy;

  dateSignUp;

  firstEngagementPeriod;

  nextEngagementPeriod;

  allowSignOutsideContractPeriod;

  requiresEmployeeSignature;

  capitalTypes;

  modalities;

  constructor({
    companyId = "",
    brokers = [],
    insuranceBrand = "",
    selectedBroker = "",
    insuranceCompany = "",
    insurancePolicy = "",
    dateSignUp = undefined,
    firstEngagementPeriod = {
      from: undefined,
      to: undefined,
    },
    nextEngagementPeriod = {
      from: undefined,
      to: undefined,
    },
    allowSignOutsideContractPeriod = false,
    requiresEmployeeSignature = false,
    capitalTypes = ["fixed", "variable"],
    modalities = [new LifeInsurancePolicyModality({})],
  }) {
    this.companyId = companyId;
    this.brokers = brokers;
    this.insuranceBrand = insuranceBrand;
    this.selectedBroker = selectedBroker;
    this.insuranceCompany = insuranceCompany;
    this.insurancePolicy = insurancePolicy;
    this.dateSignUp = dateSignUp;
    this.firstEngagementPeriod = firstEngagementPeriod;
    this.nextEngagementPeriod = nextEngagementPeriod;
    this.allowSignOutsideContractPeriod = allowSignOutsideContractPeriod;
    this.requiresEmployeeSignature = requiresEmployeeSignature;
    this.capitalTypes = capitalTypes;
    this.modalities = modalities;
  }

  modBrokers = ({ brokers }) =>
    new LifeInsurancePolicy({
      ...this,
      brokers,
    });

  modSelectedBroker = ({ selectedBroker }) =>
    new LifeInsurancePolicy({
      ...this,
      selectedBroker,
    });

  modInsuranceBrand = ({ insuranceBrand }) =>
    new LifeInsurancePolicy({
      ...this,
      insuranceBrand,
    });

  modInsuranceCompany = ({ insuranceCompany }) =>
    new LifeInsurancePolicy({
      ...this,
      insuranceCompany,
    });

  modInsurancePolicy = ({ insurancePolicy }) =>
    new LifeInsurancePolicy({
      ...this,
      insurancePolicy,
    });

  modSignupDate = ({ dateSignUp }) =>
    new LifeInsurancePolicy({
      ...this,
      dateSignUp,
    });

  modFirstEngagementPeriod = ({ from, to }) =>
    new LifeInsurancePolicy({
      ...this,
      firstEngagementPeriod: {
        from,
        to,
      },
    });

  modNextEngagementPeriod = ({ from, to }) =>
    new LifeInsurancePolicy({
      ...this,
      nextEngagementPeriod: {
        from,
        to,
      },
    });

  modAllowSignOutside = ({ allowSignOutsideContractPeriod }) =>
    new LifeInsurancePolicy({
      ...this,
      allowSignOutsideContractPeriod,
    });

  modRequiresEmployeeSignature = ({ requiresEmployeeSignature }) =>
    new LifeInsurancePolicy({
      ...this,
      requiresEmployeeSignature,
    });

  modModality = ({ modality, index }) =>
    new LifeInsurancePolicy({
      ...this,
      modalities: this.modalities.map((existentModality, i) =>
        i === index ? modality : existentModality
      ),
    });

  addModality = () =>
    new LifeInsurancePolicy({
      ...this,
      modalities: [...this.modalities, new LifeInsurancePolicyModality({})],
    });

  removeModality = ({ index }) =>
    new LifeInsurancePolicy({
      ...this,
      modalities: this.modalities.filter((a, i) => i !== index),
    });
}
