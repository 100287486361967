import React, { useState, useEffect } from "react";
import ContentWrapper from "infrastructure/renders/ContentWrapper";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CobeeDatePicker from "app/components/CobeeDatePicker";
import CobeeButton from "app/components/CobeeButton";
import { v4 } from "uuid";
import { showErrorMessage } from "usecases/commons/show-error-message";
import downloadContributionsFile from "usecases/contributions/downLoadContributionsFile";
import useLoadCompanies from "./hooks/useLoadCompanies";
import { benefits } from "./config";
import useStyles from "./styles";

export default function Contributions() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedBenefit, setSelectedBenefit] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [queryObject, setQueryObject] = useState({
    selectedCompany,
    selectedBenefit,
    dateFrom,
    dateTo,
  });

  const { loaderContainer, filterField } = useStyles();

  const { companies } = useLoadCompanies({ setIsLoading });

  useEffect(() => {
    setQueryObject({
      selectedCompany,
      selectedBenefit,
      dateFrom,
      dateTo,
    });
  }, [setQueryObject, selectedCompany, selectedBenefit, dateFrom, dateTo]);

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await downloadContributionsFile({ queryObject, selectedBenefit });
    } catch (err) {
      showErrorMessage(err?.response?.statusText || "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentWrapper title="Download contributions">
      {isLoading ? (
        <Grid
          item
          container
          xs={12}
          justify="center"
          className={loaderContainer}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Company:</Typography>
            <Select
              value={selectedCompany}
              className={filterField}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {companies.map(({ name, value }) => (
                <MenuItem key={v4()} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Benefit:</Typography>
            <Select
              value={selectedBenefit}
              className={filterField}
              onChange={(e) => setSelectedBenefit(e.target.value)}
            >
              {benefits.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">From:</Typography>
            <CobeeDatePicker
              selectedDate={dateFrom}
              onChange={(e) => setDateFrom(e)}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">To:</Typography>
            <CobeeDatePicker
              selectedDate={dateTo}
              onChange={(e) => setDateTo(e)}
            />
          </Grid>
          <Grid item xs={2} container alignItems="flex-end">
            <CobeeButton
              disabled={selectedBenefit === ""}
              isLoading={isLoading}
              onClick={handleDownload}
            >
              Download contributions
            </CobeeButton>
          </Grid>
        </Grid>
      )}
    </ContentWrapper>
  );
}
