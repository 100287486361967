import { fileDownloadHandler } from "../../usecases/commons/file-download-handler";
import { parseToCsv } from "../isValidCSV";

export default async function generateCsv({ title, headers, rows }) {
  const parseData = parseToCsv({
    headers,
    rows,
  });
  await fileDownloadHandler(title, parseData);
}
