import React from "react";
import PropTypes from "prop-types";

const BInputForm = ({
  label,
  labelStyle,
  inputStyle,
  placeholder,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label className={`${labelStyle} col-form-label col-form-label-sm`}>
          {label}
        </label>
      )}
      <div className={inputStyle}>
        <input
          className="form-control form-control-sm"
          placeholder={placeholder || label}
          {...rest}
        />
      </div>
    </>
  );
};

BInputForm.defaultProps = {
  label: "",
  labelStyle: "col-sm-2",
  inputStyle: "col-sm-2",
  placeholder: "",
};

BInputForm.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  placeholder: PropTypes.string,
};

export default BInputForm;
