import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    padding: "20px",
  },
  sectionTitle: {
    fontWeight: "bold",
  },
}));

export default useStyles;
