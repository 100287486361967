/**
 * Adds a year (YYYY) in front of a MM-DD string
 * @param {object} engagementPeriod
 * @returns {object} An engagement period with dates in YYYY-MM-DD format
 */
export const prependYearToPartialDate = ({ from, to }) => {
  const year = new Date().getFullYear();
  return { from: `${year}-${from}`, to: `${year}-${to}` };
};

/**
 * Removes the year from a YYYY-MM-DD to form a MM-DD string
 * @param {object} engagementPeriod
 * @returns {object} An engagement period with dates in MM-DD format
 */
export const trimYearOntoPartialDate = ({ from, to }) => {
  return { from: from.substring(5), to: to.substring(5) };
};
