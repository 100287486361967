import React from "react";

const EmptyHealthInsurances = ({ handlerCancel }) => {
  return (
    <main className="container-fluid">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <h5 className="card-title">Insurance Products:</h5>
            </div>
            <div className="col-12">
              <p className="card-text">
                This company does not yet have any health insurance
              </p>
            </div>
          </div>
          <div className="row float-right mr-2">
            <button className="btn btn-secondary" onClick={handlerCancel}>
              Back
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmptyHealthInsurances;
