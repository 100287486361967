import React from "react";
import ReactDOM from "react-dom";
import CompensationDetail from "../transactions/components/CompensationDetail";
import Modal from "../../app/components/modals/Modal";

export function renderTransactionCompensateModal({ trx }) {
  ReactDOM.render(
    <Modal>
      <CompensationDetail trx={trx} />
    </Modal>,
    document.getElementById("modal-container")
  );
}

export async function displayTransactionCompensateModal({ trx }) {
  renderTransactionCompensateModal({ trx });
}
