import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import EmployeesPerModel from "./components/EmployeesPerModel";
import useStyles from "./styles";

export default function BenefitModelEmployeesDetails(props) {
  const { container, sectionTitle } = useStyles();
  const { employees } = props;
  return (
    employees.length > 0 && (
      <Grid className={container}>
        <Typography className={sectionTitle}>
          Employees in benefit model
        </Typography>
        <EmployeesPerModel {...props} />
      </Grid>
    )
  );
}

BenefitModelEmployeesDetails.propTypes = {
  companyId: PropTypes.string.isRequired,
  employees: PropTypes.array,
};

BenefitModelEmployeesDetails.defaultProps = {
  employees: [],
};
