import XLSX from "xlsx";

export default function downloadCensusFailures({
  data,
  id,
  censusType,
  company,
}) {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Census",
    Subject: "Census",
    Author: "Backoffice",
    CreatedDate: new Date(2017, 12, 19),
  };

  wb.SheetNames.push("Census");

  if (data?.length > 0) {
    wb.Sheets.Census = XLSX.utils.json_to_sheet(
      data
        .filter(({ errors }) => errors?.length > 0)
        .map(({ data: dataFilter, errors }) => ({
          ...dataFilter,
          errors: errors.reduce(
            (acc, { code, field }) => `${acc} ${field}: ${code} `,
            ""
          ),
        }))
    );
    XLSX.writeFile(wb, `census-${censusType}-failures.xlsx`);
  }
  window.localStorage.setItem("lastDownloadedCensusFailures", id);
  window.location.replace(
    `/census-status?id=${id}&censusType=${censusType}&company=${company}`
  );
}
