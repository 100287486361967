import moment from "moment";
import { cachedT } from "../../adaptExcelPayload";

export const parseWellnessFailures = (data) => {
  return data.map(
    ({
      data: {
        serviceName,
        employeeLegalId,
        applicant: {
          type,
          subtype,
          name,
          surname,
          birthDate,
          legalId,
          homeAddress: {
            streetName,
            streetNumber,
            postalCode,
            province,
            locality,
            details,
          },
        },
        startDayOfYear,
      },
      ...rest
    }) => {
      return {
        data: {
          [cachedT.ProductId]: serviceName,
          [cachedT.EmployeeId]: employeeLegalId,
          [cachedT.Type]: type,
          [cachedT.Subtype]: subtype,
          [cachedT.ApplicantId]: legalId,
          [cachedT.ApplicantName]: name,
          [cachedT.ApplicantSurnames]: surname,
          [cachedT.ServiceEffectiveDate]: moment(startDayOfYear).format(
            "DD/MM/YYYY"
          ),
          [cachedT.ApplicantBirthDate]: moment(birthDate).format("DD/MM/YYYY"),
          [cachedT.ApplicantAddressStreetName]: streetName,
          [cachedT.ApplicantAddressStreetNumber]: streetNumber,
          [cachedT.ApplicantAddressPostalCode]: postalCode,
          [cachedT.ApplicantAddressProvince]: province,
          [cachedT.ApplicantAddressLocality]: locality,
          [cachedT.ApplicantAddressDetails]: details,
        },
        ...rest,
      };
    }
  );
};
