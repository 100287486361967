import showMentalWellnessDetails from "usecases/companies/mentalWellness/showMentalWellnessDetails";
import healthInsuranceImage from "../../../assets/img/health-insurance.jpg";
import retirementInsuranceImg from "../../../assets/img/retirement-insurance.png";
import lifeInsuranceImg from "../../../assets/img/life-insurance.png";
import iFeelImg from "../../../assets/img/ifeel-benefit.png";
import rentingImg from "../../../assets/img/ilustracion-renting.png";
import allExpenseImg from "../../../assets/img/all-expense-benefit.png";
import trainingImg from "../../../assets/img/education-benefit.png";
import pensionImg from "../../../assets/img/pension-plan.png";
import { HealthInsurance } from "../../../usecases/companies/displays/health-insurance";
import { HealthInsuranceDetails } from "../../../usecases/companies/displays/health-insurance-details";
import { addMentalWellnessService } from "../../../usecases/companies/mentalWellness/addMentalWellnessService";

const benefitsConfig = [
  {
    name: "Health insurance",
    id: "health-insurance-benefit",
    bgImage: healthInsuranceImage,
    toggable: false,
    options: [
      {
        label: "Show details",
        action: ({ company }) => {
          // Old action
          // HealthInsuranceDetails({ company })
          window.location.href = `/companies/${company.id}/health-insurance-overview`;
        },
      },
      {
        label: "Upload census",
        action: ({ company }) =>
          window.location.replace(
            `/upload-census-file?censusType=health-insurance-benefit&company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "Life insurance",
    id: "life-insurance-benefit",
    bgImage: lifeInsuranceImg,
    toggable: true,
    options: [
      {
        label: "Add insurance",
        action: ({ company }) =>
          window.location.replace(`/add-life-insurance?company=${company.id}`),
      },
      {
        label: "Upload census",
        action: ({ company }) =>
          window.location.replace(
            `/upload-census-file?censusType=life-insurance-benefit&company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "Renting",
    id: "renting-benefit",
    bgImage: rentingImg,
    toggable: true,
    options: [
      {
        label: "Configure",
        action: ({ company }) =>
          window.location.replace(
            `/benefit-config/renting-benefit?companyId=${company.id}`
          ),
      },
      {
        label: "Upload census",
        action: ({ company }) =>
          window.location.replace(
            `/upload-census-file?censusType=renting-benefit&company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "Training",
    id: "education-benefit",
    bgImage: trainingImg,
    toggable: false,
    options: [
      {
        label: "Upload census",
        action: ({ company }) =>
          window.location.replace(
            `/upload-census-file?censusType=education-benefit&company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "All Expense",
    id: "unknown",
    toggable: true,
    bgImage: allExpenseImg,
    options: [],
  },
  {
    name: "Wellness",
    id: "wellness-benefit",
    bgImage: iFeelImg,
    options: [
      {
        label: "Show details",
        action: ({ company }) => showMentalWellnessDetails({ company }),
      },
      {
        label: "Add products",
        action: ({ company }) => addMentalWellnessService({ company }),
      },
      {
        label: "Upload census",
        action: ({ company }) =>
          window.location.replace(
            `/upload-census-file?censusType=wellness-benefit&company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "Retirement insurance",
    id: "retirement-insurance",
    bgImage: retirementInsuranceImg,
    toggable: true,
    options: [
      {
        label: "View details",
        action: ({ company }) =>
          window.location.replace(
            `/companies/benefit-config/retirement-insurance?company=${company.id}`
          ),
      },
    ],
  },
  {
    name: "Pension plans",
    id: "pension-plan",
    bgImage: pensionImg,
    toggable: true,
    options: [
      {
        label: "View details",
        action: ({ company }) =>
          window.location.replace(
            `/companies/benefit-config/pension-plan-benefit?company=${company.id}`
          ),
      },
    ],
  },
];

export default benefitsConfig;
