import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import CobeeButton from "../../../../app/components/CobeeButton";
import { hideModal } from "../../../commons/hide-modal";

export default function CancelEducationModal({ callback }) {
  const { container, row, buttonRow, rowDescription } = useStyles();
  const [values, setValues] = useState({
    reason: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const formIsReady = () => {
    return Boolean(values.reason);
  };

  return (
    <Modal title="Cancel Education">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={rowDescription}>
          <Typography variant="body2">
            Are you sure you want to remove the education? ONLY NON-REPORTED
            transactions will be cancelled, but no new payments will be made
            after the current payroll closes
          </Typography>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row}>
            <Typography variant="body2">Reason:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              type="text"
              disabled={isLoading}
              minWidth="100%"
              defaultValue={values.reason}
              onChange={(e) =>
                setValues({
                  ...values,
                  reason: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            variant="danger"
            disabled={isLoading || !formIsReady()}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(values);
              setIsLoading(false);
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
