import {
  deleteHealthInsurance,
  createHealthInsurance,
  HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE,
} from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";

export async function editHealthInsurance({
  employee,
  insuredId,
  modalityId,
  complementsIds,
  effectiveDate,
  companyId,
}) {
  if (!employee || !insuredId || !modalityId || !effectiveDate) {
    showErrorMessage("Missing compulsory parameters", false);
  }

  const resDelete = await deleteHealthInsurance(
    employee.id,
    insuredId,
    "Health insurance updated from Back Office"
  );

  if (!resDelete.data.success) {
    showErrorMessage(`Error: ${resDelete.data.reason}`);
    displayEmployeeDetail(employee.email);
  } else {
    try {
      await createHealthInsurance({
        employeeId: employee.id,
        insuredId,
        modalityId,
        complementsIds,
        effectiveDate,
        companyId,
      });
      showSuccessMessage("Change saved!");
      displayEmployeeDetail(employee.email);
    } catch (error) {
      if (
        error?.response?.data?.code === HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE
      ) {
        showErrorMessage(
          "You may not request the Health Insurance due to fiscal limits"
        );
      } else {
        showErrorMessage(
          `Error: ${error?.response?.data?.message}` || "Error!",
          false
        );
      }
    }
  }
}
