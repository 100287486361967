import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, TextField } from "@material-ui/core";
import CobeeTextField from "app/components/CobeeTextField";
import { useFormContext } from "react-hook-form";
import parentStyles from "../../styles";

export default function OtherLanguageRetirementForm({ selectedLanguage }) {
  const { fullWidth } = parentStyles();
  const { watch, setValue } = useFormContext();
  const languageInfo = watch(selectedLanguage);

  return (
    <>
      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Product name</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeTextField
            type="text"
            minWidth={200}
            value={languageInfo?.name}
            className={fullWidth}
            onChange={(e) =>
              setValue(`${selectedLanguage}.name`, e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Description</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            multiline
            rows={5}
            value={languageInfo?.description}
            className={fullWidth}
            variant="outlined"
            onChange={(e) =>
              setValue(`${selectedLanguage}.description`, e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Brochure link</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeTextField
            type="text"
            minWidth={200}
            value={languageInfo?.attachments?.url}
            className={fullWidth}
            onChange={(e) =>
              setValue(`${selectedLanguage}.attachments.url`, e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Brochure title</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeTextField
            type="text"
            minWidth={200}
            value={languageInfo?.attachments?.title}
            className={fullWidth}
            onChange={(e) =>
              setValue(`${selectedLanguage}.attachments.title`, e.target.value)
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

OtherLanguageRetirementForm.propTypes = {
  selectedLanguage: PropTypes.string,
};

OtherLanguageRetirementForm.defaultProps = {
  selectedLanguage: "es",
};
