import { restoreNurseriesRequests } from "../../infrastructure/adapters/adaptNurseries/restore-nurseries-requests";
import { findNurseriesRequests } from "../../infrastructure/nursery-repository";
import { showErrorMessage } from "../commons/show-error-message";

export const fetchNurseriesRequests = async () => {
  try {
    const {
      data: {
        content: { recurrentPayments },
      },
    } = await findNurseriesRequests();
    const adaptedData = recurrentPayments.map((item) =>
      restoreNurseriesRequests(item)
    );
    return adaptedData;
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message || "Error fetching nurseries requests"
    );
    return {};
  }
};
