import React from "react";
import { Route } from "react-router-dom";
import auth0Client from "../auth/Auth";

export default function PrivateRoute({ component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth0Client.isAuthenticated() ? (
          React.createElement(render || component, props)
        ) : (
          <div>{auth0Client.logIn(props.history)}</div>
        )
      }
    />
  );
}
