export default function adaptEditRentingSteps({
  details,
  enabled,
  linkMoreInfo,
  steps,
}) {
  return {
    details,
    enabled,
    linkMoreInfo,
    requestSteps: steps,
  };
}
