import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Grid } from "@material-ui/core";
import { showErrorMessage } from "usecases/commons/show-error-message";
import useStyles, { baseStyle } from "./styles";

const fileTypes = {
  spreadsheet:
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  censusType:
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export default function DropZone({
  onChange,
  acceptedFileTypes,
  maxFiles = 1,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept: fileTypes[acceptedFileTypes],
    onDropRejected: () =>
      showErrorMessage("Invalid type file, please upload a xlsx"),
  });
  const { container, dropzoneText } = useStyles({
    hasFiles: acceptedFiles.length > 0,
  });

  useEffect(() => {
    onChange(acceptedFiles);
  }, [acceptedFiles, onChange]);

  return (
    <Grid item container xs={12} className={container} alignItems="center">
      <div {...getRootProps({ className: `dropzone`, style: baseStyle })}>
        <input {...getInputProps()} />
        <p className={dropzoneText}>
          {acceptedFiles.length > 0
            ? acceptedFiles.reduce(
                (acc, { path }, i) =>
                  `${acc} ${path}${i === acceptedFiles.length - 1 ? "" : ", "}`,
                ""
              )
            : `Drag 'n' drop your file here, or click to select files.`}
        </p>
      </div>
    </Grid>
  );
}

DropZone.propTypes = {
  onChange: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string,
  maxFiles: PropTypes.number,
};

DropZone.defaultProps = {
  acceptedFileTypes: "spreadsheet",
  maxFiles: 1,
};
