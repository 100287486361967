import React from "react";
import ReactDOM from "react-dom";
import ModifyRecurrentPaymentDatesModal from "./index";
import { displayEmployeeDetail } from "../../../display-employee-detail";
import { editRecurrentPayment } from "../../../../../infrastructure/recurrent-payments-repository";
import { showSuccessMessage } from "../../../../commons/show-success-message";
import { showErrorMessage } from "../../../../commons/show-error-message";

export default function renderModifyRecurrentPaymentDatesModal({
  recurrentPayment,
}) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <ModifyRecurrentPaymentDatesModal
        recurrentPayment={recurrentPayment}
        callback={async (values) => {
          try {
            await editRecurrentPayment({
              recurrentPaymentId: recurrentPayment.id,
              effectiveSince: values.effectiveSince,
              firstExecutionDate: values.firstExecutionDate,
              nextExecutionDate: values.nextExecutionDate,
              lastExecutionDate: values.lastExecutionDate,
            });
            showSuccessMessage("Recurrent payment edited successfully");
          } catch (e) {
            showErrorMessage(
              "An error has occurred while editing recurrent payment"
            );
          }
        }}
        postActionCallback={() =>
          displayEmployeeDetail(recurrentPayment.employee.email, "")
        }
      />,
      domTarget
    );
  }
}
