import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Paper } from "@cobee/cobee-ui";
import { Fieldset } from "v2/ui/components/Fieldset";
import { listComplements } from "../../../../../../../domain/contexts/healthInsurance/useCases/listComplements";
import { ComplementsTable } from "../ComplementsTable";

export const ComplementsOverview = () => {
  const { companyId } = useParams();
  const { data, isFetching, isLoading } = useQuery(
    ["complements", companyId],
    () => {
      return listComplements({
        Company: { id: companyId },
      });
    },
    {
      initialData: [],
    }
  );

  if (isFetching || isLoading) {
    return (
      <Paper>
        <Fieldset legend="Complements">loading...</Fieldset>
      </Paper>
    );
  }

  if (!data) {
    return (
      <Paper>
        <Fieldset legend="Complements">Not found!</Fieldset>
      </Paper>
    );
  }

  return (
    <Paper>
      <Fieldset legend="Complements">
        <ComplementsTable data={data} />
      </Fieldset>
    </Paper>
  );
};
