import moment from "moment";

export default function adaptContributionsQuery({
  selectedCompany,
  dateFrom,
  dateTo,
}) {
  return {
    companyId: selectedCompany,
    fromDate: moment(dateFrom).format("YYYY-MM-DD"),
    toDate: moment(dateTo).format("YYYY-MM-DD"),
  };
}
