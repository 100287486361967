import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    backgroundImage: ({ bgImage }) => `url(${bgImage})`,
    height: 100,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));

export default useStyles;
