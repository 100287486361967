export default function searchInKeys({ search, items, keys }) {
  if (search.length > 0 && items.length > 0) {
    return items.filter((item) =>
      Object.keys(item).reduce((acc, key) => {
        const propertyExistsInItem = key in keys;
        const propertyIsAString = typeof item[key] === "string";
        const searchMatches =
          propertyIsAString &&
          key in item &&
          item[key]?.toLowerCase().includes(search.toLowerCase());
        return acc || (propertyExistsInItem && searchMatches);
      }, false)
    );
  }
  return items;
}
