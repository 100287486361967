import { makeStyles } from "@material-ui/core/styles";
import { transparentize } from "polished";
import { secondary, primary } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& .MuiTablePagination-caption:first-of-type": {},
  },
  container: {
    "& table": {
      margin: 0,
    },
  },
  header: {
    "& th": {
      backgroundColor: primary,
    },
  },
  cell: {
    height: 50,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  tableRow: {
    height: 50,

    "&.Mui-selected": {
      backgroundColor: transparentize(0.9, secondary),
    },
  },
  paddingRightForCell: {
    paddingRight: theme.spacing(2),
  },
}));

export default useStyles;
