import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";
import { filterTransactions } from "../../filter-transactions";
import "../../../../index.css";
import Table from "../../../../app/components/Table";
import CobeeButton from "../../../../app/components/CobeeButton";
import useStyles from "./styles";

export default function UnknownNotificationsTable({
  notifications,
  unknownSelected,
}) {
  const [companyName, setCompanyName] = useState("");
  const [processorTransactionId, setProcessorTransactionId] = useState("");
  const [currency, setCurrency] = useState("");
  const { title } = useStyles();
  const [unknownTransactionSelected, setUnknownTransactionSelected] = useState(
    unknownSelected
  );

  const isSelectedRow = (unknownId) =>
    unknownTransactionSelected?.unknownProcessorNotificationId === unknownId;

  return (
    <main className="container-fluid">
      <Typography variant="h5" className={title}>
        Unknown Refunds
      </Typography>
      <div className="form-group row">
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Company name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Processor transaction id"
            onChange={(e) => setProcessorTransactionId(e.target.value)}
          />
        </div>
        <div className="col-sm-3 mb-2">
          <select
            className="form-control"
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option selected value="">
              All Countries
            </option>
            <option value="MXN">Mexico</option>
            <option value="EUR">ES & PT</option>
          </select>
        </div>
      </div>
      {notifications.length === 0 ? (
        <Typography variant="body1" className="text-center">
          There are currently no unknown transactions
        </Typography>
      ) : (
        <Table
          columns={[
            { label: "Processor transaction id", id: "transactionId" },
            { label: "Concept", id: "concept" },
            { label: "Company", id: "company" },
            { label: "Amount", id: "amount" },
            { label: "Initial Balance", id: "initial" },
            { label: "Final Balance", id: "final" },
            { label: "Date", id: "date" },
            { label: "Card Id", id: "cardId" },
            { label: "Actions", id: "actions" },
          ]}
          data={notifications
            .filter(
              (td) =>
                !companyName ||
                td.company?.name?.match(new RegExp(companyName, "gi"))
            )
            .filter(
              (td) =>
                !processorTransactionId ||
                td.transactionId?.match(
                  new RegExp(`^${processorTransactionId}`, "i")
                )
            )
            .filter((td) => !currency || td.amount?.currency === currency)
            .map((notification) => ({
              ...notification,
              isSelected: isSelectedRow(
                notification.unknownProcessorNotificationId
              ),
              amount: MonetaryAmount.ofCurrency(
                notification.amount
              ).prettifyMoney({}),
              company: notification.company?.name || "",
              initial: MonetaryAmount.ofCurrency(
                notification.initialAccountBalance
              ).prettifyMoney({}),
              final: MonetaryAmount.ofCurrency(
                notification.finalAccountBalance
              ).prettifyMoney({}),
              date: moment(notification.localIsoTime).format(
                "DD-MM-YYYY HH:mm"
              ),
              cardId: notification.cardId,
              actions: (
                <>
                  <CobeeButton
                    disabled={isSelectedRow(
                      notification.unknownProcessorNotificationId
                    )}
                    onClick={async () => {
                      await filterTransactions({
                        numMp: notification.cardId,
                        unknownSelected: notification,
                      });
                      setUnknownTransactionSelected(notification);
                    }}
                  >
                    Link
                  </CobeeButton>
                </>
              ),
            }))}
        />
      )}
      <div id="transaction-detail" />
    </main>
  );
}

UnknownNotificationsTable.defaultProps = {
  notifications: [],
  unknownSelected: {},
};

UnknownNotificationsTable.propTypes = {
  notifications: PropTypes.array,
  unknownSelected: PropTypes.object,
};
