import React, { Component } from "react";
import { getCsvTransfers } from "../getCsvTransfers";
import { transferMoney } from "../../../infrastructure/company-repository";
import { showErrorMessage } from "../../commons/show-error-message";
import { showSuccessMessage } from "../../commons/show-success-message";

export default class TransferSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      transfers: null,
      file: null,
      fileName: null,
    };
  }

  async handleUploadFile(e) {
    const file = e.target.files[0];
    const fileName = file.name;
    this.setState({ file, fileName });
    const csvProperties = await getCsvTransfers(file);

    if (csvProperties.errorMessage !== "") {
      this.upload.value = null;
      this.setState({ file: null, fileName: null, password: "" });
    }

    if (csvProperties.wrongTransfer.length > 0) {
      this.upload.value = null;
    }
    if (csvProperties.adaptedTransfers.length > 0) {
      this.setState({ transfers: csvProperties.adaptedTransfers });
    }
  }

  async handleTransfer(e) {
    const res = await transferMoney({
      password: this.state.password,
      transfers: this.state.transfers,
    });
    if (!res) {
      showErrorMessage("Wrong. Some Data are not correct");
    } else {
      showSuccessMessage("File send successfully, please check your email");
    }
  }

  render() {
    const { password, file, fileName } = this.state;
    return (
      <main className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Transfer money</h5>
            <p className="card-text" />
            <div className="form-group row">
              <div className="d-flex justify-content-start align-items-center align-self-center">
                <div className="input-group p-3">
                  <div className="custom-file">
                    <input
                      id="transfers-upload"
                      ref={(ref) => {
                        this.upload = ref;
                      }}
                      type="file"
                      accept="text/csv"
                      className="custom-file-input"
                      aria-describedby="transfers-upload"
                      onChange={(e) => this.handleUploadFile(e)}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="transfers-upload"
                    >
                      {fileName || "Choose file"}
                    </label>
                  </div>
                </div>
                <div className="input-group p-3">
                  <input
                    type="password"
                    className="form-control"
                    aria-label="Password"
                    placeholder="Password"
                    aria-describedby="inputGroup-sizing-default"
                    value={password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <div className="p-2">
                  <button
                    className="btn btn-primary mb-2"
                    disabled={!file || !password}
                    onClick={() => this.handleTransfer()}
                  >
                    Execute
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
