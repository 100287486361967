import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { primary, secondary } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  row: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  actionRow: {
    padding: theme.spacing(2),

    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  errorMessage: {
    maxWidth: 200,
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  select: {
    width: "80%",
  },
  currencyContainer: {
    paddingLeft: theme.spacing(1),
  },
  divider: {
    height: 1,
    backgroundColor: primary,
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export const CustomCheckbox = withStyles({
  root: {
    color: secondary,
    "&$checked": {
      color: secondary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default useStyles;
