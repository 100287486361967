import React, { Component } from "react";
import moment from "moment";
import { v4 } from "uuid";
import { displayCompaniesPage } from "../display-companies-page";
import { saveInsuranceProduct } from "../health-insurance/save-insurance-product";

import { signUpInsuredCompany } from "../sign-up-insuranced-company";
import InsuranceComplements from "./healthInsurance/InsuranceComplements";
import InsuranceModalities from "./healthInsurance/InsuranceModalities";
import InsuranceISaludBrokerForm from "./healthInsurance/InsuranceISaludBrokerForm";

export default class HealthInsurancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalities: [
        {
          id: v4(),
          name: "",
          fee: 0,
          coverage: "",
          brochures: [{}, {}, {}],
          complementsSelected: [],
          insideSignatureDocuments: [{}, {}, {}],
          outsideSignatureDocuments: [{}, {}, {}],
        },
      ],
      complements: [],
      complementsOptions: [],
      date: moment().format("YYYY-MM-DD"),
      firstEngagementPeriods: [
        {
          from: moment().format("MM-DD"),
          to: moment().format("MM-DD"),
        },
      ],
      nextEngagementsPeriods: [
        {
          from: moment().format("MM-DD"),
          to: moment().format("MM-DD"),
        },
      ],
      allowRequestOutsideEngagement: false,
      allowExemptDomesticPartner: false,
      requiresEmployeeSignature: false,
      isInstantaneous: false,
      allowDifferentModalitiesPerFamilyMember: false,
      broker: "Select broker",
      companyPhone: null,
      isLoading: false,
      insuranceCompany: "",
      insurancePolicy: "",
    };
  }

  handlerSave = async () => {
    this.setState({ isLoading: true });
    const {
      insuranceCompany,
      broker,
      insurancePolicy,
      complements,
      date,
      firstEngagementPeriods,
      nextEngagementsPeriods,
      modalities,
      allowRequestOutsideEngagement,
      allowExemptDomesticPartner,
      requiresEmployeeSignature,
      isInstantaneous,
      companyPhone,
      allowDifferentModalitiesPerFamilyMember,
    } = this.state;

    const { company } = this.props;
    const dateSignUpTimestamp = moment(date, "YYYY/MM/DD").unix();

    modalities.forEach((m) => {
      m.brochures = m.brochures.filter((b) => b.title && b.url);
    });
    complements.forEach((c) => {
      c.brochures = c.brochures.filter((b) => b.title && b.url);
    });
    modalities.forEach((m) => {
      m.insideSignatureDocuments = m.insideSignatureDocuments.filter(
        (b) => b.title && b.url
      );
    });
    modalities.forEach((m) => {
      m.outsideSignatureDocuments = m.outsideSignatureDocuments.filter(
        (b) => b.title && b.url
      );
    });

    if (broker !== "iSalud") {
      await saveInsuranceProduct(company.id, {
        insuranceCompany,
        broker,
        insurancePolicy,
        dateSignUpTimestamp,
        firstEngagementPeriods,
        nextEngagementsPeriods,
        allowRequestOutsideEngagement,
        allowExemptDomesticPartner,
        requiresEmployeeSignature,
        isInstantaneous,
        allowDifferentModalitiesPerFamilyMember,
        modalities,
        complements,
      });
    } else {
      await signUpInsuredCompany(company.id, {
        broker,
        companyPhone,
      });
    }

    this.setState({ isLoading: false });
  };

  isComplementReady = (complement) => {
    return Boolean(complement.name);
  };

  handleChangeComplement = ({ value, label, id }) => {
    const { complements, modalities } = this.state;
    const currentComplements = [...complements];
    const currentModalities = [...modalities];
    currentComplements[id][label] = value;

    const currentComplementsOptions = complements
      .filter((c) => this.isComplementReady(c))
      .map((c) => ({
        label: c.name,
        value: c.id,
      }));
    const validValues = currentComplementsOptions.map((c) => c.value);

    if (!this.isComplementReady(currentComplements[id])) {
      // eslint-disable-next-line no-restricted-syntax
      for (const modality of currentModalities) {
        modality.complementsSelected = modality.complementsSelected.filter(
          (c) => validValues.includes(c)
        );
      }
    }

    this.setState({
      modalities: currentModalities,
      complements: currentComplements,
      complementsOptions: currentComplementsOptions,
    });
  };

  handleChangeComplementsBrochure = ({ label, value, brochureIndex, id }) => {
    const { complements } = this.state;
    const currentComplements = [...complements];

    // incomplete brochures get deleted
    if (!value) {
      currentComplements[id].brochures[brochureIndex][label] = {};
    }

    currentComplements[id].brochures[brochureIndex][label] = value;
    this.setState({ complements: currentComplements });
  };

  newComplement = () => {
    const { complements } = this.state;
    const id = v4();
    const newComplements = [
      ...complements,
      { id, name: "", fee: 0, coverage: "", brochures: [{}, {}, {}] },
    ];

    this.setState({
      complements: newComplements,
    });
  };

  handleChangeModality = ({ value, label, id }) => {
    const { modalities } = this.state;
    const currentModalities = [...modalities];
    currentModalities[id][label] = value;

    this.setState({ modalities: currentModalities });
  };

  handleChangeComplementsSelected = ({ value, id }) => {
    const { modalities } = this.state;
    const currentModalities = [...modalities];
    // incomplete brochures get deleted
    if (!value) {
      currentModalities[id].complementsSelected = [];
    }
    currentModalities[id].complementsSelected = value;

    this.setState({ modalities: currentModalities });
  };

  handleChangeBrochure = ({ label, value, brochureIndex, id }) => {
    const { modalities } = this.state;
    const currentModalities = [...modalities];
    // incomplete brochures get deleted
    if (!value) {
      currentModalities[id].brochures[brochureIndex][label] = {};
    }

    currentModalities[id].brochures[brochureIndex][label] = value;
    this.setState({ modalities: currentModalities });
  };

  handleChangeSignatureDocument = ({
    type,
    label,
    value,
    signatureIndex,
    id,
  }) => {
    const { modalities } = this.state;
    const currentModalities = [...modalities];
    // incomplete signatureDocument get deleted
    if (!value) {
      currentModalities[id][type][signatureIndex][label] = {};
    }

    currentModalities[id][type][signatureIndex][label] = value;
    this.setState({ modalities: currentModalities });
  };

  handleChangeDate = (date) => {
    this.setState({
      date,
    });
  };

  handleChangeFirstStartDate = (date, i) => {
    const { firstEngagementPeriods: firstPeriodsState } = this.state;
    const firstEngagementPeriods = [...firstPeriodsState];
    firstEngagementPeriods[i].from = moment(date, "YYYY-MM-DD").format("MM-DD");
    if (
      moment(firstEngagementPeriods[i].from, "MM-DD").isAfter(
        moment(firstEngagementPeriods[i].to, "MM-DD"),
        "day"
      )
    ) {
      firstEngagementPeriods[i].from = firstEngagementPeriods[i].to;
      alert("From date should be before than from date");
    }

    this.setState({ firstEngagementPeriods });
  };

  handleChangeFirstEndDate = (date, i) => {
    const { firstEngagementPeriods: firstPeriodsState } = this.state;
    const firstEngagementPeriods = [...firstPeriodsState];
    firstEngagementPeriods[i].to = moment(date, "YYYY-MM-DD").format("MM-DD");

    if (
      moment(firstEngagementPeriods[i].to, "MM-DD").isBefore(
        moment(firstEngagementPeriods[i].from, "MM-DD"),
        "day"
      )
    ) {
      firstEngagementPeriods[i].to = firstEngagementPeriods[i].from;
      alert("To date should be after than from date");
    }

    this.setState({ firstEngagementPeriods });
  };

  handleChangeNextStartDate = (date, i) => {
    const { nextEngagementsPeriods: nextPeriodsState } = this.state;
    const nextEngagementsPeriods = [...nextPeriodsState];
    nextEngagementsPeriods[i].from = moment(date, "YYYY-MM-DD").format("MM-DD");

    if (
      moment(nextEngagementsPeriods[i].from, "MM-DD").isAfter(
        moment(nextEngagementsPeriods[i].to, "MM-DD"),
        "day"
      )
    ) {
      nextEngagementsPeriods[i].from = nextEngagementsPeriods[i].to;
      alert("From date should be before than 'to' date");
    }
    this.setState({ nextEngagementsPeriods });
  };

  handleChangeNextEndDate = (date, i) => {
    const { nextEngagementsPeriods: nextPeriodsState } = this.state;
    const nextEngagementsPeriods = [...nextPeriodsState];
    nextEngagementsPeriods[i].to = moment(date, "YYYY-MM-DD").format("MM-DD");

    if (
      moment(nextEngagementsPeriods[i].to, "MM-DD").isBefore(
        moment(nextEngagementsPeriods[i].from, "MM-DD"),
        "day"
      )
    ) {
      nextEngagementsPeriods[i].to = nextEngagementsPeriods[i].from;
      alert("To date should be after than from date");
    }
    this.setState({ nextEngagementsPeriods });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.handlerSave();
  };

  newModality = () => {
    const { modalities: modalitiesState } = this.state;
    const modalities = [
      ...modalitiesState,
      {
        id: v4(),
        name: "",
        fee: 0,
        coverage: "",
        complementsSelected: [],
        brochures: [{}, {}, {}],
        insideSignatureDocuments: [{}, {}, {}],
        outsideSignatureDocuments: [{}, {}, {}],
      },
    ];
    this.setState({ modalities });
  };

  newFirstEngagementPeriod = () => {
    const { firstEngagementPeriods: firstPeriodsState } = this.state;
    const firstEngagementPeriods = [
      ...firstPeriodsState,
      {
        from: moment().format("MM-DD"),
        to: moment().format("MM-DD"),
      },
    ];
    this.setState({ firstEngagementPeriods });
  };

  newNextEngagementPeriod = () => {
    const { nextEngagementsPeriods: nextPeriodsState } = this.state;
    const nextEngagementsPeriods = [
      ...nextPeriodsState,
      {
        from: moment().format("MM-DD"),
        to: moment().format("MM-DD"),
      },
    ];
    this.setState({ nextEngagementsPeriods });
  };

  render() {
    const {
      modalities,
      complements,
      complementsOptions,
      firstEngagementPeriods,
      nextEngagementsPeriods,
      broker,
      isLoading,
      isInstantaneous,
      allowDifferentModalitiesPerFamilyMember,
      insuranceCompany,
      insurancePolicy,
    } = this.state;
    const { brokers } = this.props;
    const brokerNoSelected = broker === "Select broker";

    return (
      <main className="container-fluid">
        <div className="card">
          <form onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Broker</label>
                <div className="col-sm-10">
                  <select
                    onChange={(e) => this.setState({ broker: e.target.value })}
                  >
                    <option defaultValue>Select broker</option>
                    {brokers?.map((brokerItem) => (
                      <option key={brokerItem} value={brokerItem}>
                        {brokerItem}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {broker === "iSalud" && (
                <InsuranceISaludBrokerForm
                  handlerInput={({ property, value }) =>
                    this.setState({ [property]: [value] })
                  }
                />
              )}

              {broker !== "iSalud" && (
                <>
                  <div className="form-group row">
                    <label className="col-sm-4 col-md-2 col-form-label">
                      Insurance company:
                    </label>
                    <div className="col-sm-8 col-md-4">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Insurance company"
                        onChange={(e) =>
                          this.setState({ insuranceCompany: e.target.value })
                        }
                      />
                    </div>

                    <label className="col-sm-4 col-md-2 col-form-label">
                      Insurance policy:
                    </label>
                    <div className="col-sm-8 col-md-4">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Insurance policy"
                        onChange={(e) =>
                          this.setState({ insurancePolicy: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  {complements.map((complement, i) => {
                    return (
                      <InsuranceComplements
                        key={v4()}
                        complement={complement}
                        handleInput={({ value, label }) =>
                          this.handleChangeComplement({ value, label, id: i })
                        }
                        handleBrochureInput={({ value, label, index }) =>
                          this.handleChangeComplementsBrochure({
                            label,
                            value,
                            brochureIndex: index,
                            id: i,
                          })
                        }
                      />
                    );
                  })}

                  <div className="form-group row">
                    <div className="col-sm-4">
                      <button
                        className="btn btn-success"
                        type="button"
                        disabled={brokerNoSelected}
                        onClick={() => this.newComplement()}
                      >
                        Add Complement
                      </button>
                    </div>
                  </div>

                  <hr />
                  <br />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      Date sign up
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="date"
                        name="from"
                        onChange={(e) => this.handleChangeDate(e.target.value)}
                        value={this.state.date}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Allow Exempt to Domestic Partner
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        checked={this.state.allowExemptDomesticPartner}
                        onChange={() =>
                          this.setState((prev) => ({
                            allowExemptDomesticPartner: !prev.allowExemptDomesticPartner,
                          }))
                        }
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Allow Sign in outside engagement periods
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        checked={this.state.allowRequestOutsideEngagement}
                        onChange={() =>
                          this.setState((prev) => ({
                            allowRequestOutsideEngagement: !prev.allowRequestOutsideEngagement,
                          }))
                        }
                      />
                    </div>
                  </div>

                  <p>
                    <i>
                      {`If you want the engagement period to cross the year (i.e.
                      from 2019-12-20 to 2020-01-10) you will need to create two
                      periods, one in the "First Engagement Period", from
                      2019-12-20 to 2019-12-31, and other in the "Next
                      Engagement Period", from 2020-01-01 to 2020-01-10`}
                    </i>
                  </p>
                  {firstEngagementPeriods.map((period, i) => (
                    <div key={v4()}>
                      <div className="form-inline">
                        <label className="col-sm-2 col-form-label">
                          First Engagement Period
                        </label>
                        <div className="input-group mb-3 mx-sm-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              From:
                            </span>
                          </div>
                          <input
                            type="date"
                            name="from"
                            value={moment(
                              firstEngagementPeriods[i].from,
                              "MM-DD"
                            ).format("YYYY-MM-DD")}
                            onChange={(e) =>
                              this.handleChangeFirstStartDate(e.target.value, i)
                            }
                          />
                        </div>

                        <div className="input-group mb-3 mx-sm-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              To:
                            </span>
                          </div>
                          <input
                            type="date"
                            name="to"
                            value={moment(
                              firstEngagementPeriods[i].to,
                              "MM-DD"
                            ).format("YYYY-MM-DD")}
                            onChange={(e) =>
                              this.handleChangeFirstEndDate(e.target.value, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <button
                        className="btn btn-success"
                        type="button"
                        disabled={brokerNoSelected}
                        onClick={() => this.newFirstEngagementPeriod()}
                      >
                        Add First Period
                      </button>
                    </div>
                  </div>

                  {nextEngagementsPeriods.map((period, i) => (
                    <div key={v4()}>
                      <div className="form-inline">
                        <label className="col-sm-2 col-form-label">
                          Next Engagement Period
                        </label>
                        <div className="input-group mb-3 mx-sm-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id={`basic-addon-${i}`}
                            >
                              From:
                            </span>
                          </div>
                          <input
                            type="date"
                            name="from"
                            value={moment(
                              nextEngagementsPeriods[i].from,
                              "MM-DD"
                            ).format("YYYY-MM-DD")}
                            onChange={(e) =>
                              this.handleChangeNextStartDate(e.target.value, i)
                            }
                          />
                        </div>

                        <div className="input-group mb-3 mx-sm-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id={`basic-addon2-${i}`}
                            >
                              To:
                            </span>
                          </div>
                          <input
                            type="date"
                            name="to"
                            value={moment(
                              nextEngagementsPeriods[i].to,
                              "MM-DD"
                            ).format("YYYY-MM-DD")}
                            onChange={(e) =>
                              this.handleChangeNextEndDate(e.target.value, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <button
                        className="btn btn-success"
                        type="button"
                        disabled={brokerNoSelected}
                        onClick={() => this.newNextEngagementPeriod()}
                      >
                        Add Next Period
                      </button>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Requires employee signature
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        checked={this.state.requiresEmployeeSignature}
                        onChange={() =>
                          this.setState((prev) => ({
                            requiresEmployeeSignature: !prev.requiresEmployeeSignature,
                          }))
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Is instantaneous
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        checked={isInstantaneous}
                        onChange={() =>
                          this.setState({
                            isInstantaneous: !isInstantaneous,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Allow Family Modalities
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        checked={allowDifferentModalitiesPerFamilyMember}
                        onChange={() =>
                          this.setState({
                            allowDifferentModalitiesPerFamilyMember: !allowDifferentModalitiesPerFamilyMember,
                          })
                        }
                      />
                    </div>
                  </div>

                  <hr />
                  <br />

                  {modalities.map((modality, i) => {
                    return (
                      <InsuranceModalities
                        key={v4()}
                        modality={modality}
                        complementsOptions={complementsOptions}
                        modalitiesSelected={modalities[i].complementsSelected}
                        requiredSignature={this.state.requiresEmployeeSignature}
                        handleInput={({ value, label }) =>
                          this.handleChangeModality({ value, label, id: i })
                        }
                        handleChangeComplementSelected={({ value }) =>
                          this.handleChangeComplementsSelected({
                            value,
                            id: i,
                          })
                        }
                        handleSignature={({ type, label, value, index }) =>
                          this.handleChangeSignatureDocument({
                            type,
                            label,
                            value,
                            signatureIndex: index,
                            id: i,
                          })
                        }
                        handleChangeBrochure={({ label, value, index }) =>
                          this.handleChangeBrochure({
                            label,
                            value,
                            brochureIndex: index,
                            id: i,
                          })
                        }
                      />
                    );
                  })}

                  <div className="form-group row">
                    <div className="col-sm-4">
                      <button
                        className="btn btn-success"
                        type="button"
                        disabled={brokerNoSelected}
                        onClick={() => this.newModality()}
                      >
                        Add Modality
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="card-body">
              <div className="row buttonCenter">
                <button
                  type="button"
                  className="btn btn-primary mr-4"
                  disabled={
                    isLoading ||
                    brokerNoSelected ||
                    insuranceCompany === "" ||
                    insurancePolicy === ""
                  }
                  onClick={this.handlerSave}
                >
                  Create
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={displayCompaniesPage}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
