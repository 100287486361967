import { solveConfirmCancelledTrx } from "infrastructure/transaction-repository";
import { findConfirmVoidTransactions } from "infrastructure/unknown-notifications-repository";
import renderConfirmVoidTransactionsPage from "infrastructure/renders/ConfirmVoidTransactions/render-confirm-void-transactions";

import displayActionConfirmationModal from "usecases/commons/display-action-confirmation-modal";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { hideModal } from "usecases/commons/hide-modal";

export default function solveConfirmedVoidTransaction({
  cardId,
  transactionId,
  unknownProcessorNotificationId,
}) {
  const solveTransaction = async () => {
    try {
      const res = await solveConfirmCancelledTrx({
        payload: { cardId, transactionId, unknownProcessorNotificationId },
      });
      if (res.data.success === false) {
        showErrorMessage(res.data.error);
      }
      const { data } = await findConfirmVoidTransactions();
      const { allNotifications: tableData } = data;
      renderConfirmVoidTransactionsPage({ tableData });
    } catch (err) {
      showErrorMessage(err.response.data.message);
    } finally {
      hideModal();
    }
  };

  displayActionConfirmationModal({
    title: "Solve a confirmed and void transaction",
    message: "Are you sure you want to perform this action?",
    callback: solveTransaction,
  });
}
