import {
  findCountries,
  findRegionTaxRules,
} from "../../infrastructure/company-repository";
import renderCountryTaxRulesPage from "../../infrastructure/renders/CountryTaxRulesPage/render-country-tax-rules-page";
import TaxRule from "../../app/domain/TaxRule";
import restoreTaxRule from "../../infrastructure/adapters/restoreTaxRule";
import { showErrorMessage } from "../commons/show-error-message";

export default async function displayCountryTaxRulesPage({
  countries = [],
  selectedCountry = "",
  selectedRegion = "",
  rules = [],
}) {
  renderCountryTaxRulesPage({
    isLoading: true,
    defaultFilters: { selectedCountry, selectedRegion },
    rules,
    countries: [],
  });

  let fetchedCountries = countries;

  if (fetchedCountries.length === 0) {
    fetchedCountries = await findCountries();
    fetchedCountries = fetchedCountries.map((country) => ({
      ...country,
    }));
  }

  let fetchedRules = [];

  if (
    selectedCountry !== "" &&
    selectedRegion !== "" &&
    fetchedRules.length === 0
  ) {
    try {
      const { data } = await findRegionTaxRules({
        countryISO2Code: selectedCountry,
        region: selectedRegion,
      });
      const benefitTypes = data.data.reduce((acc, { categories }) => {
        if (acc.length === 0) {
          return [...categories];
        }
        return [
          ...acc,
          ...categories.filter(
            (category) => !acc.some((item) => item === category)
          ),
        ];
      }, []);
      fetchedRules = benefitTypes.map((benefitType) => ({
        benefitType,
        rules: data.data
          .filter(({ categories }) =>
            categories.some((category) => category === benefitType)
          )
          .map((rule) => new TaxRule(restoreTaxRule({ benefitType, ...rule }))),
      }));
    } catch (e) {
      showErrorMessage(e?.response?.data?.error);
    }
  }

  renderCountryTaxRulesPage({
    isLoading: false,
    countries: fetchedCountries,
    defaultFilters: { selectedCountry, selectedRegion },
    rules: fetchedRules,
  });
}
