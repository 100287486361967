import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";
import { showSuccessMessage } from "../usecases/commons/show-success-message";

const updateTransactionRepository = async ({ payload, transactionId }) => {
  try {
    await apiClient().put(`/v1/back-office/transactions/${transactionId}`, {
      ...payload,
    });
    return showSuccessMessage("Transaction modified successfully");
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export default updateTransactionRepository;
