import PropTypes from "prop-types";
import React from "react";
import { GenericLayout } from "@cobee/cobee-ui";
import { NavMenu } from "../../components/NavMenu";

export const DefaultLayout = ({ children }) => (
  <GenericLayout
    headerComponent={
      <div style={{ height: 56 }}>
        <NavMenu />
      </div>
    }
  >
    {children}
  </GenericLayout>
);

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired,
};
