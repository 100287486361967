import { Modality } from "../../../../domain/contexts/healthInsurance/entities/Modality";
import { restoreBrochure } from "./restoreBrochure";
import { restoreMoney } from "../../../common/builders/restoreMoney";
import { restorePeriod } from "./restorePeriod";
import { prependYearToPartialDate } from "../../../common/utils/partialDate";

export const restoreLooseModality = ({
  insuranceCompany,
  broker,
  insurancePolicy,
  dateSignUpTimestamp,
  modalityId,
  modalityName,
  modalityFee,
  allowRequestOutsideEngagement,
  requiresEmployeeSignature,
  allowExemptDomesticPartner,
  modalityCoverageDescription,
  modalityBrochures,
  insideSignatureDocuments,
  outsideSignatureDocuments,
  insuranceBrand,
  firstEngagementPeriod,
  nextEngagementsPeriod,
  complements,
  isInstantaneous,
  allowDifferentModalitiesPerFamilyMember,
  renewalPeriod,
  expiresAt,
  availableFrom,
  state,
  pastPeriods,
  futurePeriod,
  automaticRenewalEnabled,
  modalityCommissionRate,
}) =>
  new Modality({
    insuranceCompany,
    id: modalityId,
    name: modalityName,
    fee: modalityFee ? restoreMoney(modalityFee) : undefined,
    modalityCommissionRate: modalityCommissionRate
      ? modalityCommissionRate * 100
      : undefined,
    broker,
    insurancePolicy,
    signUpDate: dateSignUpTimestamp
      ? new Date(dateSignUpTimestamp * 1000)
      : undefined,
    allowRequestOutsideEngagement,
    // TODO: remove this when the API is fixed
    requiresEmployeeSignature: !!requiresEmployeeSignature,
    allowExemptDomesticPartner,
    coverageDescription: modalityCoverageDescription,
    brochures: modalityBrochures
      ? modalityBrochures.map(restoreBrochure)
      : undefined,
    insideSignatureDocuments: insideSignatureDocuments
      ? insideSignatureDocuments.map(restoreBrochure)
      : undefined,
    outsideSignatureDocuments: outsideSignatureDocuments
      ? outsideSignatureDocuments.map(restoreBrochure)
      : undefined,
    insuranceBrand,
    firstEngagementPeriod: firstEngagementPeriod
      ? firstEngagementPeriod.map((period) =>
          restorePeriod(prependYearToPartialDate(period))
        )
      : [],
    nextEngagementPeriods: nextEngagementsPeriod
      ? nextEngagementsPeriod.map((period) =>
          restorePeriod(prependYearToPartialDate(period))
        )
      : [],
    complements,
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    renewalPeriod: renewalPeriod ? restorePeriod(renewalPeriod) : {},
    expiresAt: expiresAt ? new Date(expiresAt) : undefined,
    availableFrom: availableFrom ? new Date(availableFrom) : undefined,
    state,
    pastPeriods: pastPeriods
      ? pastPeriods.map(restoreLooseModality)
      : undefined,
    futurePeriod: futurePeriod ? restoreLooseModality(futurePeriod) : undefined,
    automaticRenewalEnabled,
  });
