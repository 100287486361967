export const benefitLegacyCategoryToCurrentCategoryDictionary = {
  "meal-benefit": "meal",
  "transport-benefit": "transport",
  "nursery-benefit": "nursery",
  "education-benefit": "training",
  "health-insurance-benefit": "health-insurance",
  "gift-card-benefit": "discount",
  "renting-benefit": "renting",
  "life-insurance-benefit": "life-insurance",
  "wellness-benefit": "wellness",
  "retirement-insurance-benefit": "retirement-insurance",
  "pension-plan-benefit": "pension-plan",
  "gym-benefit": "gym-benefit",
  "it-product-benefit": "it-product",
  unknown: "all-expenses",
  "groceries-benefit": "groceries",
  "gas-benefit": "gas",
  "restaurants-benefit": "restaurants",
  "home-office-benefit": "home-office",
  "clothing-benefit": "clothing",
};

export const benefitCategoryToLegacyCategoryDictionary = Object.keys(
  benefitLegacyCategoryToCurrentCategoryDictionary
).reduce(
  (acc, curr) => ({
    ...acc,
    [benefitLegacyCategoryToCurrentCategoryDictionary[curr]]: curr,
  }),
  {}
);
