import React from "react";
import ReactDOM from "react-dom";
import CancelEducationModal from "./index";

export default function renderCancelEducationModal({ callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<CancelEducationModal callback={callback} />, domTarget);
  }
}
