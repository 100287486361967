import { getInvoices } from "../../infrastructure/finance-repository";
import { showErrorMessage } from "../commons/show-error-message";

export default async function findInvoices({ query }) {
  try {
    const {
      data: { content },
    } = await getInvoices({ query });
    return content;
  } catch (err) {
    showErrorMessage("PENDING ERROR MESSAGE FOR COB-2801");
  }
  return { data: [] };
}
