import React, { useEffect, useState } from "react";
import { Grid, Typography, Select, MenuItem, Chip } from "@material-ui/core";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import CobeeTextField from "../../CobeeTextField";
import regexTester from "../../../../infrastructure/regexTester";

export default function SendInvoiceModal({
  isLoading,
  callback,
  emails,
  companyHasBeenFiltered,
}) {
  const { container, row, buttonRow, chipRow, emailSelect } = useStyles();
  const [selectedEmails, setSelectedEmails] = useState(emails);
  const [inputValue, setInputValue] = useState("");
  const [additionalEmails, setAdditionalEmails] = useState([]);
  const [inputIsValid, setInputIsValid] = useState(true);

  useEffect(() => {
    setSelectedEmails(emails);
  }, [emails, setSelectedEmails]);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      if (
        regexTester({ value: e.target.value, type: "email" }) &&
        !additionalEmails.some((email) => email === e.target.value)
      ) {
        setAdditionalEmails((prev) => [...prev, e.target.value]);
        setInputValue("");
        setInputIsValid(true);
      } else {
        setInputIsValid(false);
      }
    }
  };

  const onDeleteAdditionalEmail = ({ email }) => {
    setAdditionalEmails((prev) => prev.filter((item) => item !== email));
  };

  const formIsReady = () => {
    if (emails.length === 0 && additionalEmails.length > 0) {
      return true;
    }
    if (
      emails.length > 0 &&
      (additionalEmails.length > 0 || selectedEmails.length > 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Send and resolve invoices</Typography>
      </Grid>

      {companyHasBeenFiltered ||
        (emails.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                Admin emails to send invoice to:
              </Typography>
            </Grid>
            <Grid item container xs={12} className={row}>
              <Select
                className={emailSelect}
                multiple
                value={selectedEmails}
                onChange={(e) => {
                  setSelectedEmails(e.target.value);
                }}
              >
                {emails.map((email, i) => (
                  <MenuItem key={`${email}-${String(i)}`} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        ))}

      <Grid item container xs={12}>
        <Typography variant="body1">
          {!companyHasBeenFiltered ? "E" : "Additional e"}mails to send it to:
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <CobeeTextField
          error={!inputIsValid}
          helperText={!inputIsValid && "Must be a valid email"}
          onKeyDown={onEnter}
          type="email"
          minWidth="100%"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Grid>

      {additionalEmails.length > 0 && (
        <Grid item container xs={12} className={`${row} ${chipRow}`}>
          {additionalEmails.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={() => onDeleteAdditionalEmail({ email })}
            />
          ))}
        </Grid>
      )}

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading || !formIsReady()}
          isLoading={isLoading}
          onClick={() => {
            callback({
              emails: [...new Set([...selectedEmails, ...additionalEmails])],
            });
          }}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
