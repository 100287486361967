import React from "react";
import ReactDOM from "react-dom";
import ActionConfirmationModal from "../../app/components/modals/ActionConfirmationModal";

function renderActionConfirmationModal(props) {
  ReactDOM.render(
    <ActionConfirmationModal {...props} />,
    document.getElementById("modal-container")
  );
}

export default function displayActionConfirmationModal(props) {
  renderActionConfirmationModal(props);
}
