import React from "react";
import { hideModal } from "../commons/hide-modal";

function ConfirmationModal({ message, onConfirm }) {
  return (
    <div className="modal-container">
      <div className="modal-content">
        <span className="message">{message}</span>
        <div className="cob-btn-group center-group">
          <button
            className="cob-btn btn-primary-outline"
            onClick={() => onConfirm() && hideModal()}
          >
            Confirm
          </button>
          <button
            className="cob-btn btn-primary-outline"
            onClick={() => hideModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
