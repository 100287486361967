import React, { Component } from "react";
import { displayEmployeeDetail } from "../display-employee-detail";

export default class EmployeesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      numMp: "",
      legalId: "",
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleSearch = () => {
    const { email, numMp, legalId } = this.state;

    displayEmployeeDetail(
      email && email.trim(),
      numMp && numMp.trim(),
      legalId && legalId.trim()
    );
    this.setState({ email: "", numMp: "", legalId: "" });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.handleSearch();
    }
  };

  render() {
    const { email, numMp, legalId } = this.state;
    return (
      <main className="container-fluid">
        <div className="card mb-2">
          <div className="card-body">
            <h5 className="card-title">Employees</h5>
            <p className="card-text">Search and modify employees</p>

            <div className="form-group row">
              <div className="col-sm-4 mb-2">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <input
                  type="numMP"
                  className="form-control"
                  placeholder="numMp"
                  value={numMp}
                  onChange={(e) => this.setState({ numMp: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <input
                  type="legalId"
                  className="form-control"
                  placeholder="legalId"
                  value={legalId}
                  onChange={(e) => this.setState({ legalId: e.target.value })}
                />
              </div>
              <button
                className="btn btn-primary mb-2"
                disabled={!email && !numMp && !legalId}
                onClick={() => this.handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div id="employee-detail" />
      </main>
    );
  }
}
