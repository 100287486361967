import { solveConfirmationAmountAboveAuthorized } from "../../infrastructure/transaction-repository";
import { displayTransactionsPage } from "./display-transactions-page";
import { hideModal } from "../commons/hide-modal";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";

export async function solveConfirmationAboveAuthorized({
  id,
  reason,
  password,
}) {
  try {
    const res = await solveConfirmationAmountAboveAuthorized({
      unknownProcessorNotificationId: id,
      solveReason: reason,
      password,
    });

    if (res) {
      showSuccessMessage("Solved Successfully");
      hideModal();
      await displayTransactionsPage();
    } else {
      showErrorMessage(`Error: ${res.data.statusCode} ${res.data.message}`);
    }
  } catch (error) {
    if (error.response) {
      showErrorMessage(
        `Error: ${error.response.status} ${error.response.data.message}`
      );
    } else {
      showErrorMessage(`Error: ${error.message}`);
    }
  }
}
