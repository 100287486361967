import React from "react";
import ReactDOM from "react-dom";
import DeactivateCardModal from "./components/DeactivateCardModal";

export default function renderDeactivateCardModal({ card, callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <DeactivateCardModal card={card} callback={callback} />,
      domTarget
    );
  }
}
