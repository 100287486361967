import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import Table from "../../../../app/components/Table";
import { columnConfig } from "./config";
import CobeeButton from "../../../../app/components/CobeeButton";
import { displayNurseryDetail } from "../../display-nursery-detail";
import NurseriesFilter from "../NurseriesFilter";
import searchInKeys from "../../../../services/searchInKeys";
import { keys } from "../NurseriesPage/config";

export default function NurseryList({ data, isLoading, handleUpdateState }) {
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const [processedData, setProcessedData] = useState([]);
  const [page, setPage] = useState(undefined);
  const [forcePagination, setForcePagination] = useState(false);

  const searchResults = useCallback(
    (employees) => {
      return searchInKeys({
        search,
        items: employees,
        keys: keys(columnConfig),
      });
    },
    [search]
  );

  useEffect(() => {
    setProcessedData(
      searchResults(
        filterValue === "all"
          ? data
          : data.filter(({ state }) => state === filterValue)
      )
    );
  }, [setProcessedData, search, filterValue, data, searchResults]);

  const getSecondActionButton = ({ nurseryId, state }) => {
    const stateActions = {
      "pending-acquiring": {
        label: "Next state",
        action: () => {
          handleUpdateState({ nurseryId, state: "acquiring" });
        },
      },
      acquiring: {
        label: "Acquired",
        action: () => {
          handleUpdateState({ nurseryId, state: "acquired" });
        },
      },
      "not-acquired": {
        label: "Acquired",
        action: () => {
          handleUpdateState({ nurseryId, state: "acquired" });
        },
      },
    };

    const actionInfo = stateActions[state];

    return actionInfo ? (
      <CobeeButton onClick={actionInfo.action}>{actionInfo.label}</CobeeButton>
    ) : null;
  };

  const getThirdActionButton = ({ nurseryId, state }) => {
    return state === "not-acquired" ? null : (
      <CobeeButton
        variant="danger"
        onClick={() => handleUpdateState({ nurseryId, state: "not-acquired" })}
      >
        Not acquired
      </CobeeButton>
    );
  };
  const showNurseryDetail = (id) => {
    const nursery = data.find(({ nurseryId }) => nurseryId === id);
    displayNurseryDetail({ nursery, handleUpdateState });
  };

  const handleSearch = debounce(1000, false, (value) => {
    setPage(value === "" ? undefined : 0);
    setForcePagination(value !== "");
    setSearch(value);
  });

  const handleFilter = (value) => {
    setPage(value === "all" ? undefined : 0);
    setForcePagination(value !== "all");
    setFilterValue(value);
  };

  return (
    <>
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <>
          <NurseriesFilter
            items={[
              { id: "pending-acquiring", name: "pending-acquiring" },
              { id: "acquiring", name: "acquiring" },
              { id: "acquired", name: "acquired" },
              { id: "not-acquired", name: "not-acquired" },
            ]}
            handleFilter={handleFilter}
            handleSearch={handleSearch}
          />
          <Table
            columns={columnConfig}
            data={[...processedData.reverse()].map(
              ({ nurseryId, address, contactEmail, stateHistory, ...rest }) => {
                const currentState =
                  stateHistory[stateHistory.length - 1].state;
                return {
                  address: `${address.streetName} ${address.postalCode} ,
            ${address.locality}, ${address.province}`,
                  email: contactEmail,
                  state: currentState,
                  actions: (
                    <Grid container spacing={2}>
                      <Grid item>
                        <CobeeButton
                          variant="secondary"
                          onClick={() => showNurseryDetail(nurseryId)}
                        >
                          Show Details
                        </CobeeButton>
                      </Grid>
                      <Grid item>
                        {getSecondActionButton({
                          nurseryId,
                          state: currentState,
                        })}
                      </Grid>
                      <Grid item>
                        {getThirdActionButton({
                          nurseryId,
                          state: currentState,
                        })}
                      </Grid>
                    </Grid>
                  ),
                  ...rest,
                };
              }
            )}
            pageNumber={page}
            forcePagination={forcePagination}
          />
        </>
      )}
    </>
  );
}

NurseryList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  handleUpdateState: PropTypes.func,
};

NurseryList.defaultProps = {
  isLoading: false,
  handleUpdateState: () => {},
};
