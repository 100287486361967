import axios from "axios";
import authClient from "../app/auth/Auth";
import { discoverService } from "../app/discover-service";

const apiClient = () => {
  const axiosInstance = axios.create({
    baseURL: discoverService("api"),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authClient.getAccessToken()}`,
    },
  });

  return {
    post: (path, body) => axiosInstance.post(path, body),
    put: (path, body) => axiosInstance.put(path, body),
    get: (path, config) => axiosInstance.get(path, config),
    delete: (path, body) => axiosInstance.delete(path, { data: body }),
    patch: (path, body) => axiosInstance.patch(path, body),
  };
};

export default apiClient;
