import convertSafely from "../../services/convertSafely";

export class MonetaryAmount {
  static defaultCurrency = "EUR";

  static ZERO = MonetaryAmount.of(0);

  static INFINITY = MonetaryAmount.of(Number.POSITIVE_INFINITY);

  amountInCents;

  currency;

  constructor(amountInCents, currency = "EUR") {
    if (
      (!Number.isSafeInteger(amountInCents) &&
        Number.isFinite(amountInCents)) ||
      Number.isNaN(amountInCents)
    ) {
      throw new Error("amountInCents must be an integer");
    }

    this.amountInCents = convertSafely({ value: amountInCents, type: Number });
    this.currency = convertSafely({
      value: currency,
      postProcess: (converted) => converted.toUpperCase(),
    });

    if (
      this.currency !== "EUR" &&
      this.currency !== "GBP" &&
      this.currency !== "MXN"
    )
      throw new Error("Invalid currency");
  }

  static fromUnitary(amount, currency = "EUR") {
    const cents = Math.round(amount * 100);
    return new MonetaryAmount(cents, currency);
  }

  static of(cents, currency = "EUR") {
    return new MonetaryAmount(cents, currency);
  }

  static ofCurrency({ amountInCents, currency }) {
    return new MonetaryAmount(amountInCents, currency);
  }

  static zeroWithCurrency(currency) {
    return new MonetaryAmount(0, currency);
  }

  zero() {
    return new MonetaryAmount(0, this.currency);
  }

  add(amount) {
    if (this.currency !== amount.currency)
      throw new Error("Incompatible currencies");

    return new MonetaryAmount(
      this.amountInCents + amount.amountInCents,
      this.currency
    );
  }

  subtract(amount) {
    if (this.currency !== amount.currency)
      throw new Error("Incompatible currencies");

    return new MonetaryAmount(
      this.amountInCents - amount.amountInCents,
      this.currency
    );
  }

  multiply(times) {
    // check rounding
    return new MonetaryAmount(
      Math.round(this.amountInCents * times),
      this.currency
    );
  }

  abs() {
    return new MonetaryAmount(Math.abs(this.amountInCents), this.currency);
  }

  negate() {
    return new MonetaryAmount(-this.amountInCents, this.currency);
  }

  toUnitary() {
    return +(this.amountInCents / 100).toFixed(2);
  }

  equal(amount) {
    return this.amountInCents === amount.amountInCents;
  }

  greaterThan(amount) {
    return this.amountInCents > amount.amountInCents;
  }

  greaterOrEqualThan(amount) {
    return this.amountInCents >= amount.amountInCents;
  }

  isZero() {
    return this.amountInCents === 0;
  }

  prettifyMoney({ maxDecimals = 2, langTag = "es" } = {}) {
    const formattedNumber = new Intl.NumberFormat(langTag, {
      style: "decimal",
      minimumFractionDigits: maxDecimals,
      maximumFractionDigits: maxDecimals,
      useGrouping: true,
    })
      .format(this.amountInCents / 100)
      .trim();
    return `${formattedNumber} ${this.currency}`;
  }
}
