import React from "react";

export default function List() {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title buttonRight">
          Corporations
          <div className="buttonRight" />
        </h5>
        <p>
          The corporation list must now be accessed by the{" "}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://cobee.retool.com/app/corporations"
            rel="noopener"
            target="_blank"
          >
            new backoffice
          </a>
        </p>
      </div>
    </div>
  );
}
