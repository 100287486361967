import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { findCompanyDetail } from "infrastructure/company-repository";
import {
  findBenefitModel,
  updateBenefitModel,
} from "infrastructure/benefit-models-repository";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { QueryProvider } from "v2/ui/providers/QueryProvider";
import { NavMenu } from "v2/ui/components/NavMenu";
import { Link, TextField, Typography } from "@material-ui/core";
import { MUI } from "@cobee/cobee-ui";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  generateBenefitModelDetailLink,
  generateCompanyDetailLink,
} from "../../../infrastructure/links";
import CobeeButton from "../../../app/components/CobeeButton";
import { showSuccessMessage } from "../../../usecases/commons/show-success-message";
import useStyles from "./styles";
import {
  getAccumulableTransactionCategories,
  getAllTransactionCategories,
  getCardTransactionCategories,
  TransactionCategory,
} from "../../../app/domain/transaction-category";
import Loader from "../../components/loader";

const benefitCanBeActivatedMap = {
  [TransactionCategory.HEALTH_INSURANCE_BENEFIT]: (company) =>
    company.insuranceProducts.length > 0,
  [TransactionCategory.RENTING_BENEFIT]: (company) =>
    company?.benefitConfigs[TransactionCategory.RENTING_BENEFIT]?.enabled,
  [TransactionCategory.LIFE_INSURANCE_BENEFIT]: (company) =>
    company?.benefitConfigs[TransactionCategory.LIFE_INSURANCE_BENEFIT]
      ?.enabled && company?.lifeInsurancePolicies?.length > 0,
  [TransactionCategory.WELLNESS_BENEFIT]: (company) =>
    company?.benefitConfigs[TransactionCategory.WELLNESS_BENEFIT]?.enabled &&
    company?.wellnessBenefits?.length > 0,
  [TransactionCategory.PENSION_PLAN]: (company) =>
    company?.pensionPlanBenefit.enabled,
  [TransactionCategory.RETIREMENT_INSURANCE]: (company) =>
    company?.retirementInsuranceBenefit.enabled,
};

export default function EditBenefitModelPage() {
  const { companyId, benefitModelId } = useParams();

  const S = useStyles();

  const {
    data: initialData,
    isLoading,
    isFetching,
    error: hasError,
  } = useQuery(
    ["companyBenefitModels", companyId, benefitModelId],
    async () => {
      const [company, benefitModel, benefits] = await Promise.all([
        findCompanyDetail(companyId),
        findBenefitModel({ companyId, benefitModelId }),
      ]);
      return {
        company,
        benefitModel,
      };
    },
    {
      onError(error) {
        showErrorMessage(
          error?.response?.data?.message || "Error loading company details"
        );
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      notExemptAllowedBenefits: [],
      daysPerMonth: 20,
      allowedBenefits: [],
      accumulationAllowed: [],
      disabledFlex: [],
    },
  });

  const transactionCategories = getAllTransactionCategories();

  useEffect(() => {
    if (initialData?.benefitModel) {
      setValue("name", initialData.benefitModel.name);
      setValue(
        "notExemptAllowedBenefits",
        initialData.benefitModel.notExemptAllowedBenefits
      );
      setValue("daysPerMonth", initialData.benefitModel.daysPerMonth);
      setValue("allowedBenefits", initialData.benefitModel.allowedBenefits);
      setValue(
        "accumulationAllowed",
        initialData.benefitModel.accumulationAllowed
      );
      setValue(
        "disabledFlex",
        transactionCategories.filter(
          (c) => initialData.benefitModel.disabledFlex[c]
        )
      );
    }
  }, [initialData, setValue, transactionCategories]);

  if (hasError) {
    return "Error";
  }

  if (isLoading || isFetching) {
    return <Loader active />;
  }

  const { benefitModel, company } = initialData;

  const cardTransactionCategories = getCardTransactionCategories();
  const accumulableTransactionCategories = getAccumulableTransactionCategories();

  const allowedBenefitOptions = transactionCategories.filter((category) => {
    const validator = benefitCanBeActivatedMap[category] || (() => true);
    return validator(company);
  });

  const onSubmit = async (formData) => {
    try {
      const transformedData = {
        ...formData,
        disabledFlex: transactionCategories.reduce((cur, category) => {
          cur[category] = formData.disabledFlex.includes(category);
          return cur;
        }, {}),
      };
      await updateBenefitModel({
        companyId,
        benefitModelId,
        update: transformedData,
      });
      showSuccessMessage("Operation succesful");
      window.location = generateBenefitModelDetailLink({
        companyId,
        benefitModelId,
      });
      return true;
    } catch (e) {
      showErrorMessage("An error ocurred");
      return false;
    }
  };

  return (
    <>
      <main className="container-fluid" id="page-container">
        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <Typography className="card-title">{benefitModel.name}</Typography>
            <Typography className="card-title">
              Company:{" "}
              <Link href={generateCompanyDetailLink({ companyId: company.id })}>
                {company.companyName}
              </Link>
            </Typography>
          </MUI.CardContent>
        </MUI.Card>

        <MUI.Card sx={{ mb: 3 }}>
          <MUI.CardContent>
            <MUI.Grid container item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)} className={S.form}>
                <MUI.Grid container item xs={12}>
                  <MUI.Grid item xs={12} className="mb-3">
                    <Controller
                      control={control}
                      name="name"
                      rules={{
                        required: "Field is required",
                      }}
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            name={name}
                            ref={ref}
                            type="text"
                            value={value}
                            required
                            fullWidth
                            onChange={onChange}
                            label="Name"
                            error={Boolean(error)}
                            helperText={error ? error.message : null}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={12} className="mb-3">
                    <Controller
                      control={control}
                      name="daysPerMonth"
                      rules={{
                        required: "Field is required",
                      }}
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            name={name}
                            ref={ref}
                            type="text"
                            value={value}
                            required
                            fullWidth
                            onChange={onChange}
                            label="Days Per Month"
                            error={Boolean(error)}
                            helperText={error ? error.message : null}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={12} className="mb-3" sx={{ pt: 5 }}>
                    <Controller
                      control={control}
                      name="allowedBenefits"
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <MUI.InputLabel>Allowed Benefits</MUI.InputLabel>
                          <MUI.Autocomplete
                            value={value}
                            isOptionEqualToValue={(option, formValue) =>
                              option === formValue
                            }
                            autoComplete
                            multiple
                            options={allowedBenefitOptions}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={12} className="mb-3" sx={{ pt: 5 }}>
                    <Controller
                      control={control}
                      name="accumulationAllowed"
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <MUI.InputLabel>Accumulation enabled</MUI.InputLabel>
                          <MUI.Autocomplete
                            value={value}
                            isOptionEqualToValue={(option, formValue) =>
                              option === formValue
                            }
                            autoComplete
                            multiple
                            options={accumulableTransactionCategories}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={12} className="mb-3" sx={{ pt: 5 }}>
                    <Controller
                      control={control}
                      name="disabledFlex"
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <MUI.InputLabel>Disabled Flex</MUI.InputLabel>
                          <MUI.Autocomplete
                            value={value}
                            isOptionEqualToValue={(option, formValue) =>
                              option === formValue
                            }
                            autoComplete
                            multiple
                            options={transactionCategories}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={12} className="mb-3" sx={{ pt: 5 }}>
                    <Controller
                      control={control}
                      name="notExemptAllowedBenefits"
                      render={({
                        field: { onChange, value, ref, name },
                        fieldState: { error },
                      }) => (
                        <>
                          <MUI.InputLabel>
                            Allow Not Exempt Consumptions In Benefits
                          </MUI.InputLabel>
                          <MUI.Autocomplete
                            value={value}
                            isOptionEqualToValue={(option, formValue) =>
                              option === formValue
                            }
                            autoComplete
                            multiple
                            options={cardTransactionCategories}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </>
                      )}
                    />
                  </MUI.Grid>
                  <MUI.Grid container item xs={12} justifyContent="flex-end">
                    <CobeeButton type="submit" isLoading={isSubmitting}>
                      Save Changes
                    </CobeeButton>
                  </MUI.Grid>
                </MUI.Grid>
              </form>
            </MUI.Grid>
          </MUI.CardContent>
        </MUI.Card>
      </main>
    </>
  );
}
