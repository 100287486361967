import apiClient from "./api-client";

export async function downloadCardProcessorLogs({
  companyId,
  employeeId,
  cardId,
}) {
  const res = await apiClient().get(`/v1/back-office/card-processor-logs`, {
    params: {
      companyId: companyId || undefined,
      employeeId: employeeId || undefined,
      cardId: cardId || undefined,
    },
  });
  return res.data;
}
