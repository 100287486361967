import moment from "moment";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";

export default function adaptLifeInsuranceCreationPayload({
  lifeInsurancePolicy: {
    insuranceBrand: brand,
    insuranceCompany,
    insurancePolicy,
    selectedBroker: broker,
    allowSignOutsideContractPeriod: allowRequestOutsideEngagement,
    dateSignUp: dateSignUpTimestamp,
    requiresEmployeeSignature,
    firstEngagementPeriod,
    nextEngagementPeriod,
    modalities,
  },
}) {
  return {
    insuranceCompany,
    insurancePolicy,
    broker,
    brand,
    allowRequestOutsideEngagement,
    dateSignUpTimestamp: moment(dateSignUpTimestamp).unix(),
    requiresEmployeeSignature,
    firstEngagementPeriod: [
      {
        from: moment(firstEngagementPeriod.from).format("MM-DD"),
        to: moment(firstEngagementPeriod.to).format("MM-DD"),
      },
    ],
    nextEngagementsPeriod: [
      {
        from: moment(nextEngagementPeriod.from).format("MM-DD"),
        to: moment(nextEngagementPeriod.to).format("MM-DD"),
      },
    ],
    modalities: modalities.map(
      ({ legalBrochures, capitalType, capitals, coverage, ...rest }) => ({
        brochures: legalBrochures.map(({ label, link }) => ({
          title: label,
          url: link,
        })),
        type: capitalType === "variable" ? "salary-ratio" : capitalType,
        coverageDescription: coverage,
        capitals: capitals.map(({ salaryRatio, capitalAmount }) =>
          capitalType === "variable"
            ? {
                employeeSalaryRatio: Number(salaryRatio) / 100,
                type: capitalType === "variable" ? "salary-ratio" : capitalType,
              }
            : {
                fixedAmount: MonetaryAmount.fromUnitary(capitalAmount),
                type: capitalType === "variable" ? "salary-ratio" : capitalType,
              }
        ),
        ...rest,
      })
    ),
  };
}
