import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import { useForm } from "react-hook-form";
import MassiveUploadFilter from "../../../../../app/components/MassiveUploadFilter";
import Table from "../../../../../app/components/Table";
import laborDaysFile from "../../../../../assets/templates/labor-days-template.xlsx";
import { employeesColumns, keys } from "./config";
import searchInKeys from "../../../../../services/searchInKeys";
import CobeeButton from "../../../../../app/components/CobeeButton";
import { showErrorMessage } from "../../../../../usecases/commons/show-error-message";
import { showSuccessMessage } from "../../../../../usecases/commons/show-success-message";
import editLaborDays from "../../../../../usecases/companies/edit-labor-days";
import uploadLaborDaysFromFile from "../../../../../usecases/companies/upload-labor-days-file";
import CobeeTextField from "../../../../../app/components/CobeeTextField";

export default function EmployeesPerModel({ companyId, employees }) {
  const [search, setSearch] = useState("");
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSavingLaboralDays, setIsSavingLaboralDays] = useState(false);
  const [updatedRow, setUpdatedRow] = useState("");

  const { register, getValues, setValue } = useForm();

  useEffect(() => {
    employees.forEach(({ legalId, daysPerMonth }) => {
      register(`labor-days-${legalId}`);
      setValue(`labor-days-${legalId}`, daysPerMonth);
    });
    setIsRegistered(true);
  }, [employees, register, setValue]);

  const employeesData = ({ data }) =>
    data.map(({ name, surname, daysPerMonth, legalId, ...rest }) => ({
      completeName: `${name} ${surname}`,
      legalId,
      daysPerMonth: (
        <CobeeTextField
          type="number"
          min={0}
          max={27}
          step="1"
          minWidth={100}
          styleType="small"
          defaultValue={getValues(`labor-days-${legalId}`)}
          onChange={(e) => {
            e.preventDefault();
            const {
              target: { value },
            } = e;
            setValue(`labor-days-${legalId}`, value);
          }}
        />
      ),
      action: (
        <CobeeButton
          isLoading={isSavingLaboralDays && legalId === updatedRow}
          onClick={async () => {
            setIsSavingLaboralDays(true);
            setUpdatedRow(legalId);
            const { data: dataResponse } = await editLaborDays({
              companyId,
              payload: [
                {
                  legalId,
                  laborDays: getValues(`labor-days-${legalId}`),
                },
              ],
            });
            setIsSavingLaboralDays(false);

            if (dataResponse?.content?.errors?.length > 0) {
              const errorData = dataResponse?.content?.errors[0];
              showErrorMessage(errorData?.error);
            } else if (dataResponse?.content?.errors?.length === 0) {
              showSuccessMessage("Successfully updated value");
            }
          }}
        >
          Save
        </CobeeButton>
      ),
      ...rest,
    }));

  const handleSearch = debounce(1000, false, (value) => {
    setSearch(value);
  });

  const searchResults = useCallback(
    (data) => {
      return searchInKeys({ search, items: data, keys });
    },
    [search]
  );

  const handleFileChange = async (e) => {
    const fileName = e.target.files[0];
    setIsProcessingFile(true);
    await uploadLaborDaysFromFile({
      companyId,
      fileName,
    });
    setIsProcessingFile(false);
  };

  return (
    <Grid>
      {isProcessingFile ? (
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <CircularProgress size={32} />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Processing file, please wait
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <MassiveUploadFilter
            handleDownload={() => {
              const link = document.createElement("a");
              link.download = "labor-days-template.xlsx";
              link.href = laborDaysFile;
              link.click();
            }}
            handleFileChange={handleFileChange}
            handleSearch={handleSearch}
          />
          {isRegistered && (
            <Table
              columns={employeesColumns}
              data={employeesData({ data: searchResults(employees) })}
            />
          )}
        </>
      )}
    </Grid>
  );
}

EmployeesPerModel.propTypes = {
  companyId: PropTypes.string.isRequired,
  employees: PropTypes.array,
};

EmployeesPerModel.defaultProps = {
  employees: [],
};
