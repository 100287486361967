export const parseTrainingFailures = (data) => {
  return data.map(
    ({
      data: {
        legalId,
        courseName,
        centreName,
        startDate,
        endDate,
        amount,
        fromFunds,
        splitBy,
      },
      ...rest
    }) => ({
      data: {
        "DNI Empleado": legalId,
        "Nombre del curso": courseName,
        "Centro formativo": centreName,
        "Fecha de inicio (aaaa-mm-aa)": startDate,
        "Fecha de último pago (aaaa-mm-aa)": endDate,
        "Importe del curso (€)": amount,
        "Importe subsidiado por la empresa (€)": fromFunds,
        "Nóminas en las que se fracciona el pago restante (#)": splitBy,
      },
      ...rest,
    })
  );
};
