import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { useForm, useFieldArray } from "react-hook-form";
import { v4 } from "uuid";
import useStyles from "./styles";
import CobeeButton from "../../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../../app/components/CobeeTextField";
import displayActionConfirmationModal from "../../../../commons/display-action-confirmation-modal";

const dummyLanguages = [
  { label: "Spanish", id: "es" },
  { label: "Portuguese", id: "pt" },
];

const alternateLanguageColors = [
  "#70D6FF",
  "#FF70A6",
  "#FFD670",
  "#FF9770",
  "#E9FF70",
];

export default function WellnessPage({
  company,
  vendors,
  isLoading,
  onSave,
  currentData,
}) {
  const [isLoadedCurrentData, setIsLoadedCurrentData] = useState(true);

  const [selectedLanguage, setSelectedLanguage] = useState(
    dummyLanguages?.[0]?.id
  );

  const hasCurrentData = currentData !== null;

  const {
    cardContainer,
    row,
    inputLabel,
    select,
    modalityCard,
    fieldContainer,
    submitButtonContainer,
    errorCaption,
    defaultLanguageForm,
    languageForm,
    buttonSpaceFiller,
    textInput,
    modalList,
    mainContainer,
  } = useStyles({
    languageColor:
      alternateLanguageColors[
        dummyLanguages.map(({ id }) => id).indexOf(selectedLanguage)
      ],
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const vendor = register("vendorId");
  const vendorIsSelected =
    watch("vendorId") !== undefined && watch("vendorId") !== "";
  const benefitHasProducts = watch("products")?.length === 0;

  const getParseProducts = (products) =>
    products.map((product) => {
      const lang = product.languages;
      let finalProduct = { ...product };
      if (!("es" in lang)) {
        finalProduct = {
          ...product,
          languages: {
            ...lang,
            es: {
              name: undefined,
              description: undefined,
              attachments: [
                { title: "", url: "" },
                { title: "", url: "" },
                { title: "", url: "" },
              ],
            },
          },
        };
      }
      if (!("pt" in lang)) {
        finalProduct = {
          ...product,
          languages: {
            ...lang,
            pt: {
              name: undefined,
              description: undefined,
              attachments: [
                { title: "", url: "" },
                { title: "", url: "" },
                { title: "", url: "" },
              ],
            },
          },
        };
      }
      return finalProduct;
    });

  useEffect(() => {
    if (hasCurrentData && isLoadedCurrentData) {
      const { vendorId, products } = currentData;
      const parseProducts = getParseProducts(products);

      setValue("vendorId", vendorId);
      setValue("products", parseProducts);
      setIsLoadedCurrentData(false);
    }
  }, [hasCurrentData, currentData, setValue, isLoadedCurrentData]);

  const onSubmit = (data) => {
    const languageIds = dummyLanguages.map(({ id }) => id);
    const productLanguages = data.products.map(({ languages }) => languages);

    const incompleteVariants = productLanguages.map((langs) => ({
      name: langs.en.name,
      errors: languageIds.filter((langId) => {
        const { name, description, attachments } = langs[langId];
        return (
          name === undefined ||
          name === "" ||
          description === undefined ||
          description === "" ||
          attachments[0].title === undefined ||
          attachments[0].title === "" ||
          attachments[0].url === undefined ||
          attachments[0].url === ""
        );
      }),
    }));

    displayActionConfirmationModal({
      title: "Attention",
      message: (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Remember to fill the fields of the products language variants you
              want to be visible.
            </Typography>
          </Grid>
          <Grid item container xs={12} component="ul" className={modalList}>
            {incompleteVariants.map(({ name, errors: incomplete }) =>
              incomplete.length > 0 ? (
                <Typography variant="body1" key={name} component="li">
                  {name}: missing fields in variants{" "}
                  {incomplete.reduce((acc, curr) => `${acc} '${curr}'`, "")}.
                </Typography>
              ) : (
                ""
              )
            )}
          </Grid>
        </Grid>
      ),
      callback: async () => await onSave({ payload: data }),
    });
  };

  const getModalityError = ({ key }) => {
    let value = errors;
    key.split(".").forEach((attribute) => {
      value = value?.[attribute];
    });

    return value?.message ? (
      <FormHelperText className={errorCaption}>{value.message}</FormHelperText>
    ) : null;
  };

  const appendNewField = () => {
    const languageIds = dummyLanguages.map(({ id }) => id);
    const languageFields = {
      name: undefined,
      description: undefined,
      attachments: [],
    };
    const languageTemplates = languageIds.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: languageFields,
      }),
      { en: languageFields }
    );

    append({
      id: v4(),
      fee: 0,
      defaultLanguage: "en",
      languages: languageTemplates,
    });
  };

  const registerDynamicField = ({ formFields, index: i, isDefault }) => {
    formFields.forEach(({ label, options }) => {
      const value = watch(
        `products.${i}.${
          label === "fee"
            ? label
            : `languages.${isDefault ? "en" : selectedLanguage}.${label}`
        }`
      );
      if (value === undefined) {
        if (isDefault) {
          if (label === "fee") {
            register(`products.${i}.fee`, options);
            setValue(`products.${i}.fee`, 0);
          } else {
            register(`products.${i}.languages.en.${label}`, options);
            setValue(`products.${i}.languages.en.${label}`, "");
          }
        } else {
          register(
            `products.${i}.languages.${selectedLanguage}.${label}`,
            options
          );
          setValue(`products.${i}.languages.${selectedLanguage}.${label}`, "");
        }
      } else if (isDefault) {
        if (label === "fee") {
          register(`products.${i}.fee`, options);
        } else {
          register(`products.${i}.languages.en.${label}`, options);
        }
      }
    });
  };

  return (
    <main className={`container-fluid ${mainContainer}`}>
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container xs={12} className={cardContainer}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {`${
                  !hasCurrentData ? "New wellness" : "Wellness"
                } benefit for ${company.companyName}'s employees`}
              </Typography>
            </Grid>
            <Grid item container xs={12} className={row}>
              <Typography variant="body1" className={inputLabel}>
                Vendor:
              </Typography>
              <Select
                id="vendor-select"
                className={select}
                value={
                  vendors.find(({ id }) => id === watch("vendorId"))?.id || ""
                }
                inputProps={{ inputRef: vendor.ref }}
                onChange={(e) => setValue("vendorId", e.target.value)}
                disabled={isLoading || hasCurrentData}
              >
                <MenuItem value={undefined} disabled>
                  Select a vendor...
                </MenuItem>
                {vendors.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {fields.length > 0 && (
              <>
                <Grid item xs={12} className={row}>
                  <Typography variant="h5">Products:</Typography>
                </Grid>
                <Grid item xs={12} container className={row}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Default english version:
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="h6" className={inputLabel}>
                      Language variant:
                    </Typography>
                    <Select
                      className={select}
                      value={selectedLanguage}
                      onChange={(e) => {
                        setSelectedLanguage(e.target.value);
                      }}
                    >
                      {dummyLanguages.map(({ label, id }, i) => (
                        <MenuItem
                          key={id}
                          value={id}
                          style={{
                            backgroundColor: alternateLanguageColors[i],
                          }}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={6} container>
              {fields.map((field, i) => {
                registerDynamicField({
                  formFields: [
                    {
                      label: "name",
                      options: { required: "Field is required" },
                    },
                    {
                      label: "fee",
                      options: {
                        validate: (value) => {
                          return (
                            value > 0 || "Amount must be greater than 0 EUR"
                          );
                        },
                      },
                    },
                    {
                      label: "description",
                      options: { required: "Field is required" },
                    },
                    {
                      label: `attachments.${0}.title`,
                      options: { required: "Field is required" },
                    },
                    {
                      label: `attachments.${0}.url`,
                      options: { required: "Field is required" },
                    },
                    { label: `attachments.${1}.title` },
                    { label: `attachments.${1}.url` },
                    { label: `attachments.${2}.title` },
                    { label: `attachments.${2}.url` },
                  ],
                  index: i,
                  isDefault: true,
                });
                return (
                  <Grid
                    item
                    xs={12}
                    container
                    key={field.id}
                    className={`${modalityCard} ${defaultLanguageForm}`}
                  >
                    <Grid item xs={currentData?.products[i]?.code ? 6 : 12}>
                      <Typography variant="body1">#{i + 1}:</Typography>
                    </Grid>
                    {currentData?.products[i]?.code && (
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          Code: {currentData.products[i]?.code}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Name:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(`products.${i}.languages.en.name`)}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.name`,
                            e.target.value
                          )
                        }
                      />
                      {getModalityError({
                        key: `products.${i}.languages.en.name`,
                      })}
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Price:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="number"
                        min={0}
                        step="0.1"
                        value={watch(`products.${i}.fee`)}
                        onChange={(e) =>
                          setValue(`products.${i}.fee`, Number(e.target.value))
                        }
                        disabled={hasCurrentData && field.code !== undefined}
                      />
                      {getModalityError({
                        key: `products.${i}.fee`,
                      })}
                    </Grid>
                    <Grid item xs={12} className={fieldContainer}>
                      <Typography variant="body1">Description:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(`products.${i}.languages.en.description`)}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.description`,
                            e.target.value
                          )
                        }
                      />
                      {getModalityError({
                        key: `products.${i}.languages.en.description`,
                      })}
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Legal attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${0}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${0}.title`,
                            e.target.value
                          )
                        }
                      />
                      {getModalityError({
                        key: `products.${i}.languages.en.attachments.${0}.title`,
                      })}
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${0}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${0}.url`,
                            e.target.value
                          )
                        }
                      />
                      {getModalityError({
                        key: `products.${i}.languages.en.attachments.${0}.url`,
                      })}
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Commercial attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${1}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${1}.title`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${1}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${1}.url`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Commercial attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${2}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${2}.title`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.en.attachments.${2}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.en.attachments.${2}.url`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {!hasCurrentData ? (
                      <Grid
                        item
                        xs={12}
                        container
                        justify="flex-end"
                        className={row}
                      >
                        <CobeeButton
                          variant="secondary"
                          onClick={() => remove(i)}
                        >
                          Delete product
                        </CobeeButton>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        className={buttonSpaceFiller}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={6} container>
              {fields.map((field, i) => {
                registerDynamicField({
                  formFields: [
                    { label: "name" },
                    { label: "description" },
                    { label: `attachments.${0}.title` },
                    { label: `attachments.${0}.url` },
                    { label: `attachments.${1}.title` },
                    { label: `attachments.${1}.url` },
                    { label: `attachments.${2}.title` },
                    { label: `attachments.${2}.url` },
                  ],
                  index: i,
                  isDefault: false,
                });
                return (
                  <Grid
                    item
                    xs={12}
                    container
                    key={field.id}
                    className={`${modalityCard} ${languageForm}`}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Variant ({selectedLanguage}):
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={fieldContainer}>
                      <Typography variant="body1">Name:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.name`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.name`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className={fieldContainer}>
                      <Typography variant="body1">Description:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.description`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.description`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Legal attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${0}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${0}.title`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${0}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${0}.url`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Commercial attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${1}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${1}.title`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${1}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${1}.url`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">
                        Commercial attachments:
                      </Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${2}.title`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${2}.title`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} className={fieldContainer}>
                      <Typography variant="body1">Link:</Typography>
                      <CobeeTextField
                        className={textInput}
                        minWidth="100%"
                        type="text"
                        value={watch(
                          `products.${i}.languages.${selectedLanguage}.attachments.${2}.url`
                        )}
                        onChange={(e) =>
                          setValue(
                            `products.${i}.languages.${selectedLanguage}.attachments.${2}.url`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={buttonSpaceFiller}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={12} className={row} container justify="flex-end">
              <CobeeButton onClick={appendNewField}>
                Add a new product
              </CobeeButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={submitButtonContainer}
            container
            justify="flex-end"
          >
            <CobeeButton
              type="submit"
              disabled={isLoading || benefitHasProducts || !vendorIsSelected}
            >
              Save
            </CobeeButton>
          </Grid>
        </form>
      )}
    </main>
  );
}

WellnessPage.defaultProps = {
  isLoading: false,
  vendors: [],
  currentData: null,
};

WellnessPage.propTypes = {
  isLoading: PropTypes.bool,
  vendors: PropTypes.array,
  company: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  currentData: PropTypes.object,
};
