import React from "react";
import PropTypes from "prop-types";
import { Table, Column, AutoSizer } from "react-virtualized";
import { Grid, TableCell } from "@material-ui/core";
import useStyles from "./styles";
import "react-virtualized/styles.css";

export default function InfiniteTable({
  columns,
  data,
  totalItems = 100000,
  handleLoadMoreData = () => {},
  hasPagination,
  hasActions,
  emptyComponent,
  onRowClick,
}) {
  const { tableContainer, table, tableCell, lastTableCell } = useStyles({
    isEmpty: totalItems === 0,
    onRowClick,
  });

  const headerRenderer = ({ label }) => {
    return (
      label !== "" && (
        <TableCell component="div" variant="head">
          <span>{label}</span>
        </TableCell>
      )
    );
  };

  const cellRenderer = ({ cellData, columnIndex }) => {
    return (
      <TableCell
        component="div"
        title={typeof cellData === "string" ? cellData : undefined}
        variant="body"
        align={
          columnIndex < columns.length - 1 || !hasActions ? "left" : "right"
        }
        className={columnIndex < columns.length - 1 ? tableCell : lastTableCell}
      >
        {cellData}
      </TableCell>
    );
  };

  return (
    <Grid className={tableContainer}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            className={table}
            rowHeight={40}
            rowCount={data.length}
            width={width}
            height={height}
            headerHeight={40}
            rowGetter={({ index }) => data[index]}
            onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
              if (
                scrollHeight - clientHeight === scrollTop &&
                data.length < totalItems &&
                hasPagination
              ) {
                handleLoadMoreData();
              }
            }}
            noRowsRenderer={() => emptyComponent}
            onRowClick={onRowClick}
          >
            {columns.map(({ label, dataKey }) => (
              <Column
                key={dataKey}
                label={label}
                dataKey={dataKey}
                width={width}
                headerRenderer={headerRenderer}
                cellRenderer={cellRenderer}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Grid>
  );
}

InfiniteTable.propTypes = {
  totalItems: PropTypes.number,
  handleLoadMoreData: PropTypes.func,
  onRowClick: PropTypes.func,
  hasActions: PropTypes.bool,
  emptyComponent: PropTypes.node,
};

InfiniteTable.defaultProps = {
  totalItems: 0,
  handleLoadMoreData: () => {},
  onRowClick: undefined,
  hasActions: true,
  emptyComponent: <></>,
};
