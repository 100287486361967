export default class TaxRule {
  type;

  category;

  merchantCategories;

  behaviors;

  period;

  amount;

  minimumHourlyWage;

  percentageOfGrossPay;

  incapacityLevels;

  applicableOnlyForRecipient;

  constructor({
    type = "",
    category = "",
    merchantCategories = [],
    behaviors = [],
    period = "",
    amount = null,
    minimumHourlyWage = null,
    percentageOfGrossPay = null,
    incapacityLevels = null,
    applicableOnlyForRecipient = null,
  }) {
    this.type = type;
    this.category = category;
    this.merchantCategories = merchantCategories;
    this.behaviors = behaviors;
    this.period = period;
    this.amount = amount;
    this.minimumHourlyWage = minimumHourlyWage;
    this.percentageOfGrossPay = percentageOfGrossPay;
    this.incapacityLevels = incapacityLevels;
    this.applicableOnlyForRecipient = applicableOnlyForRecipient;
  }
}
