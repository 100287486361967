import React, { Component } from "react";
import { v4 } from "uuid";
import moment from "moment";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import { displayCompanyDetailsPage } from "../display-company-details";
import { updateHealthInsuranceEmails } from "../health-insurance/update-health-insurance-emails";
import { updateModalitiesComplements } from "../health-insurance/update-modalities-complements";
import { createModalityComplement } from "../health-insurance/create-modality-complement";
import { updateModality } from "../health-insurance/update-modality";
import InsuranceComplementsForm from "./healthInsurance/ComplementModalityForm";
import EmptyHealthInsurances from "./healthInsurance/EmptyHealthInsurances";
import ModalityForm from "./healthInsurance/ModalityForm";
import ModalityDetail from "./healthInsurance/ModalityDetails";
import EmailsInsuranceList from "./healthInsurance/EmailsInsuranceList";
import ComplementModalityDetail from "./healthInsurance/ComplementModalityDetail";

class InsuranceProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areEditingModality: null,
      areEditingComplement: null,
      showNewComplement: false,
      emailsList: [],
      newEmail: "",
      modalityTab: 0,
      modalitySelected: {},
      complementSelected: {},
      loading: false,
    };
  }

  componentDidMount() {
    const { insuranceRequestsEmailSubscribers } = this.props;
    this.setState({
      emailsList: insuranceRequestsEmailSubscribers,
    });
  }

  selectTab(index) {
    this.setState({ modalityTab: index, areEditingModality: null });
  }

  goBack = () => {
    const { companyId } = this.props;
    return displayCompanyDetailsPage(companyId);
  };

  updateEmailSubscribers = async (emails) => {
    const { companyId } = this.props;
    this.setState({ loading: true });

    await updateHealthInsuranceEmails({ companyId, emails });
    this.setState({ emailsList: emails, newEmail: "", loading: false });
  };

  saveEmailSubscribers = async () => {
    const { emailsList, newEmail } = this.state;
    const emails = [...emailsList, newEmail];
    await this.updateEmailSubscribers(emails);
  };

  deleteEmailSubscribers = async (email) => {
    const { emailsList } = this.state;
    const emails = emailsList.filter((e) => e !== email);
    await this.updateEmailSubscribers(emails);
  };

  editModality = (modalityId) => {
    const { insuranceProducts } = this.props;
    const { modalityTab } = this.state;

    this.setState({
      modalitySelected: insuranceProducts[modalityTab],
      areEditingModality: modalityId,
      showNewComplement: false,
    });
  };

  handlerInputs = ({ property, value }) => {
    const { modalitySelected } = this.state;
    this.setState({
      modalitySelected: {
        ...modalitySelected,
        [property]: value,
      },
    });
  };

  delDateEngagement = ({ index }) => {
    const { modalitySelected } = this.state;
    const dates = modalitySelected.nextEngagementsPeriod;
    dates.splice(index, 1);

    this.setState({
      modalitySelected: {
        ...modalitySelected,
        nextEngagementsPeriod: dates,
      },
    });
  };

  addDateEngagement = () => {
    const { modalitySelected } = this.state;
    this.setState({
      modalitySelected: {
        ...modalitySelected,
        nextEngagementsPeriod: [
          ...modalitySelected.nextEngagementsPeriod,
          {
            from: moment().format("MM-DD"),
            to: moment().format("MM-DD"),
          },
        ],
      },
    });
  };

  handlerInputsDate = ({ property, index, label, value }) => {
    const { modalitySelected } = this.state;
    const dates = modalitySelected[label];
    dates[index][property] = moment(value, "YYYY-MM-DD").format("MM-DD");

    this.setState({
      modalitySelected: {
        ...modalitySelected,
        [label]: dates,
      },
    });
  };

  handlerLinksModality = ({ index, property, label, value }) => {
    const { modalitySelected } = this.state;
    const currentBrochures = modalitySelected[label];
    currentBrochures[index][property] = value;

    this.setState({
      modalitySelected: {
        ...modalitySelected,
        [label]: currentBrochures,
      },
    });
  };

  handleAddLink = ({ label }) => {
    const { modalitySelected } = this.state;
    this.setState({
      modalitySelected: {
        ...modalitySelected,
        [label]: [
          ...modalitySelected[label],
          {
            title: "",
            url: "",
          },
        ],
      },
    });
  };

  handleDeleteLink = ({ label, index }) => {
    const { modalitySelected } = this.state;
    const links = modalitySelected[label];
    links.splice(index, 1);

    this.setState({
      modalitySelected: {
        ...modalitySelected,
        [label]: links,
      },
    });
  };

  handlerAddComplementToModality = (id) => {
    const { modalitySelected } = this.state;
    this.setState({
      modalitySelected: {
        ...modalitySelected,
        complements: [...modalitySelected.complements, id],
      },
    });
  };

  handlerDeleteComplementToModality = (id) => {
    const { modalitySelected } = this.state;
    const complementsFilter = modalitySelected.complements.filter(
      (complementId) => complementId !== id
    );
    const complementsDetailsFilter = modalitySelected.complementsDetails.filter(
      (complement) => complement.complementId !== id
    );

    this.setState({
      modalitySelected: {
        ...modalitySelected,
        complements: complementsFilter,
        complementsDetails: complementsDetailsFilter,
      },
    });
  };

  saveModality = async () => {
    const { companyId } = this.props;
    const { modalitySelected } = this.state;
    this.setState({ loading: true });
    if (modalitySelected.modalityId) {
      delete modalitySelected.complementsDetails;
      const auxModalitySelected = { ...modalitySelected };
      if (auxModalitySelected.requiresEmployeeSignature === null) {
        auxModalitySelected.requiresEmployeeSignature = false;
      }
      await updateModality({ companyId, modality: auxModalitySelected });
    }
    this.setState({ loading: false, areEditingModality: null });
  };

  editComplement = (complementId) => {
    const { healthInsuranceComplements } = this.props;
    const complement = healthInsuranceComplements.find(
      (item) => item.complementId === complementId
    );
    this.setState({
      complementSelected: complement,
      areEditingComplement: complementId,
      showNewComplement: false,
    });
  };

  handlerInputComplement = ({ property, value }) => {
    const { complementSelected } = this.state;
    this.setState({
      complementSelected: {
        ...complementSelected,
        [property]: value,
      },
    });
  };

  handlerInputComplementBrochure = ({ index, property, value }) => {
    const { complementSelected } = this.state;
    let currentBrochures = complementSelected.complementBrochures;
    if (currentBrochures.length > 0) {
      currentBrochures[index][property] = value;
    } else {
      currentBrochures = [
        {
          [property]: value,
        },
      ];
    }
    this.setState({
      complementSelected: {
        ...complementSelected,
        complementBrochures: currentBrochures,
      },
    });
  };

  saveComplement = async () => {
    const { companyId } = this.props;
    const { complementSelected } = this.state;
    this.setState({ loading: true });
    if (complementSelected.complementId) {
      await updateModalitiesComplements({
        companyId,
        complement: complementSelected,
      });
    } else {
      await createModalityComplement({
        companyId,
        complement: complementSelected,
      });
    }

    this.setState({
      loading: false,
      areEditingComplement: null,
      showNewComplement: false,
    });
  };

  createComplement = () => {
    const { showNewComplement } = this.state;
    this.setState({
      areEditingComplement: null,
      complementSelected: {
        complementName: "",
        complementFee: MonetaryAmount.ZERO,
        complementCoverage: "",
        complementBrochures: [],
      },
      showNewComplement: !showNewComplement,
    });
  };

  render() {
    const {
      insuranceProducts,
      healthInsuranceComplements,
      brokers,
    } = this.props;
    const {
      areEditingModality,
      areEditingComplement,
      showNewComplement,
      modalityTab,
      modalitySelected,
      complementSelected,
      emailsList,
      newEmail,
      loading,
    } = this.state;

    if (insuranceProducts.length === 0) {
      return <EmptyHealthInsurances handlerCancel={this.goBack} />;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const insuranceProduct of insuranceProducts) {
      insuranceProduct.complementsDetails = insuranceProduct.complements.map(
        (complementId) => {
          return healthInsuranceComplements.find(
            (complement) => complement.complementId === complementId
          );
        }
      );
    }

    const insurance = insuranceProducts[modalityTab];
    return (
      <main className="container-fluid">
        {emailsList.length > 0 && (
          <EmailsInsuranceList
            emails={emailsList}
            isLoading={loading}
            newEmail={newEmail}
            deleteEmail={(email) => this.deleteEmailSubscribers(email)}
            addEmail={this.saveEmailSubscribers}
            handlerInput={(value) => this.setState({ newEmail: value })}
          />
        )}

        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title">Modalities:</h5>

            <ul className="nav nav-tabs card-header-tabs">
              {insuranceProducts.map((item, i) => (
                <li className="nav-item" key={v4()}>
                  <button
                    className={`nav-link ${modalityTab === i && "active"}`}
                    onClick={() => this.selectTab(i)}
                  >
                    {item.modalityName}
                  </button>
                </li>
              ))}
            </ul>

            {areEditingModality === insurance.modalityId ? (
              <ModalityForm
                key={modalitySelected.modalityId}
                insurance={modalitySelected}
                brokers={brokers}
                healthInsuranceComplements={healthInsuranceComplements}
                disabled={loading}
                onChange={(change) => this.handlerInputs(change)}
                onChangeDate={(change) => this.handlerInputsDate(change)}
                addEngagement={this.addDateEngagement}
                deleteEngagement={(change) => this.delDateEngagement(change)}
                onChangeLink={(change) => this.handlerLinksModality(change)}
                addLink={(change) => this.handleAddLink(change)}
                deleteLink={(change) => this.handleDeleteLink(change)}
                addComplement={(complementId) =>
                  this.handlerAddComplementToModality(complementId)
                }
                deleteComplement={(complementId) =>
                  this.handlerDeleteComplementToModality(complementId)
                }
                handlerSave={this.saveModality}
                handlerCancel={() =>
                  this.setState({ areEditingModality: null })
                }
              />
            ) : (
              <ModalityDetail
                key={insurance.modalityId}
                insurance={insurance}
                handlerEdit={() => this.editModality(insurance.modalityId)}
              />
            )}
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title">Complements of company:</h5>
            <p className="card-text">
              <i>
                Remember that if you modify a complement, the change will be
                applied to all the modalities that are using it.
              </i>
            </p>
            {healthInsuranceComplements.length > 0 && (
              <div className="form-group row">
                <div className="col-sm-12">
                  {healthInsuranceComplements.map((complement) => {
                    if (areEditingComplement === complement.complementId) {
                      return (
                        <InsuranceComplementsForm
                          key={complement.complementId}
                          complement={complementSelected}
                          brokers={brokers}
                          disabled={loading}
                          handleInput={({ property, value }) =>
                            this.handlerInputComplement({ property, value })
                          }
                          handleBrochureInput={({ index, property, value }) =>
                            this.handlerInputComplementBrochure({
                              index,
                              property,
                              value,
                            })
                          }
                          handlerCancel={() =>
                            this.setState({ areEditingComplement: null })
                          }
                          handlerSave={this.saveComplement}
                        />
                      );
                    }
                    return (
                      <ComplementModalityDetail
                        key={complement.complementId}
                        complement={complement}
                        disabled={loading}
                        handlerEdit={() =>
                          this.editComplement(complement.complementId)
                        }
                      />
                    );
                  })}
                </div>

                {showNewComplement && (
                  <div className="col-sm-12">
                    <InsuranceComplementsForm
                      complement={complementSelected}
                      brokers={brokers}
                      disabled={loading}
                      handleInput={({ property, value }) =>
                        this.handlerInputComplement({ property, value })
                      }
                      handleBrochureInput={({ index, property, value }) =>
                        this.handlerInputComplementBrochure({
                          index,
                          property,
                          value,
                        })
                      }
                      handlerCancel={() =>
                        this.setState({ showNewComplement: false })
                      }
                      handlerSave={this.saveComplement}
                    />
                  </div>
                )}
              </div>
            )}

            {!showNewComplement && (
              <div className="form-group">
                <div className="gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={this.createComplement}
                    disabled={loading}
                  >
                    Add complement
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <button className="btn btn-secondary" onClick={this.goBack}>
              {`<- Back to company`}
            </button>
          </div>
        </div>
      </main>
    );
  }
}

export default InsuranceProducts;
