import { refundAmountNursery } from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { displayTransactionsTable } from "./display-transactions-table";

export async function refundNursery({ transactionId, amount, email, numMp }) {
  try {
    if (!amount || amount === "" || amount.trim() === "") {
      showErrorMessage("Write amount please");
    } else {
      const res = await refundAmountNursery({ transactionId, amount });
      if (res.data.success) {
        showSuccessMessage("Successfully refund!");
        displayTransactionsTable({ email, numMp });
      } else {
        showErrorMessage(`Error: ${res.data.error}`);
      }
    }
  } catch (e) {
    showErrorMessage("Error in nursery refund");
  }
}
