import { removeFromPecunpay } from "../../infrastructure/employee-repository";
import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { displayEmployeeDetail } from "./display-employee-detail";

export default async function removeEmployeeFromPecunpay({
  employee,
  email,
  numMp,
}) {
  const { id } = employee;
  const { failedEmployees } = await removeFromPecunpay(id);
  if (failedEmployees.length === 0) {
    showSuccessMessage("Change saved!");
    displayEmployeeDetail(email, numMp);
  } else {
    const {
      error: { message },
    } = failedEmployees[0];
    showErrorMessage(message);
  }
}
