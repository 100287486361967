import React, { useState } from "react";
import { displayTransactionsTable } from "../../display-transactions-table";

export default function EmployeeTransactions() {
  const [email, setEmail] = useState("");
  const [numMp, setNumMp] = useState("");

  const handleSearch = () => {
    displayTransactionsTable({
      email: email && email.trim(),
      numMp: numMp && numMp.trim(),
    });

    setEmail("");
    setNumMp("");
  };

  const handleKeyPress = (e) => {
    const { key } = e;
    if (key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="card-body">
      <p className="card-text">Search transactions</p>
      <div className="form-group row">
        <div className="col-sm-4 mb-2">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <input
            type="numMP"
            className="form-control"
            placeholder="numMp"
            value={numMp}
            onChange={(e) => setNumMp(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <button
          className="btn btn-primary mb-2"
          disabled={!email && !numMp}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="mb-3" id="transaction-detail" />
    </div>
  );
}
