import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";
import { showSuccessMessage } from "../usecases/commons/show-success-message";

export const findTransactionsByEmail = async (email, numMp) => {
  const query = (email && `email=${email}`) || (numMp && `cardId=${numMp}`);
  const { data } = await apiClient().get(
    `/v1/back-office/employee/transactions?${query}`
  );
  return data;
};

export const findLedgerTransactionsByEmail = async (email, numMp) => {
  const query = (email && `email=${email}`) || (numMp && `cardId=${numMp}`);
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/employee/ledger-transactions?${query}`
    );
    return data;
  } catch (err) {
    return {};
  }
};

export const solveUnknownNotification = async (
  transactionId,
  unknownProcessorNotificationId
) => {
  try {
    return await apiClient().post(
      `/v1/back-office/solve-unknown-refunds-notification`,
      {
        transactionId,
        unknownProcessorNotificationId,
      }
    );
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const solveGenericUnknownNotification = async ({
  unknownProcessorNotificationId,
  solveReason,
}) => {
  const res = await apiClient().post(
    `/v1/back-office/solve-generic-unknown-notification`,
    {
      unknownProcessorNotificationId,
      solveReason,
    }
  );
  return res;
};

export const solveConfirmationAmountAboveAuthorized = async ({
  unknownProcessorNotificationId,
  solveReason,
  password,
}) => {
  const res = await apiClient().post(
    `/v1/back-office/solve-confirmation-amount-above-authorized`,
    {
      unknownProcessorNotificationId,
      solveReason,
      password,
    }
  );
  return res;
};

export const findMerchantCategories = async ({ employeeId }) => {
  try {
    const url = `/v1/back-office/merchant-categories?employeeId=${employeeId}`;
    const { data } = await apiClient().get(url);
    return data;
  } catch (err) {
    return [];
  }
};

export const categorizeMerchantByTransactionId = async (payload) => {
  try {
    const { data } = await apiClient().post(
      `/v1/back-office/categorization-pattern`,
      {
        ...payload,
      }
    );
    if (!data.success) {
      showErrorMessage("Merchant could not be categorized");
    } else {
      showSuccessMessage("Merchant categorized successfully");
    }
  } catch (err) {
    showErrorMessage("Merchant could not be categorized");
  }
};

export const findCategorizationPatterns = async ({ employeeId }) => {
  try {
    const url = `/v1/back-office/categorization-patterns?employeeId=${employeeId}`;
    const { data } = await apiClient().get(url);
    return data;
  } catch (err) {
    showErrorMessage("Could not retrieve categorization patterns");
    return {};
  }
};

export const solveConfirmCancelledTrx = async ({ payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/solve-cancelled-transaction-notification`,
    payload
  );
  return res;
};

export const sendCompensation = async ({ payload }) => {
  const res = await apiClient().post(
    "/v1/back-office/companies/compensation",
    payload
  );
  return res;
};
