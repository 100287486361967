import { makeStyles } from "@material-ui/core/styles";
import { grey300, secondary } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {
    width: "100%",
  },
  searchIcon: {
    color: secondary,
    cursor: "pointer",
  },
  closeIcon: {
    color: secondary,
  },
  datePicker: {
    height: 32,
    width: 150,
    border: `1px solid ${grey300}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
