import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import RecurrentPaymentDetailsModal from "../RecurrentPaymentDetailsModal";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import { Address } from "../../../../../app/domain/address";

export default function LifeInsuranceRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const { container, row } = useStyles();

  function optionalMonetaryAmount(payment) {
    return payment ? MonetaryAmount.ofCurrency(payment).prettifyMoney({}) : "";
  }

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value?.toString() || ""}</Typography>
      </Grid>
    </>
  );

  const benefitDetailComponent = (
    <>
      <Grid item container xs={12} className={container}>
        {textProperty(
          "Insurance Company",
          recurrentPayment.lifeInsurancePolicy?.insuranceCompany
        )}
        {textProperty(
          "Policy",
          recurrentPayment?.lifeInsurancePolicy?.insurancePolicy
        )}
        {textProperty(
          "Modality",
          recurrentPayment?.lifeInsuranceModality?.name
        )}
        {textProperty(
          "Capital Type",
          recurrentPayment?.lifeInsuranceCapital?.type
        )}
        {textProperty(
          "Capital Value",
          recurrentPayment?.lifeInsuranceCapital?.type === "salary-ratio"
            ? recurrentPayment?.lifeInsuranceCapital?.employeeSalaryRatio?.toFixed(
                2
              ) || ""
            : optionalMonetaryAmount(
                recurrentPayment?.lifeInsuranceCapital?.fixedAmount
              )
        )}
      </Grid>
    </>
  );

  return RecurrentPaymentDetailsModal({
    recurrentPayment,
    benefitDetailComponent,
  });
}
