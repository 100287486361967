import { adaptDataToCsv } from "../../infrastructure/adapters/adaptDataToCsv";
import { getMovementCompany } from "../../infrastructure/finance-repository";
import generateCsv from "../../services/generateCsv";
import { showErrorMessage } from "../commons/show-error-message";

export default async function downloadMovements({
  companyId,
  companyName,
  fromDate,
  toDate,
}) {
  try {
    const title = `movements-to-${companyName}`;
    const {
      data: { content },
    } = await getMovementCompany({ companyId, fromDate, toDate });
    if (content) {
      const { headers, rows } = adaptDataToCsv({
        type: "companyMovement",
        data: content,
      });
      await generateCsv({ title, headers, rows });
    } else {
      return showErrorMessage("ERROR TO DOWNLOAD MOVEMENT COMPANY");
    }
  } catch (err) {
    showErrorMessage("ERROR TO GET MOVEMENT COMPANY");
  }
  return { data: [] };
}
