import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton } from "@material-ui/core";

export default function PopperMenu({ options }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(({ label, action }) => (
          <MenuItem
            key={label}
            onClick={() => {
              handleClose();
              action();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

PopperMenu.defaultProps = {
  options: [],
};

PopperMenu.propTypes = {
  options: PropTypes.arrayOf({
    label: PropTypes.string,
    action: PropTypes.func,
  }),
};
