import React, { Component } from "react";
import { Chip, Grid, Link } from "@material-ui/core";
import BenefitsBar from "../../../app/components/BenefitsBar";
import CobeeButton from "../../../app/components/CobeeButton";
import Table from "../../../app/components/Table";
import { generateBenefitModelDetailLink } from "../../../infrastructure/links";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
    };
  }

  componentDidMount() {
    const { company } = this.props;

    this.setState({
      companyName: company.companyName,
    });
  }

  getStatePillColor = () => {
    const { company } = this.props;
    const colorMap = {
      active: "primary",
      churned: "secondary",
    };
    return colorMap[company.state] || "default";
  };

  render() {
    const { company, benefitModels, payrollDocuments } = this.props;

    return (
      <main className="container-fluid" id="page-container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h5 className="card-title">
                  {company.companyName}
                  <Chip
                    className="ml-1 text-capitalize"
                    label={company.state}
                    color={this.getStatePillColor()}
                  />
                </h5>
              </div>
              <div className="col-6">
                <Grid container item justify="flex-end">
                  <Link
                    href={`https://cobee.retool.com/app/company-detail?companyId=${company.id}`}
                  >
                    <CobeeButton>Go To New Backoffice</CobeeButton>
                  </Link>
                </Grid>
              </div>
            </div>

            <hr />
            <div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Id</label>
                <div className="col-sm-4">
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={company.id}
                  />
                </div>
                <label className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-4">
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    value={this.state.companyName}
                    onChange={(e) =>
                      this.setState({ companyName: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <BenefitsBar company={company} />
          </div>
        </div>

        <hr />

        <p className="card-text font-weight-bold">Benefit Models: </p>
        <div className="card mb-3">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <Table
                columns={[
                  { label: "Name", id: "name" },
                  { label: "Actions", id: "actions" },
                ]}
                data={benefitModels.map((benefitModel) => {
                  return {
                    ...benefitModel,
                    actions: (
                      <>
                        <Link
                          href={generateBenefitModelDetailLink({
                            companyId: company.id,
                            benefitModelId: benefitModel.id,
                          })}
                        >
                          <CobeeButton>Details</CobeeButton>
                        </Link>
                      </>
                    ),
                  };
                })}
              />
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

export default CompanyDetails;
