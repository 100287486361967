import qs from "qs";
import { restoreNurseriesActivePayments } from "../../infrastructure/adapters/adaptNurseries/restore-nurseries-active-payments";
import { findNurseriesActivePayments } from "../../infrastructure/nursery-repository";
import { showErrorMessage } from "../commons/show-error-message";

export const fetchNurseriesActivePayments = async ({ filters }) => {
  try {
    const query = qs.stringify(filters);
    const {
      data: {
        content: { recurrentPayments: data },
      },
    } = await findNurseriesActivePayments({ query });
    const adaptedData = data.map((item) =>
      restoreNurseriesActivePayments(item)
    );
    return adaptedData;
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message ||
        "Error fetching nurseries active payments"
    );
    return {};
  }
};
