import React from "react";
import PropTypes from "prop-types";
import {
  Modal as MuiModal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function Modal({
  title,
  children,
  disableClose,
  horizontalPadding,
  containerWidth = undefined,
}) {
  const {
    container,
    buttonContainer,
    paper,
    modal,
    closeButton,
    modalTitle,
    disabled,
  } = useStyles({ horizontalPadding, containerWidth });

  const handleClose = () => {
    if (!disableClose) {
      hideModal();
    }
  };

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modal}
      open
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={container}>
        <div className={buttonContainer}>
          <IconButton
            className={`${closeButton} ${disableClose && disabled}`}
            onClick={handleClose}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={paper}>
          <Grid container item xs={12}>
            {title && (
              <Typography variant="h4" className={modalTitle}>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12}>
            {children}
          </Grid>
        </div>
      </div>
    </MuiModal>
  );
}

Modal.defaultProps = {
  title: null,
  children: null,
  disableClose: false,
  horizontalPadding: 5,
  containerWidth: undefined,
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disableClose: PropTypes.bool,
  horizontalPadding: PropTypes.number,
  containerWidth: PropTypes.string,
};
