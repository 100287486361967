import React from "react";
import ReactDOM from "react-dom";
import EducationRecurrentPaymentDetailsModal from "./components/RecurrentPayments/EducationRecurrentPaymentDetailsModal";
import LifeInsuranceRecurrentPaymentDetailsModal from "./components/RecurrentPayments/LifeInsuranceRecurrentPaymentDetailsModal";

export default function renderLifeInsuranceRecurrentPaymentDetailsModal({
  recurrentPayment,
}) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <LifeInsuranceRecurrentPaymentDetailsModal
        recurrentPayment={recurrentPayment}
      />,
      domTarget
    );
  }
}
