export function getServerErrorMessage(
  exception,
  defaultMessage = "An unexpected error ocurred"
) {
  let errorMessage = defaultMessage;
  if (exception.response && exception.response.data.code) {
    errorMessage = exception.response.data.code;
    if (exception.response.data.reason) {
      errorMessage = `${errorMessage}: ${exception.response.data.reason}`;
    } else if (exception.response.data.message) {
      errorMessage = `${errorMessage}: ${exception.response.data.message}`;
    }
  }
  return errorMessage;
}
