import React from "react";
import ReactDOM from "react-dom";
import AllowRequestNewCardModal from "./components/AllowRequestNewCardModal";

export default function renderAllowRequestNewCard({ card, callback }) {
  const domTarget = document.getElementById("modal-container");

  if (domTarget) {
    ReactDOM.render(
      <AllowRequestNewCardModal card={card} callback={callback} />,
      domTarget
    );
  }
}
