import React from "react";
import renderAssignFamiliarModal from "../../../infrastructure/renders/AssignRelativeModal/render-assign-relative-modal";

export default function assignRelative({ relatives, employee, modData }) {
  renderAssignFamiliarModal({
    employee,
    relatives,
    action: ({ selectedRelative }) => modData({ relative: selectedRelative }),
  });
}
