import { makeStyles } from "@material-ui/core/styles";
import { primary, grey300 } from "../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    paddingTop: theme.spacing(2),

    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  step: {
    borderBottom: `4px solid ${grey300}`,
    margin: theme.spacing(2),
    height: theme.spacing(6),
  },
  activeStep: {
    borderColor: primary,
    fontWeight: 700,
  },
  stepsContainer: {
    paddingBottom: theme.spacing(3),
  },
  alternateButtonBar: {
    paddingBottom: theme.spacing(2),
  },
}));

export default useStyles;
