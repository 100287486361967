import adaptWellnessBenefitUpdatedData, {
  restoreWellnessBenefit,
} from "infrastructure/adapters/adaptWellnessBenefit";
import { findVendors } from "infrastructure/vendors-repository";
import {
  findWellnessBenefit,
  updateWellnessBenefit,
} from "infrastructure/wellness-repository";
import { hideModal } from "usecases/commons/hide-modal";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { showSuccessMessage } from "usecases/commons/show-success-message";
import { displayCompanyDetailsPage } from "usecases/companies/display-company-details";
import { renderMentalWellnessPage } from "usecases/companies/displays/mental-wellness";

export default async function showMentalWellnessDetails({ company }) {
  const onSave = async ({ payload }) => {
    try {
      const adaptedData = adaptWellnessBenefitUpdatedData({ data: payload });
      await updateWellnessBenefit({
        companyId: company.id,
        benefitId: company?.wellnessBenefits[0]?.id,
        payload: adaptedData,
      });
      showSuccessMessage("Benefit edited successfully");
      displayCompanyDetailsPage(company.id);
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the benefit edition"
      );
    } finally {
      hideModal();
    }
  };

  renderMentalWellnessPage({
    company,
    isLoading: true,
    vendors: [],
    currentData: null,
    onSave: () => {},
  });

  const { data: vendors } = await findVendors({ benefit: "wellness" });
  const {
    data: { content: wellnessBenefit },
  } = await findWellnessBenefit({
    companyId: company.id,
    benefitId: company?.wellnessBenefits[0]?.id,
  });

  const currentData = restoreWellnessBenefit(wellnessBenefit);

  renderMentalWellnessPage({
    company,
    vendors: vendors.content,
    currentData,
    onSave,
  });
}
