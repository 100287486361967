import React from "react";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";

const ComplementModalityDetail = ({ complement, handlerEdit, disabled }) => {
  return (
    <>
      <div className="row form-group">
        <div className="col-sm-4">Id: {complement.complementId}</div>
        <div className="col-sm-4">Name: {complement.complementName}</div>
        <div className="col-sm-4">
          {`Fee: ${MonetaryAmount.ofCurrency(
            complement.complementFee
          ).prettifyMoney({})}`}
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-4">
          Insurance Company: {complement.insuranceCompany}
        </div>
        <div className="col-sm-4">Broker: {complement.broker}</div>
        <div className="col-sm-4">Policy: {complement.insurancePolicy}</div>
      </div>

      <div className="row form-group">
        <div className="col-sm-12">
          Coverage: {complement.complementCoverage}
        </div>
      </div>

      {complement?.complementBrochures.length !== 0 && (
        <div className="form-group row">
          <div className="col-sm-12">
            <p>Brochures: </p>
            {complement.complementBrochures.map((document) => (
              <p key={document.title} className="col-sm-12">
                {document.title}: <a href={document.url}>{document.url}</a>
              </p>
            ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <div className="gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={handlerEdit}
            disabled={disabled}
          >
            Edit complement
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ComplementModalityDetail;
