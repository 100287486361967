import React from "react";
import ReactDOM from "react-dom";
import HexModal from "../../app/components/modals/HexModal";
import SolvedConfirmationAmountAboveAuthorizedModal from "../../app/components/modals/SolvedConfirmationAmountAboveAuthorizedModal";

const renderSolvedConfirmationAmountAboveAuthorizedModal = ({
  id,
  intendedAmount,
  confirmedAmount,
}) => {
  ReactDOM.render(
    <HexModal>
      <SolvedConfirmationAmountAboveAuthorizedModal
        id={id}
        intendedAmount={intendedAmount}
        confirmedAmount={confirmedAmount}
      />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export async function displaySolvedConfirmationAboveAuthorizedModal({
  id,
  intendedAmount,
  confirmedAmount,
}) {
  renderSolvedConfirmationAmountAboveAuthorizedModal({
    id,
    intendedAmount,
    confirmedAmount,
  });
}
