import React from "react";
import ReactDOM from "react-dom";

import { getCorporations } from "infrastructure/corporation-repository";
import CompanyDetails from "./components/CompanyDetails";
import { findHrEmails } from "../../infrastructure/employee-repository";
import { findModels } from "../../infrastructure/benefit-models-repository";
import {
  findCountries,
  findPayrollCycles,
  findPayrollDocuments,
  deleteCompany,
  findCompanyDetail,
  findEmployeesPerModel,
  churnCompany,
} from "../../infrastructure/company-repository";
import { displayCompaniesPage } from "./display-companies-page";

import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { displayLoader } from "../commons/render-loader";

function displayError(e) {
  let errorMessage = "Error. Company not removed!";
  if (e.response && e.response.data.code) {
    if (e.response.data.reason) {
      errorMessage = e.response.data.reason;
    }
    errorMessage = e.response.data.code;
  }
  showErrorMessage(errorMessage);
}

export function renderCompanyDetailsPage({ company, ...props }) {
  ReactDOM.render(
    <CompanyDetails
      company={company}
      onChurn={async () => {
        try {
          await churnCompany(company.id);
          showSuccessMessage("Company churned successfully");
          await displayCompaniesPage();
        } catch (e) {
          displayError(e);
        }
      }}
      {...props}
      onRemoveHandler={async () => {
        try {
          await deleteCompany(company.id);
          showSuccessMessage("Company removed successfully");
          await displayCompaniesPage();
        } catch (e) {
          displayError(e);
        }
      }}
    />,
    document.getElementById("page-container")
  );
}

export async function displayCompanyDetailsPage(companyId) {
  try {
    displayLoader();
    const [
      company,
      hrEmails,
      benefitModels,
      payrollDocuments,
      countries,
      payrollCycles,
      corporations,
    ] = await Promise.all([
      findCompanyDetail(companyId),
      findHrEmails(companyId),
      findModels(companyId),
      findPayrollDocuments(companyId),
      findCountries(),
      findPayrollCycles(companyId),
      getCorporations(),
    ]);
    const { id: modelId } = benefitModels[0];
    const fields = "name,surname,legalId,email,daysPerMonth";
    const {
      data: { content: employeesPerModel },
    } = await findEmployeesPerModel({
      companyId,
      modelId,
      fields,
    });
    renderCompanyDetailsPage({
      company,
      hrEmails,
      benefitModels,
      employeesPerModel,
      payrollDocuments,
      countries,
      payrollCycles,
      corporations,
    });
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message || "Error loading company details"
    );
  }
}
