import apiClient from "./api-client";
import { showSuccessMessage } from "../usecases/commons/show-success-message";
import { showErrorMessage } from "../usecases/commons/show-error-message";

export async function findModels(companyId) {
  const response = await apiClient().get(
    `/v1/back-office/${companyId}/benefits`
  );

  return response.data;
}

export async function findBenefitModel({ companyId, benefitModelId }) {
  const response = await apiClient().get(
    `/v1/back-office/${companyId}/benefit-models/${benefitModelId}`
  );

  return response.data.content;
}

export async function updateBenefitModel({
  companyId,
  benefitModelId,
  update,
}) {
  await apiClient().patch(
    `/v1/back-office/${companyId}/benefit-models/${benefitModelId}`,
    update
  );
}

export async function downloadHealthInsuranceActionLogs({
  companyId,
  from,
  to,
}) {
  const searchParams = new URLSearchParams({ from, to });
  if (companyId) {
    searchParams.append("companyId", companyId);
  }
  const response = await apiClient().get(
    `/v1/back-office/health-insurance-actions-report?${searchParams.toString()}`
  );
  return response.data;
}

export const configBenefit = async ({ companyId, category, payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/benefit-config/${category}`,
    payload
  );
  return res;
};

export const updateBenefitState = async ({ companyId, category, payload }) => {
  const res = await apiClient().put(
    `/v1/back-office/companies/${companyId}/${category}/state`,
    payload
  );
  return res;
};
