const noneValues = [null, undefined];

export default function convertSafely({
  value,
  type = String,
  defaultValue = null,
  postProcess,
}) {
  const result = noneValues.includes(value) ? defaultValue : type(value);
  if (result === null) return result;
  return postProcess ? postProcess(result, defaultValue) : result;
}
