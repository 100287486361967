import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";
import { showSuccessMessage } from "../usecases/commons/show-success-message";

export const findLifeInsuranceBrokers = async () => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/life-insurance-benefit/brokers`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const createLifeInsurance = async ({ companyId, payload }) => {
  try {
    const {
      data,
    } = await apiClient().post(
      `/v1/back-office/companies/${companyId}/life-insurance-benefit/policies`,
      { ...payload }
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const getLifeInsuranceRequests = async () => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/requests/life-insurance-benefit/`
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const changeRiskInformation = async ({
  recurrentPaymentId,
  payload,
}) => {
  try {
    const {
      data,
    } = await apiClient().put(
      `/v1/back-office/requests/life-insurance-benefit/${recurrentPaymentId}/risk`,
      { ...payload }
    );
    showSuccessMessage("Risk info updated");
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};

export const resendEmail = async ({ recurrentPaymentId }) => {
  try {
    const { data } = await apiClient().get(
      `/v1/back-office/requests/life-insurance-benefit/${recurrentPaymentId}/send-fees`
    );
    showSuccessMessage("Email sent");
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};
