import React from "react";
import ReactDOM from "react-dom";
import { findEmployeesByEmail } from "../../infrastructure/employee-repository";
import { displayLoader } from "../commons/render-loader";
import { showErrorMessage } from "../commons/show-error-message";
import EmployeesWrapperDetail from "./components/EmployeesWrapperDetail";
import EmployeeDetail from "./components/EmployeeDetail";

const elementId = "employee-detail";

export function resetDisplay() {
  ReactDOM.render("", document.getElementById(elementId));
}

function renderEmployee(employees) {
  if (employees.length > 1) {
    ReactDOM.render(
      <EmployeesWrapperDetail employeesAllData={employees} />,
      document.getElementById(elementId)
    );
  } else if (employees.length === 1) {
    ReactDOM.render(
      <EmployeeDetail {...employees[0]} />,
      document.getElementById(elementId)
    );
  } else {
    showErrorMessage("Employee not found");
    resetDisplay();
  }
}

export async function displayEmployeeDetail(email, numMp, legalId) {
  try {
    displayLoader(elementId);
    const data = await findEmployeesByEmail(email, numMp, legalId);
    if (!data) {
      showErrorMessage(
        `The backend has returned an error searching for the employee`
      );
      resetDisplay();
    } else {
      renderEmployee(data);
    }
  } catch (e) {
    showErrorMessage(
      "An error has occurred rendering the employee detail page"
    );
    resetDisplay();
  }
}
