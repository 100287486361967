import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { v4 } from "uuid";
import ContentWrapper from "../ContentWrapper";
import useStyles from "./styles";
import CobeeTextField from "../../../app/components/CobeeTextField";
import CobeeButton from "../../../app/components/CobeeButton";
import CobeeDatePicker from "../../../app/components/CobeeDatePicker";
import renderAddLifeInsurancePage from "./render-add-life-insurance-page";
import LifeInsuranceModality from "./LifeInsuranceModality";
import { findLifeInsuranceBrokers } from "../../life-insurance-repository";
import createLifeInsurancePolicy from "../../../usecases/benefit-models/life-insurance/create-life-insurance-policy";

export default function AddLifeInsurancePage({
  lifeInsurancePolicy,
  lastFieldModified,
  isLoading,
}) {
  const { row, divider, checkbox, buttonRow, select, datePicker } = useStyles();

  useEffect(() => {
    const fetchBrokers = async () => {
      try {
        renderAddLifeInsurancePage({ lifeInsurancePolicy, isLoading: true });
        const res = await findLifeInsuranceBrokers();
        renderAddLifeInsurancePage({
          lifeInsurancePolicy: lifeInsurancePolicy.modBrokers({ brokers: res }),
          isLoading: false,
        });
      } catch (err) {
        renderAddLifeInsurancePage({
          lifeInsurancePolicy: lifeInsurancePolicy.modBrokers({ brokers: [] }),
          isLoading: false,
        });
      }
    };
    if (lifeInsurancePolicy.brokers.length === 0 && isLoading === undefined) {
      fetchBrokers();
    }
  }, [lifeInsurancePolicy, isLoading]);

  const policyFormIsProperlyFilled = () => {
    const {
      selectedBroker,
      insuranceBrand,
      insuranceCompany,
      insurancePolicy,
      dateSignUp,
      firstEngagementPeriod,
      nextEngagementPeriod,
      modalities,
    } = lifeInsurancePolicy;
    return (
      selectedBroker !== "" &&
      insuranceBrand !== "" &&
      insuranceCompany !== "" &&
      insurancePolicy !== "" &&
      dateSignUp &&
      firstEngagementPeriod.from &&
      firstEngagementPeriod.to &&
      moment(firstEngagementPeriod.to).diff(
        firstEngagementPeriod.from,
        "days"
      ) > 0 &&
      nextEngagementPeriod.from &&
      nextEngagementPeriod.to &&
      moment(nextEngagementPeriod.to).diff(nextEngagementPeriod.from, "days") >
        0 &&
      !modalities.some(
        ({ name, legalBrochures, coverage, capitals }) =>
          name === "" ||
          legalBrochures.reduce(
            (acc, { label, link }) => label === "" || link === "",
            false
          ) ||
          coverage === "" ||
          capitals.length < 1
      )
    );
  };

  return (
    <ContentWrapper title="Add life insurance modalities">
      <Grid item xs={12} container className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Broker</Typography>
        </Grid>
        <Grid item xs={10}>
          <Select
            className={select}
            displayEmpty
            variant="outlined"
            disabled={lifeInsurancePolicy.brokers.length === 0 || isLoading}
            value={lifeInsurancePolicy.selectedBroker}
            onChange={(e) => {
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modSelectedBroker({
                  selectedBroker: e.target.value,
                }),
              });
            }}
          >
            <MenuItem disabled value="">
              Select a broker...
            </MenuItem>
            {lifeInsurancePolicy.brokers.map((broker) => (
              <MenuItem key={broker} value="broker1">
                {broker}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item xs={12} container className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Insurance brand</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeTextField
            value={lifeInsurancePolicy.insuranceBrand}
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modInsuranceBrand({
                  insuranceBrand: e.target.value,
                }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Insurance company</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeTextField
            value={lifeInsurancePolicy.insuranceCompany}
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modInsuranceCompany({
                  insuranceCompany: e.target.value,
                }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Insurance policy</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeTextField
            value={lifeInsurancePolicy.insurancePolicy}
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modInsurancePolicy({
                  insurancePolicy: e.target.value,
                }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={divider} />
      <Grid item xs={12} container className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Date of sign up</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeDatePicker
            selectedDate={lifeInsurancePolicy.dateSignUp}
            customInput={
              <div className={datePicker}>
                {lifeInsurancePolicy.dateSignUp
                  ? moment(lifeInsurancePolicy.dateSignUp).format("DD/MM/YYYY")
                  : "DD/MM/YYYY"}
              </div>
            }
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modSignupDate({
                  dateSignUp: e,
                }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <i>
            {`If you want the engagement period to cross the year (i.e. from
            2019-12-20 to 2020-01-10) you will need to create two periods, one
            in the "First Engagement Period", from 2019-12-20 to 2019-12-31, and
            other in the "Next Engagement Period", from 2020-01-01 to 2020-01-10`}
          </i>
        </Typography>
      </Grid>
      <Grid item xs={12} container className={row}>
        <Grid item xs={12} className={row} container alignItems="center">
          <Typography variant="body1">First engagement period</Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <Typography variant="body1">From</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeDatePicker
            selectedDate={lifeInsurancePolicy.firstEngagementPeriod.from}
            endDate={lifeInsurancePolicy.firstEngagementPeriod.to}
            customInput={
              <div className={datePicker}>
                {lifeInsurancePolicy.firstEngagementPeriod.from
                  ? moment(
                      lifeInsurancePolicy.firstEngagementPeriod.from
                    ).format("DD/MM")
                  : "DD/MM"}
              </div>
            }
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modFirstEngagementPeriod(
                  {
                    ...lifeInsurancePolicy.firstEngagementPeriod,
                    from: e,
                  }
                ),
              })
            }
          />
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <Typography variant="body1">To</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeDatePicker
            selectedDate={lifeInsurancePolicy.firstEngagementPeriod.to}
            startDate={lifeInsurancePolicy.firstEngagementPeriod.from}
            customInput={
              <div className={datePicker}>
                {lifeInsurancePolicy.firstEngagementPeriod.to
                  ? moment(lifeInsurancePolicy.firstEngagementPeriod.to).format(
                      "DD/MM"
                    )
                  : "DD/MM"}
              </div>
            }
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modFirstEngagementPeriod(
                  {
                    ...lifeInsurancePolicy.firstEngagementPeriod,
                    to: e,
                  }
                ),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container className={row}>
        <Grid item xs={12} className={row} container alignItems="center">
          <Typography variant="body1">Next engagement period</Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <Typography variant="body1">From</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeDatePicker
            selectedDate={lifeInsurancePolicy.nextEngagementPeriod.from}
            endDate={lifeInsurancePolicy.nextEngagementPeriod.to}
            customInput={
              <div className={datePicker}>
                {lifeInsurancePolicy.nextEngagementPeriod.from
                  ? moment(
                      lifeInsurancePolicy.nextEngagementPeriod.from
                    ).format("DD/MM")
                  : "DD/MM"}
              </div>
            }
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modNextEngagementPeriod(
                  {
                    ...lifeInsurancePolicy.nextEngagementPeriod,
                    from: e,
                  }
                ),
              })
            }
          />
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <Typography variant="body1">To</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeDatePicker
            selectedDate={lifeInsurancePolicy.nextEngagementPeriod.to}
            startDate={lifeInsurancePolicy.nextEngagementPeriod.from}
            customInput={
              <div className={datePicker}>
                {lifeInsurancePolicy.nextEngagementPeriod.to
                  ? moment(lifeInsurancePolicy.nextEngagementPeriod.to).format(
                      "DD/MM"
                    )
                  : "DD/MM"}
              </div>
            }
            onChange={(e) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modNextEngagementPeriod(
                  {
                    ...lifeInsurancePolicy.nextEngagementPeriod,
                    to: e,
                  }
                ),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={lifeInsurancePolicy.allowSignOutsideContractPeriod}
              onChange={(e) => {
                renderAddLifeInsurancePage({
                  lifeInsurancePolicy: lifeInsurancePolicy.modAllowSignOutside({
                    allowSignOutsideContractPeriod: e.target.checked,
                  }),
                });
              }}
              disableRipple
            />
          }
          label="Allow to sign in outside contract period"
        />
      </Grid>
      <Grid item container xs={12} className={row}>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={lifeInsurancePolicy.requiresEmployeeSignature}
              onChange={(e) => {
                renderAddLifeInsurancePage({
                  lifeInsurancePolicy: lifeInsurancePolicy.modRequiresEmployeeSignature(
                    {
                      requiresEmployeeSignature: e.target.checked,
                    }
                  ),
                });
              }}
              name="asd1"
              disableRipple
            />
          }
          label="Requires employee signature"
        />
      </Grid>
      <Grid item xs={12} className={divider} />
      {lifeInsurancePolicy.modalities.map((modality, index) => {
        return (
          <LifeInsuranceModality
            key={v4()}
            index={index}
            lastFieldModified={lastFieldModified}
            onChange={({ property, value }) =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.modModality({
                  modality: value,
                  index,
                }),
                lastFieldModified: `modality-${index}-${property}`,
              })
            }
            modality={modality}
            showDeleteButton={lifeInsurancePolicy.modalities.length > 1}
            removeModality={() =>
              renderAddLifeInsurancePage({
                lifeInsurancePolicy: lifeInsurancePolicy.removeModality({
                  index,
                }),
              })
            }
            lifeInsurancePolicy={lifeInsurancePolicy}
          />
        );
      })}
      <Grid item xs={12} className={row} container alignItems="center">
        <CobeeButton
          onClick={() =>
            renderAddLifeInsurancePage({
              lifeInsurancePolicy: lifeInsurancePolicy.addModality(),
            })
          }
        >
          Add another Modality
        </CobeeButton>
      </Grid>
      <Grid item xs={12} className={divider} />
      <Grid
        item
        xs={12}
        className={`${row} ${buttonRow}`}
        container
        alignItems="center"
        justify="center"
      >
        <CobeeButton
          variant="secondary"
          onClick={() => window.location.replace("/")}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading || !policyFormIsProperlyFilled()}
          onClick={() => createLifeInsurancePolicy({ lifeInsurancePolicy })}
        >
          Create
        </CobeeButton>
      </Grid>
    </ContentWrapper>
  );
}
