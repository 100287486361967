import { pecunpayMovements } from "../../infrastructure/movement-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function sendPecunpayMovements({ companyId, from, to, email }) {
  try {
    await pecunpayMovements({ companyId, from, to, email });
    showSuccessMessage("Movements send to email");
  } catch (e) {
    showErrorMessage(getServerErrorMessage(e));
  }
}
