import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { v4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomSelect({
  label,
  options,
  onChange,
  multiple,
  ...rest
}) {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState(multiple ? [] : "");

  const handleChange = (event) => {
    onChange(event.target.value);
    setSelectedItems(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        id={v4()}
        multiple={multiple}
        value={selectedItems}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) =>
          `${
            multiple
              ? selected.reduce(
                  (acc, { name }, i) =>
                    `${acc}${name}${i === selected.length - 1 ? "" : ", "}`,
                  ""
                )
              : selected.name
          }.`
        }
        MenuProps={MenuProps}
        {...rest}
      >
        {options.map((item) => (
          <MenuItem key={v4()} value={item}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
