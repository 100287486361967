import React from "react";
import ReactDOM from "react-dom";
import FamilyMemberDetailsModal from "./components/FamilyMemberDetailsModal";

export default function renderFamilyMemberDetailModal({
  familyMember,
  homeAddress,
}) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <FamilyMemberDetailsModal
        familyMember={familyMember}
        homeAddress={homeAddress}
      />,
      domTarget
    );
  }
}
