import { adaptPensionPlanCreationData } from "infrastructure/adapters/adaptPensionPlan";
import { updatePensionPlanProduct } from "infrastructure/pension-plan-repository";

const updatePensionProduct = async ({ companyId, data }) => {
  const adaptedData = adaptPensionPlanCreationData({ data });
  await updatePensionPlanProduct({
    companyId,
    pensionPlanProductId: data.id,
    payload: adaptedData,
  });
};

export default updatePensionProduct;
