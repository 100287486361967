export class Complement {
  constructor({
    insuranceCompany,
    broker,
    insurancePolicy,
    id,
    name,
    coverage,
    fee,
    brochures,
  }) {
    Object.assign(this, {
      insuranceCompany,
      broker,
      insurancePolicy,
      id,
      name,
      coverage,
      fee,
      brochures,
    });
  }
}
