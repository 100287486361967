export default function countryNamesByIsoCode({ countryISOCode }) {
  const countries = {
    ES: "Spain",
    PT: "Portugal",
    IT: "Italy",
    DE: "Germany",
    GB: "United Kingdom",
    FR: "France",
  };

  return countryISOCode in countries ? countries[countryISOCode] : countries.ES;
}
