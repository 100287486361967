import React from "react";
import { Grid, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "../../styles";
import CobeeButton from "../../../../../app/components/CobeeButton";

export default function CapitalRow({ type, capital, onRemove }) {
  const { row, capitalContainer } = useStyles();

  return (
    <Grid item xs={12} container className={capitalContainer}>
      <Grid
        item
        container
        xs={12}
        className={row}
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Typography variant="body1">
            {`${
              type === "fixed"
                ? `${capital.capitalAmount} EUR`
                : `${capital.salaryRatio}%`
            }${
              type === "fixed" ? `` : " of the employee`s total annual salary"
            }`}
          </Typography>
        </Grid>
        <Grid item>
          <CobeeButton variant="unContained" onClick={onRemove}>
            <DeleteIcon /> Delete
          </CobeeButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
