import React from "react";
import ReactDOM from "react-dom";
import NewEmployeeNursery from "./components/NewEmployeeNursery";
import { findFamilyMembers } from "../../infrastructure/employee-repository";

export async function displayNewEmployeeNursery(employee, numMp) {
  const familyMembers = await findFamilyMembers(employee.id);
  renderNewEmployeeNursery({ ...employee, familyMembers }, numMp);
}

function renderNewEmployeeNursery(employee, numMp) {
  ReactDOM.render(
    <NewEmployeeNursery numMp={numMp} employee={employee} />,
    document.getElementById("employee-detail")
  );
}
