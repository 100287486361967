import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { StyledButton, buttonTypes, useStyles } from "./styles";

export default function CobeeButton({
  variant,
  isLoading,
  children,
  disabled,
  iconColor,
  className,
  ...rest
}) {
  const { spinner } = useStyles();
  const buttonStyles = buttonTypes({ iconColor });
  return (
    <StyledButton
      {...rest}
      className={`${variant !== "iconOnlySecondary" && buttonStyles.default} ${
        buttonStyles[variant]
      } ${className}`}
      disableRipple
      disabled={disabled || isLoading}
      variant={variant === "primary" ? "contained" : "outlined"}
    >
      {isLoading && <CircularProgress className={spinner} size={16} />}
      {children}
    </StyledButton>
  );
}

CobeeButton.defaultProps = {
  variant: "primary",
  disabled: false,
  isLoading: false,
  children: null,
  iconColor: undefined,
  className: undefined,
};

CobeeButton.propTypes = {
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  iconColor: PropTypes.string,
  className: PropTypes.string,
};
