import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import Table from "../../../app/components/Table";
import CobeeButton from "../../../app/components/CobeeButton";
import renderEditBenefitBagDetailsModal from "../render-benefit-bag-details-modal";
import displayActionConfirmationModal from "../../commons/display-action-confirmation-modal";
import { editLockedFlex } from "../../../infrastructure/employee-repository";
import { showSuccessMessage } from "../../commons/show-success-message";
import { hideModal } from "../../commons/hide-modal";
import { showErrorMessage } from "../../commons/show-error-message";
import { getServerErrorMessage } from "../../ErrorHandling";

const BalanceDetail = ({
  employeeId,
  limits,
  benefitBagsAvailable,
  accumulatedFlexByCategory,
  fundsByCategory,
  accumulationDeliveries,
  allowanceDeliveries,
  accumulatedFlexAllowed,
  lockedFlex: initialLockedFlex,
}) => {
  const [lockedFlex, setLockedFlex] = useState(initialLockedFlex);

  const onOperationSuccessful = useCallback((category, value) => {
    if (category) {
      setLockedFlex((prevLockedFlex) => ({
        ...prevLockedFlex,
        [category]: value,
      }));
    }
  }, []);

  useEffect(() => {
    onOperationSuccessful();
  }, [onOperationSuccessful, lockedFlex]);

  const formatPayroll = (payroll) => {
    if (payroll?.fiscalYear) {
      return `${payroll?.fiscalYear || ""}-${payroll?.payrollMonth || ""}`;
    }
    return "Current";
  };

  const optionalAmount = (amount) =>
    amount ? MonetaryAmount.ofCurrency(amount).prettifyMoney({}) : "N/A";

  return (
    <div className="card mb-2">
      <div className="card-body">
        <p className="card-text">Balance Detail</p>
        <hr />
        <Table
          columns={[
            { label: "Benefit", id: "category" },
            { label: "Allowance In Purses", id: "allowance" },
            { label: "Flex In Purse", id: "accumulatedFlex" },
            { label: "Available (Limit + In Purse)", id: "limit" },
            { label: "Locked Flex", id: "lockedFlex" },
            { label: "Accumulation Allowed", id: "accumulatedFlexAllowed" },
          ]}
          data={Object.keys(accumulatedFlexByCategory).map((category) => {
            const isLockedFlex = lockedFlex[category]?.toString() === "true";

            return {
              category,
              allowance: optionalAmount(fundsByCategory[category]) || "N/A",
              accumulatedFlex:
                optionalAmount(accumulatedFlexByCategory[category]) || "N/A",
              limit: optionalAmount(limits[category]) || "N/A",
              accumulatedFlexAllowed:
                accumulatedFlexAllowed[category]?.toString() || "false",
              lockedFlex: (
                <>
                  {isLockedFlex.toString()}
                  &nbsp; &nbsp;
                  <CobeeButton
                    variant="secondary"
                    onClick={() => {
                      displayActionConfirmationModal({
                        callback: async () => {
                          try {
                            await editLockedFlex(
                              employeeId,
                              category,
                              !isLockedFlex
                            );
                            showSuccessMessage("Operation Successful");

                            await hideModal();

                            onOperationSuccessful(category, !isLockedFlex);
                          } catch (e) {
                            showErrorMessage(getServerErrorMessage(e));
                          }
                        },
                        message: isLockedFlex
                          ? `Are you sure you want to unlock flex for ${category}?`
                          : `Are you sure you want to lock flex for ${category}?`,
                      });
                    }}
                  >
                    {isLockedFlex ? "unlock" : "lock"}
                  </CobeeButton>
                </>
              ),
            };
          })}
          pageSize={50}
        />
      </div>

      {benefitBagsAvailable?.length > 0 && (
        <div className="card-body">
          <p className="card-text">Benefit Bags:</p>
          <hr />
          <Table
            columns={[
              { label: "Created At", id: "createdAt" },
              { label: "Benefit Plan", id: "modelName" },
              { label: "Consumable From", id: "consumableFrom" },
              { label: "Duration In Payrolls", id: "periodDurationInPayrolls" },
              { label: "Used", id: "used" },
              { label: "Limit", id: "limitAmount" },
              { label: "Available", id: "available" },
              { label: "State", id: "state" },
              { label: "Actions", id: "actions" },
            ]}
            data={benefitBagsAvailable.map((benefitBag) => ({
              ...benefitBag,
              modelName: benefitBag?.benefitPlan?.name || "",
              state: (
                <Typography
                  style={{
                    color: benefitBag.state === "active" ? "green" : "red",
                  }}
                >
                  {benefitBag.state}
                </Typography>
              ),
              createdAt: benefitBag?.stateHistory[0]?.timestamp
                ? moment
                    .unix(benefitBag.stateHistory[0].timestamp)
                    .format("YYYY-MM-DD HH:mm")
                : "N/A",
              deliverUnusedExemptToPurses: benefitBag.benefitBagConfig?.deliverUnusedExemptToPurses?.toString(),
              renewAtPeriodEnd: benefitBag.benefitBagConfig?.renewAtPeriodEnd?.toString(),
              accumulableAtPeriodEnd: benefitBag.benefitBagConfig?.accumulableAtPeriodEnd?.toString(),
              periodDurationInPayrolls:
                benefitBag.benefitBagConfig?.periodDurationInPayrolls,
              available: optionalAmount(benefitBag.availableAmount),
              used: optionalAmount(benefitBag.usedAmount),
              limitAmount: optionalAmount(benefitBag.limitAmount),
              actions: (
                <>
                  <CobeeButton
                    variant="secondary"
                    onClick={() =>
                      renderEditBenefitBagDetailsModal({
                        benefitBag,
                      })
                    }
                  >
                    Details
                  </CobeeButton>
                </>
              ),
            }))}
          />
        </div>
      )}

      <div className="card-body">
        <p className="card-text">Allowance Deliveries:</p>
        <hr />
        <Table
          columns={[
            { label: "Payroll", id: "payroll" },
            { label: "Date", id: "date" },
            { label: "Category", id: "category" },
            { label: "Amount", id: "amount" },
          ]}
          data={allowanceDeliveries.map(
            ({ amount, date, payroll, ...rest }) => ({
              ...rest,
              payroll: formatPayroll(payroll),
              date: moment(date).format("YYYY-MM-DD HH:mm"),
              amount: MonetaryAmount.ofCurrency(amount).prettifyMoney({}),
            })
          )}
        />
      </div>

      <div className="card-body">
        <p className="card-text">Accumulated Deliveries:</p>
        <hr />
        <Table
          columns={[
            { label: "Payroll", id: "payroll" },
            { label: "Date", id: "date" },
            { label: "Category", id: "category" },
            { label: "IsFromBag", id: "isFromBenefitBag" },
            { label: "Behavior", id: "behavior" },
            { label: "Amount", id: "amount" },
          ]}
          data={accumulationDeliveries.map(
            ({ amount, benefitBagId, date, payroll, ...rest }) => ({
              ...rest,
              payroll: formatPayroll(payroll),
              date: moment(date).format("YYYY-MM-DD HH:mm"),
              amount: MonetaryAmount.ofCurrency(amount).prettifyMoney({}),
              isFromBenefitBag: Boolean(benefitBagId).toString(),
            })
          )}
        />
      </div>
    </div>
  );
};

BalanceDetail.propTypes = {
  limits: PropTypes.object,
  benefitBagsAvailable: PropTypes.array,
  accumulatedFlexByCategory: PropTypes.object,
  fundsByCategory: PropTypes.object,
  allowanceDeliveries: PropTypes.array,
  accumulationDeliveries: PropTypes.array,
  accumulatedFlexAllowed: PropTypes.object,
  lockedFlex: PropTypes.object,
  employeeId: PropTypes.string.isRequired,
};

BalanceDetail.defaultProps = {
  limits: {},
  benefitBagsAvailable: [],
  accumulatedFlexByCategory: {},
  fundsByCategory: {},
  allowanceDeliveries: [],
  accumulationDeliveries: [],
  accumulatedFlexAllowed: {},
  lockedFlex: {},
};

export default BalanceDetail;
