import { makeStyles } from "@material-ui/core/styles";
import { primary, error, warning90 } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  notApplicableStatus: {
    color: warning90,
  },
  pendingStatus: {
    color: warning90,
  },
  confirmedStatus: {
    color: primary,
  },
  rejectedStatus: {
    color: error,
  },
  buttonBar: { paddingBottom: theme.spacing(2) },
}));

export default useStyles;
