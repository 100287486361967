import React from "react";
import ReactDOM from "react-dom";
import CancelRentingModal from "./index";

export default function renderCancelRentingModal({ callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<CancelRentingModal callback={callback} />, domTarget);
  }
}
