import { makeStyles } from "@material-ui/core/styles";
import { lighten, darken } from "polished";

import { secondary, grey300 } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  container: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    width: ({ containerWidth }) => containerWidth || undefined,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: ({ horizontalPadding }) =>
      `${theme.spacing(5)}px ${theme.spacing(horizontalPadding)}px`,
    borderRadius: 4,
    outline: "none",
    maxHeight: "calc(100vh - 60px)",
    maxWidth: "calc(100vw - 60px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    backgroundColor: secondary,
    color: "white",
    right: 0,
    marginBottom: -24,
    marginRight: -24,

    "&:hover": {
      backgroundColor: lighten(0.05, secondary),
    },
  },
  disabled: {
    backgroundColor: grey300,
    color: darken(0.2, grey300),
    cursor: "context-menu",

    "&:hover": {
      backgroundColor: grey300,
      cursor: "context-menu",
    },
  },
  modalTitle: {
    marginBottom: theme.spacing(4),
    marginLeft: ({ horizontalPadding }) => theme.spacing(5 - horizontalPadding),
  },
}));

export default useStyles;
