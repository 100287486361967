import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  warningContainer: {
    padding: theme.spacing(2),

    "& svg": {
      width: theme.typography.h3.fontSize,
      height: theme.typography.h3.fontSize,
      color: theme.palette.error.main,
    },
  },
}));

export default useStyles;
