import React from "react";
import { useHistory } from "react-router-dom";

import { showSuccessMessage } from "usecases/commons/show-success-message";
import { showErrorMessage } from "usecases/commons/show-error-message";
import createCorporation from "usecases/corporations/createCorporation";

export default function Create() {
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const payload = {
        corporation: { alias: formData.get("alias") },
      };
      await createCorporation(payload);
      showSuccessMessage("Corporation created!");
      history.push("/corporations");
    } catch ({ response }) {
      showErrorMessage(response.data.message);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">New corporation</h5>
          <div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Name</label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="alias"
                  placeholder="Nombre de la corporación"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-sm-4">
                <button className="btn btn-success" type="submit">
                  Add corporation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
