import { findCompanies } from "../../infrastructure/company-repository";
import { showErrorMessage } from "../commons/show-error-message";

export default async function findAllCompanies() {
  try {
    const companies = await findCompanies();
    return companies;
  } catch (err) {
    showErrorMessage("Could not retrieve companies");
  }
  return [];
}
