import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import queryString from "query-string";
import { ClipLoader } from "react-spinners";
import ContentWrapper from "../ContentWrapper";
import CobeeButton from "../../../app/components/CobeeButton";
import useStyles from "./styles";
import { findCensus, findCensusSummary } from "../../census-repository";
import censusesConfiguration from "../../../services/censusesConfiguration";
import startCensusValidation from "../../../usecases/census/startCensusValidation";
import downloadCensusFailures from "../../../usecases/census/downloadCensusFailures";
import confirmCensus from "../../../usecases/census/confirmCensus";
import ValidateProgress from "./components/ValidateProgress";
import TableContent from "./components/TableContent";
import assignMultipleRelatives from "../../../usecases/census/assignMultipleRelatives";

const CENSUS_STATES = {
  validated: "validated",
  validating: "validating",
  pending: "pending",
  finished: "finished",
};
const LS_KEY_DOWNLOADED_CENSUS_FAILURE = "lastDownloadedCensusFailures";
const fieldTypes = {
  "health-insurance-benefit": "insured",
  "wellness-benefit": "applicant",
};

// TODO burn this in hellfire
function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  // eslint-disable-next-line no-plusplus
  return () => setValue((value) => ++value); // update the state to force render
}

export default function CensusProgressPage() {
  const {
    buttonBar,
    step,
    activeStep,
    stepsContainer,
    alternateButtonBar,
  } = useStyles();
  const { id, censusType, company } = queryString.parse(window.location.search);

  const [isValidating, setIsValidating] = useState(false);
  const [censusStatus, setCensusStatus] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  const [data, setData] = useState(null);
  const [wasModified, setWasModified] = useState({});

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const getCensusSummary = async () => {
      const { state, rowSummary } = await findCensusSummary({
        companyId: company,
        type: censusType,
        censusId: id,
      });
      setCensusStatus({ state, rowSummary });
      if (state === CENSUS_STATES.pending) {
        const census = await findCensus({
          companyId: company,
          type: censusType,
          censusId: id,
        });
        setData(census.rows);
      }
      if (state === CENSUS_STATES.validated) {
        const census = await findCensus({
          companyId: company,
          type: censusType,
          censusId: id,
        });
        setData(census.rows);
      }
      if (state === CENSUS_STATES.finished) {
        window.location.replace(`/companies/${company}`);
      }
    };
    getCensusSummary();
  }, [id, setCensusStatus, setData, censusType, company]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isValidating) {
      const intervalId = setInterval(async () => {
        const { state } = await findCensusSummary({
          companyId: company,
          type: censusType,
          censusId: id,
        });
        if (state !== CENSUS_STATES.validating) {
          setIsValidating(false);
          window.location.replace(
            `/census-status?id=${id}&company=${company}&censusType=${censusType}`
          );
        }
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [isValidating, censusType, company, id]);

  useEffect(() => {
    if (
      censusStatus.state === CENSUS_STATES.validated &&
      data?.reduce((acc, { errors }) => acc && errors.length === 0, true)
    ) {
      window.localStorage.setItem(LS_KEY_DOWNLOADED_CENSUS_FAILURE, id);
      forceUpdate();
    }
  }, [id, data, censusStatus, forceUpdate]);

  const filterErrors = useCallback(
    (item) => {
      const shouldBeInConfirmationPage =
        window.localStorage.getItem(LS_KEY_DOWNLOADED_CENSUS_FAILURE) === id;
      const shouldBeInConfirmationPageWithoutErrors =
        censusStatus.state === CENSUS_STATES.validated &&
        shouldBeInConfirmationPage &&
        item.errors.length < 1;
      const notShouldBeInConfirmationPageWithErrors =
        censusStatus.state === CENSUS_STATES.validated &&
        !shouldBeInConfirmationPage &&
        item.errors.length >= 1;

      if (
        shouldBeInConfirmationPageWithoutErrors ||
        notShouldBeInConfirmationPageWithErrors ||
        censusStatus.state === CENSUS_STATES.pending
      ) {
        return true;
      }

      return false;
    },
    [id, censusStatus]
  );

  const shouldShowConfirmButton =
    (censusStatus.state === CENSUS_STATES.validated &&
      censusStatus.rowSummary.invalid === 0) ||
    (censusStatus.state === CENSUS_STATES.validated &&
      window.localStorage.getItem(LS_KEY_DOWNLOADED_CENSUS_FAILURE) === id);

  const isFailureActive =
    censusStatus.state === CENSUS_STATES.validated && activeStep;

  const isConfirmActive =
    censusStatus.state === CENSUS_STATES.validated &&
    (window.localStorage.getItem(LS_KEY_DOWNLOADED_CENSUS_FAILURE) === id ||
      censusStatus.rowSummary.invalid === 0) &&
    activeStep;

  const shouldDisableButton =
    isConfirming ||
    data?.filter(filterErrors).map(({ data: dataFilter, errors }) =>
      censusesConfiguration[censusType].dataParser({
        ...dataFilter,
        errors,
      })
    ).length < 1;

  const possibleFamilyMembersToAdd = useMemo(() => {
    return (
      data?.filter(({ errors }) => {
        const hasDataSomeErrorFieldTypeAndHasCodeResourceNotFound = errors?.some(
          ({ field, code }) =>
            field === fieldTypes[censusType] && code === "resource-not-found"
        );
        return (
          errors?.length === 1 &&
          hasDataSomeErrorFieldTypeAndHasCodeResourceNotFound
        );
      }) || []
    );
  }, [data, censusType]);

  return (
    <ContentWrapper
      title="Census status"
      previousURL={censusesConfiguration[censusType]?.getPreviousURL({
        companyId: company,
      })}
    >
      <Grid item container xs={12} justify="center" className={stepsContainer}>
        <Grid
          item
          xs={2}
          className={`${step} ${
            (censusStatus.state === "pending" ||
              censusStatus.state === "validated") &&
            activeStep
          }`}
          container
          justify="center"
          alignItems="center"
        >
          Validation
        </Grid>
        <Grid
          item
          xs={2}
          className={`${step} ${isFailureActive}`}
          container
          justify="center"
          alignItems="center"
        >
          Failures
        </Grid>
        <Grid
          item
          xs={2}
          className={`${step} ${isConfirmActive}`}
          container
          justify="center"
          alignItems="center"
        >
          Confirmation
        </Grid>
      </Grid>
      {(censusType === "health-insurance-benefit" ||
        censusType === "wellness-benefit") &&
        !isValidating &&
        censusStatus.state === "validated" &&
        data !== null && (
          <Grid
            item
            xs={12}
            container
            justify="flex-end"
            alignItems="center"
            className={alternateButtonBar}
          >
            <CobeeButton
              disabled={possibleFamilyMembersToAdd.length === 0}
              onClick={() =>
                assignMultipleRelatives({
                  relatives: possibleFamilyMembersToAdd,
                })
              }
            >
              Assign relatives
            </CobeeButton>
          </Grid>
        )}
      <Grid item container xs={12}>
        {data === null || isValidating ? (
          <ValidateProgress isValidating={isValidating} companyId={company} />
        ) : (
          <TableContent
            data={data.map(({ errors, ...rest }) => ({
              errors: typeof errors === "function" ? errors() : errors,
              ...rest,
            }))}
            censusStatus={censusStatus}
            filterErrors={filterErrors}
            setData={setData}
            setWasModified={setWasModified}
            wasModified={wasModified}
          />
        )}
      </Grid>
      {!isValidating && (
        <Grid item container xs={12} justify="flex-end" className={buttonBar}>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              window.location.replace(
                censusesConfiguration[censusType]?.getPreviousURL({
                  companyId: company,
                })
              )
            }
          >
            Cancel
          </CobeeButton>
          {censusStatus.state === "pending" && (
            <CobeeButton
              variant="primary"
              onClick={() => {
                setIsValidating(true);
                startCensusValidation({
                  companyId: company,
                  type: censusType,
                  censusId: id,
                });
              }}
            >
              Continue
            </CobeeButton>
          )}
          {censusStatus.state === "validated" &&
            censusStatus.rowSummary.invalid > 0 && (
              <CobeeButton
                variant="primary"
                onClick={() =>
                  downloadCensusFailures({
                    data: censusesConfiguration[censusType].errorParsing(data),
                    id,
                    censusType,
                    company,
                  })
                }
              >
                Download census failures
              </CobeeButton>
            )}
          {shouldShowConfirmButton && (
            <CobeeButton
              variant="primary"
              onClick={async () => {
                setIsConfirming(true);
                await confirmCensus({ id, censusType, company });
                setIsConfirming(false);
                window.location.replace(`/companies/${company}`);
              }}
              disabled={shouldDisableButton}
            >
              {!isConfirming ? (
                "Confirm census"
              ) : (
                <ClipLoader size={16} color="white" loading />
              )}
            </CobeeButton>
          )}
        </Grid>
      )}
    </ContentWrapper>
  );
}
