import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import CobeeButton from "../../../../../app/components/CobeeButton";
import useStyles from "./styles";

const ONE_TIME_BAG_PERIODICITY = 0;

export default function EditConfigSection({
  accumulable,
  deliverUnusedExemptToPurses,
  renewAtPeriodEnd,
  periodicity,
  callback,
}) {
  const [enabledState, setEnabledState] = useState(accumulable);
  const [deliverOnCardState, setDeliverOnCardState] = useState(
    deliverUnusedExemptToPurses
  );
  const [shouldRenewAtPeriodEnd, setShouldRenewAtPeriodEnd] = useState(
    renewAtPeriodEnd
  );
  const [isLoading, setIsLoading] = useState(false);
  const isOneTimeBag = periodicity === ONE_TIME_BAG_PERIODICITY;

  const { checkbox, buttonContainer } = useStyles();
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={enabledState}
              onChange={() => setEnabledState((prev) => !prev)}
              disabled={isOneTimeBag}
            />
          }
          label={<Typography variant="body2">Enable accumulated</Typography>}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={deliverOnCardState}
              onChange={() => setDeliverOnCardState((prev) => !prev)}
              disabled={isOneTimeBag}
            />
          }
          label={
            <Typography variant="body2">
              Deliver unspent balance on card at period end
            </Typography>
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              className={checkbox}
              checked={shouldRenewAtPeriodEnd}
              onChange={() => setShouldRenewAtPeriodEnd((prev) => !prev)}
              disabled={isOneTimeBag}
            />
          }
          label={
            <Typography variant="body2">
              Renew benefit bag at period end
            </Typography>
          }
        />
      </Grid>
      <Grid item className={buttonContainer}>
        <CobeeButton
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            callback({
              isEnabled: enabledState,
              shouldDeliverOnPeriodEnd: deliverOnCardState,
              shouldRenewAtPeriodEnd,
            });
          }}
        >
          Save
        </CobeeButton>
      </Grid>
    </Grid>
  );
}

EditConfigSection.propTypes = {
  accumulable: PropTypes.bool,
  callback: PropTypes.func,
  renewAtPeriodEnd: PropTypes.bool,
  periodicity: PropTypes.number,
  deliverUnusedExemptToPurses: PropTypes.bool,
};

EditConfigSection.defaultProps = {
  accumulable: false,
  callback: () => {},
  renewAtPeriodEnd: false,
  periodicity: undefined,
  deliverUnusedExemptToPurses: false,
};
