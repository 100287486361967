import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import moment from "moment";
import CobeeButton from "../../../../app/components/CobeeButton";
import Table from "../../../../app/components/Table";
import renderFamilyMemberDetailModal from "../../render-family-member-detail-modal";
import { findFamilyMembers } from "../../../../infrastructure/employee-repository";
import { Address } from "../../../../app/domain/address";

export default function FamilyMembers({ employeeId, homeAddress }) {
  const [familyMembers, setFamilyMembers] = useState([]);

  const onOperationSuccessful = useCallback(async () => {
    const newFamilyMembers = await findFamilyMembers(employeeId);
    setFamilyMembers(
      newFamilyMembers.map((fm) => ({
        ...fm,
        homeAddress: new Address(fm.homeAddress),
      }))
    );
  }, [employeeId]);

  useEffect(() => {
    onOperationSuccessful();
  }, [onOperationSuccessful]);

  const parseTimeStamp = (timestamp) =>
    moment(new Date(timestamp * 1000)).format("YYYY-MM-DD");

  return (
    <div className="card mb-2">
      <div className="card-body">
        <p className="card-text">Family Members</p>
        <hr />
        {familyMembers.length > 0 ? (
          <Table
            columns={[
              { label: "Legal Id", id: "legalId" },
              { label: "Name", id: "name" },
              { label: "Surname", id: "surname" },
              { label: "Type", id: "type" },
              { label: "SubType", id: "subtype" },
              { label: "Birth date", id: "birthDate" },
              { label: "Incapacity", id: "incapacityLevel" },
              {
                label: "Recurrent payments",
                id: "recurrentPayments",
              },
              { label: "Actions", id: "actions" },
            ]}
            data={familyMembers.map((familyMember) => {
              const {
                birthDate,
                recurrentPayments,
                id,
                ...rest
              } = familyMember;
              return {
                birthDate: moment(birthDate).format("YYYY-MM-DD"),
                recurrentPayments: recurrentPayments.length,
                actions: (
                  <>
                    <CobeeButton
                      variant="secondary"
                      onClick={() =>
                        renderFamilyMemberDetailModal({
                          familyMember,
                          homeAddress,
                        })
                      }
                    >
                      Details
                    </CobeeButton>
                  </>
                ),
                ...rest,
              };
            })}
          />
        ) : (
          <Typography variant="body1" align="center">
            There are not created family members
          </Typography>
        )}
      </div>
    </div>
  );
}

FamilyMembers.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
