import { makeStyles } from "@material-ui/core/styles";
import { grey300 } from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  filterField: {
    width: "100%",
  },
  datePickerInput: {
    border: `1px solid ${grey300}`,
    width: "100%",
  },
  loaderContainer: {
    padding: theme.spacing(4),
  },
}));

export default useStyles;
