import ReactDOM from "react-dom";
import React from "react";
import PromptModal from "./PromptModal";
import HexModal from "../../app/components/modals/HexModal";

export function prompt({ message, placeholder, onConfirm }) {
  ReactDOM.render(
    <HexModal>
      <PromptModal
        message={message}
        placeholder={placeholder}
        onConfirm={onConfirm}
      />
    </HexModal>,
    document.getElementById("modal-container")
  );
}
