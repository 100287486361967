import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";
import { getServerErrorMessage } from "../usecases/ErrorHandling";

export const HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE =
  "recurrent-payment-not-preauthorized-by-limit";

export async function findEmployeesByEmail(email, numMp, legalId) {
  let res;
  try {
    if (email) {
      res = await apiClient().get(`/v1/back-office/employees/email/${email}`);
    } else if (numMp) {
      res = await apiClient().get(`/v1/back-office/employees/cardId/${numMp}`);
    } else if (legalId) {
      res = await apiClient().get(
        `/v1/back-office/employees/legalId/${legalId}`
      );
    }
    return res.data;
  } catch (e) {
    return null;
  }
}

export async function findHrEmails(companyId) {
  const res = await apiClient().get(`/v1/back-office/${companyId}/hrEmails`);
  return res.data;
}

export async function findAllHrEmails() {
  const res = await apiClient().get(`/v1/back-office/allHrEmails`);
  return res.data;
}

export async function findEmployeeInsuranceModalities(employeeId) {
  const res = await apiClient().get(
    `/v1/back-office/employees/${employeeId}/modalities`
  );
  return res.data;
}

export async function findEmployeeHealthInsuranceModalitiesForNextPeriod(
  employeeId,
  companyId
) {
  const res = await apiClient().get(
    `/v1/back-office/companies/${companyId}/employees/${employeeId}/recurrent-payments/health-insurance-benefit/next-period/modalities?recipientId=${employeeId}`
  );
  return res.data;
}

export async function editEmployee(companyId, employee) {
  const res = await apiClient().put(
    `/v1/back-office/${companyId}/uploaded-employees/${employee.id}`,
    { employee }
  );
  return res;
}

export async function findFamilyMembers(employeeId) {
  const res = await apiClient().get(
    `/v1/back-office/employees/${employeeId}/family-members`
  );
  return res.data.content;
}

export async function createWithdrawalRequest(employeeId, withdrawal) {
  let res;
  try {
    res = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/withdrawal-request
     `,
      withdrawal
    );
  } catch (error) {
    showErrorMessage("An error has occurred");
  }
  return res;
}

export async function createDeliveriesRequest(employeeId, delivery) {
  let res = {};
  try {
    res = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/generate-delivery`,
      delivery
    );
  } catch (error) {
    showErrorMessage("An error has occurred");
  }
  return res;
}

export async function transferMoneyFromTransaction(employeeId, transfer) {
  let res;
  try {
    res = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/transfer-money-from-transaction
     `,
      transfer
    );
    return res;
  } catch (error) {
    showErrorMessage(
      getServerErrorMessage(error, "An unexpected error has ocurred")
    );
    return {};
  }
}

export async function voidEmployeeTransaction(employeeId, payload) {
  try {
    const res = await apiClient().put(
      `/v1/back-office/employees/${employeeId}/void-transaction
     `,
      payload
    );
    if (res.data) {
      if (!res.data.success) {
        showErrorMessage(res.data.error);
      }
    }
    return res;
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
}

export async function createHealthInsurance({
  employeeId,
  insuredId,
  modalityId,
  complementsIds,
  effectiveDate,
  companyId,
}) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/employees/${employeeId}/health-insurance`,
    { insuredId, modalityId, complementsIds, effectiveDate }
  );
  return res;
}

export async function createHealthInsuranceForNextPeriod({
  companyId,
  employeeId,
  recipientId,
  healthInsuranceProductId,
  complementIds,
}) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/employees/${employeeId}/recurrent-payments/health-insurance-benefit/next-period/`,
    {
      recipientId,
      healthInsuranceProductId,
      complementIds,
    }
  );
  return res;
}

export async function deleteHealthInsurance(employeeId, insuredId, reason) {
  const res = await apiClient().delete(
    `/v1/back-office/employees/${employeeId}/insurance-request/insured/${insuredId}`,
    { reason }
  );

  return res;
}

export async function cancelNextPeriodHealthInsurance(
  employeeId,
  nextPeriodRecurrentPaymentId
) {
  const res = await apiClient().delete(
    `/v1/back-office/employees/${employeeId}/recurrent-payments/health-insurance-benefit/${nextPeriodRecurrentPaymentId}/next-period`
  );

  return res;
}

export async function createNursery({
  employeeId,
  recipientId,
  amount,
  nurseryCentreName,
  childName,
  address,
  phoneNumber,
  isRecurrent,
  exemptionIRPF,
  lastExec,
  effectiveDate,
}) {
  const data = {
    recipientId,
    amount,
    nurseryCentreName,
    phoneNumber,
    childName,
    address,
    isRecurrent,
    exemptionIRPF,
    lastExec,
    effectiveDate,
  };

  const res = await apiClient().post(
    `/v1/back-office/employees/${employeeId}/nursery-request`,
    { ...data }
  );
  return res;
}

export async function deleteNursery(employeeId, recurrentPaymentId, reason) {
  const res = await apiClient().delete(
    `/v1/back-office/employees/nursery-payments/${recurrentPaymentId}`,
    { reason }
  );

  return res;
}

export async function refundAmountNursery({ transactionId, amount }) {
  const res = await apiClient().post(
    `/v1/back-office/transactions/refund-nursery/${transactionId}`,
    { amount }
  );

  return res;
}

export async function removeFromAuth0(employeeId) {
  try {
    const { data } = await apiClient().post(
      "/v1/back-office/employees/remove-from-identity-provider",
      {
        employeeIds: [employeeId],
      }
    );
    return data;
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
}

export async function removeFromPecunpay(employeeId) {
  try {
    const { data } = await apiClient().post(
      "/v1/back-office/employees/remove-from-card-provider",
      {
        employeeIds: [employeeId],
      }
    );
    return data;
  } catch (error) {
    showErrorMessage("An error has occurred");
    return {};
  }
}

export async function removeGDPR(employeeId) {
  try {
    const { status } = await apiClient().post(
      `/v1/back-office/employees/${employeeId}/gdpr`
    );
    return status === 201;
  } catch (error) {
    if (error?.response?.data?.message) {
      showErrorMessage(error.response.data.message);
    } else {
      showErrorMessage("An error has occurred");
    }
    return false;
  }
}

export async function editLockedFlex(employeeId, category, lockFlex) {
  const res = await apiClient().patch(
    `/v1/back-office/employees/${employeeId}/cards/locked-flex/${category}`,
    {
      lockFlex,
    }
  );

  return res.data;
}
