import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CobeeButton from "app/components/CobeeButton";
import CobeeTextField from "app/components/CobeeTextField";
import useStyles from "./styles";
import { regexConfig } from "../../regexTester";

const TYPES = [
  { id: "upload", name: "upload" },
  { id: "info", name: "info" },
];

export default function RentingSteps({
  isLoading,
  steps,
  companyId,
  benefitConfig,
  callback,
}) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const {
    mainContainer,
    stepCard,
    errorColor,
    checkbox,
    select,
    textInput,
    submitButtonContainer,
    rentingStepsTitle,
  } = useStyles();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      steps,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "steps",
  });

  useEffect(() => {
    if (steps.length > 0 && initialLoading) {
      setValue("steps", steps);
      setInitialLoading(false);
    }
  }, [setValue, steps, initialLoading]);

  const getStepError = ({ key }) => {
    let value = errors;
    key.split(".").forEach((attribute) => {
      value = value?.[attribute];
    });

    return value?.message ? (
      <FormHelperText className={errorColor}>{value.message}</FormHelperText>
    ) : null;
  };

  const getConfigError = ({ key }) => {
    const value = errors?.[key];
    return value?.message ? (
      <FormHelperText className={errorColor}>{value.message}</FormHelperText>
    ) : null;
  };

  const onSubmit = async (data) => {
    setIsSaving(true);
    await callback({
      ...data,
      companyId,
      steps: data.steps,
    });
    setIsSaving(false);
  };

  return (
    <main className={`container-fluid ${mainContainer}`}>
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6">Benefit Config</Typography>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Controller
                defaultValue={benefitConfig.enabled}
                control={control}
                name="enabled"
                render={({ field: { onChange, value, ref } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={checkbox}
                        inputRef={ref}
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Is required"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Details</Typography>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <CobeeTextField
                    type="text"
                    className={textInput}
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
                name="details"
                control={control}
                defaultValue={benefitConfig.details}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Link More Info</Typography>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <CobeeTextField
                    type="text"
                    className={textInput}
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={`linkMoreInfo` in errors}
                  />
                )}
                name="linkMoreInfo"
                control={control}
                rules={{
                  pattern: {
                    value: new RegExp(regexConfig.url.regex),
                    message: "Invalid url",
                  },
                }}
                defaultValue={benefitConfig.linkMoreInfo}
              />
              {getConfigError({ key: "linkMoreInfo" })}
            </Grid>
          </Grid>
          <Typography variant="h6" className={rentingStepsTitle}>
            Renting Steps
          </Typography>
          <Grid item xs={12} container>
            {fields.map(
              ({ name, description, required, type, id, link }, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    container
                    className={stepCard}
                    key={id}
                    alignItems="center"
                  >
                    <Grid container item xs={12} justify="flex-end">
                      <CobeeButton
                        variant="secondary"
                        onClick={() => remove(index)}
                      >
                        Remove step
                      </CobeeButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{`Step ${index + 1}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        defaultValue={required}
                        control={control}
                        name={`steps.${index}.required`}
                        render={({ field: { onChange, value, ref } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                className={checkbox}
                                inputRef={ref}
                                checked={value}
                                onChange={onChange}
                              />
                            }
                            label="Step is required"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1">Type</Typography>
                      <Controller
                        defaultValue={type}
                        control={control}
                        name={`steps.${index}.type`}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            id="step-type-select"
                            className={select}
                            variant="outlined"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <MenuItem value={undefined} disabled>
                              Select a type
                            </MenuItem>
                            {TYPES.map(({ id: typeId, name: typeName }) => (
                              <MenuItem key={typeId} value={typeName}>
                                {typeName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Link</Typography>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <CobeeTextField
                            type="text"
                            className={textInput}
                            defaultValue={value}
                            onChange={(e) => onChange(e.target.value)}
                            error={`steps.${index}.link` in errors}
                          />
                        )}
                        name={`steps.${index}.link`}
                        control={control}
                        rules={{
                          pattern: {
                            value: new RegExp(regexConfig.url.regex),
                            message: "Invalid url",
                          },
                        }}
                        defaultValue={link}
                      />
                      {getStepError({ key: `steps.${index}.link` })}
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1">Name</Typography>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <CobeeTextField
                            type="text"
                            className={textInput}
                            defaultValue={value}
                            onChange={(e) => onChange(e.target.value)}
                            error={`steps.${index}.name` in errors}
                          />
                        )}
                        name={`steps.${index}.name`}
                        control={control}
                        rules={{ required: "Field is required" }}
                        defaultValue={name}
                      />
                      {getStepError({ key: `steps.${index}.name` })}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Description</Typography>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <CobeeTextField
                            type="text"
                            defaultValue={value}
                            className={textInput}
                            onChange={(e) => onChange(e.target.value)}
                            error={`steps.${index}.description` in errors}
                          />
                        )}
                        name={`steps.${index}.description`}
                        control={control}
                        rules={{
                          required: "Field is required",
                        }}
                        defaultValue={description}
                      />
                      {getStepError({ key: `steps.${index}.description` })}
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>
          <Grid />
          <Grid item xs={12} container justify="flex-end">
            <CobeeButton
              onClick={() =>
                append({
                  name: "",
                  description: "",
                  required: false,
                  type: "info",
                  link: null,
                })
              }
            >
              Add a new step
            </CobeeButton>
          </Grid>
          <Grid
            item
            xs={12}
            className={submitButtonContainer}
            container
            justify="flex-end"
          >
            <CobeeButton
              type="submit"
              disabled={isSaving || fields.length === 0}
            >
              Save
            </CobeeButton>
          </Grid>
        </form>
      )}
    </main>
  );
}

RentingSteps.propTypes = {
  isLoading: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  benefitConfig: PropTypes.object,
  steps: PropTypes.array,
  callback: PropTypes.func.isRequired,
};

RentingSteps.defaultProps = {
  isLoading: false,
  steps: [],
  benefitConfig: { enabled: false },
};
