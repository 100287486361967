import { lighten, makeStyles } from "@material-ui/core/styles";
import { grey300, secondary, error } from "../../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingBottom: theme.spacing(2),
  },
  cardContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${grey300}`,
    borderRadius: 4,
  },
  row: {
    padding: `${theme.spacing(2)}px 0`,
  },
  inputLabel: {
    marginRight: theme.spacing(1),
  },
  select: {
    width: 400,
  },
  fieldContainer: {
    padding: theme.spacing(1),
  },
  modalityCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  submitButtonContainer: {
    borderTop: `1px ${grey300} solid`,
    paddingTop: theme.spacing(2),
  },
  errorCaption: {
    color: error,
  },
  defaultLanguageForm: {
    backgroundColor: lighten(grey300, 0.8),
  },
  languageForm: {
    backgroundColor: ({ languageColor }) => lighten(languageColor, 0.8),
  },
  buttonSpaceFiller: {
    padding: 32,
  },
  textInput: {
    backgroundColor: "white",
  },
  modalList: {
    paddingTop: theme.spacing(2),
  },
}));

export default useStyles;
