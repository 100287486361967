import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import Modal from "../../../app/components/modals/Modal";
import useStyles from "./styles";
import { hideModal } from "../../../usecases/commons/hide-modal";
import CobeeButton from "../../../app/components/CobeeButton";
import CobeeTextField from "../../../app/components/CobeeTextField";

export default function AddCapitalToModalityModal({
  title,
  callback,
  callBackIsLoading,
  actionName,
  modality,
}) {
  const {
    container,
    button,
    outlinedButton,
    containedButton,
    progress,
    row,
    input,
  } = useStyles();

  const [values, setValues] = useState({});

  useEffect(() => {
    if (modality.capitalType === "fixed") {
      setValues({
        capitalAmount: 0,
      });
    } else {
      setValues({
        salaryRatio: 0,
      });
    }
  }, [modality]);

  const handleChange = ({ event, property }) => {
    setValues({
      ...values,
      [property]: event.target.value,
    });
  };

  const isInvalid = () => {
    const keys = Object.keys(values);
    return !keys.reduce((acc, curr) => acc && Number(values[curr]) > 0, true);
  };

  return (
    <Modal title={title}>
      <Grid item xs={12} container className={container}>
        {modality.capitalType === "fixed" ? (
          <Grid item container xs={12}>
            <Grid item xs={12} container className={row}>
              <Grid item xs={2} container alignItems="center">
                <Typography variant="body2">Capital amount</Typography>
              </Grid>
              <Grid item xs={10} container alignItems="center">
                <CobeeTextField
                  type="number"
                  min={0}
                  className={input}
                  defaultValue={values.capitalAmount}
                  onChange={(event) =>
                    handleChange({ event, property: "capitalAmount" })
                  }
                />{" "}
                EUR
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12} className={row}>
            <Grid item xs={2} container alignItems="center">
              <Typography variant="body2">Salary ratio</Typography>
            </Grid>
            <Grid item xs={10} container alignItems="center">
              <CobeeTextField
                type="number"
                min={0}
                className={input}
                defaultValue={values.salaryRatio}
                onChange={(event) =>
                  handleChange({ event, property: "salaryRatio" })
                }
              />{" "}
              %
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container justify="center">
          <CobeeButton
            variant="secondary"
            onClick={hideModal}
            className={`${button} ${outlinedButton}`}
            disabled={callBackIsLoading}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            onClick={() => callback(values)}
            className={`${button} ${containedButton}`}
            disabled={callBackIsLoading || isInvalid()}
          >
            {callBackIsLoading && (
              <CircularProgress className={progress} size={15} />
            )}{" "}
            {actionName || "Save"}
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

AddCapitalToModalityModal.defaultProps = {
  title: "",
  callBackIsLoading: false,
  actionName: null,
};

AddCapitalToModalityModal.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func.isRequired,
  callBackIsLoading: PropTypes.bool,
  actionName: PropTypes.string,
};
