import { renderMentalWellnessPage } from "../../displays/mental-wellness";
import { findVendors } from "../../../../infrastructure/vendors-repository";
import adaptWellnessBenefitCreationData from "../../../../infrastructure/adapters/adaptWellnessBenefitCreationData";
import { addWellnessBenefit } from "../../../../infrastructure/wellness-repository";
import { hideModal } from "../../../commons/hide-modal";
import { showErrorMessage } from "../../../commons/show-error-message";
import { displayCompanyDetailsPage } from "../../display-company-details";
import { showSuccessMessage } from "../../../commons/show-success-message";

export async function addMentalWellnessService({ company }) {
  renderMentalWellnessPage({
    company,
    isLoading: true,
    vendors: [],
    onSave: () => {},
  });

  const { data: vendors } = await findVendors({ benefit: "wellness" });

  const onSave = async ({ payload }) => {
    try {
      await addWellnessBenefit({
        companyId: company.id,
        payload: adaptWellnessBenefitCreationData({ data: payload }),
      });
      showSuccessMessage("Benefit created successfully");
      displayCompanyDetailsPage(company.id);
    } catch (err) {
      showErrorMessage(
        err?.response?.data?.message ||
          "There was an error during the benefit creation"
      );
    } finally {
      hideModal();
    }
  };

  renderMentalWellnessPage({
    company,
    vendors: vendors.content,
    onSave,
  });
}
