import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../styles";

export default function ShowBenefitsSection({
  activeBenefits,
  allowedBenefits,
}) {
  const { benefitItem, sectionTitle } = useStyles();

  const benefitIsValidForBenefitBagFilter = (benefit) =>
    benefit !== "gift-card-benefit";
  const benefitIsNotActiveFilter = (benefit) =>
    activeBenefits.indexOf(benefit) === -1;
  const availableBenefitsToAdd = allowedBenefits
    .filter(benefitIsValidForBenefitBagFilter)
    .filter(benefitIsNotActiveFilter);

  return (
    <>
      <Grid container>
        {activeBenefits.map((item) => (
          <Grid key={item} className={benefitItem} item>
            <Typography variant="body2">{item}</Typography>
          </Grid>
        ))}
      </Grid>

      <Typography className={sectionTitle}>YOU CAN INCLUDE</Typography>
      <Grid container>
        {availableBenefitsToAdd.map((benefit) => (
          <Grid key={benefit} className={benefitItem} item>
            <Typography variant="body2">{benefit}</Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

ShowBenefitsSection.propTypes = {
  activeBenefits: PropTypes.array,
  allowedBenefits: PropTypes.array,
};

ShowBenefitsSection.defaultProps = {
  activeBenefits: [],
  allowedBenefits: [],
};
