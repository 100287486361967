import React from "react";
import ReactDOM from "react-dom";
import ConfirmVoidTransactions from "./index";

export default function renderConfirmVoidTransactionsPage({
  target = "confirm-void-notification-list",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(<ConfirmVoidTransactions {...rest} />, domTarget);
  }
}
