import React from "react";
import PropTypes from "prop-types";
import MultiSelect from "@khanacademy/react-multi-select";

const InsuranceModalities = ({
  modality,
  complementsOptions,
  modalitiesSelected,
  requiredSignature,
  handleInput,
  handleChangeComplementSelected,
  handleSignature,
  handleChangeBrochure,
}) => {
  const {
    name = "",
    fee = "",
    coverage = "",
    brochures = [],
    insideSignatureDocuments = [],
    outsideSignatureDocuments = [],
  } = modality;
  return (
    <div className="mt-5">
      <hr />
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Name Modality*</label>
        <div className="col-sm-4">
          <input
            defaultValue={name}
            required
            type="text"
            className="form-control"
            placeholder="Name Modality"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "name",
              })
            }
          />
        </div>
        <label className="col-sm-2 col-form-label">Fee Modality*</label>
        <div className="col-sm-4">
          <input
            defaultValue={fee}
            required
            type="number"
            step=".01"
            className="form-control"
            placeholder="Fee Modality"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "fee",
              })
            }
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Coverage*</label>
        <div className="col-sm-10">
          <input
            defaultValue={coverage}
            required
            type="text"
            className="form-control"
            placeholder="Coverage"
            onBlur={(e) =>
              handleInput({
                value: e.target.value,
                label: "coverage",
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Complements</label>
        <div className="col-sm-10">
          <MultiSelect
            options={complementsOptions}
            selected={modalitiesSelected}
            onSelectedChanged={(complementsSelected) =>
              handleChangeComplementSelected({
                value: complementsSelected,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Legal Brochures*</label>
        <div className="col-sm-5">
          <input
            defaultValue={brochures[0]?.title || ""}
            required
            type="text"
            className="form-control"
            placeholder="Legal Brochures"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "title",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            defaultValue={brochures[0]?.url || ""}
            required
            type="url"
            className="form-control"
            placeholder="Link"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "url",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Commercial Brochures</label>
        <div className="col-sm-5">
          <input
            defaultValue={brochures[1]?.title || ""}
            type="text"
            className="form-control"
            placeholder="Commercial Brochures"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "title",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={brochures[1]?.url || ""}
            className="form-control"
            placeholder="Link"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "url",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Commercial Brochures</label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={brochures[2]?.title || ""}
            className="form-control"
            placeholder="Commercial Brochures"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "title",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={brochures[2]?.url || ""}
            className="form-control"
            placeholder="Link"
            onBlur={(e) =>
              handleChangeBrochure({
                label: "url",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Outside Engagement Period Signature Document 1*
        </label>
        <div className="col-sm-5">
          <input
            required={requiredSignature}
            type="text"
            defaultValue={outsideSignatureDocuments[0]?.title || ""}
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            required={requiredSignature}
            defaultValue={outsideSignatureDocuments[0]?.url || ""}
            type="url"
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Outside Engagement Period Signature Document 2
        </label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={outsideSignatureDocuments[1]?.title || ""}
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={outsideSignatureDocuments[1]?.url || ""}
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Outside Engagement Period Signature Document 3
        </label>
        <div className="col-sm-5">
          <input
            defaultValue={outsideSignatureDocuments[2]?.title || ""}
            type="text"
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            defaultValue={outsideSignatureDocuments[2]?.url || ""}
            type="url"
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "outsideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Inside Engagement Period Signature Document 1
        </label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={insideSignatureDocuments[0]?.title || ""}
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={insideSignatureDocuments[0]?.url || ""}
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 0,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Inside Engagement Period Signature Document 2
        </label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={insideSignatureDocuments[1]?.title || ""}
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={insideSignatureDocuments[1]?.url || ""}
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 1,
              })
            }
          />
        </div>
      </div>

      <div className="form-group row" hidden={!requiredSignature}>
        <label className="col-sm-2 col-form-label">
          Inside Engagement Period Signature Document 3
        </label>
        <div className="col-sm-5">
          <input
            type="text"
            defaultValue={insideSignatureDocuments[2]?.title || ""}
            className="form-control"
            placeholder="Título documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "title",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
        <div className="col-sm-5">
          <input
            type="url"
            defaultValue={insideSignatureDocuments[2]?.url || ""}
            className="form-control"
            placeholder="Link documento a firmar"
            onBlur={(e) =>
              handleSignature({
                type: "insideSignatureDocuments",
                label: "url",
                value: e.target.value,
                index: 2,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

InsuranceModalities.defaultProps = {
  modality: {},
  complementsOptions: [],
  modalitiesSelected: [],
  requiredSignature: false,
  handleInput: () => {},
  handleChangeComplementSelected: () => {},
  handleSignature: () => {},
  handleChangeBrochure: () => {},
};

InsuranceModalities.propTypes = {
  modality: PropTypes.object,
  complementsOptions: PropTypes.array,
  modalitiesSelected: PropTypes.array,
  requiredSignature: PropTypes.bool,
  handleInput: PropTypes.func,
  handleChangeComplementSelected: PropTypes.func,
  handleSignature: PropTypes.func,
  handleChangeBrochure: PropTypes.func,
};

export default InsuranceModalities;
