import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import queryString from "query-string";
import ContentWrapper from "../ContentWrapper";
import DropZone from "../../../app/components/Dropzone";
import CobeeButton from "../../../app/components/CobeeButton";
import useStyles from "./styles";
import censeFromSpreadsheet from "../../../usecases/census/censeFromSpreadsheet";
import renderCensusUploadPage from "./render-census-upload-page";
import censusesConfiguration from "../../../services/censusesConfiguration";

export default function CensusUploadPage({ isLoading, file }) {
  const { censusType, company } = queryString.parse(window.location.search);
  const censusParams = censusesConfiguration[censusType];

  const { buttonBar, textSpacing, boldText } = useStyles();

  const [censusFile, setCensusFile] = useState(file);

  return (
    <ContentWrapper
      title={`Upload ${censusParams?.title} census`}
      options={censusParams?.options.map(({ label, ...rest }) => ({
        label: `Download ${label} census template`,
        ...rest,
      }))}
      previousURL={censusParams?.getPreviousURL({ companyId: company })}
    >
      <Grid item container xs={12} className={textSpacing}>
        <Typography variant="h4">Important to take into account</Typography>
      </Grid>

      <Grid item container xs={12} className={textSpacing}>
        <Typography variant="h6">
          This tool is meant to help migrate EXISTING payments to Cobee
        </Typography>
      </Grid>

      <Grid item container xs={12} className={textSpacing}>
        <Typography variant="h6" className={boldText}>
          This tool is NOT meant to register new services
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <ul>
          <Typography variant="body1" component="li">
            Special characters will be ignored if used. (eg. José will be equal
            to Jose)
          </Typography>
          <Typography variant="body1" component="li">
            Check that when excel cells are copied and pasted they do not bring
            special characters that are unseen (like spaces after the content or
            line breaks).
          </Typography>
          <Typography variant="body1" component="li">
            For employees the legal id will be the field that needs to match.
            The name and surname will be ignored.
          </Typography>
          <Typography variant="body1" component="li">
            If creating a family member, all data from the census will be
            required in order to create it.
          </Typography>
          <Typography variant="body1" component="li">
            Make sure that the columns have homogeneous type of data (all are
            text and/or all are numbers).
          </Typography>
          <Typography variant="body1" component="li">
            Date cells must be formatted as such (they won`t process them as
            text cells).
          </Typography>
          <Typography variant="body1" component="li">
            If you are completing a census that requires a policy and/or
            modality, the modality and policy field must be the same than the
            one given in the company`s BO`s information.
          </Typography>
          <Typography variant="body1" component="li">
            TYPE field must only have values like: `descendant`, `partner` or
            `employee`
          </Typography>
          <Typography variant="body1" component="li">
            SUBTYPE field must only have values like: `child`, `grandchild`,
            `spouse` or `domestic-partner`
          </Typography>
        </ul>
      </Grid>
      <Grid item container xs={12}>
        <Typography variant="h6">Points to consider:</Typography>
      </Grid>
      <Grid item container xs={12}>
        <DropZone onChange={setCensusFile} acceptedFileTypes="censusType" />
      </Grid>
      <Grid item container xs={12} justify="flex-end" className={buttonBar}>
        <CobeeButton
          variant="primary"
          disabled={censusFile.length === 0}
          isLoading={isLoading}
          onClick={async () => {
            renderCensusUploadPage({ isLoading: true, file: censusFile });
            await censeFromSpreadsheet({
              type: censusType,
              fileName: censusFile[0],
              company,
            });
            renderCensusUploadPage({ isLoading: false, file: censusFile });
          }}
        >
          Continue
        </CobeeButton>
      </Grid>
    </ContentWrapper>
  );
}

CensusUploadPage.defaultProps = {
  isLoading: false,
  file: [],
};

CensusUploadPage.propTypes = {
  isLoading: PropTypes.bool,
  file: PropTypes.array,
};
