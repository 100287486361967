import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";

export async function findCompanies(orderByName = true) {
  const res = await apiClient().get(`/v1/back-office/companies`);
  let companies = res.data;
  if (orderByName) {
    companies = companies.sort(function (a, b) {
      const textA = a.companyName.toUpperCase();
      const textB = b.companyName.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
  }
  return companies;
}

export async function findCompanyDetail(companyId) {
  const res = await apiClient().get(`/v1/back-office/companies/${companyId}`);
  return res.data;
}

export async function findBrokers() {
  const res = await apiClient().get(`/v1/back-office/health-insurance-brokers`);
  return res.data;
}

export async function createCompany(company) {
  const res = await apiClient().post(`/v1/back-office/create-company`, {
    company,
  });
  return res;
}

export async function updateCompany(company) {
  const res = await apiClient().put(
    `/v1/back-office/${company.id}/update-company`,
    { company }
  );
  return res;
}

export async function createHrAdmin({
  email,
  password,
  companyId,
  corporationId,
}) {
  let res;
  try {
    res = await apiClient().post(`/v1/back-office/create-hr-admin`, {
      email,
      password,
      companyId,
      corporationId,
    });
  } catch (error) {
    showErrorMessage("Error! HrAdmin not created", false);
  }
  return res;
}

export async function updateHrAdmin({ email, corporationId }) {
  const res = await apiClient().patch(`/v1/back-office/hr-admin/${email}`, {
    email,
    corporationId,
  });
  return res;
}

export async function transferMoney(transfers) {
  let res;
  try {
    res = await apiClient().post(
      `/v1/back-office/companies/transfer-money`,
      transfers
    );
  } catch (error) {
    showErrorMessage("An error has occurred");
  }
  return res;
}

export async function createInsuranceProduct(companyId, insuranceProduct) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/create-insurance-product`,
    { insuranceProduct }
  );
  return res;
}

export async function uploadInsuredEmployees({ employees, companyId }) {
  const response = await apiClient().post(
    `/v1/companies/${companyId}/insured-employees`,
    { employees }
  );
  return response;
}

export async function deleteHrAdmin(hrEmail) {
  const res = await apiClient().delete(`/v1/back-office/hrEmail/${hrEmail}`);
  return res;
}

export async function deleteHrAdminFromCompany({ email, companyId }) {
  try {
    const res = await apiClient().delete(
      `/v1/back-office/companies/${companyId}/privileged-users/${email}`
    );
    return res;
  } catch (error) {
    return error;
  }
}

export async function findPayrollDocuments(companyId) {
  const res = await apiClient().get(
    `/v1/back-office/${companyId}/payroll-documents/`
  );
  return res.data;
}

export async function downloadPayrollDocument(companyId, payrollDocumentId) {
  const res = await apiClient().get(
    `/v1/back-office/${companyId}/payroll-documents/${payrollDocumentId}`
  );
  return res.data;
}

export async function deleteCompany(companyId) {
  const res = await apiClient().delete(
    `/v1/back-office/companies/${companyId}`
  );
  return res;
}

export async function churnCompany(companyId) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/churn`
  );
  return res;
}

export async function saveInsuredCompany(companyId, payload) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/sign-up-insured-company`,
    payload
  );
  return res;
}

export async function findCountries() {
  const res = await apiClient().get(`/v1/back-office/countries`);
  return res.data;
}

export async function findPayrollCycles(companyId) {
  const res = await apiClient().get(
    `/v1/back-office/companies/${companyId}/payroll-cycles`
  );
  return res.data;
}

export async function recreatePayrollCycleAdjustment(
  companyId,
  payrollCycleId,
  email
) {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/payroll-cycle-adjustments/${payrollCycleId}/recreate`,
    { email }
  );
  return res.data;
}

export async function findRegionTaxRules({ countryISO2Code, region }) {
  const res = await apiClient().get(
    `/v1/back-office/country-tax-rules?country=${countryISO2Code}&region=${region}`
  );
  return res;
}

export const findBenefitBagDetail = async ({
  companyId,
  benefitPlanId,
  benefitBagConfigId,
}) => {
  const res = await apiClient().get(
    `/v1/back-office/companies/${companyId}/benefit-plans/${benefitPlanId}/benefit-bag-configs/${benefitBagConfigId}`
  );
  return res;
};

export const updateBenefitBagConfigAvailable = async ({
  companyId,
  benefitPlanId,
  benefitConfigId,
  operations,
}) => {
  const res = await apiClient().put(
    `/v1/back-office/companies/${companyId}/benefit-plans/${benefitPlanId}/benefit-bag-configs/${benefitConfigId}/benefit-bags/available`,
    { operations }
  );
  return res;
};

export const updateBenefitBagConfig = async ({
  companyId,
  benefitPlanId,
  benefitBagConfigId,
  payload,
}) => {
  const res = await apiClient().patch(
    `/v1/back-office/companies/${companyId}/benefit-plans/${benefitPlanId}/benefit-bag-configs/${benefitBagConfigId}`,
    {
      ...payload,
    }
  );
  return res;
};

export const findEmployeesPerModel = async ({ companyId, modelId, fields }) => {
  const res = await apiClient().get(
    `/v2/back-office/companies/${companyId}/employees?benefitPlanId=${modelId}&fields=${fields}`
  );

  return res;
};

export const updateLaborDaysEmployees = async ({ companyId, employees }) => {
  const res = await apiClient().patch(
    `/v2/back-office/companies/${companyId}/employees`,
    { employees }
  );
  return res;
};

export const requestCompaniesList = async ({ emails }) => {
  const res = await apiClient().post("/v1/back-office/companies/csv-report", {
    recipients: emails,
  });

  return res;
};
