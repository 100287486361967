import React, { useState } from "react";
import { Grid, Typography, FormControlLabel } from "@material-ui/core";
import useStyles, { CustomCheckbox } from "./styles";
import CobeeTextField from "../../CobeeTextField";
import CobeeButton from "../../CobeeButton";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import { solveConfirmationAboveAuthorized } from "../../../../usecases/transactions/solve-confirmation-amount-above-authorized";

export default function SolvedConfirmationAmountAboveAuthorizedModal({
  id,
  intendedAmount,
  confirmedAmount,
}) {
  const message = `You are going to create a compensation, an employee's transaction will be created and a compensation to company with remaining amount.`;
  const { container, row, buttonRow } = useStyles();
  const [values, setValues] = useState({
    reason: "",
    password: "",
    checked: false,
    isLoading: false,
  });

  const handleClickSolved = async () => {
    const { reason, password } = values;
    setValues({ ...values, isLoading: true });
    await solveConfirmationAboveAuthorized({ id, reason, password });
    setValues({ ...values, isLoading: false });
  };

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">
          Solved Confirmation Amount Above Authorized.
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Typography variant="body1">{message}</Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Authorization Amount: <b>{intendedAmount}</b>
          </Typography>
        </Grid>

        <Grid item container xs={3} className={row}>
          <Typography variant="body2">
            Confirmation Amount: <b> {confirmedAmount}</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row} alignItems="center">
          <Typography variant="body2">Reason:</Typography>
        </Grid>
        <Grid item container xs={10} className={row} alignItems="center">
          <CobeeTextField
            minWidth="100%"
            onChange={(e) => {
              setValues({
                ...values,
                reason: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row} alignItems="center">
          <Typography variant="body2">Password:</Typography>
        </Grid>
        <Grid item container xs={10} className={row} alignItems="center">
          <CobeeTextField
            type="password"
            minWidth="100%"
            onChange={(e) => {
              setValues({
                ...values,
                password: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={12} alignItems="center">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={values.checked}
                onChange={() => {
                  setValues({
                    ...values,
                    checked: !values.checked,
                  });
                }}
                disabled={values.reason === "" || values.password === ""}
              />
            }
            label="I am sure to perform this operation."
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          variant="secondary"
          isLoading={values.isLoading}
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={
            values.reason === "" || !values.checked || values.password === ""
          }
          isLoading={values.isLoading}
          onClick={handleClickSolved}
        >
          Solved Transaction
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
