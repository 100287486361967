import moment from "moment";
import apiClient from "./api-client";

export async function findNurseries() {
  const res = await apiClient().get("/v1/back-office/nurseries");
  return res;
}

export async function findNurseryPayments() {
  const from = moment().startOf("month");
  const to = moment().add(1, "month").endOf("month");
  const res = await apiClient().get(
    `/v1/back-office/nursery-payments?from=${from.toISOString()}&to=${to.toISOString()}`
  );
  return res.data;
}

export async function downloadNurseryPaymentsCsv() {
  const res = await apiClient().get(
    "/v1/back-office/nursery-payments?format=csv"
  );
  return res.data;
}

export async function updateNursery({ nurseryId, ...rest }) {
  const res = await apiClient().patch(
    `/v1/back-office/nurseries/${nurseryId}`,
    {
      ...rest,
    }
  );
  return res;
}

export const findNurseriesRequests = async () => {
  const res = await apiClient().get("/v1/back-office/nursery-requests");
  return res;
};

export const findNurseriesActivePayments = async ({ query }) => {
  const res = await apiClient().get(
    `/v2/back-office/nursery-payments?${query}`
  );
  return res;
};

export const downloadMonthNurseryPayments = async ({ query }) => {
  const res = await apiClient().get(
    `/v2/back-office/nursery-payments/csv?${query}`
  );
  return res;
};
