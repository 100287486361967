import { makeStyles } from "@material-ui/core/styles";
import { primary, lightGrey } from "../../../assets/themeColors";

const useStyles = makeStyles(() => ({
  tableContainer: {
    height: ({ isEmpty }) => (isEmpty ? "500px" : "300px"),
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      backgroundColor: primary,
      textTransform: "none",
    },

    "& .ReactVirtualized__Table__row": {
      borderBottom: `1px solid ${lightGrey}`,
      cursor: ({ onRowClick }) =>
        onRowClick !== undefined ? "pointer" : "default",
    },
  },
  tableCell: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flex: 1,
    fontSize: "13px",
  },
  lastTableCell: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flex: 1,
    paddingRight: 0,
  },
}));

export default useStyles;
