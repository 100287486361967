import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import findAllCompanies from "../../../companies/find-all-companies";
import useStyles from "./styles";
import CustomSelect from "../../../../app/components/Select";
import CobeeButton from "../../../../app/components/CobeeButton";
import downloadMovements from "../../download-movements";
import CobeeTextField from "../../../../app/components/CobeeTextField";

function DownloadMovement() {
  const { title, filterBar } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({
    name: "Select a company...",
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    const loadCompanies = async () => {
      const fetchedCompanies = await findAllCompanies();
      setCompanies(fetchedCompanies);
    };
    loadCompanies();
  }, [setCompanies]);

  const fetchMovements = async () => {
    setIsLoading(true);
    await downloadMovements({
      companyId: selectedCompany.id,
      companyName: selectedCompany.name,
      fromDate,
      toDate,
    });
    setIsLoading(false);
  };
  return (
    <main className="container-fluid pb-3">
      <Grid container xs={12} item>
        <Grid item xs={12} container className={title}>
          <Typography variant="h4">Download movements</Typography>
        </Grid>
        <Grid item xs={9} container className={filterBar}>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body1">Filter by company:</Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <CustomSelect
              value={selectedCompany}
              onChange={(value) => setSelectedCompany(value)}
              options={companies.map(({ companyName, id, ...rest }) => ({
                name: companyName,
                companyName,
                value: id,
                id,
                ...rest,
              }))}
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body1">From:</Typography>
            <CobeeTextField
              type="date"
              disabled={isLoading}
              minWidth="100%"
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body1">To:</Typography>
            <CobeeTextField
              type="date"
              disabled={isLoading}
              minWidth="100%"
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          container
          justify="flex-end"
          alignItems="center"
        >
          <CobeeButton
            onClick={fetchMovements}
            isLoading={isLoading}
            disabled={!selectedCompany.id}
          >
            Download
          </CobeeButton>
        </Grid>
      </Grid>
    </main>
  );
}

export default DownloadMovement;
