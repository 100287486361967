import React from "react";
import Modal from "react-modal";
import { hideModal } from "../../../usecases/commons/hide-modal";

import "../../../assets/modals.scss";

const HexModal = ({ children, disabled }) => {
  return (
    <Modal
      isOpen
      overlayClassName="Modal-overlay"
      onRequestClose={() => {
        if (!disabled) hideModal();
      }}
      contentLabel="modal"
      ariaHideApp={false}
      className={{
        base: "ReactModal__Overlay ReactModal__Content",
        afterOpen: "ReactModal__Overlay--after-open fadeIn",
        beforeClose: "ReactModal__Overlay--before-close fadeOut",
      }}
    >
      {children}
    </Modal>
  );
};

export default HexModal;
