import React from "react";
import ReactDOM from "react-dom";
import LifeInsuranceRequestsPage from "./index";

export default function renderLifeInsuranceRequestsPage({
  target = "page-container",
  ...rest
}) {
  const domTarget = document.getElementById(target);
  if (domTarget) {
    ReactDOM.render(<LifeInsuranceRequestsPage {...rest} />, domTarget);
  }
}
