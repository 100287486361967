import moment from "moment";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";

const parseField = ({ property, field }) =>
  property?.[field] !== undefined && property?.[field] !== null
    ? property?.[field]
    : "-";

export const restoreNurseriesActivePayments = ({
  requestedAtTimestamp,
  employee,
  company,
  recipient,
  fares,
  state,
  nursery,

  monthExecutionResult,
  monthExecutionDatetime,
}) => ({
  state,
  nurseryName: parseField({ property: nursery, field: "name" }),
  nurseryLegalName: parseField({ property: nursery, field: "legalName" }),
  nurseryId: parseField({ property: nursery, field: "legalId" }),
  nurseryType: parseField({ property: nursery, field: "type" }),
  nurseryEmail: parseField({ property: nursery, field: "contactEmail" }),
  commission: parseField({ property: nursery, field: "commission" }),
  employeeName: `${parseField({
    property: employee,
    field: "name",
  })} ${parseField({ property: employee, field: "surname" })}`,
  email: employee.email,
  companyName: parseField({ property: company, field: "companyName" }),
  childName: `${parseField({
    property: recipient,
    field: "name",
  })} ${parseField({ property: recipient, field: "surname" })}`,
  requestDate: moment.unix(requestedAtTimestamp).format("YYYY-MM-DD"),
  monthlyAmount: fares?.totalAmount
    ? MonetaryAmount.of(fares?.totalAmount?.amountInCents).prettifyMoney({})
    : "-",
  paymentDate: monthExecutionDatetime
    ? monthExecutionDatetime.split("T")[0]
    : "-",
  status: monthExecutionResult,
});
