import React from "react";
import ReactDOM from "react-dom";
import { showErrorMessage } from "../commons/show-error-message";
import { findAllUnknownNotifications } from "../../infrastructure/unknown-notifications-repository";
import UnknownNotificationsTable from "./components/UnknownNotificationsTable/UnknownNotificationsTable";
import { displayLoader } from "../commons/render-loader";

function renderUnknownNotificationsTable({ notifications, unknownSelected }) {
  ReactDOM.render(
    <UnknownNotificationsTable
      key={unknownSelected?.unknownProcessorNotificationId}
      notifications={notifications}
      unknownSelected={unknownSelected}
    />,
    document.getElementById("unknown-notification-list")
  );
}

export async function displayUnknownNotificationsTable(
  { unknownSelected = null } = { unknownSelected: null }
) {
  try {
    if (unknownSelected === null) displayLoader("unknown-notification-list");
    const res = await findAllUnknownNotifications();

    if (res.status === 200) {
      const { allNotifications: notifications } = res.data;

      renderUnknownNotificationsTable({
        notifications,
        unknownSelected,
      });
    } else {
      showErrorMessage(res.data.error);
      ReactDOM.render("", document.getElementById("transaction-detail"));
    }
  } catch (e) {
    showErrorMessage(
      "Employee not found. Only one of the two fields must be filled in and the employee must exist"
    );
    ReactDOM.render("", document.getElementById("transaction-detail"));
  }
}
