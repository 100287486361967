import React from "react";
import ReactDOM from "react-dom";
import RequestDuplicateCardModal from "./components/RequestDuplicateCardModal";

export default function renderRequestDuplicateCardModal({ card, callback }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(
      <RequestDuplicateCardModal card={card} callback={callback} />,
      domTarget
    );
  }
}
