import apiClient from "./api-client";

export const deleteRecurrentPaymentById = ({ recurrentPaymentId, reason }) => {
  return apiClient().delete(
    `/v2/back-office/recurrent-payments/${recurrentPaymentId}`,
    { reason }
  );
};

export const editRecurrentPayment = ({
  recurrentPaymentId,
  paused,
  effectiveSince,
  firstExecutionDate,
  nextExecutionDate,
  lastExecutionDate,
  requestedAtDate,
  concept,
}) => {
  return apiClient().patch(
    `/v2/back-office/recurrent-payments/${recurrentPaymentId}/`,
    {
      paused,
      effectiveSince,
      firstExecutionDate,
      nextExecutionDate,
      lastExecutionDate,
      requestedAtDate,
      concept,
    }
  );
};
