import React from "react";
import ReactDOM from "react-dom";
import HexModal from "../../../app/components/modals/HexModal";
import CensusErrorModal from "../../../app/components/modals/CensusErrorModal";

const renderCensusErrorModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <CensusErrorModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default function reviewCensusErrors({ errors }) {
  renderCensusErrorModal({ errors });
}
