import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { v4 } from "uuid";
import {
  SplitTreatments,
  withSplitFactory,
} from "@splitsoftware/splitio-react";
import useStyles from "./styles";
import BenefitImage from "./components/BenefitImage";

import CobeeButton from "../CobeeButton";
import featureFlagsDictionary from "../../../infrastructure/split/feature-flags-dictionary";
import sdkConfig from "../../../infrastructure/split/sdkConfig";
import benefitsConfig from "./config";
import Toggle from "./components/Toggle";

const BenefitsBar = ({ company }) => {
  const {
    cardContainer,
    card,
    benefitImage,
    benefitName,
    optionsContainer,
  } = useStyles();

  const shouldDisabledAddProducts = ({ label, id }) =>
    id === "wellness-benefit" &&
    label === "Add products" &&
    company.wellnessBenefits.length > 0;

  const shouldDisableShowProductDetails = ({ label, id }) =>
    id === "wellness-benefit" &&
    label === "Show details" &&
    company.wellnessBenefits.length === 0;

  const shouldDisabledShowModalitiesOverview = ({ label, id }) =>
    id === "health-insurance-benefit" &&
    label === "Show details" &&
    company.insuranceProducts.length === 0;

  const getResultantConfig = ({ isReady, treatments }) => {
    let result = [...benefitsConfig];
    if (
      isReady &&
      treatments[featureFlagsDictionary.COB_1796].treatment === "off"
    ) {
      result = result.filter(({ id }) => id !== "wellness-benefit");
    }
    if (
      isReady &&
      treatments[featureFlagsDictionary.BT_13].treatment === "off"
    ) {
      result = result.filter(({ id }) => id !== "pension-plan");
    }
    if (
      isReady &&
      treatments[featureFlagsDictionary.BT_11].treatment === "off"
    ) {
      result = result.filter(({ id }) => id !== "retirement-insurance");
    }

    return result;
  };

  const canBeToggled = ({ id }) => {
    const barConfig = benefitsConfig.find((c) => c.id === id);
    return barConfig.toggable;
  };

  return (
    <Grid container>
      <SplitTreatments
        names={[
          featureFlagsDictionary.COB_1796,
          featureFlagsDictionary.BT_13,
          featureFlagsDictionary.BT_11,
        ]}
        attributes={{ companyId: company.id, country: company.countryISO2Code }}
      >
        {({ treatments, isReady }) => {
          const resultantConfig = getResultantConfig({ isReady, treatments });
          return resultantConfig.map(({ name, bgImage, options, id }) => (
            <Grid key={v4()} item xs={2} className={cardContainer} container>
              <Grid container className={card} justify="center">
                {canBeToggled({ id }) && (
                  <Toggle company={company} benefitId={id} />
                )}
                <BenefitImage className={benefitImage} bgImage={bgImage} />
                <Typography variant="body2" className={benefitName}>
                  {name}
                </Typography>
                <div className={optionsContainer}>
                  {options.map(({ label, action }) => (
                    <CobeeButton
                      key={v4()}
                      variant="unContained"
                      onClick={() => action({ company })}
                      disabled={
                        shouldDisabledShowModalitiesOverview({ label, id }) ||
                        shouldDisabledAddProducts({ label, id }) ||
                        shouldDisableShowProductDetails({ label, id })
                      }
                    >
                      {label}
                    </CobeeButton>
                  ))}
                </div>
              </Grid>
            </Grid>
          ));
        }}
      </SplitTreatments>
    </Grid>
  );
};

BenefitsBar.propTypes = {
  company: PropTypes.object.isRequired,
};

export default withSplitFactory(sdkConfig)(BenefitsBar);
