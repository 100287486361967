import moment from "moment";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";

const parseField = ({ property, field }) => property[field] || "-";

export const restoreNurseriesRequests = ({
  requestedAtTimestamp,
  employee,
  company,
  nursery,
  benefitDetails,
  state,
  recipient,
  ...rest
}) => ({
  requestDate: moment.unix(requestedAtTimestamp).format("YYYY-MM-DD"),
  employeeLegalId: parseField({ property: employee, field: "legalId" }),
  employeeName: `${parseField({
    property: employee,
    field: "name",
  })} ${parseField({ property: employee, field: "surname" })}`,
  employeeEmail: `${parseField({
    property: employee,
    field: "email",
  })}`,
  companyName: parseField({ property: company, field: "companyName" }),
  nurseryId: parseField({ property: nursery, field: "nurseryId" }),
  nurseryName: parseField({ property: nursery, field: "name" }),
  monthlyAmount: benefitDetails?.nurseryPrice
    ? MonetaryAmount.ofCurrency(benefitDetails?.nurseryPrice).prettifyMoney({})
    : "-",
  nurseryState: parseField({ property: nursery, field: "state" }),
  state,
  recipientName: `${recipient?.name || ""} ${recipient?.surname || ""}`,
  ...nursery,
  ...rest,
});
