import React from "react";
import ReactDOM from "react-dom";

import updateTransactionRepository from "../../infrastructure/update-transaction-repository";
import HexModal from "../../app/components/modals/HexModal";

import { hideModal } from "../commons/hide-modal";
import ConfirmationWithPasswordModal from "../../app/components/modals/ConfirmationWithPasswordModal";

const renderConfirmationWithPasswordModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <ConfirmationWithPasswordModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default async function updateTransactionDetail({
  payload,
  transactionId,
}) {
  renderConfirmationWithPasswordModal({
    payload,
    callback: async (values) => {
      await updateTransactionRepository({ payload: values, transactionId });
      hideModal();
    },
  });
}
