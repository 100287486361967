import apiClient from "infrastructure/api-client";

export const addPensionPlanBenefit = async ({ companyId, payload }) => {
  const res = await apiClient().post(
    `/v1/back-office/companies/${companyId}/pension-plan/products`,
    payload
  );
  return res;
};

export const updatePensionPlanProduct = async ({
  companyId,
  pensionPlanProductId,
  payload,
}) => {
  const res = await apiClient().put(
    `/v1/back-office/companies/${companyId}/pension-plan/products/${pensionPlanProductId}`,
    payload
  );

  return res;
};
