import { toast } from "react-toastify";

export function showErrorMessage(message, autoreload = false, error = null) {
  toast.error(message, {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    bodyClassName: "cob-toast-body",
    className: "cob-toast-error",
  });

  if (autoreload)
    setTimeout(function () {
      window.location.reload();
    }, 1000);
}
