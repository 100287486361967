import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { primary } from "../../../../../assets/themeColors";

export const CustomLinearProgress = withStyles(() => ({
  root: {
    width: 496,
    height: 16,
    borderRadius: 8,
  },
  bar: {
    borderRadius: 8,
    backgroundColor: primary,
  },
}))(LinearProgress);

export const useStyles = makeStyles((theme) => ({
  containerMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  loadingStyle: {
    width: 400,
  },
  validateStyle: {
    width: 640,
  },
  buttonRow: {
    marginTop: theme.spacing(5),
  },
}));
