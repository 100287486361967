import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "./styles";
import adaptTaxRuleConfig from "../../../infrastructure/adapters/adaptTaxRuleConfig";

export default function TaxRuleCard({ name, rule }) {
  const { ruleTypeCard, ruleTypeCardContent, emptyCard } = useStyles();
  const ruleAttributes = adaptTaxRuleConfig({
    name,
    rule,
  });

  return (
    <Grid item xs={3} key={name} className={ruleTypeCard}>
      <Grid
        item
        className={`${ruleTypeCardContent} ${
          ruleAttributes.data.length === 0 ? emptyCard : ""
        }`}
        container
        xs={12}
      >
        <Grid item container xs={12} justify="center">
          <Typography variant="body1">
            <b>{ruleAttributes.label}</b>
          </Typography>
        </Grid>
        {ruleAttributes.data.length > 0 ? (
          ruleAttributes.data.map(({ label, value }) => (
            <Grid key={label} item container xs={12}>
              <Typography variant="body2">
                {label}: {value}
              </Typography>
            </Grid>
          ))
        ) : (
          <Grid item container justify="center">
            <Typography variant="h3">N/A</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

TaxRuleCard.defaultProps = {};

TaxRuleCard.propTypes = {
  name: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired,
};
