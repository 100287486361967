import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Button, CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Modal from "../Modal";
import useStyles from "./styles";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function ActionConfirmationModal({
  title,
  message,
  extraInfo,
  isWarning,
  cantBeReverted,
  callback,
  actionName,
  disableClose,
  onSuccess,
}) {
  const {
    container,
    icon,
    warning,
    textSeparation,
    button,
    outlinedButton,
    containedButton,
    progress,
  } = useStyles();

  const [callBackIsLoading, setCallBackIsLoading] = useState(false);

  return (
    <Modal title={title} disableClose={disableClose}>
      <Grid container className={container} item xs={12}>
        <Grid container item xs={12} justify="center">
          <ErrorIcon className={`${icon} ${isWarning && warning}`} />
        </Grid>
        <Grid container item xs={12}>
          {extraInfo}
        </Grid>

        <Grid
          item
          container
          xs={12}
          justify="center"
          className={textSeparation}
        >
          {message && (
            <>
              {typeof message === "string" ? (
                <Typography variant={disableClose ? "h5" : "body1"}>
                  {message}
                </Typography>
              ) : (
                message
              )}
            </>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="center"
          className={textSeparation}
        >
          {cantBeReverted && (
            <Typography variant="body2">
              This action cannot be undone
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          variant="outlined"
          onClick={hideModal}
          className={`${button} ${outlinedButton}`}
          disabled={callBackIsLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setCallBackIsLoading(true);
            callback();
          }}
          className={`${button} ${containedButton}`}
          disabled={callBackIsLoading}
        >
          {callBackIsLoading && (
            <CircularProgress className={progress} size={15} />
          )}
          {actionName || "Confirm"}
        </Button>
      </Grid>
    </Modal>
  );
}

ActionConfirmationModal.defaultProps = {
  title: "",
  isWarning: false,
  extraInfo: null,
  cantBeReverted: false,
  disableClose: false,
  actionName: null,
};

ActionConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  extraInfo: PropTypes.node,
  isWarning: PropTypes.bool,
  cantBeReverted: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  disableClose: PropTypes.bool,
};
