import { makeStyles } from "@material-ui/core/styles";
import {
  black,
  secondary,
  warningLight,
  greyMedium,
  warning90,
  softGrey,
} from "assets/themeColors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTab-textColorPrimary": {
      color: black,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: black,
    },
  },
  mainContainer: {
    padding: `${20}px ${theme.spacing(4)}px`,
  },
  firstRow: {
    marginBottom: theme.spacing(4),
  },
  backContainer: {
    width: "81px",
    marginRight: "26px",
    cursor: "pointer",
  },
  arrowBackStyle: {
    marginRight: "11px",
    color: secondary,
  },
  arrowForwardStyle: {
    margin: "0px 17px",
  },
  backText: {
    color: secondary,
  },
  title: {
    color: black,
    fontWeight: 700,
  },
  productSubtitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  tabsContainer: {
    marginTop: "30px",
    maxWidth: "90vw",
    borderBottom: `1px solid ${softGrey}`,
  },
  addIconContainer: {
    maxWidth: "10vw",
    marginLeft: theme.spacing(5),
    cursor: "pointer",
  },
  addProductText: {
    marginLeft: "10px",
    color: secondary,
  },
  addIconStyle: {
    color: secondary,
  },
  pendingConfigBox: {
    backgroundColor: warningLight,
    marginLeft: "auto",
    borderRadius: "4px",
  },
  uploadCensusButton: {
    marginLeft: "auto",
  },
  warningIcon: {
    color: warning90,
  },
  pendingConfigText: {
    fontSize: "12px",
    color: greyMedium,
    marginLeft: "10px",
  },
}));

export default useStyles;
