import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import moment from "moment";
import qs from "qs";
import Table from "../../../../app/components/Table";
import { columnConfig } from "./config";
import NurseriesFilter from "../NurseriesFilter";
import searchInKeys from "../../../../services/searchInKeys";
import { keys } from "../NurseriesPage/config";
import useStyles from "./styles";
import CobeeButton from "../../../../app/components/CobeeButton";
import { downloadMonthNurseryPayments } from "../../../../infrastructure/nursery-repository";
import { fileDownloadHandler } from "../../../commons/file-download-handler";
import { showErrorMessage } from "../../../commons/show-error-message";

export default function NurseriesActivePayments({
  data,
  isLoading,
  dateFilter,
  setDateFilter,
}) {
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [page, setPage] = useState(undefined);
  const [forcePagination, setForcePagination] = useState(false);

  const {
    notApplicableStatus,
    pendingStatus,
    confirmedStatus,
    rejectedStatus,
    buttonBar,
  } = useStyles();

  const searchResults = useCallback(
    (employees) => {
      return searchInKeys({
        search,
        items: employees,
        keys: keys(columnConfig),
      });
    },
    [search]
  );

  useEffect(() => {
    setProcessedData(
      searchResults(
        filterValue === "all"
          ? data
          : data.filter(({ status }) => status === filterValue)
      )
    );
  }, [setProcessedData, search, filterValue, data, searchResults]);

  const statusClassName = {
    notApplicable: notApplicableStatus,
    pending: pendingStatus,
    confirmed: confirmedStatus,
    "rejected-by-cobee": rejectedStatus,
    cancelled: rejectedStatus,
  };

  const handleSearch = debounce(1000, false, (value) => {
    setPage(value === "" ? undefined : 0);
    setForcePagination(value !== "");
    setSearch(value);
  });

  return (
    <>
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <>
          <NurseriesFilter
            items={[
              { id: "not-applicable", name: "not-applicable" },
              { id: "pending", name: "pending" },
              { id: "postponed", name: "postponed" },
              { id: "succeeded", name: "succeeded" },
              { id: "rejected", name: "rejected" },
              { id: "error", name: "error" },
            ]}
            dateFilter={dateFilter}
            handleDateFilter={setDateFilter}
            handleFilter={(value) => {
              setPage(value === "all" ? undefined : 0);
              setForcePagination(value !== "all");
              setFilterValue(value);
            }}
            handleSearch={handleSearch}
          />
          <Grid container justify="flex-end" className={buttonBar}>
            <CobeeButton
              isLoading={downloadIsLoading}
              onClick={async () => {
                setDownloadIsLoading(true);
                try {
                  const { data: res } = await downloadMonthNurseryPayments({
                    query: qs.stringify({
                      month: moment(dateFilter).format("YYYY-MM"),
                    }),
                  });
                  fileDownloadHandler(
                    `${moment(dateFilter).format("YYYY-MM")}-nursery-payments`,
                    res
                  );
                } catch (err) {
                  showErrorMessage(
                    err?.response?.data?.message ||
                      "An error occurred while attempting to download the nursery payments"
                  );
                } finally {
                  setDownloadIsLoading(false);
                }
              }}
            >
              Download month payments
            </CobeeButton>
          </Grid>
          <Table
            columns={columnConfig}
            data={processedData.map(({ status, ...rest }) => ({
              status: (
                <Typography className={statusClassName[status]}>
                  {status}
                </Typography>
              ),
              ...rest,
            }))}
            forcePagination={forcePagination}
            pageNumber={page}
          />
        </>
      )}
    </>
  );
}

NurseriesActivePayments.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  setDateFilter: PropTypes.func,
  dateFilter: PropTypes.object,
};

NurseriesActivePayments.defaultProps = {
  isLoading: false,
  setDateFilter: () => {},
  dateFilter: new Date(),
};
