import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { darken, lighten, transparentize } from "polished";
import { secondary, white, primary } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    maxWidth: 1200,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(2),
  },
  buttonRow: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  chipRow: {
    padding: `${theme.spacing(1)}px 0`,

    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      backgroundColor: lighten(0.1, secondary),
      color: white,
      fontSize: theme.typography.body2.fontSize,

      "& svg": {
        color: transparentize(0.3, white),
      },
    },
  },
  emailSelect: {
    width: "100%",
  },
}));

export default useStyles;
