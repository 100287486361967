import React from "react";
import ReactDOM from "react-dom";
import { displayLoader } from "../commons/render-loader";
import { showErrorMessage } from "../commons/show-error-message";
import {
  findCategorizationPatterns,
  findLedgerTransactionsByEmail,
  findMerchantCategories,
} from "../../infrastructure/transaction-repository";
import TransactionsTablePage from "./components/TransactionsTablePage";
import { mapCategorization } from "./components/TransactionsTablePage/utils";

export async function displayTransactionsTable({
  email,
  numMp,
  unknownSelected,
  showOnlyUnknown,
}) {
  try {
    displayLoader("transaction-detail");

    const res = await findLedgerTransactionsByEmail(email, numMp);

    if (res.success) {
      let transactions = res.data;
      const employeeId = res.data[0]?.employeeId;
      let categories;
      if (employeeId) {
        categories = await findMerchantCategories({ employeeId });
      }
      const categorizationPatterns = await findCategorizationPatterns({
        employeeId,
      });
      if (res.data.length) {
        if (unknownSelected) {
          transactions = transactions.filter(
            (trans) => trans.type === "card-purchase"
          );
        }

        renderTransactionsTable({
          transactions: mapCategorization({
            transactions,
            categorizationPatterns,
          }),
          email,
          numMp,
          unknownSelected,
          categories,
          showOnlyUnknown,
        });
      } else {
        showErrorMessage("transactions not found");
        ReactDOM.render("", document.getElementById("transaction-detail"));
      }
    } else {
      showErrorMessage(res.error);
      ReactDOM.render("", document.getElementById("transaction-detail"));
    }
  } catch (e) {
    showErrorMessage(
      "Employee not found. Only one of the two fields must be filled in and the employee must exist"
    );
    ReactDOM.render("", document.getElementById("transaction-detail"));
  }
}

function renderTransactionsTable(props) {
  ReactDOM.render(
    <TransactionsTablePage {...props} />,
    document.getElementById("transaction-detail")
  );
}
