import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import Proptypes from "prop-types";
import Modal from "../../../../../app/components/modals/Modal";
import CobeeTextField from "../../../../../app/components/CobeeTextField";
import CobeeButton from "../../../../../app/components/CobeeButton";
import { hideModal } from "../../../../commons/hide-modal";
import useStyles from "./styles";

const ISO_DATE_FORMAT = "YYYY-MM-DD";

export default function ModifyRecurrentPaymentDatesModal({
  recurrentPayment,
  callback,
  postActionCallback,
}) {
  const { container, row, buttonRow, rowDescription } = useStyles();
  const [values, setValues] = useState({
    effectiveSince: recurrentPayment.effectiveSince,
    firstExecutionDate: moment
      .unix(recurrentPayment.firstExecTimestamp)
      .format(ISO_DATE_FORMAT),
    nextExecutionDate: moment
      .unix(recurrentPayment.nextExecTimestamp)
      .format(ISO_DATE_FORMAT),
    lastExecutionDate: moment
      .unix(recurrentPayment.lastExecTimestamp)
      .format(ISO_DATE_FORMAT),
  });
  const [isLoading, setIsLoading] = useState(false);

  const formIsReady = () => {
    const {
      effectiveSince,
      firstExecutionDate,
      nextExecutionDate,
      lastExecutionDate,
    } = values;
    return (
      Boolean(effectiveSince) &&
      Boolean(firstExecutionDate) &&
      Boolean(nextExecutionDate) &&
      Boolean(lastExecutionDate) &&
      firstExecutionDate <= nextExecutionDate &&
      nextExecutionDate <= lastExecutionDate
    );
  };

  return (
    <Modal title="Edit Recurrent Payment Dates">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={rowDescription} />
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row} alignItems="center">
            <Typography variant="body2">Effective Since Date:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              minWidth="100%"
              type="date"
              defaultValue={moment(recurrentPayment.effectiveSince).format(
                ISO_DATE_FORMAT
              )}
              onChange={(e) =>
                setValues({
                  ...values,
                  effectiveSince: moment(e.target.value).format(
                    ISO_DATE_FORMAT
                  ),
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row} alignItems="center">
            <Typography variant="body2">First exec Date:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              minWidth="100%"
              type="date"
              defaultValue={moment(
                new Date(recurrentPayment.firstExecTimestamp * 1000)
              ).format(ISO_DATE_FORMAT)}
              onChange={(e) =>
                setValues({
                  ...values,
                  firstExecutionDate: moment(e.target.value).format(
                    ISO_DATE_FORMAT
                  ),
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row} alignItems="center">
            <Typography variant="body2">Next exec Date:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              minWidth="100%"
              type="date"
              defaultValue={moment(
                new Date(recurrentPayment.nextExecTimestamp * 1000)
              ).format(ISO_DATE_FORMAT)}
              onChange={(e) =>
                setValues({
                  ...values,
                  nextExecutionDate: moment(e.target.value).format(
                    ISO_DATE_FORMAT
                  ),
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row} alignItems="center">
            <Typography variant="body2">Last exec Date:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              minWidth="100%"
              type="date"
              defaultValue={moment(
                new Date(recurrentPayment.lastExecTimestamp * 1000)
              ).format(ISO_DATE_FORMAT)}
              onChange={(e) =>
                setValues({
                  ...values,
                  lastExecutionDate: moment(e.target.value).format(
                    ISO_DATE_FORMAT
                  ),
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            variant="danger"
            disabled={isLoading || !formIsReady()}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(values);
              setIsLoading(false);
              await hideModal();
              await postActionCallback();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

ModifyRecurrentPaymentDatesModal.propTypes = {
  recurrentPayment: Proptypes.shape({
    effectiveSince: Proptypes.string.isRequired,
    firstExecTimestamp: Proptypes.number.isRequired,
    nextExecTimestamp: Proptypes.number.isRequired,
    lastExecTimestamp: Proptypes.number.isRequired,
  }).isRequired,
  callback: Proptypes.func.isRequired,
  postActionCallback: Proptypes.func.isRequired,
};
