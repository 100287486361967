import { updateHealthInsuranceEmailsSubscribers } from "../../../infrastructure/health-insurance-repository";
import { showErrorMessage } from "../../commons/show-error-message";
import { showSuccessMessage } from "../../commons/show-success-message";

export async function updateHealthInsuranceEmails({ companyId, emails }) {
  const res = await updateHealthInsuranceEmailsSubscribers({
    companyId,
    emails,
  });
  if (res.status === 200) {
    showSuccessMessage("Emails updates");
  } else {
    showErrorMessage("Error updating emails");
  }
}
