import { useState, useEffect } from "react";
import { findCompanies } from "infrastructure/company-repository";
import { showErrorMessage } from "usecases/commons/show-error-message";
import restoreContributionsCompanies from "infrastructure/adapters/contributions/restoreContributionsCompanies";

export default function useLoadCompanies({ setIsLoading }) {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const loadCompanies = async () => {
      setIsLoading(true);
      try {
        const res = await findCompanies();
        const restoredData = restoreContributionsCompanies({ data: res });
        setCompanies(restoredData);
      } catch (err) {
        showErrorMessage(
          "An error occurred while attempting to fetch companies"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (companies.length === 0) {
      loadCompanies();
    }
  }, [companies, setCompanies, setIsLoading]);

  return { companies };
}
