import * as cardProcessorLogRepository from "../../infrastructure/card-processor-logs-repository";
import { fileDownloadHandler } from "../commons/file-download-handler";

export async function downloadCardProcessorLogs({
  companyId,
  cardId,
  employeeId,
}) {
  const file = await cardProcessorLogRepository.downloadCardProcessorLogs({
    companyId,
    cardId,
    employeeId,
  });
  const title = `card-processor-logs`;
  await fileDownloadHandler(title, file);
}
