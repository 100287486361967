import apiClient from "./api-client";
import { showErrorMessage } from "../usecases/commons/show-error-message";

export const findErrorLogs = async ({ employeeId, pageNumber, pageSize }) => {
  try {
    const {
      data,
    } = await apiClient().get(
      `/v1/back-office/employees/${employeeId}/error-logs`,
      { params: { pageNumber, pageSize } }
    );
    return data;
  } catch (err) {
    showErrorMessage("An error has occurred");
    return {};
  }
};
