import { useEffect, useState, useCallback } from "react";
import * as repository from "infrastructure/corporation-repository";

function useCorporation(corporationId) {
  const [corporation, setCorporation] = useState([]);

  const fetch = useCallback(async () => {
    await repository
      .getCorporation(corporationId)
      .then((response) => setCorporation(response));
  }, [corporationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { corporation };
}

function useCompanies(corporationId) {
  const [companies, setCompanies] = useState([]);

  const fetch = useCallback(async () => {
    await repository
      .getCompanies(corporationId)
      .then((response) => setCompanies(response));
  }, [corporationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { companies, refetch: fetch };
}

function useHrAdmins(corporationId) {
  const [hrAdmins, setHrAdmins] = useState([]);

  const fetch = useCallback(async () => {
    repository
      .getHrAdmins(corporationId)
      .then((response) => setHrAdmins(response));
  }, [corporationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { hrAdmins, refetch: fetch };
}

export function useInitialData(corporationId) {
  const { corporation } = useCorporation(corporationId);
  const { companies, refetch: refetchCompanies } = useCompanies(corporationId);
  const { hrAdmins, refetch: refetchHRAdmins } = useHrAdmins(corporationId);

  return {
    corporation,
    companies,
    refetchCompanies,
    hrAdmins,
    refetchHRAdmins,
  };
}
