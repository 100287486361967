import React from "react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";
import TransactionsPage from "./components/TransactionsPage";

export async function displayTransactionsPage() {
  renderTransactionsPage();
}

export function renderTransactionsPage() {
  ReactDOM.render(
    <TransactionsPage key={v4()} />,
    document.getElementById("page-container")
  );
}
