import { displayTransactionsTable } from "./display-transactions-table";
import { displayUnknownNotificationsTable } from "./display-unknown-notifications-table";

export async function filterTransactions({
  email = "",
  numMp = "",
  unknownSelected = null,
}) {
  displayTransactionsTable({
    email,
    numMp,
    unknownSelected,
    showOnlyUnknown: true,
  });
}
