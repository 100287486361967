import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import CobeeButton from "app/components/CobeeButton";
import displayActionConfirmationModal from "usecases/commons/display-action-confirmation-modal";
import { hideModal } from "usecases/commons/hide-modal";
import englishFlag from "../../../../../assets/img/english-flag.png";
import spanishFlag from "../../../../../assets/img/spanish-flag.png";
import portugueseFlag from "../../../../../assets/img/portuguese-flag.png";
import useStyles from "./styles";
import DefaultLanguagePensionPlanForm from "./components/DefaultLanguagePensionPlanForm";
import OtherLanguagePensionPlanForm from "./components/OtherLanguagePensionPlanForm";

const languages = [
  { label: "Spanish", id: "es", flag: spanishFlag },
  { label: "Portuguese", id: "pt", flag: portugueseFlag },
];

export default function PensionPlanProductForm({
  product,
  onChangeTabName,
  brokers,
  vendors,
  isSaving,
  handleSubmitData,
  handleUpdateData,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const {
    subTitle,
    defaultEnglish,
    languageBlock,
    languageChecked,
    verticalDivider,
    horizontalDivider,
    englishBlock,
    languageLabel,
  } = useStyles();

  const isEmptyProduct = Object.entries(product).length === 0;

  const initialValues = isEmptyProduct
    ? { enabled: true }
    : { enabled: product.enabled };
  languages.forEach(({ id }) => {
    initialValues[id] =
      isEmptyProduct || product?.languages[id] === undefined
        ? {
            name: "",
            description: "",
            attachments: {
              url: "",
              title: "",
            },
          }
        : product?.languages[id];
  });

  const methods = useForm({ mode: "onSubmit", defaultValues: initialValues });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    displayActionConfirmationModal({
      title: "Attention",
      message: (
        <Typography variant="body1" align="center">
          Remember to fill all the fields of the products language variants you
          want to be visible.
        </Typography>
      ),
      callback: () => {
        hideModal();
        if (isEmptyProduct) {
          handleSubmitData({ data });
        } else {
          handleUpdateData({ data: { ...data, id: product.id } });
        }
      },
    });
  };

  const isSelectedLanguage = ({ index }) =>
    languages[index].id === selectedLanguage;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={3}>
          <DefaultLanguagePensionPlanForm
            product={product}
            brokers={brokers}
            vendors={vendors}
            isEmptyProduct={isEmptyProduct}
            onChangeTabName={onChangeTabName}
          />
          <Grid container item spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">Languages specifications</Typography>
              <Typography className={subTitle}>
                Choose how you want the admin to see it on their dashboard
                platform
              </Typography>
              <Grid container className={englishBlock}>
                <img src={englishFlag} alt="english-flag" />
                <Typography variant="body2" className={defaultEnglish}>
                  English by default
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {languages.map(({ label, id, flag }, index) => (
                <Box
                  key={id}
                  p={2}
                  className={
                    isSelectedLanguage({ index })
                      ? `${languageBlock} ${languageChecked}`
                      : languageBlock
                  }
                  onClick={() => setSelectedLanguage(id)}
                >
                  <Grid container>
                    <img src={flag} alt={`${id}-flag`} />
                    <Typography variant="body2" className={languageLabel}>
                      {label}
                    </Typography>
                  </Grid>
                </Box>
              ))}
            </Grid>
            <span className={verticalDivider} />
            <Grid container item xs={6} spacing={2}>
              <OtherLanguagePensionPlanForm
                selectedLanguage={selectedLanguage}
              />
            </Grid>
          </Grid>
          <span className={horizontalDivider} />

          <Grid container justify="flex-end">
            <CobeeButton type="submit" isLoading={isSaving}>
              {isEmptyProduct ? "Create product" : "Save changes"}
            </CobeeButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

PensionPlanProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  onChangeTabName: PropTypes.func.isRequired,
  brokers: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  isSaving: PropTypes.bool,
  handleSubmitData: PropTypes.func.isRequired,
  handleUpdateData: PropTypes.func.isRequired,
};

PensionPlanProductForm.defaultProps = {
  isSaving: false,
};
