import React from "react";
import ReactDOM from "react-dom";
import TransactionDetail from "../transactions/components/TransactionDetail";
import Modal from "../../app/components/modals/Modal";

export async function displayTransactionDetailsModal(
  transaction,
  email,
  numMp
) {
  renderTransactionDetailsModal({ transaction, email, numMp });
}

export function renderTransactionDetailsModal({ transaction, email, numMp }) {
  ReactDOM.render(
    <Modal>
      <TransactionDetail
        transaction={transaction}
        email={email}
        numMp={numMp}
        inModal
      />
    </Modal>,
    document.getElementById("modal-container")
  );
}
