import * as React from "react";
import PropTypes from "prop-types";
import { MUI } from "@cobee/cobee-ui";
import { Modality } from "v2/domain/contexts/healthInsurance/entities/Modality";
import { TableHead } from "./TableHead";
import { Row } from "./Row";

export const ModalitiesTable = ({ data, keyExtractor = (item) => item.id }) => (
  <MUI.TableContainer>
    <MUI.Table
      stickyHeader
      aria-labelledby="tableTitle"
      size="small"
      width="100%"
    >
      <TableHead />
      <MUI.TableBody>
        {data.map((item) => {
          const id = keyExtractor(item);
          return <Row key={id} id={id} data={item} />;
        })}
      </MUI.TableBody>
    </MUI.Table>
  </MUI.TableContainer>
);

ModalitiesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Modality)).isRequired,
  keyExtractor: PropTypes.func,
};

ModalitiesTable.defaultProps = {
  keyExtractor: (item) => item.id,
};
