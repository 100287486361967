import adaptLifeInsuranceCreationPayload from "../../../../infrastructure/adapters/lifeInsurance/adaptLifeInsuranceCreationPayload";
import { createLifeInsurance } from "../../../../infrastructure/life-insurance-repository";
import { showErrorMessage } from "../../../commons/show-error-message";
import { displayCompanyDetailsPage } from "../../../companies/display-company-details";
import { showSuccessMessage } from "../../../commons/show-success-message";

export default async function createLifeInsurancePolicy({
  lifeInsurancePolicy,
}) {
  try {
    await createLifeInsurance({
      companyId: lifeInsurancePolicy.companyId,
      payload: adaptLifeInsuranceCreationPayload({
        lifeInsurancePolicy,
      }),
    });
    displayCompanyDetailsPage(lifeInsurancePolicy.companyId);
    showSuccessMessage("Life insurance policy created successfully");
  } catch (err) {
    showErrorMessage(
      "An error has occurred while creating a life insurance policy"
    );
  }
}
