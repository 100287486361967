import { updateLaborDaysEmployees } from "../../infrastructure/company-repository";
import { showErrorMessage } from "../commons/show-error-message";

export default async function editLaborDays({ companyId, payload }) {
  try {
    const parsePayload = payload.map(({ legalId, laborDays }) => ({
      legalId,
      daysPerMonth: laborDays,
    }));
    const res = await updateLaborDaysEmployees({
      companyId,
      employees: parsePayload,
    });
    return res;
  } catch (error) {
    showErrorMessage(
      error?.response?.data?.message ||
        "There was an error during the update operation"
    );
    return {};
  }
}
