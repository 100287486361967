import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { displayTransactionsPage } from "./display-transactions-page";
import { solveUnknownNotification } from "../../infrastructure/transaction-repository";

export const cancelTransaction = async ({ transactionId, unknownId }) => {
  try {
    const res = await solveUnknownNotification(transactionId, unknownId);
    const { error } = res?.data;
    if (error) {
      showErrorMessage(error);
    }
    showSuccessMessage("Unknown transaction solved!");
    displayTransactionsPage();
  } catch (e) {
    showErrorMessage("Error when cancelling notification.");
  }
};
