import { showSuccessMessage } from "../commons/show-success-message";
import {
  createHealthInsurance,
  HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE,
} from "../../infrastructure/employee-repository";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";

export async function saveEmployeeHealthInsurance({
  employee,
  insuredId,
  modalityId,
  complementsIds,
  effectiveDate,
  companyId,
}) {
  if (!employee || !insuredId || !modalityId || !effectiveDate)
    showErrorMessage("Missing compulsory parameters", false);

  try {
    await createHealthInsurance({
      employeeId: employee.id,
      insuredId,
      modalityId,
      complementsIds,
      effectiveDate,
      companyId,
    });
    showSuccessMessage("Successfully!");
    displayEmployeeDetail(employee.email);
  } catch (error) {
    if (
      error?.response?.data?.code === HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE
    ) {
      showErrorMessage(
        "You may not request the Health Insurance due to fiscal limits"
      );
    } else {
      showErrorMessage(error?.response?.data?.message || "Error!", false);
    }
  }
}
