import React from "react";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { v4 } from "uuid";
import useStyles, { CustomRadio } from "../styles";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import CobeeButton from "../../../../app/components/CobeeButton";
import CapitalRow from "./CapitalRow";
import renderAddCapitalToModalityModal from "../../AddCapitalToModalityModal/render-add-capital-to-modality-modal";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import renderAddLifeInsurancePage from "../render-add-life-insurance-page";
import isAValidURL from "../../../../services/isAValidURL";

export default function LifeInsuranceModality({
  onChange,
  index,
  lastFieldModified,
  modality,
  showDeleteButton,
  removeModality,
  lifeInsurancePolicy,
}) {
  const { row, divider, validURLMessage } = useStyles();

  return (
    <Grid item xs={12} container>
      <Grid item container xs={12} className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Modality name</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeTextField
            autoFocus={lastFieldModified === `modality-${index}-name`}
            value={modality.name}
            onChange={(e) =>
              onChange({
                property: "name",
                value: modality.modName({ name: e.target.value }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Legal brochure</Typography>
        </Grid>
        <Grid item xs={4}>
          <CobeeTextField
            autoFocus={
              lastFieldModified === `modality-${index}-legalBrochure-label`
            }
            value={modality.legalBrochures[0].label}
            onChange={(e) =>
              onChange({
                property: `legalBrochure-label`,
                value: modality.modLegalBrochures({
                  legalBrochures: [
                    {
                      label: e.target.value,
                      link: modality.legalBrochures[0].link,
                    },
                  ],
                }),
              })
            }
          />
        </Grid>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Link</Typography>
        </Grid>
        <Grid item xs={4} container alignItems="center">
          <CobeeTextField
            placeholder="http://www.cobee.io"
            autoFocus={
              lastFieldModified === `modality-${index}-legalBrochure-link`
            }
            value={modality.legalBrochures[0].link}
            onChange={(e) =>
              onChange({
                property: `legalBrochure-link`,
                value: modality.modLegalBrochures({
                  legalBrochures: [
                    {
                      link: e.target.value,
                      label: modality.legalBrochures[0].label,
                    },
                  ],
                }),
              })
            }
            error={!isAValidURL(modality.legalBrochures[0].link)}
          />
          {!isAValidURL(modality.legalBrochures[0].link) && (
            <Typography variant="caption" className={validURLMessage}>
              Plase input a valid URL
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Coverage</Typography>
        </Grid>
        <Grid item xs={10}>
          <CobeeTextField
            autoFocus={lastFieldModified === `modality-${index}-coverage`}
            value={modality.coverage}
            onChange={(e) =>
              onChange({
                property: "coverage",
                value: modality.modCoverage({ coverage: e.target.value }),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item xs={2} container alignItems="center">
          <Typography variant="body1">Capital</Typography>
        </Grid>
        <Grid item xs={10}>
          <RadioGroup
            value={modality.capitalType}
            onChange={(e) =>
              onChange({
                property: "capitalType",
                value: modality.modCapitalType({ capitalType: e.target.value }),
              })
            }
          >
            <Grid container>
              <FormControlLabel
                value="fixed"
                control={<CustomRadio disableRipple />}
                label="Fixed"
              />
              <FormControlLabel
                value="variable"
                control={<CustomRadio disableRipple />}
                label="Variable"
              />
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item xs={2} />
        <Grid item xs={10} container>
          {modality.capitals.map((capital, i) => (
            <CapitalRow
              key={v4()}
              type={modality.capitalType}
              capital={capital}
              onRemove={() => {
                renderAddLifeInsurancePage({
                  lifeInsurancePolicy: lifeInsurancePolicy.modModality({
                    modality: modality.removeCapital({ index: i }),
                    index,
                  }),
                });
                hideModal();
              }}
            />
          ))}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={10} className={row} container alignItems="center">
          <CobeeButton
            onClick={() =>
              renderAddCapitalToModalityModal({
                modality,
                callback: (capital) => {
                  renderAddLifeInsurancePage({
                    lifeInsurancePolicy: lifeInsurancePolicy.modModality({
                      modality: modality.addCapital({ capital }),
                      index,
                    }),
                  });
                  hideModal();
                },
              })
            }
          >
            {`Add ${modality.capitals.length > 0 ? "another " : "an "}amount`}
          </CobeeButton>
        </Grid>
        {showDeleteButton && (
          <Grid
            item
            xs={12}
            className={row}
            container
            alignItems="center"
            justify="flex-end"
          >
            <CobeeButton variant="secondary" onClick={removeModality}>
              Delete modality
            </CobeeButton>
          </Grid>
        )}
        <Grid item xs={12} className={divider} />
      </Grid>
    </Grid>
  );
}
