export default class LifeInsurancePolicyModality {
  name;

  legalBrochures;

  coverage;

  capitalType;

  capitalAmount;

  constructor({
    name = "",
    legalBrochures = [
      {
        label: "",
        link: "",
      },
    ],
    coverage = "",
    capitalType = "fixed",
    capitals = [],
  }) {
    this.name = name;
    this.legalBrochures = legalBrochures;
    this.coverage = coverage;
    this.capitalType = capitalType;
    this.capitals = capitals;
  }

  modName = ({ name }) =>
    new LifeInsurancePolicyModality({
      ...this,
      name,
    });

  modLegalBrochures = ({ legalBrochures }) =>
    new LifeInsurancePolicyModality({
      ...this,
      legalBrochures,
    });

  modCoverage = ({ coverage }) =>
    new LifeInsurancePolicyModality({
      ...this,
      coverage,
    });

  modCapitalType = ({ capitalType }) =>
    new LifeInsurancePolicyModality({
      ...this,
      capitalType,
      capitals: [],
    });

  modCapital = ({ capital, index }) =>
    new LifeInsurancePolicyModality({
      ...this,
      capitals: this.capitals.map((cap, i) =>
        i === index ? { ...cap, ...capital } : cap
      ),
    });

  addCapital = ({ capital }) =>
    new LifeInsurancePolicyModality({
      ...this,
      capitals: [...this.capitals, capital].sort((a, b) => {
        if (this.capitalType === "fixed") {
          if (Number(a.capitalAmount) < Number(b.capitalAmount)) {
            return -1;
          }
          if (Number(a.capitalAmount) > Number(b.capitalAmount)) {
            return 1;
          }
          return 0;
        }
        if (this.capitalType === "variable") {
          if (Number(a.salaryRatio) < Number(b.salaryRatio)) {
            return -1;
          }
          if (Number(a.salaryRatio) > Number(b.salaryRatio)) {
            return 1;
          }
          return 0;
        }
        return 0;
      }),
    });

  removeCapital = ({ index }) =>
    new LifeInsurancePolicyModality({
      ...this,
      capitals: this.capitals.filter((a, i) => i !== index),
    });
}
