import renderSendInvoiceModal from "./render-send-invoice-modal";
import { sendAndResolveInvoices } from "../../../infrastructure/finance-repository";
import { showErrorMessage } from "../../commons/show-error-message";
import { findCompanyDetail } from "../../../infrastructure/company-repository";
import { hideModal } from "../../commons/hide-modal";

export default async function sendInvoices({
  invoiceIds,
  companyHasBeenFiltered,
  companyId,
  refetch,
}) {
  renderSendInvoiceModal({
    isLoading: true,
    emails: [],
    companyHasBeenFiltered,
    callback: () => {},
  });

  let financeEmails = [];

  if (companyId) {
    const companyDetail = await findCompanyDetail(companyId);
    financeEmails = companyDetail?.financeEmailSubscribers || [];
  }

  const action = async ({ emails }) => {
    renderSendInvoiceModal({
      isLoading: true,
      emails: [],
      companyHasBeenFiltered,
      callback: () => {},
    });
    try {
      await Promise.all(
        invoiceIds.map(async (invoiceId) => {
          await sendAndResolveInvoices({ invoiceId, emails });
        })
      );
      refetch();
      hideModal();
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);
      renderSendInvoiceModal({
        emails: financeEmails,
        companyHasBeenFiltered,
        callback: action,
      });
    }
  };

  renderSendInvoiceModal({
    emails: financeEmails,
    companyHasBeenFiltered,
    callback: action,
  });
}
