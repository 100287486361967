import React from "react";
import ReactDOM from "react-dom";
import GenericModalErrors from "./index";

export default function renderGenericModalErrors({ ...rest }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<GenericModalErrors {...rest} />, domTarget);
  }
}
