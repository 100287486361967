import apiClient from "./api-client";

export async function findAuthenticationLogs({
  searchTerm,
  page,
  pageSize = 20,
}) {
  const response = await apiClient().get(
    `/v1/back-office/authentication-logs?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`
  );
  return response.data;
}
