import { showSuccessMessage } from "../commons/show-success-message";

import * as cardRepository from "../../infrastructure/card-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function requestDuplicateCard({
  cardId,
  employeeId,
  replacementReason,
}) {
  try {
    const response = await cardRepository.requestDuplicateCard({
      cardId,
      employeeId,
      replacementReason,
    });
    showSuccessMessage("Card duplicate requested successfully");
    return response;
  } catch (e) {
    showErrorMessage(
      getServerErrorMessage(e, "An unexpected error has ocurred")
    );
    return false;
  }
}
