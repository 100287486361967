export const columnConfig = [
  { label: "Request date", id: "requestDate" },
  { label: "DNI/NIE", id: "employeeLegalId", searchable: true },
  { label: "Employee", id: "employeeName", searchable: true },
  { label: "Emp. Email", id: "employeeEmail", searchable: true },
  { label: "Recipient", id: "recipientName", searchable: true },
  { label: "Company", id: "companyName", searchable: true },
  { label: "Nursery", id: "nurseryName", searchable: true },
  { label: "Nursery state", id: "nurseryState" },
  { label: "Amount", id: "monthlyAmount" },
  { label: "Action", id: "action" },
];
