import React from "react";
import ReactDOM from "react-dom";

import HexModal from "../../app/components/modals/HexModal";
import RestoreVoidTransactionModal from "../../app/components/modals/RestoreVoidTransactionModal";
import {
  findEmployeeAvailableBagAmount,
  findEmployeeAvailablePursesAmount,
  findEmployeePayrollCycles,
  restoreEmployeeVoidTransaction,
} from "../../infrastructure/finance-repository";
import { findFields } from "../../infrastructure/public-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { hideModal } from "../commons/hide-modal";
import { displayFinancePage } from "./display-finance-page";
import { showSuccessMessage } from "../commons/show-success-message";
import { MonetaryAmount } from "../../app/domain/monetary-amount";

const renderRestoreVoidTransactionModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <RestoreVoidTransactionModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default async function restoreVoidTransaction({
  transactionId,
  intendedAmount,
  employeeId,
  category,
  searchValues,
  type,
}) {
  renderRestoreVoidTransactionModal({ isLoading: true });

  const {
    data: { available: bagAmount },
  } = await findEmployeeAvailableBagAmount({ employeeId, category });
  const purses = await findEmployeeAvailablePursesAmount({
    employeeId,
    category,
  });

  const allowance = MonetaryAmount.ofCurrency(purses.allowance);
  const allowanceNotExempt = MonetaryAmount.ofCurrency(
    purses["allowance-not-exempt"]
  );
  const flex = MonetaryAmount.ofCurrency(purses.flex);
  const flexNotExempt = MonetaryAmount.ofCurrency(purses["flex-not-exempt"]);
  const pursesAmount = allowance
    .add(allowanceNotExempt)
    .add(flex)
    .add(flexNotExempt);

  const {
    data: { payrollCycles },
  } = await findEmployeePayrollCycles({ employeeId });
  const { employeeBehaviours: behaviours } = await findFields();

  const action = () =>
    renderRestoreVoidTransactionModal({
      isLoading: false,
      intendedAmount,
      behaviours,
      bagAmount,
      pursesAmount,
      allowance,
      allowanceNotExempt,
      flex,
      flexNotExempt,
      payrollCycles,
      type,
      callback: async ({ values }) => {
        renderRestoreVoidTransactionModal({
          isLoading: true,
          intendedAmount,
          behaviours,
          bagAmount,
          pursesAmount,
          allowance,
          allowanceNotExempt,
          flex,
          flexNotExempt,
          payrollCycles,
          type,
        });

        const res = await restoreEmployeeVoidTransaction({
          employeeId,
          payload: {
            transactionId,
            payrollId: values.payrollId,
            behaviours: Object.keys(values.data)
              .map((key) => values.data[key])
              .filter(({ amount: { amountInCents } }) => amountInCents > 0),
            password: values.password,
          },
        });

        if (res.success) {
          hideModal();
          await displayFinancePage({ searchValues });
          showSuccessMessage("Transaction restored successfully");
        } else {
          action();
          showErrorMessage(res.error);
        }
      },
    });

  action();
}
