import React from "react";
import ReactDOM from "react-dom";
import TransactionDetail from "../transactions/components/TransactionDetail";

export async function displayTransactionDetailsPage(transaction, email, numMp) {
  renderTransactionDetailsPage({ transaction, email, numMp });
}

export function renderTransactionDetailsPage({ transaction, email, numMp }) {
  ReactDOM.render(
    <TransactionDetail transaction={transaction} email={email} numMp={numMp} />,
    document.getElementById("transaction-detail")
  );
}
