import { apiClient } from "../../../common/api";
import { restoreModality } from "../builders/restoreModality";

/**
 * Performs a POST request to create a modality resource.
 * @param {object} pathParams
 * @param {object} bodyParams
 * @returns
 */
export const create = async (
  { companyId },
  {
    insuranceCompany,
    broker,
    insurancePolicy,
    dateSignUp,
    modalityName,
    modalityFee,
    modalityCoverageDescription,
    allowRequestOutsideEngagement,
    requiresEmployeeSignature,
    allowExemptDomesticPartner,
    modalityBrochures,
    insideSignatureDocuments,
    outsideSignatureDocuments,
    insuranceBrand,
    firstEngagementPeriod,
    nextEngagementsPeriod,
    complements,
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    expiresAt,
    availableFrom,
    renewalPeriod,
    automaticRenewalEnabled,
    modalityCommissionRate,
  }
) => {
  const { data } = await apiClient().post(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/products`,
    {
      insuranceCompany,
      broker,
      insurancePolicy,
      dateSignUp,
      modalityName,
      modalityFee,
      modalityCoverageDescription,
      allowRequestOutsideEngagement: allowRequestOutsideEngagement || false,
      requiresEmployeeSignature: requiresEmployeeSignature || false,
      allowExemptDomesticPartner: allowExemptDomesticPartner || false,
      modalityBrochures,
      insideSignatureDocuments,
      outsideSignatureDocuments,
      insuranceBrand,
      firstEngagementPeriod,
      nextEngagementsPeriod,
      complements,
      isInstantaneous: isInstantaneous || false,
      allowDifferentModalitiesPerFamilyMember:
        allowDifferentModalitiesPerFamilyMember || false,
      expiresAt,
      availableFrom,
      renewalPeriod,
      automaticRenewalEnabled:
        automaticRenewalEnabled !== undefined ? automaticRenewalEnabled : true,
      modalityCommissionRate,
    }
  );
  return data.content ? restoreModality(data.content) : data.content;
};

/**
 * Performs a GET request that retrieves a list of modalities for a given company.
 * @param {object} pathParams
 * @returns {Promise<Modality[]>}
 */
export const list = async ({ companyId }) => {
  const { data } = await apiClient().get(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/products`
  );
  return data.content.map(restoreModality);
};

/**
 * Performs a PUT request to update a modality resource.
 * @param {object} pathParams
 * @param {object} bodyParams
 * @returns
 */
export const update = async (
  { companyId, modalityId },
  {
    insuranceCompany,
    broker,
    insurancePolicy,
    dateSignUp,
    modalityName,
    modalityFee,
    modalityCoverageDescription,
    allowRequestOutsideEngagement,
    requiresEmployeeSignature,
    allowExemptDomesticPartner,
    modalityBrochures,
    insideSignatureDocuments,
    outsideSignatureDocuments,
    insuranceBrand,
    firstEngagementPeriod,
    nextEngagementsPeriod,
    complements,
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    expiresAt,
    availableFrom,
    renewalPeriod,
    automaticRenewalEnabled,
    modalityCommissionRate,
  }
) => {
  const { data } = await apiClient().put(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/products/${modalityId}`,
    {
      insuranceCompany,
      broker,
      insurancePolicy,
      dateSignUp,
      modalityName,
      modalityFee,
      modalityCoverageDescription,
      allowRequestOutsideEngagement: allowRequestOutsideEngagement || false,
      requiresEmployeeSignature: requiresEmployeeSignature || false,
      allowExemptDomesticPartner: allowExemptDomesticPartner || false,
      modalityBrochures,
      insideSignatureDocuments,
      outsideSignatureDocuments,
      insuranceBrand,
      firstEngagementPeriod,
      nextEngagementsPeriod,
      complements,
      isInstantaneous: isInstantaneous || false,
      allowDifferentModalitiesPerFamilyMember:
        allowDifferentModalitiesPerFamilyMember || false,
      expiresAt,
      availableFrom,
      renewalPeriod,
      automaticRenewalEnabled:
        automaticRenewalEnabled !== undefined ? automaticRenewalEnabled : true,
      modalityCommissionRate,
    }
  );
  return data.content ? restoreModality(data.content) : data.content;
};

/**
 * Performs a POST request to create a modality resource for the next period.
 * @param {object} pathParams
 * @param {object} bodyParams
 * @returns
 */
export const createNextPeriod = async (
  { companyId, modalityId },
  {
    insuranceCompany,
    broker,
    insurancePolicy,
    dateSignUp,
    modalityName,
    modalityFee,
    modalityCoverageDescription,
    allowRequestOutsideEngagement,
    requiresEmployeeSignature,
    allowExemptDomesticPartner,
    modalityBrochures,
    insideSignatureDocuments,
    outsideSignatureDocuments,
    insuranceBrand,
    firstEngagementPeriod,
    nextEngagementsPeriod,
    complements,
    isInstantaneous,
    allowDifferentModalitiesPerFamilyMember,
    expiresAt,
    availableFrom,
    renewalPeriod,
    automaticRenewalEnabled,
    needExplicitApproval,
    modalityCommissionRate,
  }
) => {
  const { data } = await apiClient().post(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/products/${modalityId}/next-period`,
    {
      insuranceCompany,
      broker,
      insurancePolicy,
      dateSignUp,
      modalityName,
      modalityFee,
      modalityCoverageDescription,
      allowRequestOutsideEngagement: allowRequestOutsideEngagement || false,
      requiresEmployeeSignature: requiresEmployeeSignature || false,
      allowExemptDomesticPartner: allowExemptDomesticPartner || false,
      modalityBrochures,
      insideSignatureDocuments,
      outsideSignatureDocuments,
      insuranceBrand,
      firstEngagementPeriod,
      nextEngagementsPeriod,
      complements,
      isInstantaneous: isInstantaneous || false,
      allowDifferentModalitiesPerFamilyMember:
        allowDifferentModalitiesPerFamilyMember || false,
      expiresAt,
      availableFrom,
      renewalPeriod,
      automaticRenewalEnabled:
        automaticRenewalEnabled !== undefined ? automaticRenewalEnabled : true,
      needExplicitApproval,
      modalityCommissionRate,
    }
  );
  return data.content ? restoreModality(data.content) : data.content;
};

/**
 * Performs a DELETE request to archive/delete a modality resource.
 * @param {object} pathParams
 * @returns
 */
export const archive = async ({ companyId, modalityId }) => {
  const { data } = await apiClient().delete(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/products/${modalityId}`
  );
  return data;
};
