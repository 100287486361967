import { showSuccessMessage } from "../commons/show-success-message";

import * as cardRepository from "../../infrastructure/card-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { getServerErrorMessage } from "../ErrorHandling";

export async function deactivateCard({ cardId, employeeId }) {
  try {
    const card = await cardRepository.deactivateCard({ cardId, employeeId });
    showSuccessMessage("Card deactivated successfully");
    return card;
  } catch (e) {
    showErrorMessage(
      getServerErrorMessage(e, "An unexpected error has ocurred")
    );
    return false;
  }
}
