import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Grid, Input, InputAdornment } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import CobeeButton from "../CobeeButton";
import CobeeTextField from "../CobeeTextField";
import useStyles from "./styles";

export default function MassiveUploadFilter({
  handleDownload,
  handleSearch,
  handleFileChange,
}) {
  const { filterRow, fileInput, searchIcon } = useStyles();
  const fileInputElement = useRef(null);

  return (
    <Grid container className={filterRow} justify="flex-end" spacing={3}>
      <Grid item>
        <CobeeButton variant="unContained" onClick={handleDownload}>
          <GetAppIcon />
          Download spreadsheet template
        </CobeeButton>
      </Grid>
      <Grid item>
        <Input
          type="file"
          inputRef={fileInputElement}
          onChange={handleFileChange}
          className={fileInput}
        />
        <CobeeButton
          variant="secondary"
          onClick={() => fileInputElement.current.click()}
        >
          Edit from spreadsheet
        </CobeeButton>
      </Grid>
      <Grid item>
        <CobeeTextField
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

MassiveUploadFilter.propTypes = {
  handleDownload: PropTypes.func,
  handleSearch: PropTypes.func,
  handleFileChange: PropTypes.func,
};

MassiveUploadFilter.defaultProps = {
  handleDownload: () => {},
  handleSearch: () => {},
  handleFileChange: () => {},
};
