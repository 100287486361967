import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  findCountries,
  findPayrollCycles,
  findPayrollDocuments,
  deleteCompany,
  findCompanyDetail,
  findEmployeesPerModel,
  churnCompany,
} from "infrastructure/company-repository";
import CompanyDetails from "usecases/companies/components/CompanyDetails";
import { findHrEmails } from "infrastructure/employee-repository";
import { getCorporations } from "infrastructure/corporation-repository";
import { findModels } from "infrastructure/benefit-models-repository";
import { showSuccessMessage } from "usecases/commons/show-success-message";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { QueryProvider } from "v2/ui/providers/QueryProvider";
import { NavMenu } from "v2/ui/components/NavMenu";
import Loader from "../../components/loader";

function displayError(e) {
  let errorMessage = "Error. Company not removed!";
  if (e.response && e.response.data.code) {
    if (e.response.data.reason) {
      errorMessage = e.response.data.reason;
    }
    errorMessage = e.response.data.code;
  }
  showErrorMessage(errorMessage);
}

export default function CompanyDetailsPage() {
  const { companyId } = useParams();

  const { data, isLoading, isFetching, error: hasError } = useQuery(
    ["companyDetails", companyId],
    async () => {
      const [
        company,
        hrEmails,
        benefitModels,
        payrollDocuments,
        countries,
        payrollCycles,
        corporations,
      ] = await Promise.all([
        findCompanyDetail(companyId),
        findHrEmails(companyId),
        findModels(companyId),
        findPayrollDocuments(companyId),
        findCountries(),
        findPayrollCycles(companyId),
        getCorporations(),
      ]);
      return {
        company,
        hrEmails,
        benefitModels,
        payrollDocuments,
        countries,
        payrollCycles,
        corporations,
      };
    },
    {
      onError(error) {
        showErrorMessage(
          error?.response?.data?.message || "Error loading company details"
        );
      },
    }
  );

  if (hasError) {
    return "Error";
  }

  if (isLoading || isFetching) {
    return <Loader active />;
  }

  const { _company, ...props } = data;
  return (
    <CompanyDetails
      company={_company}
      {...props}
      onRemoveHandler={async () => {
        try {
          await deleteCompany(companyId);
          showSuccessMessage("Company removed successfully");
          window.location.href = "/companies";
        } catch (e) {
          displayError(e);
        }
      }}
      onChurn={async () => {
        try {
          await churnCompany(companyId);
          showSuccessMessage("Company churned successfully");
          window.location.href = "/companies";
        } catch (e) {
          displayError(e);
        }
      }}
    />
  );
}
