import { get } from "lodash";
import { showSuccessMessage } from "../commons/show-success-message";
import {
  createHealthInsuranceForNextPeriod,
  HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE,
} from "../../infrastructure/employee-repository";
import { displayEmployeeDetail } from "./display-employee-detail";
import { showErrorMessage } from "../commons/show-error-message";

export async function saveEmployeeHealthInsuranceForNextPeriod({
  employee,
  recipientId,
  healthInsuranceProductId,
  complementIds,
}) {
  if (!employee || !recipientId || !healthInsuranceProductId) {
    showErrorMessage("Missing required parameters", false);
    return;
  }

  try {
    await createHealthInsuranceForNextPeriod({
      companyId: employee.companyId,
      employeeId: employee.id,
      recipientId,
      healthInsuranceProductId,
      complementIds,
    });

    showSuccessMessage("Successfully!");
    displayEmployeeDetail(employee.email);
  } catch (error) {
    if (
      error?.response?.data?.code === HEALTH_INSURANCE_OVER_LIMITS_ERROR_CODE
    ) {
      showErrorMessage(
        "You may not request the Health Insurance due to fiscal limits"
      );
    } else {
      showErrorMessage(
        get(error, "response.data.message", "Unexpected error"),
        false
      );
    }
  }
}
