import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";

export default function RequestDuplicateCardModal({ card, callback }) {
  const { container, row, buttonRow } = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <Modal title="Request Duplicate card">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          <Typography variant="body2">
            <i>
              Are you sure you want to request a duplicate of this card? It will
              cancel permanently this card. NumMP: {card.cardId}
            </i>
          </Typography>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              Select a reason for the duplicate
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <select
              className="custom-select custom-select-sm"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            >
              <option value="">Select...</option>
              <option value="defective">Defective</option>
              <option value="expired">Expired</option>
              <option value="lost">Lost</option>
              <option value="robbery">Robbery</option>
              <option value="undelivered">Undelivered</option>
              <option value="wrong-information">Wrong information</option>
              <option value="other">Other</option>
            </select>
          </Grid>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            variant="danger"
            disabled={isLoading || reason === ""}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(reason);
              setIsLoading(false);
              await hideModal();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
