import React, { Component } from "react";
import TransferSection from "./TransferSection";
import WithdrawalSection from "./WithdrawalSection";
import TransferTransactionSection from "./TransferTransactionSection";
import DeliveriesSection from "./DeliveriesSection";
import ManualTransactionSection from "./ManualTransactionSection";
import InvoicesSection from "./InvoicesSection";
import DownloadMovement from "./DownloadMovements";

export default class FinancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "transfersTransaction",
    };
  }

  render() {
    const { currentTab } = this.state;
    const { behaviours, categories, searchValues } = this.props;
    return (
      <main className="container-fluid">
        <div className="card mb-3">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <button
                  className={
                    currentTab === "transfersTransaction"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() =>
                    this.setState({ currentTab: "transfersTransaction" })
                  }
                >
                  Transactions by employee
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "transfers" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.setState({ currentTab: "transfers" })}
                >
                  Transfers Money
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "withdrawals"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => this.setState({ currentTab: "withdrawals" })}
                >
                  Withdrawals
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "deliveries" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.setState({ currentTab: "deliveries" })}
                >
                  Deliveries
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "generateManualTransaction"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() =>
                    this.setState({ currentTab: "generateManualTransaction" })
                  }
                >
                  Manual Transactions
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "invoices" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.setState({ currentTab: "invoices" })}
                >
                  Invoices
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    currentTab === "download-movements"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() =>
                    this.setState({ currentTab: "download-movements" })
                  }
                >
                  Download Movements
                </button>
              </li>
            </ul>
          </div>
          {currentTab === "transfersTransaction" && (
            <TransferTransactionSection searchValues={searchValues} />
          )}
          {currentTab === "transfers" && <TransferSection />}
          {currentTab === "withdrawals" && (
            <WithdrawalSection
              behaviours={behaviours}
              categories={categories}
            />
          )}
          {currentTab === "deliveries" && <DeliveriesSection />}

          {currentTab === "generateManualTransaction" && (
            <ManualTransactionSection
              behaviours={behaviours}
              categories={categories}
            />
          )}

          {currentTab === "invoices" && <InvoicesSection />}
          {currentTab === "download-movements" && <DownloadMovement />}
        </div>
      </main>
    );
  }
}
