import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import useStyles from "./styles";
import CobeeButton from "../../CobeeButton";
import { hideModal } from "../../../../usecases/commons/hide-modal";

export default function DiscardInvoiceModal({ isLoading, action }) {
  const { container, row, buttonRow, icon } = useStyles();

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={row}>
        <Typography variant="h5">Discard invoices</Typography>
      </Grid>

      <Grid container item xs={12} justify="center">
        <ErrorIcon className={icon} />
      </Grid>

      <Grid item container xs={12} className={row} justify="center">
        <Typography variant="body1">
          Are you sure you want to discard the selected invoice/s?
        </Typography>
      </Grid>

      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton
          disabled={isLoading}
          variant="secondary"
          onClick={hideModal}
        >
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => {
            action();
          }}
        >
          Continue
        </CobeeButton>
      </Grid>
    </Grid>
  );
}
