import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import Table from "../../../../../app/components/Table";
import CobeeButton from "../../../../../app/components/CobeeButton";
import renderEducationRecurrentPaymentDetailsModal from "../../../render-education-recurrent-payment-details-modal";
import renderNurseryRecurrentPaymentDetailsModal from "../../../render-nursery-recurrent-payment-details-modal";
import renderLifeInsuranceRecurrentPaymentDetailsModal from "../../../render-life-insurance-recurrent-payment-details-modal";
import renderRentingRecurrentPaymentDetailsModal from "../../../render-renting-recurrent-payment-details-modal";
import { removeHealthInsurance } from "../../../remove-health-insurance";
import { removeNextPeriodHealthInsurance } from "../../../remove-next-period-health-insurance";
import renderHealthInsuranceRecurrentPaymentDetailsModal from "../../../render-health-insurance-recurrent-payment-details-modal";
import { removeNursery } from "../../../remove-nursery";
import { displayEmployeeHealthInsurance } from "../../../display-employee-health-insurance";
import renderRecurrentPaymentEffectiveDateModal from "../../../render-recurrent-payment-effective-date-modal";
import renderWellnessRecurrentPaymentDetailsModal from "../../../render-wellness-recurrent-payment-details-modal";
import useStyles from "./styles";
import { displayNewEmployeeNursery } from "../../../display-new-employee-nursery";
import renderCancelHealthInsuranceModal from "../../../render-cancel-health-insurance-modal";
import renderCancelNurseryModal from "../../../render-cancel-nursery-modal";

import {
  formatDateFromTimestamp,
  optionalMonetaryAmount,
} from "../../../../../infrastructure/format";
import { EMPLOYEE_ACTIVE_STATES } from "../../../../../app/domain/user-state";
import renderCancelEducationModal from "../../CancelEducationModal/renderEducationModal";
import renderCancelRentingModal from "../../CancelRentingModal/renderRentingModal";
import { cancelRecurrentPaymentById } from "../../../cancelRecurrentPaymentByCategory";
import renderModifyRecurrentPaymentDatesModal from "../ModifyRecurrentPaymentDatesModal/render";

export default function RecurrentPaymentTable({
  recurrentPayments,
  employee,
  category,
}) {
  const isInFinalState = (recurrentPayment) =>
    ["cancelled", "finished", "denied"].includes(recurrentPayment.state);

  const getColorFromState = (state) => {
    const colorPerState = {
      cancelled: "red",
      finished: "gray",
      pending: "orange",
      started: "blue",
      denied: "red",
    };
    return colorPerState[state];
  };

  const { buttonStyle } = useStyles();

  function actionsPerBenefit(recurrentPayment) {
    if (recurrentPayment.category === "education-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderEducationRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>

          <CobeeButton
            variant="secondary"
            disabled={
              isInFinalState(recurrentPayment) ||
              recurrentPayment.state === "pending"
            }
            onClick={() =>
              renderModifyRecurrentPaymentDatesModal({
                recurrentPayment,
              })
            }
          >
            Edit Dates
          </CobeeButton>

          <CobeeButton
            disabled={isInFinalState(recurrentPayment)}
            variant="danger"
            onClick={() =>
              renderCancelEducationModal({
                callback: ({ reason }) =>
                  cancelRecurrentPaymentById({
                    recurrentPaymentId: recurrentPayment.id,
                    reason,
                    employee,
                  }),
              })
            }
          >
            Cancel
          </CobeeButton>
        </>
      );
    }
    if (recurrentPayment.category === "nursery-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderNurseryRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>
          {!isInFinalState(recurrentPayment) && (
            <CobeeButton
              variant="danger"
              onClick={() =>
                renderCancelNurseryModal({
                  callback: ({ reason }) =>
                    removeNursery(
                      recurrentPayment.employee,
                      recurrentPayment.id,
                      reason,
                      ""
                    ),
                })
              }
            >
              Cancel
            </CobeeButton>
          )}
        </>
      );
    }
    if (recurrentPayment.category === "life-insurance-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderLifeInsuranceRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>
        </>
      );
    }
    if (recurrentPayment.category === "renting-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderRentingRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>

          <CobeeButton
            variant="secondary"
            disabled={isInFinalState(recurrentPayment)}
            onClick={() =>
              renderModifyRecurrentPaymentDatesModal({
                recurrentPayment,
              })
            }
          >
            Edit Dates
          </CobeeButton>

          <CobeeButton
            disabled={isInFinalState(recurrentPayment)}
            variant="danger"
            onClick={() =>
              renderCancelRentingModal({
                callback: ({ reason }) =>
                  cancelRecurrentPaymentById({
                    recurrentPaymentId: recurrentPayment.id,
                    reason,
                    employee,
                  }),
              })
            }
          >
            Cancel
          </CobeeButton>
        </>
      );
    }
    if (recurrentPayment.category === "health-insurance-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderHealthInsuranceRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>
          {!isInFinalState(recurrentPayment) && (
            <CobeeButton
              variant="secondary"
              onClick={() =>
                displayEmployeeHealthInsurance({
                  employee: recurrentPayment.employee,
                  healthInsurance: recurrentPayment,
                  cardId: "",
                })
              }
            >
              Replace
            </CobeeButton>
          )}
          {!isInFinalState(recurrentPayment) && (
            <CobeeButton
              variant="secondary"
              onClick={() =>
                renderModifyRecurrentPaymentDatesModal({
                  recurrentPayment,
                })
              }
            >
              Edit dates
            </CobeeButton>
          )}

          {!isInFinalState(recurrentPayment) && (
            <CobeeButton
              variant="danger"
              onClick={() => {
                if (recurrentPayment.state === "pending") {
                  removeNextPeriodHealthInsurance(
                    recurrentPayment.employee,
                    recurrentPayment.id
                  );
                } else {
                  renderCancelHealthInsuranceModal({
                    callback: ({ reason }) =>
                      removeHealthInsurance(
                        recurrentPayment.employee,
                        recurrentPayment.recipientId,
                        reason,
                        ""
                      ),
                  });
                }
              }}
            >
              Cancel
            </CobeeButton>
          )}
        </>
      );
    }
    if (recurrentPayment.category === "wellness-benefit") {
      return (
        <>
          <CobeeButton
            variant="secondary"
            onClick={() =>
              renderWellnessRecurrentPaymentDetailsModal({
                recurrentPayment,
              })
            }
          >
            Details
          </CobeeButton>
        </>
      );
    }
    return <></>;
  }

  return (
    <>
      {recurrentPayments.length === 0 && (
        <Typography variant="body1" align="center">
          No results found
        </Typography>
      )}
      {recurrentPayments.length > 0 && (
        <Table
          columns={[
            { label: "Requested At", id: "requestedAt" },
            { label: "State", id: "state" },
            { label: "Recipient", id: "recipient" },
            { label: "Concept", id: "concept" },
            { label: "First Execution", id: "firstExec" },
            { label: "Next Execution", id: "nextExec" },
            { label: "Last Execution", id: "lastExec" },
            { label: "Monthly Amount", id: "totalAmount" },
            { label: "Actions", id: "actions" },
          ]}
          data={recurrentPayments.map((recurrentPayment) => {
            const {
              state,
              requestedAtTimestamp,
              firstExecTimestamp,
              nextExecTimestamp,
              lastExecTimestamp,
              recipient,
              fares,
            } = recurrentPayment;
            return {
              ...recurrentPayment,
              requestedAt: formatDateFromTimestamp(requestedAtTimestamp),
              state: (
                <Typography style={{ color: getColorFromState(state) }}>
                  {state}
                </Typography>
              ),
              firstExec: formatDateFromTimestamp(firstExecTimestamp),
              nextExec: formatDateFromTimestamp(nextExecTimestamp),
              lastExec: formatDateFromTimestamp(lastExecTimestamp),
              recipient: `${recipient?.name} ${recipient?.surname}`,
              totalAmount: optionalMonetaryAmount(fares.totalAmount),
              actions: actionsPerBenefit(recurrentPayment),
            };
          })}
        />
      )}
      <Grid className={buttonStyle}>
        {EMPLOYEE_ACTIVE_STATES.includes(employee.state) &&
          category === "health-insurance-benefit" && (
            <>
              <CobeeButton
                variant="secondary"
                onClick={() =>
                  displayEmployeeHealthInsurance({
                    employee,
                    cardId: "",
                  })
                }
              >
                Add Health Insurance
              </CobeeButton>

              <CobeeButton
                variant="secondary"
                onClick={() =>
                  displayEmployeeHealthInsurance({
                    employee,
                    cardId: "",
                    isNextPeriod: true,
                  })
                }
              >
                Add Next Period Health Insurance
              </CobeeButton>
            </>
          )}
        {EMPLOYEE_ACTIVE_STATES.includes(employee.state) &&
          category === "nursery-benefit" && (
            <CobeeButton
              variant="primary"
              onClick={() => displayNewEmployeeNursery(employee, "")}
            >
              Add Nursery
            </CobeeButton>
          )}
      </Grid>
    </>
  );
}

RecurrentPaymentTable.propTypes = {
  recurrentPayments: PropTypes.array,
  employee: PropTypes.object,
  category: PropTypes.string,
};

RecurrentPaymentTable.defaultProps = {
  recurrentPayments: [],
  employee: {},
  category: "",
};
