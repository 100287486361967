import React from "react";
import ReactDOM from "react-dom";
import ErrorLogDetailModal from "./components/ErrorLogTable/ErrorLogDetailModal";

export default function renderErrorLogDetailsModal({ errorLog }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<ErrorLogDetailModal errorLog={errorLog} />, domTarget);
  }
}
