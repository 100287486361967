import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function TabPanel({ children, value, index, ...rest }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number,
  index: PropTypes.number,
};

TabPanel.defaultProps = {
  value: 0,
  index: 0,
};
