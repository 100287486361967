import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useInitialData } from "./hooks";
import CobeeButton from "../../../../../app/components/CobeeButton";
import displayActionConfirmationModal from "../../../../../usecases/commons/display-action-confirmation-modal";
import removeCompanyFromCorporation from "../../../../../usecases/corporations/removeCompanyFromCoporation";
import { showErrorMessage } from "../../../../../usecases/commons/show-error-message";
import { showSuccessMessage } from "../../../../../usecases/commons/show-success-message";
import { hideModal } from "../../../../../usecases/commons/hide-modal";

export default function Detail() {
  const history = useHistory();
  const { corporationId } = useParams();
  const {
    corporation,
    companies,
    refetchCompanies,
    hrAdmins,
    refetchHRAdmins,
  } = useInitialData(corporationId);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{corporation.alias}</h5>
        <hr />
        <div className="form group row mb-5">
          <label className="col-sm-2 col-form-label">ID</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              readOnly
              defaultValue={corporation.id}
            />
          </div>

          <label className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              defaultValue={corporation.alias}
              readOnly
            />
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Companies</h5>
            <div className="table-responsive">
              <table className="table table-hover table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Legal</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies?.map((company) => (
                    <tr key={company.id}>
                      <td className="align-middle" width="500">
                        <div>{company.companyName}</div>
                        <div className="font-weight-lighter">{company.id}</div>
                      </td>
                      <td className="align-middle">
                        <div>{company.companyLegalName}</div>
                      </td>
                      <td>
                        <CobeeButton
                          variant="danger"
                          onClick={() =>
                            displayActionConfirmationModal({
                              title: "Remove a company from corporation",
                              message:
                                "Are you sure you want to remove the company from the corporation?",
                              isWarning: true,
                              cantBeReverted: true,
                              callback: async () => {
                                try {
                                  await removeCompanyFromCorporation({
                                    companyId: company.id,
                                    corporationId: corporation.id,
                                  });
                                  await refetchCompanies();
                                  await refetchHRAdmins();
                                  hideModal();
                                  showSuccessMessage(
                                    "Company removed successfully"
                                  );
                                } catch (e) {
                                  showErrorMessage(
                                    "An error has occurred while attempting to remove the company"
                                  );
                                }
                              },
                              actionName: "Confirm",
                              disableClose: false,
                            })
                          }
                        >
                          Remove
                        </CobeeButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <hr />

            <h5 className="card-title">Hr Admin</h5>

            <table className="table table-hover table-sm">
              <thead className="thead-dark">
                <tr>
                  <th width="500">Name</th>
                  <th>E-mail</th>
                  <th>Companies</th>
                </tr>
              </thead>
              <tbody>
                {hrAdmins?.map((admin) => (
                  <tr key={admin.id}>
                    <td>{admin.name}</td>
                    <td>{admin.email}</td>
                    <td>
                      {admin.companies?.map((company) => (
                        <span
                          key={company.id}
                          className="badge rounded-pill bg-primary text-light mr-2 mb-2"
                        >
                          {company.companyName}
                        </span>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr className="mt-5_" />
        <div className="row float-right">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => history.push("/corporations")}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
