import { updateHealthInsuranceModality } from "../../../infrastructure/health-insurance-repository";
import { HealthInsuranceDetails } from "../displays/health-insurance-details";
import { showSuccessMessage } from "../../commons/show-success-message";
import { showErrorMessage } from "../../commons/show-error-message";

export async function updateModality({ companyId, modality }) {
  const res = await updateHealthInsuranceModality({ companyId, modality });
  if (res.status === 200) {
    showSuccessMessage("Modality updates");
    HealthInsuranceDetails({ company: res.data });
  } else {
    showErrorMessage("Error updating modality");
  }
}
