import moment from "moment";
import {
  dataCompanyMovements,
  headersCompanyMovements,
} from "./companyMovementsConfig";
import adaptAmountToCSVNumber from "../adaptAmountToCsvNumber";

const headersConfig = {
  companyMovement: headersCompanyMovements,
  movements: [
    "Nombre",
    "Nombre Legal",
    "CIF",
    "Pecunpay Account",
    "Id",
    "Tarjeta",
    "Empleado",
    "Dni Empleado",
    "Razón social empleador",
    "Centro de coste",
    "Numero Autorizacion",
    "Numero Autorizacion Relacionado",
    "Fecha Operacion",
    "Nómina/Cierre",
    "Categoria",
    "Pagado con flex",
    "Pagado con acumulado",
    "Pagado con subvencion",
    "Total transaccion",
    "Concepto",
    "Tipo de operacion",
    "Saldo inicial",
    "Saldo final",
  ],
  hrEmails: ["Email", "Empresa", "type"],
  ledgerTransactions: [
    "transactionId",
    "cardId",
    "employeeId",
    "authorizationId",
    "companyId",
    "merchantId",
    "merchantName",
    "concept",
    "intendedAmount",
    "flexDebitAmount",
    "flexCreditAmount",
    "flexNotExemptDebitAmount",
    "flexNotExemptCreditAmount",
    "allowanceDebitAmount",
    "allowanceCreditAmount",
    "allowanceNotExemptDebitAmount",
    "allowanceNotExemptCreditAmount",
    "benefitBagAmount",
    "originalAmount",
    "category",
    "iso2CountryCode",
    "localIsoTime",
    "timestamp",
    "payroll",
    "merchantCategoryCode",
    "state",
    "reason",
    "type",
    "referenceId",
    "brand",
  ],
};

const dataConfig = (data) => {
  const {
    companyName = "",
    companyLegalName = "",
    legalId = "",
    accountId = "",
    movementId = "",
    cardId = "",
    employeeId = "",
    employeeLegalId = "",
    payrollCompany = "",
    costCenter = "",
    authorizationId = "",
    referenceAuthorizationId = "",
    timestamp,
    associatedPayroll = "",
    category = "",
    amountFromAccount = "",
    amountFromAccumulated = "",
    amountFromCard = "",
    totalAmount = "",
    concept = "",
    description = "",
    type = "",
    initialAccountBalance = "",
    finalAccountBalance = "",
    hrEmail = "",
    transactionId = "",
    id = "",
    companyId = "",
    merchantId = "",
    merchantName = "",
    intendedAmount = { amountInCents: "" },
    flexDebitAmount = { amountInCents: "" },
    flexCreditAmount = { amountInCents: "" },
    flexNotExemptDebitAmount = { amountInCents: "" },
    flexNotExemptCreditAmount = { amountInCents: "" },
    allowanceDebitAmount = { amountInCents: "" },
    allowanceCreditAmount = { amountInCents: "" },
    allowanceNotExemptDebitAmount = { amountInCents: "" },
    allowanceNotExemptCreditAmount = { amountInCents: "" },
    benefitBagAmount = { amountInCents: "" },
    originalAmount = { amountInCents: "" },
    iso2CountryCode = "",
    localIsoTime = "",
    merchantCategoryCode = "",
    status = "",
    reason = "",
    referenceId = "",
    brand = "",
    pricingType = "",
    merchantInfo = {
      merchantCategoryCode: "",
      merchantName: "",
      merchantId: "",
      country: "",
      terminalId: "",
      brand: "",
      merchantCategory: "",
    },
    processorDetails = {
      transactionId: "",
      authorizationId: "",
      processorName: "",
    },
    payrollCycle = {
      startTimestamp: "",
      endTimestamp: "",
      reported: "",
      payrollMonth: "",
      fiscalYear: "",
    },
    userTransactionIsoTime = "",
    localDateTime = 0,
  } = data;
  const realTimestamp = timestamp || localDateTime;
  let payroll = "";
  if (!payrollCycle) {
    payroll = `None`;
  } else if (payrollCycle?.fiscalYear) {
    payroll = `${payrollCycle.fiscalYear}-${payrollCycle.payrollMonth}`;
  } else if (
    payrollCycle?.startTimestamp < 0 ||
    payrollCycle?.endTimestamp < 0
  ) {
    payroll = "Void Payroll";
  } else {
    payroll = moment
      .unix(payrollCycle.startTimestamp)
      .add(1, "month")
      .format("YYYY-MM");
  }
  return {
    companyMovement: dataCompanyMovements(data),
    movements: [
      companyName,
      companyLegalName,
      legalId,
      accountId,
      movementId,
      cardId,
      employeeId,
      employeeLegalId,
      payrollCompany,
      costCenter,
      authorizationId,
      referenceAuthorizationId,
      timestamp ? moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss") : "",
      associatedPayroll,
      category,
      amountFromAccount,
      amountFromAccumulated,
      amountFromCard,
      totalAmount,
      concept,
      type,
      initialAccountBalance,
      finalAccountBalance,
    ],
    hrEmails: [hrEmail, companyName, pricingType],
    ledgerTransactions: [
      id || processorDetails.transactionId || transactionId,
      cardId,
      employeeId,
      processorDetails?.authorizationId || authorizationId,
      companyId,
      merchantInfo?.merchantId || merchantId,
      merchantInfo?.merchantName || merchantName,
      description || concept,
      adaptAmountToCSVNumber({ amount: intendedAmount?.amountInCents }),
      adaptAmountToCSVNumber({ amount: flexDebitAmount?.amountInCents }),
      adaptAmountToCSVNumber({ amount: flexCreditAmount?.amountInCents }),
      adaptAmountToCSVNumber({
        amount: flexNotExemptDebitAmount?.amountInCents,
      }),
      adaptAmountToCSVNumber({
        amount: flexNotExemptCreditAmount?.amountInCents,
      }),
      adaptAmountToCSVNumber({ amount: allowanceDebitAmount?.amountInCents }),
      adaptAmountToCSVNumber({ amount: allowanceCreditAmount?.amountInCents }),
      adaptAmountToCSVNumber({
        amount: allowanceNotExemptDebitAmount?.amountInCents,
      }),
      adaptAmountToCSVNumber({
        amount: allowanceNotExemptCreditAmount?.amountInCents,
      }),
      adaptAmountToCSVNumber({
        amount: benefitBagAmount?.amountInCents,
      }),
      adaptAmountToCSVNumber({ amount: originalAmount?.amountInCents }),
      category,
      merchantInfo?.country || iso2CountryCode,
      userTransactionIsoTime || localIsoTime,
      realTimestamp,
      payroll,
      merchantInfo?.merchantCategoryCode || merchantCategoryCode,
      status,
      reason,
      type,
      referenceId,
      merchantInfo?.brand || brand,
    ],
  };
};

export const adaptDataToCsv = ({ type, data }) => {
  const rows = data.map((item) => dataConfig(item)[type]);
  return { headers: headersConfig[type], rows };
};
