import Dinero from "dinero.js";

export class Money {
  constructor({ amountInCents = 0, currency = "EUR" }) {
    this.amountInCents = amountInCents;
    this.currency = currency;
  }

  static zero(currency) {
    return new Money({
      amountInCents: 0,
      currency,
    });
  }

  add(money) {
    return Money.fromDineroInstance(
      this.toDineroInstance().add(money.toDineroInstance())
    );
  }

  equal(money) {
    return this.toDineroInstance().equalsTo(money.toDineroInstance());
  }

  greaterThan(money) {
    return this.toDineroInstance().greaterThan(money.toDineroInstance());
  }

  greaterThanOrEqual(money) {
    return this.toDineroInstance().greaterThanOrEqual(money.toDineroInstance());
  }

  toUnitary() {
    return this.toDineroInstance().toUnit();
  }

  parsed() {
    return this.toDineroInstance().toFormat("$0,0.00");
  }

  toDineroInstance() {
    return Dinero({
      amount: this.amountInCents,
      currency: this.currency ? this.currency.toUpperCase() : undefined,
      precision: 2,
    });
  }

  static fromDineroInstance(instance) {
    return new Money({
      amountInCents: instance.getAmount(),
      currency: instance.getCurrency(),
    });
  }
}
