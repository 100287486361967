import PropTypes from "prop-types";
import * as React from "react";
import moment from "moment";
import { TextList, MUI } from "@cobee/cobee-ui";
import { ReadOnlyTextField } from "v2/ui/components/ReadOnlyTextField";
import { ReadOnlyCheckbox } from "v2/ui/components/ReadOnlyCheckbox";
import { Modality } from "../../../../../../../domain/contexts/healthInsurance/entities/Modality";
import { FlexBox } from "../../../../../../components/FlexBox";

function literalEngagementPeriod(period) {
  return `${moment(period.from).format("DD MMMM")} until ${moment(
    period.to
  ).format("DD MMMM")}`;
}

export const PeriodDetails = ({ data }) => {
  return (
    <>
      <MUI.Grid container spacing={2}>
        <MUI.Grid item xs={6}>
          <ReadOnlyTextField label="Modality name" value={data.name} />
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <ReadOnlyTextField label="Modality Id" value={data.id} />
        </MUI.Grid>{" "}
        <MUI.Grid item xs={6}>
          <ReadOnlyTextField label="Price" value={data.fee.parsed()} />
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <ReadOnlyTextField
            label="Commission"
            value={data.modalityCommissionRate}
          />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField
            label="Insurance Company"
            value={data.insuranceCompany}
          />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField label="Broker" value={data.broker} />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField label="Policy" value={data.insurancePolicy} />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField
            type="date"
            label="Sign-up date"
            value={moment(data.signUpDate).format("DD/MM/YYYY")}
          />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField
            type="date"
            label="Available from"
            value={moment(data.availableFrom).format("DD/MM/YYYY")}
          />
        </MUI.Grid>
        <MUI.Grid item xs={4}>
          <ReadOnlyTextField
            type="date"
            label="Expiration date"
            value={moment(data.expiresAt).format("DD/MM/YYYY")}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <ReadOnlyTextField
            type="text"
            label="Renewal period"
            value={`${moment(data.renewalPeriod.from).format(
              "DD MMMM"
            )} until ${moment(data.renewalPeriod.to).format("DD MMMM")}`}
          />
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <MUI.Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ paddingBottom: 1 }}
          >
            First engagement periods
          </MUI.Typography>
          <TextList
            items={data.firstEngagementPeriod.map((period, index) => (
              <span key={`${period.from}-${period.to}-${index + 0}`}>
                {literalEngagementPeriod(period)}
              </span>
            ))}
          />
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <MUI.Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ paddingBottom: 1 }}
          >
            Next engagement periods
          </MUI.Typography>
          <TextList
            items={data.nextEngagementPeriods.map((period, index) => (
              <span key={`${period.from}-${period.to}-${index + 0}`}>
                {literalEngagementPeriod(period)}
              </span>
            ))}
          />
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <FlexBox direction="column">
            <ReadOnlyCheckbox
              label="Allow exempt to domestic partners"
              checked={data.allowExemptDomesticPartner}
            />
            <ReadOnlyCheckbox
              label="Allow sign-ins outside engagement periods"
              checked={data.allowRequestOutsideEngagement}
            />
            <ReadOnlyCheckbox
              label="Requires employee signature"
              checked={data.requiresEmployeeSignature}
            />
          </FlexBox>
        </MUI.Grid>
        <MUI.Grid item xs={6}>
          <FlexBox direction="column">
            <ReadOnlyCheckbox
              label="Is instantaneous"
              checked={data.isInstantaneous}
            />
            <ReadOnlyCheckbox
              label="Allow different modalities for family members"
              checked={data.allowDifferentModalitiesPerFamilyMember}
            />
            <ReadOnlyCheckbox
              label="Renew automatically"
              checked={data.automaticRenewalEnabled}
            />
          </FlexBox>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ paddingBottom: 1 }}
          >
            Coverage
          </MUI.Typography>
          {data.coverageDescription.split("\n").map((paragraph) => (
            <p key={paragraph}>{paragraph}</p>
          ))}
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ paddingBottom: 1 }}
          >
            Brochures
          </MUI.Typography>
          <TextList
            items={data.brochures.map(({ title, url }, index) => (
              <span key={`${title}-${index + 0}`}>
                {title}:&nbsp;&nbsp;
                <a href={url} title={title} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </span>
            ))}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ paddingBottom: 1 }}
          >
            Complements
          </MUI.Typography>
          <TextList
            items={data.complements.map((id, index) => (
              <span key={`${id}-${index + 0}`}>{id}</span>
            ))}
          />
        </MUI.Grid>
      </MUI.Grid>
    </>
  );
};

PeriodDetails.propTypes = {
  data: PropTypes.instanceOf(Modality).isRequired,
};
