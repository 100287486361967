import React, { Component } from "react";
import { hideModal } from "../../commons/hide-modal";

export default class NurseryModalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      legalName: "",
      legalId: "",
      address: {
        streetName: "",
        streetNumber: "",
        details: "",
        postalCode: "",
        province: "",
        locality: "",
      },
      phoneNumber: "",
      state: "",
      type: null,
      contactPerson: "",
      contactEmail: "",
      bankAccountNumber: "",
      commission: null,
      comments: "",
      billingAddress: "",
      billingEmail: "",
    };
  }

  componentDidMount() {
    const { nursery } = this.props;
    this.setState({
      name: nursery.name || "",
      legalName: nursery.legalName,
      legalId: nursery.legalId,
      address: nursery.address || "",
      phoneNumber: nursery.phoneNumber || "",
      state: nursery.state || "",
      type: nursery.type,
      contactPerson: nursery.contactPerson || "",
      contactEmail: nursery.contactEmail || "",
      bankAccountNumber: nursery.bankAccountNumber || "",
      commission:
        typeof nursery.commission === "number" ? nursery.commission : null,
      comments: nursery.comments || "",
      billingAddress: nursery.billingAddress || "",
      billingEmail: nursery.billingEmail || "",
    });
  }

  updateNursery = () => {
    const {
      name,
      legalName,
      legalId,
      address,
      phoneNumber,
      type,
      contactPerson,
      contactEmail,
      bankAccountNumber,
      commission,
      comments,
      billingAddress,
      billingEmail,
    } = this.state;
    this.props.handleUpdateState({
      ...this.props.nursery,
      name,
      legalName,
      legalId,
      address,
      phoneNumber,
      type,
      contactPerson,
      contactEmail,
      bankAccountNumber,
      commission,
      comments,
      billingAddress,
      billingEmail,
    });
  };

  render() {
    const {
      name,
      address,
      phoneNumber,
      state,
      type,
      contactPerson,
      contactEmail,
      bankAccountNumber,
      commission,
      comments,
      legalName,
      legalId,
      billingAddress,
      billingEmail,
    } = this.state;
    return (
      <div className="modal-body">
        <div className="modal-header">
          <h5 className="modal-title">{`Nursery: ${this.props.nursery.nurseryId}`}</h5>
          <span className="badge badge-pill badge-info">{state}</span>
        </div>

        <div className="container-fluid">
          <div className="form-group row mt-4">
            <label className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row mt-4">
            <label className="col-sm-2 col-form-label">Legal name</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={legalName}
                onChange={(e) => this.setState({ legalName: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row mt-4">
            <label className="col-sm-2 col-form-label">Legal id</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={legalId}
                onChange={(e) => this.setState({ legalId: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Email</label>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={contactEmail}
                onChange={(e) =>
                  this.setState({ contactEmail: e.target.value })
                }
              />
            </div>

            <label className="col-sm-1 col-form-label">Type</label>
            <div className="col-sm-3">
              <select
                className="custom-select"
                value={type}
                onChange={(e) => this.setState({ type: e.target.value })}
              >
                <option selected value={null}>
                  Select option
                </option>
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Phone</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={phoneNumber}
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              />
            </div>

            <label className="col-sm-2 col-form-label">Contact</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={contactPerson}
                onChange={(e) =>
                  this.setState({ contactPerson: e.target.value })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Street</label>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={address.streetName}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      streetName: e.target.value,
                    },
                  })
                }
              />
            </div>

            <label className="col-sm-1 col-form-label">Number</label>
            <div className="col-sm-1">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={address.streetNumber}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      streetNumber: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="col-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder="Pl."
                value={address.details}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      details: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Postal Code </label>
            <div className="col-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={address.postalCode}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      postalCode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <label className="col-sm-1 col-form-label">Province</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={address.province}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      province: e.target.value,
                    },
                  })
                }
              />
            </div>

            <label className="col-sm-1 col-form-label">Locality</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={address.locality}
                onChange={(e) =>
                  this.setState({
                    address: {
                      ...address,
                      locality: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row mt-4">
            <label className="col-sm-2 col-form-label">Billing Address</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={billingAddress}
                onChange={(e) =>
                  this.setState({ billingAddress: e.target.value })
                }
              />
            </div>
          </div>
          <div className="form-group row mt-4">
            <label className="col-sm-2 col-form-label">Billing Email</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={billingEmail}
                onChange={(e) =>
                  this.setState({ billingEmail: e.target.value })
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Bank Account</label>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={bankAccountNumber}
                onChange={(e) =>
                  this.setState({ bankAccountNumber: e.target.value })
                }
              />
            </div>

            <label className="col-sm-2 col-form-label">Commission</label>
            <div className="col-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder="-"
                value={commission}
                onChange={(e) => this.setState({ commission: e.target.value })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Comments:</label>
            <textarea
              className="col-sm-10 form-control"
              rows="3"
              value={comments}
              onChange={(e) => this.setState({ comments: e.target.value })}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => hideModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              this.updateNursery();
              hideModal();
            }}
          >
            Save changes
          </button>
        </div>
      </div>
    );
  }
}
