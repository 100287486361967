import React from "react";
import ReactDOM from "react-dom";
import CardPurchaseRefundModal from "./index";

export default function renderCardPurchaseRefundModal({ ...rest }) {
  const domTarget = document.getElementById("modal-container");
  if (domTarget) {
    ReactDOM.render(<CardPurchaseRefundModal {...rest} />, domTarget);
  }
}
