import { updateBenefitBagConfig } from "../../infrastructure/company-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { showSuccessMessage } from "../commons/show-success-message";
import showBenefitBagDetail from "./show-benefit-bag-details";

export default async function editBagBenefits({
  companyId,
  benefitPlanId,
  benefitBagConfigId,
  benefits,
  allowedBenefits,
  alias,
}) {
  const payload = {
    allowedFor: benefits.map((item) => ({
      benefit: item,
      config: {},
    })),
  };
  try {
    await updateBenefitBagConfig({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
      payload,
    });
    await showBenefitBagDetail({
      companyId,
      benefitPlanId,
      benefitBagConfigId,
      allowedBenefits,
      alias,
    });
    showSuccessMessage("Benefits edited successfully");
  } catch (error) {
    showErrorMessage("There was an error editing the benefits");
  }
}
