import ReactDOM from "react-dom";
import React from "react";
import { MonetaryAmount } from "app/domain/monetary-amount";
import HexModal from "../../app/components/modals/HexModal";
import VoidTransactionModal from "../../app/components/modals/VoidTransactionModal";
import { voidEmployeeTransaction } from "../../infrastructure/employee-repository";
import { showErrorMessage } from "../commons/show-error-message";
import { hideModal } from "../commons/hide-modal";
import { displayFinancePage } from "./display-finance-page";

const renderVoidTransactionModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <VoidTransactionModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default function voidTransactions({
  intendedAmount,
  transactionIds,
  employeeId,
  searchValues,
}) {
  renderVoidTransactionModal({
    isLoading: false,
    intendedAmount: intendedAmount || MonetaryAmount.ZERO,
    values: {
      password: "",
      amount: 0,
      displayApp: true,
    },
    callback: async ({ values }) => {
      renderVoidTransactionModal({
        isLoading: true,
        intendedAmount,
        values,
        callback: () => {},
      });
      try {
        await Promise.all(
          transactionIds.map(
            async (transactionId) =>
              await voidEmployeeTransaction(employeeId, {
                transactionId,
                password: values.password,
                displayApp: !values.displayApp,
              })
          )
        );
      } catch (err) {
        showErrorMessage(`An error has ocurred while voiding a transaction`);
      } finally {
        hideModal();
        displayFinancePage({ searchValues });
      }
    },
  });
}
