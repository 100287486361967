export function generateCompanyDetailLink({ companyId }) {
  return `/companies/${companyId}`;
}

export function generateBenefitModelDetailLink({ companyId, benefitModelId }) {
  return `/companies/${companyId}/benefit-models/${benefitModelId}`;
}

export function generateEditBenefitModelLink({ companyId, benefitModelId }) {
  return `/companies/${companyId}/benefit-models/${benefitModelId}/edit`;
}

export function generateBenefitModelEmployeesLink({
  companyId,
  benefitModelId,
}) {
  return `/companies/${companyId}/benefit-models/${benefitModelId}/employees`;
}

export function generateCreateCompanyLink() {
  return `https://cobee.retool.com/apps/enterprise/create-company`;
}
