import React, { useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import CobeeTextField from "../../../../app/components/CobeeTextField";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";

export default function EditCardIdModal({ card, callback }) {
  const { container, row, buttonRow, rowDescription } = useStyles();
  const [values, setValues] = useState({
    cardId: card.cardId,
  });
  const [isLoading, setIsLoading] = useState(false);

  const formIsReady = () => {
    return Boolean(values.cardId);
  };

  return (
    <Modal title="Edit Card ID">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={rowDescription}>
          <Typography variant="body2">
            Changing a Card ID can have multiple consequences and should only be
            done if neccesary
          </Typography>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Grid item container xs={2} className={row}>
            <Typography variant="body2">CardId:</Typography>
          </Grid>
          <Grid item container xs={10} className={row}>
            <CobeeTextField
              type="text"
              disabled={isLoading}
              minWidth="100%"
              defaultValue={values.cardId}
              onChange={(e) =>
                setValues({
                  ...values,
                  cardId: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton
            disabled={isLoading}
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </CobeeButton>
          <CobeeButton
            variant="danger"
            disabled={isLoading || !formIsReady()}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await callback(values);
              setIsLoading(false);
              await hideModal();
            }}
          >
            Continue
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
