import { MonetaryAmount } from "app/domain/monetary-amount";
import { findEmployeePayrollCycles } from "infrastructure/finance-repository";
import { sendCompensation } from "infrastructure/transaction-repository";
import { hideModal } from "usecases/commons/hide-modal";

import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";

export async function compensateTransactions({ form, trx }) {
  try {
    const {
      data: { payrollCycles },
    } = await findEmployeePayrollCycles({
      employeeId: trx?.employeeId,
    });

    const payroll = payrollCycles.find(
      ({ endTimestamp }) => endTimestamp === -1
    );

    const payload = {
      password: form?.password,
      transactionId: trx?.id,
      payrollId: payroll?.id,
      dateTimestamp: new Date().getTime() / 1000,
      movementConcept:
        form?.concept ||
        `COMPENSACION ${trx?.description} ${trx?.processorDetails?.transactionId}`,
      transactionConcept: trx?.description,
      amount: MonetaryAmount.fromUnitary(form?.amount),
      behaviour: "flex",
    };

    await sendCompensation({ payload });
    showSuccessMessage("Successful compensation");
  } catch (e) {
    showErrorMessage("Error sending compensation");
  } finally {
    hideModal();
  }
}
