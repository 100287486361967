import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { primary, secondary } from "../../../../assets/themeColors";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 300,
    maxWidth: 960,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(2),
  },
  header: {
    justifyContent: "space-between",
  },
  buttonRow: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  select: {
    width: "100%",
  },
  checkboxLabel: {
    cursor: "pointer",
  },
  behaviourRow: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)}px 0`,
  },
  divider: {
    height: 1,
    backgroundColor: primary,
    margin: `${theme.spacing(2)}px 0`,
  },
  currencyContainer: {
    paddingLeft: theme.spacing(1),
  },
}));

export const CustomCheckbox = withStyles({
  root: {
    color: secondary,
    "&$checked": {
      color: secondary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default useStyles;
