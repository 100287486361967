import { apiClient } from "../../../common/api";
import { restoreComplement } from "../builders/restoreComplement";

/**
 * Performs a GET request that retrieves a list of complements for a given company.
 * @param {object} pathParams
 * @returns {Promise<Complement[]>}
 */
export const list = async ({ companyId }) => {
  const { data } = await apiClient().get(
    `/v1/back-office/companies/${companyId}/health-insurance-benefit/complements`
  );
  return data.content.map(restoreComplement);
};
