import { showSuccessMessage } from "../commons/show-success-message";
import { showErrorMessage } from "../commons/show-error-message";
import { deleteRecurrentPaymentById } from "../../infrastructure/recurrent-payments-repository";
import { hideModal } from "../commons/hide-modal";
import { displayEmployeeDetail } from "./display-employee-detail";

export async function cancelRecurrentPaymentById({
  recurrentPaymentId,
  reason,
  employee,
}) {
  try {
    await deleteRecurrentPaymentById({ recurrentPaymentId, reason });
    showSuccessMessage("Successfully removed");
    hideModal();
    displayEmployeeDetail(employee.email);
  } catch (e) {
    showErrorMessage("There was an error removing the recurrent payment");
  }
}
