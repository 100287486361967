import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  ButtonGroup,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import qs from "qs";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import EmptyDataForTableMessage from "../../../../app/components/EmptyDataForTableMessage";
import findInvoices from "../../find-invoices";
import InvoicesTable from "../InvoicesTable";
import CustomSelect from "../../../../app/components/Select";
import findAllCompanies from "../../../companies/find-all-companies";
import CobeeButton from "../../../../app/components/CobeeButton";
import sendInvoices from "../../send-invoices/send-invoices";
import discardInvoices from "../../discard-invoices/discard-invoices";

const statusDictionary = {
  pending: "PENDING",
  resolved: "RESOLVED",
  discarded: "DISCARDED",
};

export default function InvoicesSection() {
  const {
    activeButton,
    loaderContainer,
    title,
    filterBar,
    actionButtons,
  } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("pending");
  const [data, setData] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({
    name: "Select a company...",
  });

  const { register, getValues, watch, setValue } = useForm();

  const someAreSelected = watch("selected")?.length > 0;

  useEffect(() => {
    register("selected");
    setValue("selected", []);
  }, [register, setValue]);

  useEffect(() => {
    const loadCompanies = async () => {
      const fetchedCompanies = await findAllCompanies();
      setCompanies(fetchedCompanies);
    };
    loadCompanies();
  }, [setCompanies]);

  const fetchAction = useCallback(
    async ({ currentTab, company }) => {
      setIsLoading(true);
      let query = { status: [statusDictionary[currentTab]], type: "FUND_LOAD" };
      if (company?.id) {
        query = {
          ...query,
          companyId: company.id,
        };
      }
      const invoices = await findInvoices({
        query: qs.stringify(query, { encode: false }),
      });
      setData(invoices);
      setIsLoading(false);
    },
    [setIsLoading, setData]
  );

  useEffect(() => {
    fetchAction({ currentTab: selectedTab, company: selectedCompany });
  }, [selectedTab, fetchAction, selectedCompany]);

  const clearFilters = () => {
    if (selectedCompany?.id) {
      setSelectedCompany({ name: "Select a company..." });
    }
    setValue("selected", []);
  };

  const contentWrapper = useCallback(() => {
    if (isLoading) {
      return (
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className={loaderContainer}
        >
          <CircularProgress />
        </Grid>
      );
    }

    if (data.length === 0) {
      return (
        <EmptyDataForTableMessage
          message={`Could not find ${selectedTab} invoices`}
        />
      );
    }

    return (
      <InvoicesTable
        statusFilter={selectedTab}
        data={data}
        defaultSelected={watch("selected")}
        setSelectedItems={(value) => setValue("selected", value)}
        refetch={() => {
          fetchAction({ currentTab: selectedTab, company: selectedCompany });
          setValue("selected", []);
        }}
      />
    );
  }, [
    isLoading,
    data,
    selectedTab,
    watch,
    loaderContainer,
    setValue,
    fetchAction,
    selectedCompany,
  ]);

  return (
    <main className="container-fluid pb-3">
      <Grid container xs={12} item>
        <Grid item xs={12} container className={title}>
          <Typography variant="h4">Invoices</Typography>
        </Grid>
        <Grid item xs={12} container className={filterBar}>
          <Grid item xs={6} container alignItems="center">
            <Typography variant="body1">Filter by company:</Typography>
            <CustomSelect
              value={selectedCompany}
              onChange={(value) => {
                setSelectedCompany(value);
                setValue("selected", []);
              }}
              options={companies.map(({ companyName, id, ...rest }) => ({
                name: companyName,
                companyName,
                value: id,
                id,
                ...rest,
              }))}
            />
          </Grid>
          <Grid item xs={6} container justify="flex-end" alignItems="center">
            <CobeeButton onClick={clearFilters} disabled={!selectedCompany.id}>
              Clear filters
            </CobeeButton>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={6} container alignItems="flex-end">
            <ButtonGroup variant="contained" disableElevation>
              <Button
                className={selectedTab === "pending" ? activeButton : ""}
                onClick={() => {
                  setSelectedTab("pending");
                  setValue("selected", []);
                }}
              >
                Pending
              </Button>
              <Button
                className={selectedTab === "resolved" ? activeButton : ""}
                onClick={() => {
                  setSelectedTab("resolved");
                  setValue("selected", []);
                }}
              >
                Resolved
              </Button>
              <Button
                className={selectedTab === "discarded" ? activeButton : ""}
                onClick={() => {
                  setSelectedTab("discarded");
                  setValue("selected", []);
                }}
              >
                Discarded
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justify="flex-end"
            alignItems="center"
            spacing={1}
            className={actionButtons}
          >
            <Grid item>
              <CobeeButton
                disabled={!someAreSelected}
                onClick={() =>
                  sendInvoices({
                    invoiceIds: getValues("selected"),
                    companyHasBeenFiltered: selectedCompany?.id !== undefined,
                    companyId: selectedCompany?.id,
                    refetch: () => {
                      fetchAction({
                        currentTab: selectedTab,
                        company: selectedCompany,
                      });
                      setValue("selected", []);
                    },
                  })
                }
              >
                Send
              </CobeeButton>
            </Grid>
            {selectedTab !== "discarded" && (
              <Grid item>
                <CobeeButton
                  disabled={!someAreSelected}
                  onClick={() =>
                    discardInvoices({
                      invoiceIds: getValues("selected"),
                      refetch: () => {
                        fetchAction({
                          currentTab: selectedTab,
                          company: selectedCompany,
                        });
                        setValue("selected", []);
                      },
                    })
                  }
                >
                  Discard
                </CobeeButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          {contentWrapper()}
        </Grid>
      </Grid>
    </main>
  );
}
