import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { ArrowForwardIos, ArrowBack, Warning } from "@material-ui/icons";

import TabPanel from "app/components/TabPanel";
import {
  findBrokers,
  findCompanyDetail,
} from "infrastructure/company-repository";
import { findVendors } from "infrastructure/vendors-repository";
import { showSuccessMessage } from "usecases/commons/show-success-message";
import { showErrorMessage } from "usecases/commons/show-error-message";
import { restorePensionPlanProduct } from "infrastructure/adapters/adaptPensionPlan";
import addPensionProduct from "usecases/companies/pension-plan-benefit/addPensionProduct";
import updatePensionProduct from "usecases/companies/pension-plan-benefit/updatePensionProduct";
import CobeeButton from "app/components/CobeeButton";
import useStyles from "./styles";
import PensionPlanProductForm from "./components/PensionPlanProductForm";

export default function PensionPlanConfigPage() {
  const [currentTab, setCurrentTab] = useState(0);
  const [productTabs, setProductTabs] = useState([
    {
      index: 0,
      label: "New Product name",
    },
  ]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const {
    root,
    mainContainer,
    firstRow,
    backContainer,
    title,
    productSubtitle,
    backText,
    arrowBackStyle,
    arrowForwardStyle,
    tabsContainer,
    pendingConfigBox,
    pendingConfigText,
    warningIcon,
    uploadCensusButton,
  } = useStyles();
  const { company: companyId } = queryString.parse(window.location.search);

  const censusURL = `/upload-census-file?censusType=pension-plan-benefit&company=${companyId}`;

  const loadInitialPensionPlanProducts = useCallback(async () => {
    try {
      const [
        companyDetails,
        vendorsDetails,
        brokersDetails,
      ] = await Promise.all([
        findCompanyDetail(companyId),
        findVendors({
          benefit: "pension-plan",
        }),
        findBrokers(),
      ]);

      const {
        pensionPlanBenefit: { products: initialProducts },
      } = companyDetails;

      const {
        data: { content: pensionPlanVendors },
      } = vendorsDetails;

      const brokersResponse = brokersDetails;

      const restoredProducts = initialProducts.map((item) =>
        restorePensionPlanProduct({ product: item })
      );

      setProducts(restoredProducts);
      setVendors(pensionPlanVendors);
      setBrokers(
        brokersResponse.map((item) => ({
          name: item,
          id: item,
        }))
      );
      if (initialProducts.length > 0) {
        const productsNames = initialProducts.map(
          ({ languages: { en } }, index) => {
            return {
              index,
              label: en.name,
            };
          }
        );
        setProductTabs(productsNames);
      }
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the load of products information"
      );
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (isLoading) {
      loadInitialPensionPlanProducts({ companyId });
    }
  }, [companyId, loadInitialPensionPlanProducts, isLoading]);

  const handleChange = (_event, value) => {
    setCurrentTab(value);
  };

  const updateTabName = ({ name, index }) => {
    const aux = [...productTabs];
    aux[index] = { index, label: name };
    setProductTabs(aux);
  };

  const onSubmitProductData = async ({ data }) => {
    try {
      setIsSaving(true);
      await addPensionProduct({ companyId, data });
      showSuccessMessage("Product created successfully");
      setIsLoading(true);
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the product creation"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const onUpdateProductData = async ({ data }) => {
    try {
      setIsSaving(true);
      await updatePensionProduct({ companyId, data });
      showSuccessMessage("Product saved successfully");
      setIsLoading(true);
    } catch (error) {
      showErrorMessage(
        error?.response?.data?.message ||
          "There was an error during the product edition"
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <main className={`container-fluid ${mainContainer}`}>
      {!isLoading ? (
        <>
          <Grid container alignItems="center" className={firstRow}>
            <Grid
              container
              className={backContainer}
              alignItems="center"
              onClick={() => window.location.replace(`/companies/${companyId}`)}
            >
              <ArrowBack className={arrowBackStyle} color="primary" />
              <Typography variant="body2" className={backText}>
                Back
              </Typography>
            </Grid>

            <Typography variant="h6" className={title}>
              Cobee
            </Typography>

            <ArrowForwardIos fontSize="small" className={arrowForwardStyle} />

            <Typography variant="h6" className={title}>
              Pension plan
            </Typography>

            {products.length === 0 ? (
              <Box p={2} className={pendingConfigBox}>
                <Grid container alignItems="center">
                  <Warning fontSize="small" className={warningIcon} />
                  <Typography className={pendingConfigText}>
                    Pending configuration
                  </Typography>
                </Grid>
              </Box>
            ) : (
              <Box className={uploadCensusButton}>
                <CobeeButton
                  variant="secondary"
                  onClick={() => window.location.replace(censusURL)}
                >
                  Upload census
                </CobeeButton>
              </Box>
            )}
          </Grid>
          <Typography variant="subtitle1" className={productSubtitle}>
            PRODUCT CONFIGURATION
          </Typography>
          <Typography variant="body2">
            You can create products associated with the insurance companies you
            want.
          </Typography>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={tabsContainer}
          >
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              className={root}
            >
              {productTabs.map(({ label, index }) => (
                <Tab key={index} label={label} />
              ))}
            </Tabs>
          </Grid>
          {productTabs.map(({ index }) => (
            <TabPanel key={index} value={currentTab} index={index}>
              <PensionPlanProductForm
                product={products[index] || {}}
                brokers={brokers}
                vendors={vendors}
                isSaving={isSaving}
                onChangeTabName={(name) => updateTabName({ name, index })}
                handleSubmitData={onSubmitProductData}
                handleUpdateData={onUpdateProductData}
              />
            </TabPanel>
          ))}
        </>
      ) : (
        <Grid container justify="center">
          <CircularProgress size={32} />
        </Grid>
      )}
    </main>
  );
}
