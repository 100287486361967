import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { MonetaryAmount } from "../../../../../app/domain/monetary-amount";
import useStyles from "./styles";

const recurrenceMap = {
  0: "Punctual",
  1: "Monthly",
  12: "Annual",
};

export default function ShowConfigSection({
  amount,
  periodicity,
  accumulable,
  deliverUnusedExemptToPurses,
  renewAtPeriodEnd,
}) {
  const { configurationItem, configurationLabel } = useStyles();
  return (
    <Grid container>
      <Grid container direction="column" item className={configurationItem}>
        <Typography variant="body2" className={configurationLabel}>
          Amount
        </Typography>
        <Typography>
          {MonetaryAmount.ofCurrency(amount).prettifyMoney({})}
        </Typography>
      </Grid>
      <Grid container direction="column" item className={configurationItem}>
        <Typography variant="body2" className={configurationLabel}>
          Periodicity
        </Typography>
        <Typography>{recurrenceMap[periodicity] || "N/A"}</Typography>
      </Grid>
      <Grid container direction="column" item className={configurationItem}>
        <Typography variant="body2" className={configurationLabel}>
          Accumulate balance
        </Typography>
        <Typography>{accumulable ? "Activated" : "Deactivated"}</Typography>
      </Grid>
      <Grid container direction="column" item className={configurationItem}>
        <Typography variant="body2" className={configurationLabel}>
          Deliver unspent on card on period end
        </Typography>
        <Typography>
          {deliverUnusedExemptToPurses ? "Activated" : "Deactivated"}
        </Typography>
      </Grid>
      <Grid container direction="column" item className={configurationItem}>
        <Typography variant="body2" className={configurationLabel}>
          Renew at period end
        </Typography>
        <Typography>
          {renewAtPeriodEnd ? "Activated" : "Deactivated"}
        </Typography>
      </Grid>
    </Grid>
  );
}

ShowConfigSection.propTypes = {
  amount: PropTypes.object,
  periodicity: PropTypes.number,
  accumulable: PropTypes.bool,
  deliverUnusedExemptToPurses: PropTypes.bool,
  renewAtPeriodEnd: PropTypes.bool,
};

ShowConfigSection.defaultProps = {
  amount: MonetaryAmount.ZERO,
  periodicity: 12,
  accumulable: false,
  deliverUnusedExemptToPurses: false,
  renewAtPeriodEnd: false,
};
