import { MonetaryAmount } from "../../app/domain/monetary-amount";

export const sumAmountsOfType = ({ type = "amount", movements }) =>
  movements.reduce((result, current) => {
    return result.add(current[type]);
  }, MonetaryAmount.ZERO);

export const getEntriesOfType = ({ type, entries }) =>
  entries.reduce((results, current) => {
    return [...results, ...current[type]];
  }, []);

export const getCreditsEntriesAmounts = (entries) => {
  const entriesOfType = getEntriesOfType({
    type: "credits",
    entries,
  });
  return sumAmountsOfType({ movements: entriesOfType });
};

export const getDebitsEntriesAmounts = (entries) => {
  const entriesOfType = getEntriesOfType({ type: "debits", entries });
  return sumAmountsOfType({ movements: entriesOfType });
};
