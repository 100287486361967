export const columnConfig = [
  { label: "Request date", id: "requestDate" },
  { label: "Payment date", id: "paymentDate" },
  { label: "Recurrent payment status", id: "state" },
  { label: "Payment State", id: "status" },
  { label: "Nursery", id: "nurseryName", searchable: true },
  { label: "Nursery legal name", id: "nurseryLegalName", searchable: true },
  { label: "Nursery legal id", id: "nurseryId", searchable: true },
  { label: "Nursery type", id: "nurseryType", searchable: true },
  { label: "Nursery email", id: "nurseryEmail", searchable: true },
  { label: "Commission", id: "commission" },
  { label: "Employee", id: "employeeName", searchable: true },
  { label: "Employee email", id: "email", searchable: true },
  { label: "Company", id: "companyName", searchable: true },
  { label: "Child", id: "childName", searchable: true },
  { label: "Amount", id: "monthlyAmount" },
];
